import React, {useState, useEffect} from 'react';
import '../../App.css';
import styled from "styled-components";
import DatePicker, {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ru from 'date-fns/locale/ru';
import moment from 'moment';
import 'moment/locale/ru';
import { sr } from '../../utils';
import {Select, Switch} from "antd";
import _ from "underscore";

import {deliveryCycleInfoMessage} from "../../actions/ModalActions";
import {useDispatch, useSelector} from 'react-redux';
import NoPermissions from "../../components/NoPermissions";
import {getPermissionsAction} from "../../reducers/toolkit/mainAppReducer"


const {Option} = Select;
registerLocale('ru', ru);
const Reports = () => {
    const [date2, setDate2] = useState(new Date())
    const [startDate1, setStartDate1] = useState(null)
    const [endDate1, setEndDate1] = useState(null)
    const [startDate2, setStartDate2] = useState(null)
    const [endDate2, setEndDate2] = useState(null)
    const [startDate3, setStartDate3] = useState(null)
    const [endDate3, setEndDate3] = useState(null)
    const [startDate4, setStartDate4] = useState(null)
    const [endDate4, setEndDate4] = useState(null)
    const [startDate5, setStartDate5] = useState(null)
    const [periodId, setPerioders] = useState([])
    const [accounts, setAccounts] = useState([])
    const [selectedAccount, setSelectedAccount] = useState(null)
    const [completedOrders, setCompletedOrders] = useState(true)
    const [companyNumber1, setCompanyNumber1] = useState('')
    const [companyNumber2, setCompanyNumber2] = useState('')
    const [companyNumber3, setCompanyNumber3] = useState('')
    const [contracts, setContracts] = useState([])
    const [selectedContract, setSelectedContract] = useState(null)
    const [selectedList, setSelectedList] = useState('')
    const [selectedCity, setSelectedCity] = useState('')
    const [centers, setCenter] = useState([])

    const [cities,setCities] = useState([])

    const dispatch = useDispatch();
    const getPermissionsSuccess = useSelector(store => store.appMain.getPermissionsSuccess);
    const permissions = useSelector(store => store.appMain.permissions);

    useEffect(() => {
        if (!getPermissionsSuccess) {
            dispatch(getPermissionsAction())
        }
    }, [])

    const firstRequest = async () => {
        await sr('Reports', 'OffsettingPeriod', {}).then(res => {
            setAccounts(res.data.accounts)
        })
        await sr('WalletsController', 'GetContracts', {}).then(res2 => {
            setContracts(res2.data)
        })
        await sr('SettingsService', 'GetOperatorPeriods', {}).then(res3 => {
            setPerioders(res3.data.periods)
        })
        await sr('SettingsService', 'GetOperatorPermissions', {}).then(result => {
            setCenter(result.data.centers)
            
        })
        
    } 


    useEffect(() => {
        if (accounts.length) {
            setSelectedAccount(accounts[0].id)
        }
    }, [accounts])
    
    useEffect(() => {
        if (checkRole('screen_reports')) {
            
            firstRequest().then()
        }

        getCenters()
    }, [permissions])

    useEffect(() => {
    }, [cities])

    const getCenters = () => {
        let arr = []
        permissions.centers.forEach(center => {
            if (!_.some(arr, item => item.cityId === center.cityId)) {
                arr.push({cityId: center.cityId, cityName: center.cityName})
            }
        })
        setCities(arr)
    }

            
        



    const GetActAcceptance = (type) => {
        if (startDate1 === null || endDate1 === null || date2 === null) {
            dispatch(deliveryCycleInfoMessage('need_to_fill_dates'))
        } else if (selectedAccount === null) {
            dispatch(deliveryCycleInfoMessage('no_rests_for_report'))
        } else {
            OffsettingPeriod(startDate1, endDate1, date2, selectedAccount, completedOrders, true, type)
        }
    }

    const OffsettingPeriod = (dateFrom, dateTo, actDate, account, orderStatus, isAct, type) => {
        if (isAct) {
            sr('Reports', 'ActAcceptance', {
                dateFrom: moment(dateFrom).format('YYYY-MM-DD'),
                dateTo: moment(dateTo).format('YYYY-MM-DD'),
                actDate: moment(actDate).format('YYYY-MM-DD'),
                account: account,
                type: type,
                ORDER_STATUS_SUCCESS_OF_DELIVERY: +orderStatus
            }).then(res => {
                if (res.result === 0) {
                    dispatch(deliveryCycleInfoMessage('error_from_server', res.resultCode + '\n' + res.data.message))
                } else {
                    window.open("https://menuforme.online" + res.data.FilePath);
                }
            })
        } else {

        }
    }
  
    const SendExcelOrdersSumByPersonsByDates = () => {
        if (startDate2 === null || endDate2 === null) {
            dispatch(deliveryCycleInfoMessage('need_to_fill_dates'))
        } else if (companyNumber1 === '') {
            dispatch(deliveryCycleInfoMessage('no_company_id'))
        } else {
            let login = !localStorage.getItem('login') ? "" : localStorage.getItem('login')
            excelOrdersSumByPersonsByDates(startDate2, endDate2, companyNumber1, login)
        }
    }

    const excelOrdersSumByPersonsByDates = (dateFrom, dateTo, companyId, mailTo) => {
        if (!mailTo) {
            dispatch(deliveryCycleInfoMessage('not_auth'))
        } else {
            sr('Reports', 'excelOrdersSumByPersonsByDates', {
                dateFrom: moment(dateFrom).format('YYYY-MM-DD'),
                dateTo: moment(dateTo).format('YYYY-MM-DD'),
                companyId: companyId,
                mailTo: mailTo
            }).then(res => {
                if (res.result === 0) {
                    dispatch(deliveryCycleInfoMessage('error_from_server', res.resultCode + '\n' + res.data.message))
                } else {
                    if (res.data.openPage) {
                        window.location.assign(res.data.link);
                    } else {
                        dispatch(deliveryCycleInfoMessage('report_sended', mailTo))
                    }
                }
            })
        }
    }

    const SendExcelOrdersSumByPersonsByDatesRest = () => {
        if (startDate3 === null || endDate3 === null) {
            dispatch(deliveryCycleInfoMessage('need_to_fill_dates'))
        } else if (companyNumber2 === '') {
            dispatch(deliveryCycleInfoMessage('no_company_id'))
        } else {
            let login = !localStorage.getItem('login') ? "" : localStorage.getItem('login')
            excelOrdersSumByPersonsByDatesRest(startDate3, endDate3, companyNumber2, login)
        }
    }

    const excelOrdersSumByPersonsByDatesRest = (dateFrom, dateTo, companyId, mailTo) => {
        if (!mailTo) {
            dispatch(deliveryCycleInfoMessage('not_auth'))
        } else {
            sr('Reports', 'excelOrdersSumByPersonsByDatesRest', {
                dateFrom: moment(dateFrom).format('YYYY-MM-DD'),
                dateTo: moment(dateTo).format('YYYY-MM-DD'),
                companyId: companyId,
                mailTo: mailTo
            }).then(res => {
                if (res.result === 0) {
                    dispatch(deliveryCycleInfoMessage('error_from_server', res.resultCode + '\n' + res.data.message))
                } else {
                    if (res.data.openPage) {
                        window.location.assign(res.data.link);
                    } else {
                        dispatch(deliveryCycleInfoMessage('report_sended', mailTo))
                    }
                }
            })//
        }
    }

    const SendExcelOrdersSumByContractByDates = () => {
        if (startDate4 === null || endDate4 === null) {
            dispatch(deliveryCycleInfoMessage('need_to_fill_dates'))
        } else if (selectedContract === '') {
            dispatch(deliveryCycleInfoMessage('no_contract'))
        } else {
            excelOrdersSumByContractByDates(startDate4, endDate4, selectedContract)
        }
    }
    

    const excelOrdersSumByContractByDates = (dateFrom, dateTo, contractId) => {
        sr('Reports', 'excelOrdersSumByContractByDates', {
            dateFrom: moment(dateFrom).format('YYYY-MM-DD'),
            dateTo: moment(dateTo).format('YYYY-MM-DD'),
            contractId: contractId,
        }).then(res => {
            if (res.result === 0) {
                dispatch(deliveryCycleInfoMessage('error_from_server', res.resultCode + '\n' + res.data.message))
            } else {
                window.location.assign(res.data.link)
            }
        })
    }
    ///////////
    const GetFinancialReportAsExcel = (date, periodId, cityId) => {
        //console.log(moment(date).format('YYYY-MM-DD'), periodId, cityId, '123')
        sr('Reports', 'GetFinancialReportAsExcel', {
            date: moment(date).format('YYYY-MM-DD'),
            periodId,
            cityId,
        }).then(res => {
            
            if (res.result === 0) {
                dispatch(deliveryCycleInfoMessage('error_from_server', res.resultCode + '\n' + res.data.message))
            } else {
                window.location.assign(res.data.link)
            }
        })
    }
    
   
    //////////
    
    const checkRole = (param) => {
        if (permissions.staff) {
            if (permissions.staff.role === 'admin' && !permissions.permissions.length) {
                return true
            } else {
                return permissions.permissions.includes(param)
            }
        } else {
            return false
        }
    }

    return (
        <ReportWrapper className="reports">
            {checkRole('screen_reports') ? (
                <Container>
                    <Row style={{marginBottom: 20}}>
                        <Select
                            style={{width: 300, marginRight: 10}}
                            value={selectedAccount}
                            onChange={(value) => setSelectedAccount(value)}
                        >
                            {accounts.map(v => {
                                return (
                                    <Option key={v.id} value={v.id}>
                                        {v.name}
                                    </Option>
                                )
                            })}
                        </Select>
                        <SimpleText>с:</SimpleText>
                        <DatePicker
                            className="reports_date"
                            selected={startDate1}
                            startDate={startDate1}
                            endDate={endDate1}
                            selectsStart
                            onChange={date => setStartDate1(date)}
                            dateFormat="d MMMM yyyy"
                            locale={ru}
                        />
                        <SimpleText>по:</SimpleText>
                        <DatePicker
                            className="reports_date"
                            selected={endDate1}
                            startDate={startDate1}
                            endDate={endDate1}
                            minDate={startDate1}
                            selectsEnd
                            onChange={date => setEndDate1(date)}
                            dateFormat="d MMMM yyyy"
                            locale={ru}
                        />
                        <Switch
                            checked={completedOrders}
                            onChange={() => setCompletedOrders(prev => !prev)}
                            style={{marginRight: 10}}
                        />
                        <SimpleText>Только выполненные (доставленные) заказы</SimpleText>
                    </Row>
                    <Row style={{marginBottom: 20}}>
                        <SimpleText>Акт от</SimpleText>
                        <DatePicker
                            dateFormat="d MMMM yyyy"
                            selected={date2}
                            onChange={date => setDate2(date)}
                            locale={ru}
                        />
                    </Row>
                    <Row style={{marginBottom: 20}}>
                        <Button onClick={() => GetActAcceptance(1)}>
                            Акт выполненных работ
                        </Button>
                        <Button onClick={() => GetActAcceptance(2)}>
                            Отчёт
                        </Button>
                    </Row>
                    <hr/>
                    {checkRole('report_address') && (
                        <>
                            <Row style={{marginBottom: 20, marginTop: 20}}>
                                <SimpleText>с:</SimpleText>
                                <DatePicker
                                    className="reports_date"
                                    selected={startDate2}
                                    startDate={startDate2}
                                    endDate={endDate2}
                                    selectsStart
                                    onChange={date => setStartDate2(date)}
                                    dateFormat="d MMMM yyyy"
                                    locale={ru}
                                />
                                <SimpleText>по:</SimpleText>
                                <DatePicker
                                    className="reports_date"
                                    selected={endDate2}
                                    startDate={startDate2}
                                    endDate={endDate2}
                                    minDate={startDate2}
                                    selectsEnd
                                    onChange={date => setEndDate2(date)}
                                    dateFormat="d MMMM yyyy"
                                    locale={ru}
                                />
                                <Input
                                    className="reports_date"
                                    value={companyNumber1}
                                    onChange={e => setCompanyNumber1(e.target.value)}
                                    placeholder="компания №"
                                />
                                <Button onClick={SendExcelOrdersSumByPersonsByDates}>
                                    Отчёт по адресу
                                </Button>
                            </Row>
                            <hr/>
                        </>
                    )}
                    {checkRole('report_kitchen') && (
                        <>
                            <Row style={{marginBottom: 20, marginTop: 20}}>
                                <SimpleText>с:</SimpleText>
                                <DatePicker
                                    className="reports_date"
                                    selected={startDate3}
                                    startDate={startDate3}
                                    endDate={endDate3}
                                    selectsStart
                                    onChange={date => setStartDate3(date)}
                                    dateFormat="d MMMM yyyy"
                                    locale={ru}
                                />
                                <SimpleText>по:</SimpleText>
                                <DatePicker
                                    className="reports_date"
                                    selected={endDate3}
                                    startDate={startDate3}
                                    endDate={endDate3}
                                    minDate={startDate3}
                                    selectsEnd
                                    onChange={date => setEndDate3(date)}
                                    // onChange={date => setEndDate3(date)}
                                    dateFormat="d MMMM yyyy"
                                    locale={ru}
                                />
                                <Input
                                    className="reports_date"
                                    value={companyNumber2}
                                    onChange={e => setCompanyNumber2(e.target.value)}
                                    placeholder="компания №"
                                />
                                <Button onClick={SendExcelOrdersSumByPersonsByDatesRest}>
                                    Заказы компании с разбивкой по кухням
                                </Button>
                            </Row>
                            <hr/>
                        </>
                    )}
                    {checkRole('report_counterparty') && (
                        <>
                            <Row style={{marginBottom: 20, marginTop: 20}}>
                                <SimpleText>с:</SimpleText>
                                <DatePicker
                                    className="reports_date"
                                    selected={startDate4}
                                    startDate={startDate4}
                                    endDate={endDate4}
                                    selectsStart
                                    onChange={date => setStartDate4(date)}
                                    dateFormat="d MMMM yyyy"
                                    locale={ru}
                                />
                                <SimpleText>по:</SimpleText>
                                <DatePicker
                                    className="reports_date"
                                    selected={endDate4}
                                    startDate={startDate4}
                                    endDate={endDate4}
                                    minDate={startDate4}
                                    selectsEnd
                                    onChange={date => setEndDate4(date)}
                                    dateFormat="d MMMM yyyy"
                                    locale={ru}
                                />
                                <Select
                                    style={{width: 500, marginRight: 10}}
                                    value={selectedContract}
                                    onChange={(value) => setSelectedContract(value)}
                                >
                                    {contracts.map(v => {
                                        return (
                                            <Option key={v.id} value={v.id}>
                                                {`${v.contractNum} ${moment(v.contractDate).format('YYYY-MM-DD')} ${v.counterpartiesName} ${v.cityName}`}
                                            </Option>
                                        )
                                    })}
                                </Select>
                                <Button onClick={SendExcelOrdersSumByContractByDates}>
                                    Отчёт по контрагенту
                                </Button>
                            </Row>
                            <hr/>
                        </>
                    )}
                    {checkRole('report_financial') && (
                        <>
                            <Row style={{marginBottom: 20, marginTop: 20, marginLeft:20}}>
                                <DatePicker
                                    className="reports_date"
                                    selected={startDate5}
                                    value ={startDate5}
                                    onChange={value => setStartDate5(value)}
                                    dateFormat="d MMMM yyyy"
                                    locale={ru}
                                    required={true}
                                />
                                 <Select    
                                    size="large"
                                    value={selectedList}
                                    onChange={(value) => setSelectedList(value)}
                                    style={{width: 150, marginRight: 10}}
                                 >
                                {periodId.map(per => {
                                        return (
                                <Option key={per.id} value={per.id}>
                                          {`${per.timeFrom.slice(0, -3) } - ${per.timeTo.slice(0, -3) }`}
                                </Option>
                                                )
                                  })}
                                </Select>
                                     <>
                                <Select
                                    size="large"
                                    value={selectedCity}
                                    onChange={(value) => setSelectedCity(value)}
                                    style={{width: 150,  marginRight: 10}}
                                >       
                                    {cities.map((item)=> {
                                return (
                                <Option key={item.cityId}>
                                        {`${item.cityId} - ${item.cityName}`}
                                </Option>
                                       )
                                })}
                                </Select>
                                    </>
                                <Button
                                  style = {{margin:5}}
                                  onClick={() => GetFinancialReportAsExcel(startDate5, selectedList, selectedCity)}
                                  >
                                  Отчет по финансам
                                </Button>
                            </Row>
                            <hr/>
                        </>
                    )}
                    <a href="https://convertio.co/ru/pdf-doc/" target="_blank">
                        Сайт для конвертации файлов pdf в любой формат
                    </a>
                </Container>
            ) : (
                getPermissionsSuccess && (
                    <NoPermissions />
                )
            )}
        </ReportWrapper>
    )
}
                

const ReportWrapper = styled.div`
    width: 100%;
    min-height: calc(100vh - 60px);
    text-align: left;
    font-family: "Roboto", sans-serif;
`;

const Container = styled.div`
    width: 1280px;
    min-height: 100%;
    margin: 0 auto;
    margin-top: 20px;
    padding: 20px;
    background-color: #fff;
`;

const Row = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
`;

const SimpleText = styled.p`
    font-size: 16px;
    line-height: 20px;
    margin-right: 10px !important;
`;

const Input = styled.input`
    text-align: left !important;
    padding: 0 10px !important;
`;

const Button = styled.button`
    &:not(last-child) {
        margin-right: 10px;
    }
`;

export default Reports;
