import React, {useEffect, useRef, useState} from "react";
import {modalGrid} from "./AddWaybillModal"
import styled from "styled-components"
import {useDispatch, useSelector} from "react-redux"
import {getProductListByRecipient} from "../../../../actions/WarehouseActions"
import DepotInput from "../../DepotInput"
import AutocompleteInput from "../../AutocompleteInput"


const AddListItem = ({addProduct, products, selectedRecipientModal}) => {

    const dispatch = useDispatch()

    const debounceProductList = useSelector(store => store.warehouse.debounceProductList.productList)

    const [productName, setProductName] = useState('')
    const [productCount, setProductCount] = useState('')
    const [productId, setProductId] = useState('')
    const [selects, setSelects] = useState([])

    const productInput = useRef()
    const countInput = useRef()

    useEffect(() => {
        if (productName) {
            setSelects([])
            dispatch(getProductListByRecipient({text: productName, recipientId: selectedRecipientModal.id}))
        }
    }, [productName])

    useEffect(() => {
        if (debounceProductList.length) {
            setSelects(debounceProductList.map(el => el.name))
        }
    }, [debounceProductList])

    const setProductMeta = (name, id) => {
        setProductName(name)
        setProductId(debounceProductList[id]?.id)
    }

    const addHandle = () => {
        if(productName && products && productCount && productId) {
            addProduct(productName, productCount, productId)
            setProductCount('')
            setProductName('')
            setProductId('')
            productInput.current.select()
        }
    }

    return (
        <AddListItemWrapper style={modalGrid}>
            <p>{products.length + 1}</p>
            <AutocompleteInput
                value={productName}
                setValue={setProductMeta}
                selectorTransmission={countInput}
                inputRef={productInput}
                selects={selects}
            />
            <div>
                <DepotInput
                    type='number'
                    value={productCount}
                    setValue={ e => setProductCount(e.target.value) }
                    inputOnKeyDown={e => { if (e.keyCode === 13) addHandle() }}
                    inputRef={countInput}
                />
            </div>
            <AddButton
                style={{padding: 0}}
                onClick={addHandle}
            >
                +
            </AddButton>
        </AddListItemWrapper>
    )
}

export default AddListItem;

const AddListItemWrapper = styled.div`
  position: relative;
  padding: 0 20px;
  text-align: left;
  align-items: center;
  p {
    margin: 0;
  }
  div {
    padding: 5px 5px 5px 0;
  }
`
const AddButton = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: #14142a;
  width: 28px;
  height:  28px;
  border-radius: 50%;
  color: white;
  transition: background-color .2s ease;

  
  :hover {
    background: #383881;
  }
  :active {
    background: #6866a1;
  }
`

{/*<DepotInput*/}
{/*    setValue={ (e) => setProductName(e.target.value) }*/}
{/*    value={productName}*/}
{/*    inputRef={productInput}*/}
{/*/>*/}
{/*{*/}
{/*    showAutocomplete && (*/}
{/*        <AutoComplete*/}
{/*            style={{position: 'absolute', top: -105, left: 0}}*/}
{/*            selects={autocomplete}*/}
{/*            onSelect={setSelectAutocomplete}*/}
{/*        />*/}
{/*    )*/}
{/*}*/}