import React, {useCallback, useEffect, useState} from 'react';
import { Form, Icon, Input, Button } from 'antd';
import { Modal } from 'semantic-ui-react';
import {useDispatch, useSelector} from "react-redux"
import {resetRecoveryPasswordStatus, resetStatus} from "../reducers/toolkit/profileReducer"
import styled from "styled-components";
import { deliveryCycleInfoMessage } from '../actions/ModalActions';

const NormalLoginForm = (props) => {
  const dispatch = useDispatch()
  const [type, setType] = useState('auth')
  const recoveryPasswordStatus = useSelector(store => store.profile.recoveryPasswordStatus)
  // const recoveryPasswordStatus = useSelector(store => store.profile.recoveryPasswordStatus)

  const registerStatus = useSelector(store => store.profile.registerStatus)
  const Status = useSelector(store => store.profile)


  useEffect(() => {
    if(type === 'auth'){
      dispatch(resetStatus())
    }
  }, [type])

  const redirectToAuth = () => {
    setTimeout(() => {
      if(type !== 'auth'){
        switchType('auth')
      }
    }, 10000)
  }

  useEffect(() => {
    if(recoveryPasswordStatus === 'inProgress'){
      redirectToAuth()
    }
  }, [recoveryPasswordStatus])

  useEffect(() => {
    if(registerStatus?.status === 'inProgress'){
      redirectToAuth()
    }
  }, [registerStatus])

  const handleSubmit = useCallback(
    e => {
      e.preventDefault();
      props.form.validateFields((err, values) => {
        if (!err) {
          switch (type) {
            case 'registration':
                props.registrationByEmail(values);
                console.log('Received values of form: ', values,err);
              break;
            case 'recovery':
              console.log('Received values of form: ', values);
              props.recoveryPassword(values);
              break;
            case 'auth':
              console.log('Received values of form: ', values);
              props.createTokenByPassword(values);
              break;
            default:
              alert('Что-то пошло не так')
          }
        }
      });
    },
    [type],
  );

  const switchType = useCallback(val => {
    if(val === 'auth') dispatch(resetRecoveryPasswordStatus())
    setType(val)
  }, [])

  const renderForm = () => {
    const { getFieldDecorator } = props.form;
    switch (type) {
      case 'registration':
        return (
          <Form.Item>
            {
              registerStatus?.message && (
                <Warning status={registerStatus.status}>
                  <div><p>!</p></div>
                  {registerStatus.message}
                </Warning>
              )
            }
            {getFieldDecorator('email', {
              rules: [{ required: true, message: 'Пожалуйста введите свой e-mail!' }],
            })(
                <Input
                    prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    placeholder="Введите свой E-mail"
                />,
            )}
          </Form.Item>
        )

      case 'recovery':
        return (
          <Form.Item>
            {
              recoveryPasswordStatus === 'inProgress'
                ?
                <p style={{fontSize:17,fontWeight:600}}>Проверьте почту. Мы отправили вам ссылку на восстановление пароля.</p>
                // <p>Проверьте почтовый ящик</p>
                :
              recoveryPasswordStatus === 'notFoundByEmail' || recoveryPasswordStatus === 'invalid email'
              ?
           
              (
                getFieldDecorator('email', {
                  rules: [{ required: true, message: 'Пожалуйста введите свой e-mail!' }],
                })(
                  <>
                  <Warning status={recoveryPasswordStatus}>
                  <div><p>!</p></div>
                  {recoveryPasswordStatus}
                  </Warning>

                  <Input
                    prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    placeholder="Введите свой e-mail"
                  />
                  </>
                  ,
                )
              )
              :
                (
                  getFieldDecorator('email', {
                    rules: [{ required: true, message: 'Пожалуйста введите свой e-mail!' }],
                  })(
                    <Input
                      prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
                      placeholder="Введите свой e-mail"
                    />,
                  )
                )
            }
          </Form.Item>
        )

      case 'auth':
        return (
          <>
            <p style={{margin: 0}}>Логин</p>
            <Form.Item>
              {getFieldDecorator('username', {
                rules: [{ required: true, message: 'Пожалуйста введите свой e-mail!' }],
              })(
                  <Input
                      prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                      placeholder="example@gmail.com"
                  />,
              )}
            </Form.Item>
            <p style={{margin: 0}}>Пароль</p>
            <Form.Item>
              {getFieldDecorator('password', {
                rules: [{ required: true, message: 'Пожалуйста введите свой пароль!' }],
              })(
                  <Input
                      prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                      type="password"
                      placeholder="Введите пароль"
                  />,
              )}
            </Form.Item>
          </>
        )
      default:
        return null
    }
  }

  const renderButtons = () => {
    switch (type) {
      case 'registration':
        return (
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              disabled={registerStatus && 'status' in registerStatus && registerStatus.status !== 'reject'}
            > 
              Зарегистрироваться
            </Button>
            <Button type="primary" htmlType="button" className="login-form-button" style={{marginLeft: 20}}
                    onClick={() => switchType('auth')}>
              Вернуться к авторизации
            </Button>
          </Form.Item>
        )

      case 'recovery':
        return (
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                disabled={recoveryPasswordStatus === 'inProgress'}
              >
                Восстановить
              </Button>
              <Button
                type="primary"
                htmlType="button"
                className="login-form-button"
                style={{marginLeft: 20}}
                onClick={() => switchType('auth')}
              >
                  Вернуться к авторизации
              </Button>
            </Form.Item>
        )

      case 'auth':
        return (
          <Form.Item>
            <Button type="primary" htmlType="submit" className="login-form-button">
              Войти
            </Button>
            <Button type="primary" htmlType="button" className="login-form-button" style={{marginLeft: 20}}
                    onClick={() => switchType('registration')}
            >
              Создать личный кабинет
            </Button>
            <Button htmlType="button" className="login-form-button" style={{marginLeft: 20}}
                    onClick={() => switchType('recovery')}
            >
              Забыли пароль?
            </Button>
          </Form.Item>
        )
      default:
        return null
    }
  }

  return (
    <Modal open={props.open} size='small' onClose={props.onClose}  >

      <Form onSubmit={handleSubmit} className="login-form" style={{padding:'22px'}}>
        {renderForm()}
        {renderButtons()}
      </Form>

    </Modal>
  );
}

const Warning = styled.p`
  width: fit-content;
  padding: 10px 10px;
  background: ${({status}) => status === 'inProgress' ? '#a4ff7d' : '#ff7d7d'};
  color: ${({status}) => status === 'inProgress' ? '#000' : '#fff'};
  border-radius: 8px;
  transition: background-color .2s ease;
  display: flex;
  align-items: center;

  > div {
    display: ${({status}) => status === 'inProgress' ? 'none' : 'flex'};
    align-items: center;
    justify-content: center;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #ff0000;
    color: #fff;
    margin-right: 10px;
  }
`

const WrappedNormalLoginForm = Form.create({ name: 'normal_login' })(NormalLoginForm);
export default WrappedNormalLoginForm;
