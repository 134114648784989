import React, {useEffect, useState} from "react";
import DepotInput from "./DepotInput"
import {nanoid} from "nanoid"
import styled from "styled-components"
import {selectStyles} from "./Header"

const AutocompleteInput = ({
                               value,
                               setValue,
                               inputRef, // reference from parent
                               selectorTransmission, //transmit selection on another React ref element
                               selects = [], // selection array (max 5 items if more, need rework css)
                           }) => {

    const [selectId, setSelectID] = useState( null )
    const [selectionId, setSelectionId] = useState( null )
    const [showAutocomplete, setShowAutocomplete] = useState( false )
    const [selectableElement, setSelectableElement] = useState(  null  )

    useEffect(() => {
        if (selectId !== null) { setSelectionId(selectId) }
        setSelectID( null )
    }, [selectId])

    useEffect(() => {
        if (value && (value !== selects[selectionId])) { setShowAutocomplete(true) }
        if (!value) { setShowAutocomplete(false) }
        setSelectionId(null)
    }, [value])

    useEffect(() => {
        if (selectionId !== null) {
            setValue(selects[selectionId], selectionId)
            setShowAutocomplete(false)
            selectorTransmission.current.select()
            setSelectID(null)
        }
    }, [selectionId])

    const selectByMouse = (index) => {
        setSelectID(index)
        setSelectableElement(null)
    }
    const selectByKeyboard = (direction) => {
        if (direction === 'up') {
            if (selectableElement === null) {
                setSelectableElement(selects.length - 1)
            }
            if (selectableElement > 0) {
                setSelectableElement(prev => prev - 1)
            }
            if (selectableElement === 0) {
                setSelectableElement(0)
            }
        }
        if (direction === 'down') {
            if (selectableElement === null) {
                setSelectableElement(0)
            }
            if (selectableElement <= selects.length - 1) {
                setSelectableElement(prev => prev + 1)
            }
            if (selectableElement === selects.length - 1) {
                setSelectableElement(selects.length - 1)
            }
        }
    }
    const mouseOverHandler = (id) => {
        setSelectableElement(id)
    }
    const keyboardSelectionHandler = (e) => {
        switch (e.keyCode) {
            case 13:
                setSelectID(selectableElement)
                setSelectableElement(null)
            case 38:
                selectByKeyboard('up')
                break
            case 40:
                selectByKeyboard('down')
                break
        }
    }

    return (
        <AutocompleteInputWrapper>
            <DepotInput
                setValue={ e => setValue(e.target.value) }
                value={ value }
                inputRef={ inputRef }
                inputOnKeyDown={keyboardSelectionHandler}
            />
            {
                showAutocomplete && selects.length ?
                    (
                        <AutoCompleteWrapper style={{position: 'absolute', bottom: 43, left: 0, padding: 5}}>
                            {
                                selects.map((el, i) => {
                                    return <SelectItem
                                        key={nanoid()}
                                        el={el}
                                        id={i}
                                        selectId={selectableElement}
                                        mouseOver={mouseOverHandler}
                                        mouseLeave={() => setSelectableElement(null)}
                                        clickHandler={selectByMouse}/>
                                })
                            }
                        </AutoCompleteWrapper>
                    ) : null
            }
        </AutocompleteInputWrapper>
    )
}

export default AutocompleteInput;


const selectedStyle = {
    background: 'rgba(0,0,0,0.6)',
    color: 'white',
}

const SelectItem = ({el, clickHandler, mouseOver, mouseLeave, id, selectId}) => {
    return (
        <Element
            selected={(selectId === id)}
            onClick={() => clickHandler(id) }
            onMouseOver={ () => mouseOver(id) }
            onMouseLeave={ mouseLeave }
        >{el}</Element>
    )
}


const AutocompleteInputWrapper = styled.div`
  width: 100%;
  position: relative;
`

const AutoCompleteWrapper = styled.div`
  border-radius: 8px;
  width: 100%;
  background: rgb(238,238,238);
  border: 1px solid silver;
`
const Element = styled.p`
    ${({selected}) => selected && selectedStyle};
    padding: 2px;
`