import React, {memo, useEffect, useMemo, useState, useRef, useCallback} from "react";
import styled from "styled-components";
import {Switch, Route, BrowserRouter, useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import MenuByDay from "../pages/Kitchens/MenuByDay"
import Assortment from "../pages/Kitchens/Assortment"
import NoPermissions from "../components/NoPermissions"
import ClientCard from "../pages/Clients/ClientCard"
import Promocodes from "../pages/Financial/Promocodes"
import Moderations from "../pages/Moderation/Moderations";
import Reviews from "../pages/Kitchens/Reviews"
import { NewReports } from "../pages/Partners/NewReports";
import Wallets from "../pages/Financial/Wallets"
import Assembly from "../pages/Kitchens/Assembly"
import Reports from "../pages/Settings/Reports"
import Warehouse from "../pages/Financial/Warehouse"
import SimpleMap from "../pages/SimpleMap"
import OrderMap from "../pages/Delivery/OrderMap"
import UIKit from "../pages/UIKit";
import {useHistory} from "react-router-dom"
import {nanoid} from "nanoid"
import errPageImg from '../images/404.png'
import { Settings } from "../pages/Kitchens/Settings";
import MainPage from "../pages/MainPage";
// import DashBoard from '../pages/DashBoard'
// import Addresses from "../pages/Addresses";
import ApplicationVersion from "../pages/ApplicationVersion";
import ComplexProducts from "../pages/ComplexProducts";
import CorporateClients from "../pages/CorporateClients";
import CourierManagement from "../pages/CourierManagement";
// import Financial from "../pages/Financial";
// import FinancialReports from "../pages/FinancialReports";
// import PUSHnotifications from "../pages/PUSHnotifications";
// import RightsManagement from "../pages/RightsManagement";
// import StatisticsOfKitchens from "../pages/StatisticsOfKitchens";
// import WorkingAndWeekends from "../pages/WorkingAndWeekends";
// import Supports from "../pages/Supports";
import Orders from "../pages/Kitchens/Orders";
// import { ReportsItem } from "../pages/ReportsItem";
// import AdditionalSettigns from "../pages/AdditionalSettings";
// import Stocks from "../components/Settings/Stocks/Stocks";
import DashBoard from '../pages/Kitchens/DashBoard'
import Addresses from "../pages/Clients/Addresses";
import CorpClients from "../pages/Clients/CorpClients";
import FindClients from "../pages/Clients/FindClients";
import DeliveryManagment from "../pages/Delivery/DeliveryManagment";
import PushNotif from "../pages/Financial/PushNotif";
import ComplexProduct from "../pages/Kitchens/ComplexProduct";
import Financial from "../pages/Kitchens/Financial";
import FinancialReports from "../pages/Partners/FinancialReports";
import Statistics from "../pages/Partners/Statistics";
import ManagmentAdmin from "../pages/Service/ManagmentAdmin";
import Version from "../pages/Service/Version";
import WorkWekeend from "../pages/Service/WorkWekeend";
import AboutKitchens from "../pages/Settings/AboutKitchens";
import AdditionalSettings from "../pages/Settings/AdditionalSettings";
import MainSettings from "../pages/Settings/MainSettings";
import StartNotif from "../pages/Settings/StartNotif";
import Stocks from "../pages/Settings/Stocks";
import Supports from "../pages/Support/Supports";
import { Contracts } from "../pages/Settings/Contracts";

const Content = ({path}) => {

  const dispatch = useDispatch()

  const isAuth = useSelector(store => store.profile.isAuth)
  const permissions = useSelector(store => store.appMain.permissions)
  const getPermissionsSuccess = useSelector(store => store.appMain.getPermissionsSuccess)
  
  const navigationEnum = {
    'screen_main': {path: '/', component: <DashBoard/>,exact:'/'},
    'screen_dashboard': {path: '/dashboard', component: <MainPage/>},
    "screen_map": {path: '/map', component: /*<SimpleMap/>*/ <OrderMap />},
    "screen_client-card": {path: '/client-card', component: <ClientCard/>},
    'screen_wallets': {path: '/wallets', component: <Wallets/>},
    'screen_promocode': {path: '/promocodes', component: <Promocodes/>},
    'screen_assembly': {path: '/assembly', component: <Assembly/>},
    'screen_reports': {path: '/reports', component: <NewReports/>},
    'screen_reviews': {path: '/reviews', component: <Reviews/>},
    'screen_menu-by-day': {path: '/menu_by_day', component: <MenuByDay/>},
    'screen_warehouse': {path: '/warehouse', component: <Warehouse/>},
    'screen_assortment': {path: '/assortment', component: <Assortment/>},
    'screen_moderation':{path: '/moderation',component:<Moderations/>},
    'screen_settingKitchens':{path: '/setting',component:<Settings/>},
    'screen_ui-kit': {path: '/ui-kit', component: <UIKit/>},
    'screen_setting': {path: '/reportsSetting', component: <Contracts/>},
    'screen_orders': {path: '/orders', component: <Orders/>},
    // 'screen_main': {path: '/main', component: <MainPage/>},
    'screen_addresses': {path: '/addresses', component: <Addresses/>},
    'screen_corpClients': {path: '/corpClients', component: <CorpClients/>},
    'screen_findClients': {path: '/findClients', component: <FindClients/>},
    'screen_delManagment': {path: '/delManagment', component: <DeliveryManagment/>},
    'screen_push': {path: '/push', component: <PushNotif/>},
    'screen_complex': {path: '/complex', component: <ComplexProduct/>},
    'screen_financial': {path: '/financial', component: <Financial/>},
    'screen_financialRep': {path: '/financialRep', component: <FinancialReports/>},
    'screen_reportsStatistics': {path: '/statistics', component: <Statistics/>},
    'screen_managment': {path: '/managment', component: <ManagmentAdmin/>},
    'screen_version': {path: '/version', component: <Version/>},
    'screen_workweek': {path: '/workweek', component: <WorkWekeend/>},
    'screen_aboutKitchen': {path: '/aboutKitchen', component: <AboutKitchens/>},
    'screen_additional': {path: '/additional', component: <AdditionalSettings/>},
    'screen_mainSetting': {path: '/mainSetting', component: <MainSettings/>},
    'screen_startnotif': {path: '/startnotif', component: <StartNotif/>},
    'screen_stocks': {path: '/stocks', component: <Stocks/>},
    'screen_supports': {path: '/supports', component: <Supports/>},
  }

  const [routs, setRouts] = useState(null);
  const history = useHistory()
  // const pathname = useLocation()
  console.log(Object.values(navigationEnum),'routs')

  const paths = useMemo(() => {
    return Object.values(navigationEnum).reduce((acc, el) => {
      acc.push(el.path)
      return acc
    }, [])
  }, [permissions]);

    useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    }
  }, []);

  
  useEffect(() => {
    if (permissions?.permissions) {
      if (!permissions.permissions.length) {
        setRouts('all')
        return
      }
      const permissionsOnScreen = permissions.permissions.filter(el => el.split('_')[0] === 'screen')
      setRouts(permissionsOnScreen)
    }
  }, [permissions]);
  const drawNav = () => {
    if (routs) {
      switch (routs) {
        case 'all':
          const appRouts = Object.keys(navigationEnum)
          // if(history.location.pathname === "/") {
          //   history.push(navigationEnum[appRouts[appRouts.length -1]].path)
          // }
          return appRouts.map(el => {
            return navigationEnum[el] && (
              navigationEnum[el].exact
              ?
              <Route key={nanoid()} path={navigationEnum[el].path} exact={`${navigationEnum[el].exact}`}>
                {navigationEnum[el].component}
              </Route>
              :
              navigationEnum[el] &&
              <Route key={nanoid()} path={navigationEnum[el].path}>
                {navigationEnum[el].component}
              </Route>
            )
          })
          default:
            // history.push(paths.includes(history.location.pathname) ? history.location.pathname : navigationEnum[routs[0]].path)
          
            return routs.map(el => {
            return navigationEnum[el] &&  (
              navigationEnum[el].exact
              ?
              <Route key={nanoid()} exact={`${navigationEnum[el].exact}`} path={navigationEnum[el].path}>
                {navigationEnum[el].component}
              </Route>
              :
              navigationEnum[el] &&
              <Route key={nanoid()} path={navigationEnum[el].path}>
                {navigationEnum[el].component}
              </Route>
            )
          })
        }
      }
    }
    

  const nav = drawNav()
  return (
    <ContentWrapper>
      <div className="App">
        <Switch>
          { permissions && nav }
          {
            nav?.length > 0 && (
              <ErrorPage>
                <img src={errPageImg} alt="error404"/>
              </ErrorPage>
            )
          }
        </Switch>
      </div>
    </ContentWrapper>
  )
}

export default memo(Content);


const ContentWrapper = styled.main`
  box-sizing: border-box;
  background: #f4f5f9;
  width: calc(100vw - 64px);
  margin-top: 64px;
  margin-left: 64px;
`;

const ErrorPage = styled.div`
  height: calc(100vh - 64px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > img {
    border-radius: 8px;
  }
`;
/* height: calc(100vh - 64px); */
/* overflow-y:scroll; */

/* Hide scrollbar for Chrome, Safari and Opera */

/* ::-webkit-scrollbar { 
    display: none;
  }  */
