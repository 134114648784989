import React,{useState} from 'react'
import styled from 'styled-components'
import selectSvg from '../../../../images/select.svg'
const StocksItems = ({info,setShowModal,setSelectedStocks,selectedStocks}) => {
    const handleSelect=(e)=>{
      e.stopPropagation()
      setSelectedStocks(prev=>{
        if(prev.includes(info.id)){
            return prev.filter(el=> el !== info.id)
        }else{
            return [...prev,info.id]
        }
      })
    }
  return (
    <WrapperStocks onClick={()=>setShowModal(true)} active={selectedStocks.includes(info.id) ? true: false}>
        <SelectWrapps onClick={handleSelect}>
            <img src={selectSvg}/>
        </SelectWrapps>
        <ContentStocks>
            <Title active={selectedStocks.includes(info.id) ? true: false} >{info.title}</Title>
            <Deck active={selectedStocks.includes(info.id) ? true: false}>{info.deck}</Deck>
        </ContentStocks>

        <Date active={selectedStocks.includes(info.id) ? true: false}>
        {info.title}
        </Date>
    </WrapperStocks>
  )
}

export default StocksItems

const WrapperStocks = styled.div`
display: grid;
grid-template-columns: 40px 2fr .7fr;
border-bottom: 1px solid rgb(169,169,169,0.5);
padding:10px 0px 10px 0px ;
background-color: ${({active})=>active ? '#2196F3':'#FFFFFF'};
`
const Title = styled.div`
padding-right: 7px;
color:${({active})=>active ?'#FFFFFF' :'#2196F3'};

`
const Deck = styled.div`
color:${({active})=>active ?'#FFFFFF' :'#999999'};
`
const Date = styled.div`
color:${({active})=>active ?'#FFFFFF' :'#999999'};
`
const SelectWrapps = styled.div`
  cursor: pointer;
`
const ContentStocks = styled.div`
display: flex;
flex-direction: row;
`