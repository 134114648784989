import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {useDispatch} from "react-redux"
import {setDrawingMode} from "../../reducers/toolkit/orderMapReducer"
import {PenIcon} from "../../images/svgs"


const DrawPolygonButton = (props) => {

  const dispatch = useDispatch()
  const [drawPoly, setDrawPoly] = useState(false)

  useEffect(() => {
    if(drawPoly) {
      dispatch(setDrawingMode(true))
    } else {
      dispatch(setDrawingMode())
    }
  }, [drawPoly])

  return (
    <ControlButtonWrapper isSelect={drawPoly} onClick={() => setDrawPoly(prev => !prev)}>
      <PenIcon />
    </ControlButtonWrapper>
  )
}

export default DrawPolygonButton;

export const ControlButtonWrapper = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 8px;
  background: ${({isSelect}) => isSelect ? '#1890ff' : '#fff'};
  transition: background-color .2s ease;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  
  >svg{
    width: 60%;
    fill: ${({isSelect}) => isSelect ? '#fff' : '#000'};
  }
  
  :hover{
    background: #1890ff;

    >svg{
      fill: white;
    }
  }
`
