import {useEffect, useState} from "react"

export const useScrollObserver = (parentRef, childRef) => {

    const [intersectEvent, setIntersectEvent] = useState( false )

    useEffect(() => {
        const options = {
            root: parentRef.current,
            rootMargin: '0px',
            threshold: 1.0
        }
        const observer = new IntersectionObserver((entries) => {
            setIntersectEvent(entries[0].isIntersecting)
        }, options)

        observer.observe(childRef.current)

        return () => {
            observer.unobserve(childRef.current)
        }
    }, [])

    return intersectEvent
}