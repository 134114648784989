import React from "react";
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import {changePushMessage, sendPushSingleMessage} from "../../actions/ReviewsActions";


const PushMessagePopup = (props) => {


    const dispatch = useDispatch();

    const {
        pushMessageState
    } =  useSelector(store => store.reviews);

    const {
        showPushMessagePopup,
        pushMessageText,
        sendingMessageIsStart,
        targetOrder,
        sendingMessageIsSuccess,
    } = pushMessageState;

    const textAreaPushMessageHandle = e => {
        dispatch(changePushMessage({
            text: e.target.value
        }))
    };
    const cancelPopupMessage = () => {
        if(pushMessageText){
            dispatch(changePushMessage({
                text:''
            }))
        }
    };

    const sendPopupMessage = () => {
        if(pushMessageText) {
            dispatch(sendPushSingleMessage({
                title: '',
                message: pushMessageText,
                orderId: targetOrder,
            }))
        }
    };
    return (
        <PushMessagePopupWrapper onClick={e => e.stopPropagation()} showPushMessagePopup={showPushMessagePopup}>
          <MessagePopupTextWrapTitle>
                <textarea onChange={e => textAreaPushMessageHandle(e)} value={pushMessageText}/>
            </MessagePopupTextWrapTitle>
            <MessagePopupControlsWrap>
                <CancelButton onClick={cancelPopupMessage}>Отменить</CancelButton>
                {
                    sendingMessageIsStart
                        ? <ApplyButton disable >Отправляю... </ApplyButton>
                        : sendingMessageIsSuccess
                            ? <ApplyButton sendingMessageIsSuccess={sendingMessageIsSuccess}>Готово!</ApplyButton>
                            : <ApplyButton onClick={sendPopupMessage}>Отправить</ApplyButton>
                }
            </MessagePopupControlsWrap>
        </PushMessagePopupWrapper>
    )
}
const PushMessagePopupWrapper = styled.div`
  ${({showPushMessagePopup}) => showPushMessagePopup ? {display: "flex"} : {display: "none"}};
  width: 400px;
  height: 300px;
  box-shadow: black 0 0 10px;
  background: #ffffff;
  flex-direction: column;
  padding: 10px;
  border-radius: 6px;
`
const MessagePopupTextWrapTitle = styled.div`
    flex-grow:2;
    padding:0 0 10px 0;
  & textarea {
    width: 100%;
    height: 90%;
    resize: none;
    padding: 5px;
    border-radius: 6px;
  }
`

const MessagePopupControlsWrap = styled.div`
  display: flex;
  justify-content: space-between;
`
const DefaultButton = styled.button`
  padding: 5px 20px;
  background: none;
  border: none;
  cursor: pointer;
`
const CancelButton = styled(DefaultButton)`
  text-decoration: underline;
`
const ApplyButton = styled(DefaultButton)`
  transition: background-color .2s ease;
  background: #3498DB;
  border-radius: 6px;
  color: white;
  
  :hover {
    background: #2a70a3;
  }
`

export default PushMessagePopup;
