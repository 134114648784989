import React, { useState } from 'react'
import styled from 'styled-components'

const AdminCheckBox = () => {

const [check,setCheck] = useState(false)

  return (
    <WrapperCheckBox onClick={()=>setCheck(!check)}>
                        {check &&
                        <svg viewBox="0 0 22 22" style={{position:'absolute',top:0,left:0}}>
                            <path class="tick" stroke="#000000" fill="none" stroke-linecap="round" stroke-width="2" d="M4 10l5 5 9-9" />
                        </svg>
                        }
    </WrapperCheckBox>
  )
}

export default AdminCheckBox

const WrapperCheckBox = styled.div`
height:18px;
width:18px;
border: 1px solid #A4A4A4;
border-radius: 4px;
position: relative;
cursor: pointer;
`