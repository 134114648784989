import React, {memo} from "react";
import styled from "styled-components";
import {MoneyIco, PersonIco} from "../../../images/svgs"
import PayVariant from "./PayVariant"

const moneyFormat = str => {
  return str.split(/([0-9]{3}$)/).join(' ')
}

const AboutOrder = ({companyData}) => {

  const {persons} = companyData

  return (
    <AboutOrderWrapper>
      <Person>
        <PersonIco/>
        {persons}
      </Person>
      <PayVariant sumPriceResultCash={(+companyData?.sumPriceResultCash)} sumPriceResult={(+companyData.sumPriceResult)}/>
      <TotalPay>
        <MoneyIco/>
        {moneyFormat(companyData.sumPriceResult.split('.')[0]) + " ₽"}
      </TotalPay>
    </AboutOrderWrapper>
  )
}

export default memo(AboutOrder);

const AboutOrderWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  > p {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }
`
const Person = styled.p``

const TotalPay = styled.p``
