import React, {useState,useEffect} from "react";
import styled from "styled-components";
import {CONTAINER_PADDING, CONTAINER_PADDING_MOD, GIRD_CONSISTENCY_SETTINGS, GIRD_CONSISTENCY_SETTINGS_MOD} from "../Assort/commonStyles";
import {deleteSelectedProducts, getKithensStarted, saveKithensId, toggleToSelectAllProducts,allKitchens} from "../../actions/AssortmentAction";
import {useDispatch, useSelector} from "react-redux";
import trashBin from '../../images/trash.svg'
import {sr} from "../../utils";
import {Select,Switch} from "antd";
import AdminSwitch from '../../UI_ADMIN/AdminSwitch'
import AdminCheckBox from "../../UI_ADMIN/AdminCheckBox";



const HeaderMod = ({setAllKitchens}) => {
  
    const {Option} = Select;
    const dispatch = useDispatch()
    const selectAllToggle = useSelector(store => store.assortment.selectAllToggle)
    const selectCat = useSelector(store => store.assortment.selectedCategory)
    const cat = useSelector(store => store.assortment.categoryList)
    const pls = useSelector(store=>store.assortment.productListModeration)
    const kitchensStore = useSelector(store=>store.appMain.kitchens)
    const allKitch = useSelector(store=> store.assortment.allKitch)
    // const accessibleKitchens = useSelector(store => store.assortment.accessibleKitchens);
    // const selectKithens = useSelector(store => store.assortment.selectKithens)

    const [countProd , sestCountProd] = useState('')
    const [kitchens,setKitchens] = useState([])
    const [productName,setProductName] = useState('')
    const [countMod,setCountMod] = useState('')
    const [selectKit,setSelectKit] = useState([])
    const deleteHandle = () => {
        dispatch(deleteSelectedProducts())
    }
    // <p style={{color:'#161616'}}>На модерации • {countProd ? countProd :'0'}</p> 
    

console.log(allKitch,'selectKitselectKit')

useEffect(()=>{
  if(pls?.length > 0){
    sestCountProd(pls?.length)
  }

},[pls])

const reverse=()=> dispatch(allKitchens(!allKitch))

    return (
    <>
        <HeaderWrapper>
          {/* <SwitchWrapper
        allKitchens = {allKitchens}
          >
        <Switch
        onChange={()=>setAllKitchens(prev=>!prev)}
        />
        <label className="tittle">Показывать товары всех кухонь</label>
          </SwitchWrapper> */}
          <InfoModeration>
            На модерации • {countProd ? countProd : 0 }
          </InfoModeration>

          <SwitchWrapper>

            <SwitchItem>
            <AdminSwitch
            setCheck={reverse}
            check={allKitch}
            />
              <div className="InfoSwitch">Показать все кухни</div>
            </SwitchItem>

            <SwitchItem>
            <AdminSwitch/>
            <div className="InfoSwitch">Новые товары</div>
            </SwitchItem>

            <SwitchItem>
            <AdminSwitch/>
            <div className="InfoSwitch">Повторная модерация</div>
            </SwitchItem>

          </SwitchWrapper>

         {!allKitch &&
           <KitchensWrapper>
                {
                  kitchensStore.map(el=>
                  <KitItem key={el.id}
                  > 
                     <AdminCheckBox/> 
                     <div className="NameKitc">{el.name}</div>
                  </KitItem>
                  )}
          </KitchensWrapper>
          }
        </HeaderWrapper>
        </>
    )
}

export default HeaderMod;
const HeaderWrapper = styled.header`
display: grid;
grid-template-columns:1fr;
grid-template-rows: repeat(3,auto);
grid-area: header;
font-family: Roboto;
font-style: normal;
margin-top: 32px;
max-width: 1100px;
min-width: 1100px;
`
const SwitchWrapper = styled.div`
display: grid;
grid-template-columns: repeat(3,auto);
margin-top: 24px;
`
const KitchensWrapper = styled.div`
display: grid;
grid-template-columns: repeat(4,1fr);  
margin-top: 24px;
`
const KitItem = styled.div`
display: flex;
margin-top: 8px;
  .NameKitc{
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #2C2E32;
    margin-left: 8px;
  }
`
const SwitchItem = styled.div`
display: flex;
align-items: center;

  .InfoSwitch{
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #2C2E32;
    margin-left: 16px;
  }
`
const InfoModeration = styled.div`
display: flex;
font-weight: 700;
font-size: 24px;
line-height: 28px;
color: #161616;
`