import moment from "moment";
import {
    GET_RESTS_STARTED,
    GET_RESTS_SUCCESS,
    GET_RESTS_ERROR,
    SET_ACTIVE_REST,
    GET_DAYS_STARTED,
    GET_DAYS_SUCCESS,
    GET_DAYS_ERROR,
    GET_MENU_STARTED,
    GET_MENU_SUCCESS,
    GET_MENU_ERROR,
    SLIDER_SHIFT_FORWARD,
    SLIDER_SHIFT_BACK,
    SET_ACTIVE_PERIOD,
    TOGGLE_ACTIVE_DISH,
    TOGGLE_ACTIVE_CATEGORY,
    SET_EDIT_MODE,
    REVERT_EDIT_MODE,
    APPLY_FOOD_LIST_CHANGES,
    CHANGE_DISH_PRICE,
    CHANGE_DISH_AMOUNT,
    SHOW_BLOCK_POPUP,
    HIDE_BLOCK_POPUP,
    SEND_SUSPEND_START,
    SEND_SUSPEND_SUCCESS,
    PREPARE_MENU_TO_SEND,
    PUBLISHED_SUCCESS,
    RESET_ACTIVE_PERIOD,
    SEND_MENU_START,
    SEND_MENU_SUCCESS,
    CLEAR,
    COPY_MENU_DATA_SET_DATE,
    COPY_MENU_DATA_SET_PERIOD,
    SEND_COPY_DATE_START,
    SEND_COPY_DATE_SUCCESS,
} from "../actions/MenuByDayActions";
import React from "react";

//init val
const today = _setToday();
const dayRange = _setDayRange(today);
const minVal = 14;
const maxVal = 21;
const currentShowDays = _setShowDays(dayRange, minVal, maxVal);

//init state
const initialState = {
    getRestsIsStarted: false,
    getRestsIsSuccess: false,
    getDaysPeriodsIsStarted: false,
    getDaysPeriodsIsSuccess: false,
    getMenuIsStarted: false,
    getMenuIsSuccess: false,
    sendMenuIsStart: false,
    sendMenuIsSuccess: false,
    loadPopupArea: false,
    showBlockPopup: false,
    showWarning: false,
    showSendPreloader: false,
    menuSendStart: false,
    menuSendSuccess: false,
    warningMessage: "",
    suspendSendingStart: false,
    suspendSendingSuccess: false,
    today: today,
    permissions: "",
    accessibleRests: [],
    activeRest: "",
    sliderState: {
        dayRange: dayRange,
        minVal: minVal,
        maxVal: maxVal,
        currentShowDays: currentShowDays,
        menuInfoPeriod: [],
        periods: []
    },
    publicBarState: {
        activePeriod: {},
        status: false,
        amountRestrictionsChecker: 0,
    },
    editMode: false,
    foodListState: [],
    activeFoodList: [],
    preparedFoodListData: [],
    copyMenuData: {},
    copyMenuSendingStarted: false,
    copyMenuSendingSuccess: false,
};


let tempFoodListState;

export function menuByDayReducer(state = initialState, action) {
    switch (action.type) {
        //Get rest handlers
        case GET_RESTS_STARTED:
            return {
                ...state,
                getRestsIsSuccess: false,
                getRestsIsStarted: true,
            };
        case GET_RESTS_SUCCESS:
            const tempState = {
                ...state,
                getRestsIsStarted: false,
                getRestsIsSuccess: true,
            };
            return _setAccessibleRests(tempState, action.payload);
        case GET_RESTS_ERROR:
            //предумать обработку ошибок
            console.log("rests is error");
            return state;
        case SET_ACTIVE_REST:
            return _setActiveRests(state, action.payload);
        //Get days periods handlers
        case GET_DAYS_STARTED:
            // Подумать над обработкой ожиданий, нужна ли она тут?
            return {
                ...state,
                getDaysPeriodsIsStarted: true,
            };
        case GET_DAYS_SUCCESS:
            return _setMenuInfoPeriod(state, action.payload);
        case GET_DAYS_ERROR:
            //предумать обработку ошибок
            console.log("periods is error");
            return state;
        //Get menu handlers
        case GET_MENU_STARTED:
            return {
                ...state,
                getMenuIsStarted: true,
            };
        case GET_MENU_SUCCESS:
            return _compose([
                _parseDataToFoodList,
                _markMenuIsSuccess,
                _hideEmptyCategory,
                _setActiveDishesFoodListCount,
                _markActiveCategory,
                _setActiveFoodList,
                _amountRestrictions,
                _prepareFoodListToSend,
                // _setTotalPrice
            ], state, action.payload);
        case GET_MENU_ERROR:
            console.log("menu is error");
            //предумать обработку ошибок
            return state;
        //Slider handlers
        case SLIDER_SHIFT_FORWARD:
            return _sliderShift(state, action.type);
        case SLIDER_SHIFT_BACK:
            return _sliderShift(state, action.type);
        case SET_ACTIVE_PERIOD:
            return _setActivePeriod(state, action.payload);
        case RESET_ACTIVE_PERIOD:
            return _resetActivePeriod(state);
        //FoodList handlers
        case TOGGLE_ACTIVE_DISH:
            return _compose([
                _toggleActiveFoodListDish,
                _setActiveDishesFoodListCount,
            ], state, action.payload);
        case TOGGLE_ACTIVE_CATEGORY:
            return _compose([
                _toggleActiveFoodListCategory,
                _setActiveDishesFoodListCount,
                _activeDishesFoodListObserver,
            ], state, action.payload);
        case SET_EDIT_MODE:
            tempFoodListState = _rollBackFoodListState(state);
            return _activeEditMode(state);
        case REVERT_EDIT_MODE:
            return  _revertFoodList(); 
        case APPLY_FOOD_LIST_CHANGES:
            return _compose([
                _applyFoodListChanges,
                _setActiveFoodList,
                _amountRestrictions,
                _prepareFoodListToSend
            ], state);
        case CHANGE_DISH_PRICE:
            return _setDishPrice(state, action.payload);
        case CHANGE_DISH_AMOUNT:
            return _setDishAmount(state, action.payload);
        case SHOW_BLOCK_POPUP:
            return _showBlockPopup(state);
        case HIDE_BLOCK_POPUP:
            return _hideBlockPopup(state);
        case PREPARE_MENU_TO_SEND:
            return _prepareFoodListToSend(state);
        case SEND_SUSPEND_START:
            return {
                ...state,
                suspendSendingStart: true,
                suspendSendingSuccess: false,
            }
        case SEND_SUSPEND_SUCCESS:
            return _compose([
                _updateSuspendInfo,
            ], state, action.payload);
        case PUBLISHED_SUCCESS:
            // return _updatePeriodToPublic(state, action.payload);
        case SEND_MENU_START:
            return {
                ...state,
                showSendPreloader: true,
                menuSendStart: true,
                menuSendSuccess: false,
            };
        case SEND_MENU_SUCCESS:
            return {
                ...state,
                showSendPreloader: false,
                menuSendStart: false,
                menuSendSuccess: true,
            };
        case CLEAR:
            return initialState;
        case COPY_MENU_DATA_SET_DATE:
            const date = action.payload.date ? action.payload.date : null;
            return {
                ...state,
                copyMenuData: {
                    ...state.copyMenuData,
                    date: date,
                },
            };
        case COPY_MENU_DATA_SET_PERIOD:
            const periodId = action.payload.periodId ? action.payload.periodId : null;
            return {
                ...state,
                copyMenuData: {
                    ...state.copyMenuData,
                    periodId: periodId,
                },
            };
        case SEND_COPY_DATE_START:
            return {
                ...state,
                copyMenuSendingStarted: true,
            };
        case SEND_COPY_DATE_SUCCESS:
            return {
                ...state,
                copyMenuSendingStarted: false,
                copyMenuSendingSuccess: true,
            };

        default:
            return state;

    }
}

//Common handling functions
const _compose = (funcArray, state, payload) => {
    return funcArray.reduce((acc, el) => {
        acc = el(acc, payload);
        return acc;
    }, state)
};
let _rollBackFoodListState = function (state) {
    const tempFoodListState = state.foodListState.map(el => {
        return {
            ...el,
            elements: [
                ...el.elements.map(subEl => {
                    return {...subEl}
                })
            ]
        }
    });
    const tempState = {
        ...state,
        foodListState: [...tempFoodListState]
    };
    return function () {
        return tempState;
    }
};
//
const _setAccessibleRests = (state, payload) => {
    return {
        ...state,
        accessibleRests: [...payload.items],
    }
};
const _setActiveRests = (state, payload) => {
    return {
        ...state,
        activeRest: payload
    }
};
const _setMenuInfoPeriod = (state, payload) => {
    return {
        ...state,
        getDaysPeriodsIsStarted: false,
        getDaysPeriodsIsSuccess: true,
        sliderState:
            {
                ...state.sliderState,
                ...payload
            }
    }
};
function _setToday (){
    const date = new Date();
    return {
        date: date,
        toString: () => {
            return `${moment(date).format('dddd').substr(0, 2)} ${moment(date).format("D MMM")}`;
        }
    }
}
function _setDayRange (today) {
    const dayRangeStart = -28;
    const dayRangeEnd = 14;
    let tempTime = moment(today.date).subtract(dayRangeEnd, 'days').format();
    const daysArray = [];
    for(let i = dayRangeStart; i <= dayRangeEnd - 1; i++) {
        daysArray.push(tempTime);
        tempTime = moment(tempTime).add(1, 'days').format();
    }
    return daysArray;
}
function _setShowDays (range, minVal, maxVal) {
    return range.slice(minVal, maxVal)
}
const _sliderShift = (state, direction) => {
    let minVal, maxVal;
    if( direction === SLIDER_SHIFT_FORWARD) {
        minVal = state.sliderState.minVal + 1;
        maxVal = state.sliderState.maxVal + 1;
    }
    if ( direction === SLIDER_SHIFT_BACK ) {
        minVal = state.sliderState.minVal - 1;
        maxVal = state.sliderState.maxVal - 1;
    }
    const range = state.sliderState.dayRange;
    const currentShowDays = _setShowDays(range, minVal, maxVal);

    return {
        ...state,
        sliderState: {
            ...state.sliderState,
            minVal: minVal,
            maxVal: maxVal,
            currentShowDays: [...currentShowDays],
        },
    };
};
const _setActivePeriod = (state, payload) => {
    // console.log(payload)
    return {
        ...state,
        publicBarState: {
            ...state.publicBarState,
            activePeriod: {...payload},
            status: payload.periodIsDefined.length
                ? payload.periodIsDefined
                : false,
        },
        preparedFoodListData: []
    }
};

const _checkPublishStatus = (statusArray, payload) => {
    let result = false;
    for(let i = 0; i < statusArray.length; i++) {
        if(statusArray[i].periodId === payload.periodId) {
            result = statusArray[i].status;
        }
    }
    return result;
};
const _toggleActiveFoodListDish = (state, payload) => {
    let newFoodListState = state.foodListState;
    const targetElement = newFoodListState[payload.listId].elements[payload.dishId];
    targetElement.isActive = !state.foodListState[payload.listId].elements[payload.dishId].isActive;
    return {
        ...state,
        foodListState: [...newFoodListState]
    };
};
const _setActiveDishesFoodListCount = state => {
    const newFoodListState = [...state.foodListState];
    newFoodListState.map(el => {
        el.activeCount = el.elements.reduce((acc, el) => {
            if (el.isActive) {
                acc += 1;
            }
            return acc;
        }, 0);
    });
    return {
        ...state,
        foodListState: [...newFoodListState]
    }
};
const _activeDishesFoodListObserver = state => {
    let newFoodListState = [...state.foodListState];
    newFoodListState = newFoodListState.map(el => {
        el.isActive = el.activeCount === el.elements.length;
        return el;
    });
    return {
        ...state,
        foodListState: [...newFoodListState]
    }
};
const _toggleActiveFoodListCategory = (state, payload) => {
    const newFoodListState = [...state.foodListState];
    if(newFoodListState[payload.idx].isActive){
        newFoodListState[payload.idx].elements = newFoodListState[payload.idx].elements.map(el => {
            el.isActive = false;
            return el;
        });
    } else {
        newFoodListState[payload.idx].elements = newFoodListState[payload.idx].elements.map(el => {
            el.isActive = true;
            return el;
        });
    }
    return {
        ...state,
        foodListState: [...newFoodListState]
    }
};
const _activeEditMode = state => {
    return {
        ...state,
        editMode: true,
    }
};
const _revertFoodList = () => {
    const revertState = tempFoodListState();
    return {
        ...revertState
    };
};
const _applyFoodListChanges = state => {
    return {
        ...state,
        editMode: false,
    }
};
const _parseDataToFoodList = (state, payload) => {
    // этот говномассив фиксит хуйню которая прилетает с сервера на фронт, данные могут рандомно раздваеватся,
    // я смог побороть это таким образом, так как бекендер не знает как это устранить.
    // ясен хуй это не устранение проблемы, но мои ручки чистенькие так как у меня это работает, а фикс бэка не мой стак!
    const stupidIdea = []
    const newFoodList = payload.categories.reduce((acc, el) => {
        acc.push({
            header: el.name,
            isActive: false,
            activeCount: 0,
            elements: payload.menu.filter(sub => sub.categoryIds === el.id).reduce((acc, el) => {
                if(!stupidIdea.includes(el.name)){
                    let extra;
                    if( el.extra ) {
                        extra = JSON.parse(el.extra);
                    }
                    acc.push({
                        id: el.id,
                        name: el.name,
                        price: el.price || el.defaultPrice,
                        evaluation: extra ? (+extra.ratingValue).toFixed(1) : null,
                        reviewCount: extra ? extra.countReview : null,
                        isActive: el.productStatus === "on",
                        count: el.amountForOrder !== "0" ? el.amountForOrder : null,
                        amoutForOrderAlonePerson: el.amoutForOrderAlonePerson,
                    });
                }
                stupidIdea.push(el.name)
                return acc;
            }, [])
        });
        return acc;
    }, []);
    return {
        ...state,
        foodListState: [...newFoodList],
    }
};
const _hideEmptyCategory = state => {
    const newFoodListState = [...state.foodListState];
    const newA = newFoodListState.filter(el => {
        if(el.elements.length !== 0){
            return el;
        }
    });
    return {
        ...state,
        foodListState: [...newA],
    }
};
const _markActiveCategory = state => {
    const newFoodListState = [...state.foodListState];
    const newA = newFoodListState.map(el => {
        if (el.activeCount === el.elements.length) {
            el.isActive = true;
        }
        return el;
    });

    return {
        ...state,
        foodListState: [...newA],
    }
};
const _setActiveFoodList = state => {
    return {
        ...state,
        activeFoodList: state.foodListState.reduce((acc, el) =>  {
            if(el.activeCount !== 0) {
                const prepareValue = {...el, elements: el.elements.filter(el2 => {
                            if (el2.isActive) {
                                return el2;
                            }
                        })
                    };
                acc = [...acc, prepareValue];
            }
            return acc;
        }, [])
    };
};
const _setDishPrice = (state, payload) => {
    let newFoodListState = [...state.foodListState];
    const targetElement = newFoodListState[payload.listId].elements[payload.dishId];
    targetElement.price = payload.newPrice;
    return {
        ...state,
        foodListState: [...newFoodListState]
    };
};
const _setDishAmount = (state, payload) => {
    let newFoodListState = [...state.foodListState];
    const targetElement = newFoodListState[payload.listId].elements[payload.dishId];
    targetElement.count = payload.newAmount;
    return {
        ...state,
        foodListState: [...newFoodListState]
    };
};
const _amountRestrictions = state => {
    const amountRestrictionsCount = state.activeFoodList.reduce((acc, el) => {
        acc += el.elements.reduce((acc, el) => {
            if(+el.count === 0) {
                acc++;
            }
            return acc;
        }, 0);
        return acc;
    }, 0);
    return {
        ...state,
        publicBarState: {
            ...state.publicBarState,
            amountRestrictionsChecker: amountRestrictionsCount,
        }
    };
};
// const _setTotalPrice = state => {
//     const amountRestrictionsCount = state.activeFoodList.reduce((acc, el) => {
//         acc += el.elements.reduce((acc, el) => {
//             if(+el.price === 0) {
//                 acc++;
//             }
//             return acc;
//         }, 0);
//         return acc;
//     }, 0);
//     return {
//         ...state,
//         publicBarState: {
//             ...state.publicBarState,
//             amountRestrictionsChecker: amountRestrictionsCount,
//         }
//     };
// }
const _showBlockPopup = state => {
    return {
        ...state,
        loadPopupArea: true,
        showBlockPopup: true,
    }
};
const _hideBlockPopup = state => {
    return {
        ...state,
        loadPopupArea: false,
        showBlockPopup: false,
    }
};
const _updateSuspendInfo = (state, payload) => {
    const distractPayload = {...payload.payload};
    return {
        ...state,
        sliderState: {
            ...state.sliderState,
            menuInfoPeriod: state.sliderState.menuInfoPeriod.map(el => {
                if ( el.id === distractPayload.periodSearchId ) {
                    el.serviceSuspended = distractPayload.serviceSuspended;
                }
                return el;
            })
        },
        publicBarState: {
            ...state.publicBarState,
            activePeriod: {
                ...state.publicBarState.activePeriod,
                serviceSuspended: distractPayload.serviceSuspended,
            },
        },
        suspendSendingStart: false,
        suspendSendingSuccess: true,
}
};
const _prepareFoodListToSend = state => {
    return {
        ...state,
        preparedFoodListData: state.activeFoodList.reduce((acc, el) => {
            const preparedData = el.elements.filter(subEl => {
                if(subEl.isActive) {
                    return subEl
                }
            }).map(el => {
                return {
                    id: el.id,
                    amountForOrder: el.count,
                    modifiedPrice: el.price,
                    amoutForOrderAlonePerson: el.price.amoutForOrderAlonePerson || null,
                };
            });
            acc = [...acc, ...preparedData];
            return acc;
        }, [])
    };
};
const _resetActivePeriod = state => {
    return {
        ...state,
        publicBarState: {
            ...state.publicBarState,
            activePeriod: {},
        }
    };
};
const _markMenuIsSuccess = (state) => {
    return {
        ...state,
        getMenuIsStarted: false,
        getMenuIsSuccess: true,
    }
};
