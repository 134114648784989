import React from "react";
import styled from "styled-components";


export const FormControls = ({saveHandler, cancelHandler,disableBtn}) => {
const cancelHand=(e)=>{
  cancelHandler()
  e.stopPropagation()
}

    return (
        <FormControlsWrapper>
            <Btn.save  style={{marginRight: 18}} disabled={disableBtn} disProp={disableBtn} onClick={saveHandler}>Отправить на проверку</Btn.save>
            <Btn.cancel onClick={cancelHand}>Отмена</Btn.cancel>
        </FormControlsWrapper>
    )
}

const FormControlsWrapper = styled.div`
  display: flex;
  padding:0px;
  justify-content: flex-start;
`
const BtnParent = styled.button`
  border-radius: 6px;
  padding: 8px 16px;
  cursor: pointer;
  transition: all .2s ease-out;
`
const Btn = {

    save: styled(BtnParent)`
      background:  ${({disProp})=> disProp ? '#BBBBBB;':'#3D425E'};
      color: ${({disProp})=>disProp ?'#DDDDDD;':'#fff'};
      border: 1px solid transparent;
      :hover {
        border: ${({disProp})=>disProp ? '1px solid transparent': '1px solid #3D425E'} ;
        background: ${({disProp})=>disProp ?'#BBBBBB':'#fff' } ;
        color: ${({disProp})=>disProp ? '#DDDDDD': 'inherit'};
      }
    `,
    cancel: styled(BtnParent)`
      background: #fff;
      border: 1px solid #DDDDDD;
      
      :hover {
        border: 1px solid #3D425E;
      }
    `,
}