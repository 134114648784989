import React, {memo, useEffect, useState} from "react";
import styled from "styled-components";
import Courier from "../Courier"
import {gridLayout} from "../CourierSideBar"
import {cutTime} from "../CouriersStatistic"
import {useDispatch, useSelector} from "react-redux"
import {getCourierTrack, toggleShowCourierTrack} from "../../../reducers/toolkit/orderMapReducer"

//по клику на Timer отображает пройденный путь курьера
const CourierOnLineListItem = ({courierData, id, showCourierDetails}) => {
  const dispatch = useDispatch()

  const date = useSelector(store => store.orderMap.date)
  const periodId = useSelector(store => store.orderMap.deliveryPointsFilter.selectedPeriodId)
  const targetCourierForTrackDraw = useSelector(store => store.orderMap.targetCourierForTrackDraw)
  // console.log(courierData, id, showCourierDetails,"DATAATATA")
  const {ordersCount, finishOrdersCount, timeToStart, timeToFinish} = courierData
  const percentOfFinishedOrders = courierData.ordersCount === undefined ? '-': (100 / ordersCount) * finishOrdersCount
  const orders = courierData.ordersCount === undefined ? 0 : ordersCount
  const timeToStartCut = timeToStart ? cutTime(timeToStart.split(' ')[1]) : ''
  const timeToFinishCut = timeToFinish ? cutTime(timeToFinish.split(' ')[1]) : ''
  const distance = courierData.distance === undefined? '-': (+courierData.distance / 1000).toFixed(1) 
  const sum = courierData.sumPrice === undefined ? '-' : (courierData.sumPrice + '').split(/([0-9]{3}$)/).join(' ') 
  const cash = courierData.sumPriceResultCash === undefined ? '-' :(courierData.sumPriceResultCash + '').split(/([0-9]{3}$)/).join(' ') 
  const salary = courierData.salary === undefined ? '-' :((+courierData.salary).toFixed(0) + '').split(/([0-9]{3}$)/).join(' ') 

  const showCourierTrack = () => {
    if(targetCourierForTrackDraw?.id === courierData.id){
      dispatch(toggleShowCourierTrack())
    } else {
      dispatch(toggleShowCourierTrack(courierData))
      dispatch(getCourierTrack({
        personIds: [courierData.id],
        date: date.format('YYYY-MM-DD'),
        periodId: periodId
      }))
    }
  } 
  // console.log(sum,cash,salary)

  return (
    <CourierOnLineWrapper showDetails={showCourierDetails}>
      <div>
        <Courier {...{courierData, id}}/> 
      </div>
      {
        showCourierDetails ? (
          <>
            <p>{distance}</p>
            <p>{sum}</p>
            <p>{cash}</p>
            <p>{salary}</p>
          </>
        ) : (
          <>
            <p>{`${finishOrdersCount || "0"}/${orders}`}</p>
            <Timer
              onClick={showCourierTrack}
              {...{percentOfFinishedOrders}}>{timeToStartCut}  {timeToFinishCut}</Timer>
          </>
        )
      }
    </CourierOnLineWrapper>
  )
}

export default memo(CourierOnLineListItem);

const CourierOnLineWrapper = styled(gridLayout())`
  align-items: center;

  p {
    margin: 0;
    
    @media (max-width: 480px) {
      font-size: 8px;
      line-height: 11px;
    }
  }
`
const Timer = styled.div`
  padding: 2px 5px;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
  border: 1px solid silver;
  font-size: 14px;
  cursor: pointer;

  :before {
    content: '';
    position: absolute;
    left: ${({percentOfFinishedOrders}) => percentOfFinishedOrders ? `${-100 + percentOfFinishedOrders}%` : '-100%'};
    top: 0;
    width: 100%;
    height: 100%;
    background: #7AFF8F;
    z-index: -1;
  }
  
  @media (max-width: 480px) {
    font-size: 10px;
    line-height: 13px;
  }
`
