import {takeEvery} from "@redux-saga/core/effects";
import {
    DELETE_WAYBILL, GET_DOWNLOAD_LINK,
    GET_PRODUCT_LIST_BY_RECIPIENT,
    GET_RECIPIENT,
    GET_SENDER,
    GET_WAYBILL,
    GET_WAYBILL_DETAILS,
    SEND_WAYBILL, UPDATE_WAYBILL,
} from "../../actions/WarehouseActions";
import {
    getRecipientWorker,
    getSenderWorker,
    getWaybills,
    getWProductsOfWaybill,
    getProducts,
    sendWaybill, deleteWaybillOnServer, updateWaybill, getStorageDownloadLink
} from "./warehouseWorkers";

function* warehouseWatcher() {
    yield takeEvery(GET_RECIPIENT, getRecipientWorker)
    yield takeEvery(GET_SENDER, getSenderWorker)
    yield takeEvery(GET_WAYBILL, getWaybills)
    yield takeEvery(GET_WAYBILL_DETAILS, getWProductsOfWaybill)
    yield takeEvery(GET_PRODUCT_LIST_BY_RECIPIENT, getProducts)
    yield takeEvery(SEND_WAYBILL, sendWaybill)
    yield takeEvery(DELETE_WAYBILL, deleteWaybillOnServer)
    yield takeEvery(UPDATE_WAYBILL, updateWaybill)
    yield takeEvery(GET_DOWNLOAD_LINK, getStorageDownloadLink)
}

export default warehouseWatcher;
