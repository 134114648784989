import React from "react";
import styled from "styled-components";
import SenderSelector from "../SenderSelector";
import RecipientSelector from "../RecipientSelector";
import {selectStyles} from "../Header"
import DefaultBtn from "../../share/UI/DefaultBtn"
import RangePicker from "../../share/UI/RangePicker"
import {useDispatch} from "react-redux"
import {selectDateRange, toggleModal} from "../../../actions/WarehouseActions"

const WaybillsHeader = ({columnFlex, rowFlex}) => {

    const dispatch = useDispatch()

    const setDate = (rangeObject) => {
        dispatch(selectDateRange(rangeObject))
    }

    return (
        <div>
            <Controls>
                <div style={columnFlex}>
                    <Control>
                        <p>Поставщик</p>
                        <SenderSelector style={selectStyles}/>
                    </Control>
                    <Control>
                        <p>Получатель</p>
                        <RecipientSelector style={selectStyles}/>
                    </Control>
                </div>
                <div style={{...rowFlex, marginBottom: 'auto'}}>
                    <Control>
                        <RangePicker callback={setDate}/>
                    </Control>
                </div>
                <DefaultBtn style={{marginLeft: 'auto'}} handler={() => dispatch(toggleModal('CREATE'))}>
                    <span style={{
                        fontSize: 22,
                        fontWeight: 300,
                    }}>Создать</span>
                    <span style={{
                        fontSize: 22,
                    }}>+</span>
                </DefaultBtn>
            </Controls>
        </div>
    )
}

export default WaybillsHeader;

const Controls = styled.div`
  display: flex;
  align-items: center;
  
  div:first-child{
    padding-left: 0;
  }
  & > div {
    padding: 10px;
  }
`
const Control = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  p {
    margin: 0 20px 0 0;
  }
`