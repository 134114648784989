import React from "react";
import styled from "styled-components";
import PayVariant from "../../share/OrderMapCommonComponents/PayVariant"
import moment from "moment"
import {GlobalIcon, PhoneIcon, TerminalIcon} from "../../../images/svgs"

const identifyOrderSource = (systemSource) => {
  //приложение
  const app = 'com.menu4me.dinner.sitiyoffice'

  //киоск (он же терминал)
  const terminal = 'com.menu4me.dinner.kiosk.so'

  //сайт
  const site = [
    'com.menu4me.dinner.site.sitiyoffice',
    'com.menu4me.dinner.site.mis163',
    'com.menu4me.dinner.site.51obed',
    'com.menu4me.dinner.site.straus'
  ]

  switch (systemSource){
    case app:
      return <PhoneIcon />
    case terminal:
      return <TerminalIcon />
    default:
      return site.includes(systemSource) && <GlobalIcon />
  }
}

const PersonalOrderListItem = ({orderData}) => {

  const {restName, priceCash, priceCard, pricePoint, created, systemSource} = orderData

  const formatDate = moment(created).format('MM.DD hh:mm')

  return (
    <Wrapper>
      <OrderMain>
        <RestName>
          {restName}
        </RestName>
        <PayVariant fullPaymentData={{cash: priceCash, card: priceCard, points: pricePoint}}/>
      </OrderMain>
      <OrderMeta>
        <OrderCreationTime>
          <p>{formatDate}</p>
        </OrderCreationTime>
        <OrderSource>
          {identifyOrderSource(systemSource)}
        </OrderSource>
      </OrderMeta>
    </Wrapper>
  )
}

export default PersonalOrderListItem;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0;
`

const OrderMain = styled.div`
  display: flex;
  justify-content: space-between;
`
const RestName = styled.div`

`

const OrderMeta = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
`
const OrderCreationTime = styled.div`
  width: max-content;
  display: flex;
  padding: 2px;
  border-radius: 6px;
  border: 1px solid rgba(114, 114, 114, 0.24);
`
const OrderSource = styled.div`
  width: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
`
const OrderMetaInfo = styled.div`

`
