import React from 'react'
import styled from 'styled-components'
import { Content } from '../../components/NewReports/Content'
const Statistics = () => {
  return (
    <Wrappers>
      <Content/>
    </Wrappers>
  )
}

export default Statistics
const Wrappers = styled.div`
 display: grid; 
height: calc(100vh - 64px); 
margin-top:30px;
 justify-content: center;
/* align-items: center;  */

`