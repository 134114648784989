import React from 'react'
import styled from 'styled-components'
import AdminCheckBox from '../../UI_ADMIN/AdminCheckBox'

const Version = () => {
  return (
    <Wrappers>
      Здесь будет раздел "Крайняя версия приложения"
    </Wrappers>
  )
}

export default Version
const Wrappers = styled.div`
display: grid;
height: calc(100vh - 64px);
justify-content: center;
align-items: center;
color:#161616;
font-size: 30px;
font-weight: 600;
`