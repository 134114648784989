import React, {memo, Profiler, useEffect, useState} from "react";
import styled from "styled-components";
import MapComponent from "../../components/OrderMap/MapComponent"
import InfoSidebar from "../../components/OrderMap/InfoSidebar"
import {useDispatch, useSelector} from "react-redux"
import {getOperatorPeriodsInit} from "../../reducers/toolkit/comonReducer"
import Controls from "../../components/OrderMap/Controls"
import {toggleInfoSidebar, toggleModalWrapper} from "../../reducers/toolkit/orderMapReducer"
import {AnimatePresence} from "framer-motion"
import RightControlPanel from "../../components/OrderMap/RightControlPanel"
import CouriersControlButton from "../../components/OrderMap/CouriersControlButton"
import ReloadPointsButton from "../../components/OrderMap/ReloadPointsButton"
import CourierSideBar from "../../components/OrderMap/CourierSideBar"
import DrawPolygonButton from "../../components/OrderMap/DrawPolygonButton"
import ModalWrapper from "../../components/OrderMap/ModalWrapper"
import store from "../../store"
import RedirectOrdersFromCourierToCourier, {MODAL_NAME_REDIRECT_ORDERS} from "../../components/OrderMap/Modals/RedirectOrdersFromCourierToCourier"
import PushMessageToClient, {MODAL_NAME_PUSH_MESSAGE} from "../../components/OrderMap/Modals/PushMessageToClient"


export function toggleModal(modal = null) {
  if(modal) {
    store.dispatch(toggleModalWrapper({modalName: modal}))
  } else {
    store.dispatch(toggleModalWrapper())
  }
}

const OrderMap = () => {
  const dispatch = useDispatch()
  const showModal = useSelector(store => store.orderMap.showModal)
  const activeModal = useSelector(store => store.orderMap.activeModal)
  const OptionCenter = useSelector(store => store.orderMap.OptionCenter)
  const selectedOrdersInstances = useSelector(store => store.orderMap.selectedOrdersInstances)
  const date = useSelector(store => store.orderMap.date)
  const allPeriodsByDate = useSelector(store => store.common.allPeriodsByDate)
  const showPopupWrapper = useSelector(store => store.reviews.showPopupWrapper)
  //sidebars size
  const [infoSidebarSize, setInfoSidebarSize] = useState(330)
  const [couriersSidebarSize, setCouriersSidebarSize] = useState(470)
  const [controlsXShift, setControlsXShift] = useState(null)
  const [coloredPoints, setColoredPoints] = useState([]) //основной массив ордеров, содержащих ссылку на собственный маркер на карте
  const [showCourierSidebar, setShowCourierSidebar] = useState(false)
  const [showCourierDetails, setShowCourierDetails] = useState(false)

  useEffect(() => {
    setControlsXShift(couriersSidebarSize)
  }, [couriersSidebarSize])

  useEffect(() => {
    if (date) {
      dispatch(getOperatorPeriodsInit({dates:date,value:OptionCenter}))
    }
  }, [date,OptionCenter])
   
  useEffect(() => {
    if(selectedOrdersInstances.length) {
      dispatch(toggleInfoSidebar(true))
    }
  }, [selectedOrdersInstances])

//console.log(allPeriodsByDate,'allPeriodsByDateallPeriodsByDateallPeriodsByDate')

  return (
    <OrderMapWrapper>
      <AnimatePresence initial={false}>
        <InfoSidebar {...{infoSidebarSize, coloredPoints}} />
      </AnimatePresence>
      {/*панель с кнопками справа*/}
      <RightControlPanel {...{showCourierSidebar, controlsXShift}}>
        <DrawPolygonButton />
        <ReloadPointsButton/>
        <Controls/>
        <CouriersControlButton {...{showCourierSidebar, setShowCourierSidebar}}/>
      </RightControlPanel>
      <AnimatePresence initial={false}>
        <CourierSideBar {...{
          coloredPoints,
          showCourierSidebar,
          setShowCourierSidebar,
          couriersSidebarSize,
          setCouriersSidebarSize,
          setShowCourierDetails,
          showCourierDetails
        }} />
      </AnimatePresence>
      <MapComponent {...{setColoredPoints,}}/>
      {
        showModal && (
          //контроллер модалок
          <ModalWrapper>
            {(activeModal === MODAL_NAME_REDIRECT_ORDERS) && <RedirectOrdersFromCourierToCourier />}
            {(activeModal === MODAL_NAME_PUSH_MESSAGE) &&  <PushMessageToClient /> }
          </ModalWrapper>
        ) 
      }
    </OrderMapWrapper>
  )
}

export default memo(OrderMap);

const OrderMapWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 64px);
  overflow: hidden;
  position: relative;
`
