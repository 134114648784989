import React from 'react'
import styled from 'styled-components'
export const Btn = (props) => {
  return (
    <WrappBtn  style={props.style ?{...props.style} : null} onClick={props.handler}>
    {props.children}
   </WrappBtn>
  )
}
const NavItem = styled.div`
border: 2px solid #7882A7;
background-color: ${({isSelect})=>isSelect ? '#7882A7': '#FFFF'};
/* background-color: #FFFF; */
color:${({isSelect})=>isSelect ?'#FFFF':'#111111'};
border-radius: 6px;
margin-right: 10px;
display: flex;
justify-content: center;
align-items: center;
font-weight: 600;
font-size: 16px;
height: 50px;
cursor: pointer;
    :hover{
        background-color: #7882A7;
        color:#FFFF;
    }
`
const WrappBtn = styled(NavItem)`
background-color:#7882A7;
margin-bottom: 15px;
align-items: flex-start;
justify-self: flex-start;
color:#FFFF;
padding: 15px;
align-items: center;
 :hover{
     color:#111111;
 }
`