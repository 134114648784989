import React, {memo, useState,useEffect} from "react";
import styled from "styled-components";
import {sr} from "../../utils";
import {Checkmark, CommentIcon, Cross, EditIcon, HouseIcon} from "../../images/svgs"
import {getCenterInfo, getCenterInfoUpdate, updateAddrComment, updateOrderComment} from "../../reducers/toolkit/orderMapReducer"
import {useSelector, useDispatch} from "react-redux"
import {Select, Switch} from "antd";

const Comments = ({parentOrderID, detailedOrderInfo,commentAddr, commentOrder, ordId, companyId,centerIds,centerId}) => {
  const {Option} = Select;

  const dispatch = useDispatch()
  const [editCommentAddr, setEditCommentAddr] = useState(false)
  const [addrCommentVal, setAddrCommentVal] = useState(detailedOrderInfo.commentAddr || '')

  const [editOrderComment, setEditOrderComment] = useState(false)
  const [orderCommentVal, setOrderCommentVal] = useState(detailedOrderInfo.commentOrder || '')

  const [center, setCenter] = useState([])
  const [selectedCenter,setSelectedCenter] = useState(centerIds)

  const [selectedCenterName, setSelectedCenterName] = useState(null)

  const {getCenterInfoStatus, centerInfoUpdateStatus, centerInfo} = useSelector(store => store.orderMap)

  const Request = async () => {
    await sr('SettingsService', 'GetOperatorPermissions', {}).then(result => {
    setCenter(result.data.centers)
    })
  }
  useEffect(() => {
    if (center && center.length) {
      getCenter()
    }
    if (centerInfoUpdateStatus === 'resolved') {
      getCenter(centerInfo.centerId)
    }
  }, [center, centerInfoUpdateStatus])

  function getCenter(id = null){
    let cent;
    if (id) {
      cent = center.find(item=> item.centerId === id)
    } else {
      cent = center.find(item=> item.centerId === centerIds)
    }

    cent && setSelectedCenterName(cent.name)
  }

  useEffect(() => {
    Request()
  }, [])
  return (
    <CommentsWrapper>
      <AdminComment>
        <CommentWrapper>
          <IconWrapper>
            <HouseIcon/>
          </IconWrapper>
          {
            editCommentAddr ? (
              <CommentTextArea value={addrCommentVal} onChange={(e) => setAddrCommentVal(e.target.value)}/>
            ) : (
              <p>{addrCommentVal || 'Добавить коментарий...'}</p>
            )
          }
        </CommentWrapper>
        {
          editCommentAddr ? (
            <Controls>
              <ApplyComment onClick={() => {
                dispatch(updateAddrComment({
                  commentType: 'order',
                  company: {
                    id: companyId,
                    extra: {
                      comment: addrCommentVal
                    }
                  }
                }))
                setEditCommentAddr(false)
              }}>
                <Checkmark />
              </ApplyComment>
              <CancelComment onClick={() => {
                setAddrCommentVal(commentAddr)
                setEditCommentAddr(false)
              }}>
                <Cross />
              </CancelComment>
            </Controls>
          ) : (
            <EditButton onClick={() => setEditCommentAddr(true)}>
              <EditIcon/>
            </EditButton>
          )
        }
      </AdminComment>
      <CommentOrder>
        <CommentWrapper>
          <IconWrapper>
            <CommentIcon/>
          </IconWrapper>
          {
            editOrderComment ? (
              <CommentTextArea value={orderCommentVal} onChange={(e) => setOrderCommentVal(e.target.value)}/>
            ) : (
              <p>{orderCommentVal || 'Добавить коментарий...'}</p>
            )
          }
        </CommentWrapper>
        {
          editOrderComment ? (
            <Controls>
              <ApplyComment onClick={() => {
                dispatch(updateOrderComment({
                  commentType: 'addr',
                  orderId: ordId,
                  comment: orderCommentVal
                }))
                setEditOrderComment(false)
              }}>
                <Checkmark />
              </ApplyComment>
              <CancelComment onClick={() => {
                setAddrCommentVal(commentAddr)
                setEditOrderComment(false)
              }}>
                <Cross />
              </CancelComment>
            </Controls>
          ) : (
            <EditButton onClick={() => setEditOrderComment(true)}>
              <EditIcon/>
            </EditButton>
          )
        }
      </CommentOrder>
       
        {centerIds ? 
        <>
      <CommentWrapper>
        <Select 
        style={{width:'200px'}} 
        placeholder='Поменять рц'
        value={selectedCenter}
        onChange={(value)=>setSelectedCenter(value)}
        >
        {center.map(v => {
        return (
           <Option key={v.centerId} value={v.centerId}>
              {v.name}
           </Option>
                )
           })}
        </Select>
        <button style={{border:'none'}}
        onClick={()=>dispatch(getCenterInfoUpdate({
          orderId:parentOrderID,
          centerId:selectedCenter,
        }))}
        >Ок</button>
      </CommentWrapper>
      </>
      :
      ""
        }
    </CommentsWrapper>

  )
}

export default memo(Comments);

const CommentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`
const Comment = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
`
const AdminComment = styled(Comment)`

`
const CommentOrder = styled(Comment)`

`
const CommentWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  gap: 5px;

  > p {
    margin-right: auto;
    line-height: 1em;
  }
`

const CommentTextArea = styled.textarea`
  width: 100%;
  overflow: initial;
  resize: none;
  border: 1px solid silver;
  border-radius: 6px;
`
const IconWrapper = styled.div`
  min-width: 15px;
  align-self: flex-start;
`
const EditButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: 1px solid transparent;
  width: 25px;
  height: 25px;
  border-radius: 6px;
  cursor: pointer;

  :hover {
    border: 1px solid rgba(0, 0, 0, 0.18);
  }
`

const Controls = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`
const ApplyComment = styled(EditButton)`

`
const CancelComment = styled(EditButton)`

`
