import React, {useCallback} from "react";
import {Dropdown, Menu} from "antd";
import {useDispatch, useSelector} from "react-redux";
import styled from "styled-components";
import {permissionsClear} from "../reducers/toolkit/mainAppReducer"
import {deleteToken} from "../reducers/toolkit/profileReducer"


const DropdownMenu = ({children}) => {

  const dispatch = useDispatch()
  const isAuth = useSelector(store => store.profile.isAuth)

  const deauthHandler = useCallback(
    () => {
      localStorage.removeItem("token");
      localStorage.removeItem("kitchens")
      localStorage.removeItem("session");
      dispatch(permissionsClear())
      dispatch(deleteToken())
    },
    [],
  );


  const dropdown = () => (
    <Menu>
      <Menu.Item>Настройки профиля</Menu.Item>
      <Separator/>
      {
        isAuth && (
          <Menu.Item
            onClick={deauthHandler}
            style={{
              minWidth: 150,
            }}>
            Выйти
          </Menu.Item>
        )
      }
    </Menu>
  );

  return (
    <Dropdown overlay={dropdown()}>
      {children}
    </Dropdown>
  )
}

export default DropdownMenu;

const Separator = styled.div`
  width: 90%;
  height: 1px;
  margin: 5px auto;
  background: #3D425D;
`
