import React,{useState,useEffect,useRef} from 'react'
import PropTypes from 'prop-types'
import styled from "styled-components";
import moment from "moment";
import { useDispatch,useSelector} from 'react-redux';
import {deliveryCycleInfoMessage} from '../../actions/ModalActions'
import {Input, Switch,Select} from "antd";
import { Btn } from '../../components/Settings/UI/Btn';
import Cutoff from '../../components/Settings/AdditionalSettings/Components/Cutoff';
import { sr } from '../../utils';

const {Option} = Select;
const weeks = [
    {id:1,name:'Понедельник'},
    {id:2,name:'Вторник'},
    {id:3,name:'Среда'},
    {id:4,name:'Четверг'},
    {id:5,name:'Пятница'},
    {id:6,name:'Суббота'},
    {id:7,name:'Воскресенье'},
]
const AdditionalSettigns = props => {
    const [commercialName,setCommercialName] = useState('')
    const [descriptionKitchen, setDescriptionKitchen] = useState('')
    const [availableOrder,setAvailableOrder] = useState('')
    const [getDataKitchen,setGetDataKitchen] = useState('')
    const [testersPhone,setTestersPhone] = useState('')
    const [typeKitchen,setTypeKitchen] = useState('')
    const [tagsKitchen,setTagsKitchens] = useState([])
    const [averageСheque,setAverageСheque] = useState('')
    const [displayRating,setDisplayRating] = useState('')
    const [deliverySelfRest,setDeliverySelfRest] = useState('')
    const [kitchenPlateImg,setKitchenPlateImg] = useState('')
    const [emailSendOrders,setEmailSendOrders] = useState('')
    const [emailForFeedback,setEmailForFeedBack] = useState('')
    const SelectedKitchens = useSelector(store => store.appMain.selectedKitchens)
    const getPeriods = ()=>{
      sr('SettingsService','GetOperatorPeriods',{})
      .then(res=>{
          if(res.result){
            setTagsKitchens(res.data.periods)
          }
      })
    }
    useEffect(()=>{
      getPeriods()
    },[])
  return (
    <>
    <WrapperMain>
    {
      SelectedKitchens.name !== 'Не выбрано'
       ?
    <WrapperSettings>
            <ItemsBlockCourier>
                <HeaderItems>
                  Отсечки
                </HeaderItems>
                <div style={{width:'auto',borderRadius:8}}>
                {tagsKitchen?.map(per=>
                <Cutoff key={per.id} {...{per}}/>
                )
                }
                </div>
            </ItemsBlockCourier>
            <ItemsBlock>
              <HeaderItems>
                Сбербанк
              </HeaderItems>
              <ElementItem>
                  <div style={{display:'flex',fontSize:16}}>
                  <p >Логин</p>
                  <Input
                  auto
                  />
                  </div>
                  <div style={{display:'flex',marginTop:20,fontSize:15}}> 
                    <p>Пароль</p>
                   <Input
                   type='password'
                   />
                   </div>
              </ElementItem>
            </ItemsBlock>
            <Btn style={{marginLeft:15}}>
              Сохранить изменения
            </Btn>
    </WrapperSettings>
    :
    <div style={{
      fontSize:'30px',
      fontWeight:700,
      margin:'0 auto',
      marginTop:'20%',
      color:'#111111'
    }}>
      Выберите кухню
    </div>
              }
              </WrapperMain>
      </>
  )
}


export default AdditionalSettigns
const WrapperMain = styled.div`
padding: 50px;
`
const WrapperSettings = styled.div`
display: grid;
grid-template-rows: auto;
`
const ItemsBlock = styled.div`
grid-template-rows: auto 1fr;
padding: 20px;
justify-items: start;
`
const ItemsBlockCourier = styled.div`
display: grid;
grid-template-rows  : auto 1fr;
padding: 15px;
justify-items: start;  
height:max-content ;
`
const HeaderItems = styled.div`
font-weight: 600;
font-size: 19px; 
width: 100%;
text-align: start;
padding-right: 22px;
font-family: 'Roboto';
font-style: normal;
`


const ElementItem = styled.div`
display: flex;
align-items: flex-start;
justify-content: start;
flex-direction: column;
margin-top: 15px;
    input{
     margin-left: 50px;
     width: auto;
     min-width: 250px;
     min-height: 30px;
     border-radius: 6px;
     border: none;
     box-shadow: 0px 0px 2px 2px rgba(206, 206, 206,0.5);
    }
    p{
      width: 55px;
    }
`