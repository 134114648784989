import React, { useEffect } from 'react'
import styled from "styled-components";
import Clock from '../../images/Clock.svg'
export const Aside = ({productItem}) => {

  return (
    <>
    {productItem ?
    <AsideWrapper>
      {
        'id_product' in productItem? 
        // `Новая ${productItem.date_and_time_of_dispatch}`<>
      <>
        <WrapperTime>
        <StateProd productState={true}>
         <TextProd productState={true}>{productItem.id_product ? 'Новая' : 'Создан'}</TextProd>
         {/* {console.log(productItem.id_product,'IDPROD')} */}
        </StateProd>
        <Time>
        <img src={Clock} style={{width:16,height:16,marginLeft:10}}/>
        {productItem.date_and_time_of_dispatch}
         </Time>
         </WrapperTime>
        </>
        :
        <>
        <WrapperTime>
      <StateProd productState={false}>
        <TextProd productState={false}>Активная</TextProd>
      </StateProd>
      <Time>
      <img src={Clock} style={{width:16,height:16,marginLeft:10}}/>
      {/* {console.log(productItem.id_product,'IDPROD')} */}
        {productItem.date_and_time_of_changed ?? 'Дата потеряна'}
        
      </Time>
        </WrapperTime>
      </>
        // `Активная ${productItem.date_and_time_of_dispatch}`
        
      }
    </AsideWrapper>
    :
    '' 
    }
    </>
  
  )
}
const AsideWrapper = styled.aside`
  display: flex;
  height: auto;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
`
const StateProd = styled.div`
display: flex;
justify-content: center;
align-items: center;
  height: 32px;
  width: 87px;
  border-radius: 6px;
  background-color:${({productState}) => productState ? '#A9B1E1' : '#FFFFFF'  }; 
`
const TextProd = styled.p`
justify-content: center;
align-items: center;
font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 14px;
color:${({productState}) => productState ? '#FFFFFF' :  '#161616'};
`
const WrapperTime = styled.div`
display: flex;
flex-direction: row;
margin-right: auto;

`
const Time = styled.div`

`