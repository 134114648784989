import React, { Component } from "react";
import styled from "styled-components";
import { sr } from "../../utils";
import moment from "moment";
import { connect } from "react-redux";
import { deliveryCycleInfoMessage } from "../../actions/ModalActions";
import { Button, Modal } from "antd";
import RcCC from "../../images/RcCC.svg";
import CarCC from "../../images/CarCC.svg";
import CloseCC from "../../images/CloseCC.svg";
import FoodCC from "../../images/FoodCC.svg";
import GiftCC from "../../images/GiftCC.svg";
import HzCC from "../../images/HzCC.svg";
import PhoneCC from "../../images/PhoneCC.svg";
import WebCC from "../../images/WebCC.svg";
import UnoMoneyCC from "../../images/UnoMoneyCC.svg";
import QuestCC from "../../images/QuestCC.svg";
import RcDisabled from "../../images/RcCCDisable.svg";
import Succes from "../../images/Succes.svg";
import Reject from "../../images/Reject.svg";
import checked from "../../images/checked.svg";

export class InfoOrderItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
      yesterDay: moment().subtract(1, "days").format("YYYY-MM-DD"),
      todayDay: moment().format("YYYY-MM-DD"),
      setUnoMoney: false,
      modalsProduct: false,
      productsForModal: [],
      ModalInfoVisible: false,
      infoProduct: [],
      valueMoney: "",
      orderGroupId: [],
    };
  }
  modalsProduct = (e) => {
    return (
      <ModalsWrap>
        <ItemInModal>
          <div>
            {this.state.productsForModal?.map((el, id) => {
              return (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    padding: 24,
                    height: "auto",
                    width: "auto",
                  }}
                >
                  <div
                    style={{ height: "auto", width: "200px", marginRight: 90 }}
                  >
                    {el.modifiers ? (
                      <p style={{ fontWeight: 400, fontSize: 16 }}>
                        {id + 1}.{el?.name} {el.modifiers.map((el) => el.name)}{" "}
                        ({+el.modifiers.map((el) => el.price)}₽)
                      </p>
                    ) : (
                      <p style={{ fontWeight: 400, fontSize: 16 }}>
                        {id + 1}.{el?.name}
                      </p>
                    )}
                  </div>
                  <div
                    style={{
                      height: "30px",
                      width: "max-content",
                      marginLeft: "auto",
                    }}
                  >
                    <p style={{ fontWeight: 500, fontSize: 16, width: "auto" }}>
                      {el?.amount} шт, {+el?.price} ₽
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </ItemInModal>
      </ModalsWrap>
    );
  };
  handleClose = () => {
    this.setState({ modalsProduct: false });
  };

  handleOpen = () => {
    this.setState({ modalsProduct: true });
  };
  handleModalClose = () => {
    this.setState({ ModalInfoVisible: false });
  };
  handlePaymentStatus = () => {
    sr("PaySystemService", "PaymentStatus", {
      orderId: this.props.v.id,
    }).then((res) => {
      if (res.result === 0 || res.result === "0") {
        this.props.deliveryCycleInfoMessage(
          "error_ClientCard",
          res.data.message
        );
      }
      if (res.result === 1 || res.result === "1") {
        this.props.deliveryCycleInfoMessage(
          "succesDelete_ClientCard",
          res.data.message
        );
      }
    });
  };
  handleUnoMoney = () => {
    if (
      this.state.valueMoney &&
      +this.props.v.priceResult >= this.state.valueMoney &&
      this.state.valueMoney <=
        +this.props.v.priceResult - Number(this?.props?.v?.refundPay)
    ) {
      sr("PaySystemService", "RefundPayOrder", {
        orderId: this.props.v.id,
        amount: this.state.valueMoney,
      }).then((res) => {
        if (res.result) {
          this.props.handleSubmit(this.props.phone);
          this.props.deliveryCycleInfoMessage("succesDelete_ClientCard");
          this.setState({ setUnoMoney: false });
        } else {
          this.props.deliveryCycleInfoMessage("error_ClientCard");
          this.setState({ setUnoMoney: false });
        }
      });
    } else {
      this.props.deliveryCycleInfoMessage("empty_fieldsGlobal");
    }
  };
  handleChange = (e) => {
    this.setState({ valueMoney: e.target.value });
  };
  componentDidMount() {
    this.setState({
      orderGroupId: this.props.allData.filter((el) => el.orderGroupId),
    });
    sr("AppDinnerController", "LoadOrder", {
      orderId: this.props.v.id,
    }).then((res) =>
      this.setState({
        productsForModal: res?.data?.products,
        infoProduct: res?.data?.order,
      })
    );
  }
  // switch (index) {
  //   case "1":
  //     name = "Наличные";
  //     break;
  //   case "8":
  //     name = "Картой курьеру";
  //     break;
  //   case "16":
  //     name = "Оплата онлайн";
  //     break;
  //   case "32":
  //     name = "Оплата баллами";
  //     break;
  priceUIhandler = () => {
    if (+this.props.v.priceResult) {
      if (this.props.v.paysystemId === "1") {
        return (
          <p style={{ fontWeight: 500, fontSize: 20 }}>
            {+this.props.v.priceResult} ₽ <img src={Succes} />
          </p>
        );
      }
      if (this.props.v.paysystemId === "16") {
        if (this.props.v.paymentStatus === "2") {
          return (
            <p style={{ fontWeight: 500, fontSize: 20 }}>
              {+this.props.v.priceResult} ₽ <img src={Succes} />
            </p>
          );
        } else {
          return (
            <p style={{ fontWeight: 500, fontSize: 20 }}>
              {+this.props.v.priceResult} ₽ <img src={Reject} />
            </p>
          );
        }
      }
      if (this.props.v.paysystemId === "32") {
        return (
          <p style={{ fontWeight: 500, fontSize: 20 }}>
            {+this.props.v.priceResult} ₽ <img src={Succes} />
          </p>
        );
      }
    } else {
      return (
        <p style={{ fontWeight: 500, fontSize: 20 }}>
          {+this.props.v.priceResult} ₽
        </p>
      );
    }
  };

  allUI = (moderate) => {


  return(
    <InfoWrapper
      style={{marginLeft:moderate  ?40:0}}
      key={this.props.v}
      price={+this.props.v.priceResult}
      //  style={{display: 'flex', marginBottom: i === this.state.results.orders.length - 1 ? 0 : 10,borderBottom:'1px solid black'}}
    >
      <Create>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <p style={{ fontWeight: 500, fontSize: 20 }}>{this.props.v.id}</p>
          <img
            src={
              this.props.v.appName === "site SO"
                ? WebCC
                : this.props.v.appName === "приложение СО"
                ? PhoneCC
                : HzCC
            }
          />
        </div>
        <p>
          {console.log(this.state.orderGroupId, "ROORRORO")}
          {moment(this.state.infoProduct.created).format("YYYY-MM-DD") ===
          this.state.todayDay ? (
            "Сегодня"
          ) : moment(this.state.infoProduct.created).format("YYYY-MM-DD") ===
            this.state.yesterDay ? (
            "Вчера"
          ) : (
            <span>
              {moment(this.state.infoProduct.created).format("DD-MM-YYYY")}
            </span>
          )}
        </p>
        <p style={{ fontWeight: 500, fontSize: 20 }}>
          {this.state.infoProduct
            ? moment(this.state.infoProduct.created).format("HH:mm")
            : "-"}
        </p>
        {this.props.v.paysystemId === "16" ? (
          <p style={{ fontWeight: 400, fontSize: 13, color: "#858585" }}>
            {this.props.v.payId}_{this.props.v.id}
          </p>
        ) : (
          ""
        )}
        {/* <p style={{fontWeight:500,fontSize:20}}>{this.props.v.timeDelivery ? moment(this.props.v.timeDelivery).format("HH:mm") : '-'}</p> */}
      </Create>
      <Kitchen>
        {(moderate || !this.props.v.orderGroupId) &&
        <>
        <div
          style={{ position: "relative" }}
          onMouseEnter={this.handleOpen}
          onMouseLeave={this.handleClose}
        >
          <p
            style={{
              fontWeight: 400,
              fontSize: 16,
              textDecorationLine: "underline",
              color: +this.props.v.priceResult ? "#2196F3" : "#999999",
            }}
          >
            {this.props.v.restName}
          </p>
          {this.state.modalsProduct ? this.modalsProduct() : ""}
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          {this.props.v.centerId ? (
            <>
              <img src={+this.props.v.priceResult ? RcCC : RcDisabled} />
              <p style={{ fontWeight: 500, fontSize: 16 }}>
                {this.props.v.centerId === "1" ? "РЦ-1" : "РЦ-2"}
              </p>
            </>
          ) : (
            ""
          )}
        </div>
        </>
        }
      </Kitchen>
      <Sum>
        {/* <p style={{fontWeight:500,fontSize:20}} >{+this.props.v.priceResult} ₽ {this.props.v.paymentStatus === '1' && +this.props.v.priceResult && this.props.v.paysystemId === '16' ?<img src={Succes}/> : <img src={Reject}/>}</p> */}
        {this.priceUIhandler()}
        {this.props.v.paysystemMulti === "1" ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <p
              style={{
                fontWeight: 400,
                fontSize: 16,
                color: " #2196F3",
                marginRight: 4,
              }}
            >
              {+this.props.v.priceCard} ₽
            </p>{" "}
            ⋅{" "}
            
            <p
              style={{
                fontWeight: 400,
                fontSize: 16,
                color: "#999999",
                marginLeft: 4,
              }}
            >
              {+this.props.v.priceBall}
            </p>
          </div>
        ) : (
          ""
        )}
        {
        (moderate || !this.props.v.orderGroupId) &&
        this.props.v.paysystemId === "16" ? (
          <p style={{ fontSize: 16, color: "#2196F3" }}>
            {+this.props.v.priceResult} ₽
          </p>
        ) : this.props.v.paysystemId === "32" ? (
          <p style={{ fontSize: 16, color: "#999999" }}>
            {+this.props.v.priceResult} ₽
          </p>
        ) : this.props.v.paysystemId === "1" ? (
          <p style={{ fontSize: 16, color: "#528E51" }}>
            {+this.props.v.priceResult} ₽
          </p>
        ) : (
          ""
        )}
        {(moderate || !this.props.v.orderGroupId) &&
        <div style={{ display: "flex", flexDirection: "row" }}>
          <img src={FoodCC} />
          <p
            style={{
              color: +this.props.v.priceResult ? "#2C2E32" : "#999999",
            }}
          >
            {+this.props.v.price} ₽
          </p>
        </div>
          }

        {(moderate || !this.props.v.orderGroupId) && 
        <div style={{ display: "flex", flexDirection: "row" }}>
          <img src={GiftCC} />
          <p
            style={{
              color: +this.props.v.priceResult ? "#2C2E32" : "#999999",
            }}
          >
            {+this.props.v.serviceFee} ₽
          </p>
        </div>
          }

        {(moderate || !this.props.v.orderGroupId)&&
        <div style={{ display: "flex", flexDirection: "row" }}>
          <img src={CarCC} />
          <p
            style={{
              color: +this.props.v.priceResult ? "#2C2E32" : "#999999",
            }}
          >
            {+this.props.v.deliveryPrice} ₽
          </p>
        </div>
        }
      </Sum>
      <Addres>
        {(moderate || !this.props.v.orderGroupId) &&
        <>
        <p style={{ fontWeight: 500, fontSize: 20 }}>
          ID {this.props.v.companyId}
        </p>
        <p style={{ fontWeight: 500, fontSize: 15 }}>
          {this.props.companies.map((el) => {
            if (el.id === this.props.v.companyId) {
              if (el.companyType === "1" || el.name === "Дом") {
                return "Дом";
              } else {
                return el.name;
              }
            } else {
            }
          })}
        </p>
        <p>{this.props.showAddress(this.props.v.companyId)}</p>
          </>
        }
      </Addres>
      <Average>
        {(moderate || !this.props.v.orderGroupId) &&
        <>
        <p style={{ fontWeight: 500, fontSize: 20 }}>
          {this.props.v.takingOrdersBefore
            ? this.props.v.takingOrdersBefore
            : "-"}
        </p>
        <p>{moment(this.props.v.dateTo).format("DD.MM")}</p>
            </>
      }
      </Average>
      <Delivery>
      
        {
        (moderate || !this.props.v.orderGroupId) &&
        (
        this.props.v.centerId  ?  (
          <>
            <p style={{ fontWeight: 600, fontSize: 15 }}>
              {moment(this.props.v.dateTo).format("DD.MM")}
            </p>
            {"/"}
            <p style={{ fontWeight: 600, fontSize: 15 }}>
              {" "}
              c {this.props.v.timeFrom}
            </p>
            <p style={{ marginLeft: 3, fontWeight: 600, fontSize: 15 }}>
              {" "}
              до {this.props.v.timeTo}
            </p>
          </>
        ) : (
          <>
            <p style={{ fontWeight: 600, fontSize: 15 }}>
              {moment(this.props.v.dateTo).format("DD.MM")}
            </p>
            {"/"}
            <p style={{ fontWeight: 600, fontSize: 15 }}>
              {this.props.v.timeFrom}
            </p>
          </>
          )
        )}
        
      </Delivery>
      <Couriers>
        <div>
          <p>{this.props.v.courierName ? this.props.v.courierName : "-"}</p>
          {+this.props.v.priceResult > 0 ? (
            this.props.v.status === "67108864" ? (
              <p
                style={{ color: "#528E51", fontWeight: 500, fontSize: 16 }}
              >{`${this.props.deliveryStatus} ${
                this.props.v.timeDelivery
                  ? moment(this.props.v.timeDelivery).format("HH:mm")
                  : "-"
              }`}</p>
            ) : (
              <p style={{ fontWeight: 500, fontSize: 16 }}>
                {this.props.deliveryStatus}
              </p>
            )
          ) : (
            <p style={{ fontWeight: 500, fontSize: 16 }}>Отменен</p>
          )}
        </div>
        {+this.props.v.priceResult ? (
          // true ?
          <div>
            <button
            type="button"

            style={{
                width: 44,
                height: 44,
                borderRadius: 8,
                marginLeft: 8,
                border: "1px solid #7882A7",
                cursor: "pointer",
              }}
              onClick={() =>
                this.props.cancel(
                  this.props.v.order_system,
                  true,
                  this.props.v.courierId,
                  this.props.v.restTypeDelivery
                )
              }
              >
              <img src={checked} />
            </button>
    
          {(!moderate || !this.props.v.orderGroupId) &&
          <>
            <button
            type="button"
              disabled={!(this.props.v.paysystemId === "2" || this.props.v.paysystemId === "16")}
              style={{
                width: 44,
                height: 44,
                borderRadius: 8,
                marginLeft: 8,
                border: "1px solid #7882A7",
                cursor: "pointer",
              }}
              onClick={this.handlePaymentStatus}
            >
              <img src={QuestCC} />
            </button>
            <button
            type="button"

              style={{
                width: 44,
                height: 44,
                borderRadius: 8,
                marginLeft: 8,
                border: "1px solid #7882A7",
                cursor: "pointer",
              }}
              onClick={() => this.props.cancel(this.props.v.id, false)}
            >
              <img src={CloseCC} />
            </button>
            <button
            type="button"

              disabled={this.props.v.paysystemId !== "1" ? false : true}
              style={{
                width: 44,
                height: 44,
                borderRadius: 8,
                marginLeft: 8,
                border: "1px solid #7882A7",
                cursor: "pointer",
              }}
              onClick={() =>
                this.setState({ setUnoMoney: !this.state.setUnoMoney })
              }
            >
              <img src={UnoMoneyCC} />
            </button>
            </>
              }
            {this.props.v.refundPay && this.props.v.refundPay !== "0" ? (
              <p
                style={{
                  color: "#2196F3",
                  fontWeight: 500,
                  fontSize: 15,
                  marginTop: 10,
                  marginRight: "auto",
                }}
              >
                Денег на возврат:{this?.props?.v?.refundPay}₽
              </p>
            ) : (
              ""
            )}
            {this.state.setUnoMoney ? (
              <div style={{ display: "flex", flexDirection: "row" }}>
                <input
                  type="number"
                  onChange={this.handleChange}
                  value={this.state.valueMoney}
                  style={{
                    height: 44,
                    width: 96,
                    borderRadius: 6,
                    backgroundColor: " #F3F3F3",
                    marginLeft: 8,
                    marginTop: 7,
                  }}
                />
                {console.log(
                  +this.props.v.priceResult,
                  "+this.props.v.priceResult"
                )}
                <p
                  style={{
                    textDecorationLine: "underline",
                    color: "#2196F3",
                    alignSelf: "center",
                    marginLeft: 8,
                    cursor: "pointer",
                  }}
                  onClick={this.handleUnoMoney}
                >
                  ok
                </p>
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
      </Couriers>
    </InfoWrapper>
  )
  };

  render() {
    return (
      <>
        {this.props.v.id !== this.props.v.orderGroupId && this.allUI(false)}

        <div>{this.props.v.orderGroupId && this.allUI(true)}</div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  deliveryCycleInfoMessage: (action, data) =>
    dispatch(deliveryCycleInfoMessage(action, data)),
});

export default connect(null, mapDispatchToProps)(InfoOrderItem);

const InfoWrapper = styled.div`
  display: flex;
  margin-bottom: 20px;
  opacity: ${({ price }) => (price === 0 ? "0.5" : "1")};
  font-family: "Roboto";
  font-style: normal;
  border-bottom: 1px solid #f7f7f7;
`;
const Create = styled.div`
  width: 15%;
`;
const Kitchen = styled.div`
  width: 15%;
`;
const Sum = styled.div`
  width: 15%;
`;
const Addres = styled.div`
  width: 15%;
`;
const Average = styled.div`
  width: 15%;
`;
const Delivery = styled.div`
  display: flex;
  flex-direction: row;
  width: 15%;
`;
const Couriers = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 20%;
`;
const ModalsWrap = styled.div`
  background: #f4f4f4;
  border: 1px solid #e9e9e9;
  border-radius: 8px;
  position: absolute;
  width: auto;
  /* max-width: 800px;
min-width: 360px; */
  height: auto;
  z-index: 9999;
`;
const ItemInModal = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  width: auto;
`;
