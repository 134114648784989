import Kitchens from '../sidebarNew/Kitchens.svg'
import DarkKitchens from '../sidebarNew/dark/DarkKitchens.svg'
import Clients from '../sidebarNew/Clients.svg'
import DarkClients from '../sidebarNew/dark/DarkClients.svg'
import Delivery from '../sidebarNew/Delivery.svg'
import DarkDelivery from '../sidebarNew/dark/DarkDelivery.svg'
import Financial from '../sidebarNew/Financial.svg'
import DarkFinancial from '../sidebarNew/dark/DarkFinancial.svg'
import Moderation from '../sidebarNew/Moderation.svg'
import DarkModeration from '../sidebarNew/dark/DarkModeration.svg'
import Partners from '../sidebarNew/Partners.svg'
import DarkPartners from '../sidebarNew/dark/DarkPartners.svg'
import Service from '../sidebarNew/Service.svg'
import DarkService from '../sidebarNew/dark/DarkService.svg'
import Settings from '../sidebarNew/Settings.svg'
import DarkSettings from '../sidebarNew/dark/DarkSettings.svg'
import Support from '../sidebarNew/Support.svg'
import DarkSupport from '../sidebarNew/dark/DarkSupports.svg'


export const imagesResource = {
    Kitchens: {
        default: Kitchens,
        dark: DarkKitchens,
        hover:'Кухни',
        path:["/dashboard","/assortment"
              ,"/financial","/reviews",
                "/complex",
                "/assembly","/orders",
                "/menu_by_day","/setting"],
    },
    Settings: {
        default: Settings,
        dark: DarkSettings,
        hover:'Настройки',
        path:["/mainSetting","/stocks",
              "/startnotif","/aboutKitchen",
              "/additional","/reportsSetting"],

    },
    Delivery: {
        default: Delivery,
        dark: DarkDelivery,
        hover:'Управление доставкой',
        path:["/map","/delManagment"],

    },
    Clients: {
        default: Clients,
        dark: DarkClients,
        hover:'Клиенты',
        path:["/findClients","/client-card",
              "/addresses","/corpClients"],

    },
    Financial: {
        default: Financial,
        dark: DarkFinancial,
        hover:'Маркетинг/Финансы',
        path:["/promocodes","/wallets",
              "/push"  ],

    },
    Partners: {
        default: Partners,
        dark: DarkPartners,
        hover:'Партнёры',
        path:["/statistics","/financialRep","/reports"],

    },
    Support: {
        default: Support,
        dark: DarkSupport,
        hover:'Клиентская поддержка',
        path:["/supports"],

    },
    Moderation: {
        default: Moderation,
        dark: DarkModeration,
        hover:'Модерация товаров',
        path:["/moderation"],

    },
    Service: {
        default: Service,
        dark: DarkService,
        hover:'Настройки сервиса',
        path:["/workweek","/managment",
              "/version"],

    },
  
}