import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import store from "./store";
import App from './App';
import './index.css';
import './antd.css';
import {ConfigProvider} from "antd"
import ru_RU from 'antd/lib/locale/ru_RU';
import ScrollToTop from './ScrollToTop';
import { BrowserRouter } from 'react-router-dom';

if (document.location.protocol === 'https:') {
    caches.keys().then(function(names) {
        for (let name of names) {
            console.log(name, 'cache name')
            caches.delete(name).then(res => console.log('cache is cleared?', res));
        }
    })
}

ReactDOM.render(
    <Provider store={store}>
        <ConfigProvider locale={ru_RU}>
            <App />
        </ConfigProvider>
    </Provider>,
    document.getElementById('root')
);
