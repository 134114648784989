import React, {useState} from 'react';
import styled from 'styled-components';
import TextInput from '../components/uikit/TextInput';
import TextArea from '../components/uikit/TextArea';
import CheckBox from '../components/uikit/CheckBox';
import SimpleSelect from "../components/uikit/SimpleSelect";
import SimpleSwitch from "../components/uikit/SimpleSwitch";
import MultiSwitch from "../components/uikit/MultiSwitch";

const UIKit = props => {
    const [someState, setSomeState] = useState('');
    const [someState2, setSomeState2] = useState(true);
    const [selectedOption, setSelectedOption] = useState(null);

    return (
        <Container>
            {/*<Aside>*/}
            {/*    <AsideItem onClick={() => setCurrentComponent('input_with_title')}>*/}
            {/*        Текстовое поле с заголовком*/}
            {/*    </AsideItem>*/}
            {/*</Aside>*/}
            <Main>
                {/*{currentComponent === 'input_with_title' && (*/}
                {/*    <Item id='input_with_title'>*/}
                {/*        <ItemTitle>Текстовое поле с заголовком</ItemTitle>*/}
                {/*        <TextInput title="Текстовое поле на всю ширину блока" />*/}
                {/*        <TextInput title="Текстовое поле с плейсхолдером" placeholder="Введите любой текст" />*/}
                {/*        <TextInput title="Короткое текстовое поле" short />*/}
                {/*    </Item>*/}
                {/*)}*/}
                <TextInput
                    value={someState}
                    onChange1={() => {}}
                    placeholder="Введите любой текст"
                    title="<TextInput value={valueState} onChange1={text => setValue(text)} [placeholder='Введите любой текст'] [title='Заголовок'] />"
                />
                <hr style={{marginBottom: 18}} color="#E0E0E0" size={1} />
                <TextInput
                    value={someState}
                    onChange1={() => {}}
                    width={200}
                    title="<TextInput value={valueState} onChange1={text => setValue(text)} [width={200}] [title='Заголовок'] />"
                />
                <hr style={{marginBottom: 18}} color="#E0E0E0" size={1} />
                <TextArea
                    value={someState}
                    onChange1={() => {}}
                    title="<TextArea value={valueState} onChange1={text => setValue(text)} [title='Заголовок'] />"
                />
                <hr style={{marginBottom: 18}} color="#E0E0E0" size={1} />
                <TextArea
                    value={someState}
                    onChange1={() => {}}
                    width={400}
                    height={100}
                    title="<TextArea value={valueState} onChange1={text => setValue(text)} [width={400}] [height={100}] [title='Заголовок'] />"
                />
                <hr style={{marginBottom: 18}} color="#E0E0E0" size={1} />
                <CheckBox
                    isActive={someState2}
                    onClick1={() => setSomeState2(prev => !prev)}
                    title="<CheckBox isActive={valueState} onClick1={() => setValue(prev => !prev)} title='Включить/исключить' />"
                />
                <hr style={{marginBottom: 18}} color="#E0E0E0" size={1} />
                <SimpleSelect
                    data={[{id: '1', name: 'Тольятти'}, {id: '2', name: 'Самара'}]}
                    selected={selectedOption}
                    handleChange={item => setSelectedOption(item)}
                    title="<SimpleSelect data={[..]} selected={selectedOption} handleChange={item => setSelectedOption(item)} title='Единичный выбор' [allowClear={true}] [style={{width: 200}}] [itemName={'name'}] [itemValue={'id'}] />"
                    allowClear={true} // Кнопка очистки
                    style={{width: 200}}
                    itemName={'name'}
                    itemValue={'id'}
                />
                <hr style={{marginBottom: 18}} color="#E0E0E0" size={1} />
                <SimpleSwitch
                    isChecked={someState2}
                    onChange1={() => setSomeState2(prev => !prev)}
                    title="<SimpleSwitch isChecked={valueState} onChange1={() => setValue(prev => !prev)} title='Включить/выключить' [style={{marginBottom: 16}}] />"
                    style={{marginBottom: 16}}
                />
                <hr style={{marginBottom: 18}} color="#E0E0E0" size={1} />
                <MultiSwitch title="<MultiSwitch title='Несколько свитчей'></MultiSwitch>" >
                    <SimpleSwitch
                        isChecked={someState2}
                        onChange1={() => setSomeState2(prev => !prev)}
                        title="Пример"
                        style={{marginBottom: 16, width: '33%'}}
                    />
                    <SimpleSwitch
                        isChecked={someState2}
                        onChange1={() => setSomeState2(prev => !prev)}
                        title="Пример"
                        style={{marginBottom: 16, width: '33%'}}
                    />
                    <SimpleSwitch
                        isChecked={someState2}
                        onChange1={() => setSomeState2(prev => !prev)}
                        title="Пример"
                        style={{marginBottom: 16, width: '33%'}}
                    />
                    <SimpleSwitch
                        isChecked={someState2}
                        onChange1={() => setSomeState2(prev => !prev)}
                        title="Пример"
                        style={{marginBottom: 16, width: '33%'}}
                    />
                    <SimpleSwitch
                        isChecked={someState2}
                        onChange1={() => setSomeState2(prev => !prev)}
                        title="Пример"
                        style={{marginBottom: 16, width: '33%'}}
                    />
                </MultiSwitch>
                <hr style={{marginBottom: 18}} color="#E0E0E0" size={1} />
            </Main>
        </Container>
    )
};

const Container = styled.div`
    position: relative;
    width: 100%;
    height: calc(100vh - 64px);
    background-color: #FFFFFF;
    font-family: "Roboto", sans-serif;
    text-align: left;
    color: #111111;
`;

const Main = styled.div`
    height: 100%;
    /*padding: 24px 24px 24px 348px;*/
    padding: 24px;
    background-color: #FFFFFF;
    overflow-y: scroll;
`;

/*
const Aside = styled.aside`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 1;
    width: 300px;
    padding: 24px;
    background-color: #FFFFFF;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.15);
`;

const AsideItem = styled.a`
    margin-bottom: 8px;
    font-size: 15px;
    line-height: 18px;
    color: #777777;
`;

const Item = styled.div`
    width: 100%;
    margin-bottom: 36px;
`;

const ItemTitle = styled.p`
    margin-bottom: 24px;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
`;
*/

export default UIKit;
