import React, {useEffect} from "react";
import styled from "styled-components";

const Storage = (props) => {
    
    return (
        <StorageWrapper>

        </StorageWrapper>
    )
}

export default Storage;

const StorageWrapper = styled.div`
  height: 100%;
  padding: 20px 0;
  border-top: 1px solid silver;
  border-bottom: 1px solid silver;
`