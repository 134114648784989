import React, {useEffect, useState} from "react";
import {nanoid} from "nanoid"

// Краткая документация

// useList строит коллекцию компонентов потомков (node) на основе переданного массива array
// по скольку построенный потомок не считается Functional Componet в нем нельзя вызывать хуки,
// но их результат можно передать как callback. Пример рабочего варианта:
// например нам нужно диспатчить экшены в редакс, мы можем воспользоватся хуком useDispatch,
// передав возвращаемую им функцию ввиде колбэка

// const waybillsList = useList(waybills, WaybillListItem, {selectedSenderName: senderName, selectedRecipientName: recipientName, dispatch, grid})

// Код потомка вызываемого в моем хуке:
// const WaybillListItem = ({data, selectedSenderName, selectedRecipientName, dispatch, grid}) => { const clickHandle = () => { dispatch(...) }}

function useList(array = [], node, sideProps) {

    const [nodeList, setNodeList] = useState([])

    useEffect(() => {
        if (Array.isArray(array)) {
            setNodeList(
                array.map((e, i) => {
                    const reactNode = node({data: e, id: i, ...sideProps}) //build react node with component props
                    return React.cloneElement(reactNode, {key: nanoid()}) //clone them and pass key prop
                })
            )
        }
    }, [array])

    return nodeList
}

export default useList