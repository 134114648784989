import React, {memo, useEffect, useState} from "react";
import styled from "styled-components";
import {
  setFilteredDeliveryPoints,
  setFiltering,
} from "../../reducers/toolkit/orderMapReducer"
import {useDispatch, useSelector} from "react-redux"
import {Cross} from "../../images/svgs"
import {nanoid} from "nanoid"
import {findPointsInBounds} from "./MapComponent"

let initTypeFilterSettings = {
  del: false,
  noDel: false,
  dir: false,
  noDir: false,
}

const InfoSidebarFilter = ({showFilters, searchSubstrDebounced, setFilterCount, setClearFilters, coloredPoints}) => {
  const dispatch = useDispatch()

  const kitchens = useSelector(store => store.orderMap.kitchens)
  const polygon = useSelector(store => store.orderMap.polygon)
  const map = useSelector(store => store.orderMap.map)

  const [filterBySubstrIsOn, setFilterBySubstrIsOn] = useState(false)

  const [filterByStatusIsOn, setFilterByStatusIsOn] = useState(false)
  const [filterByStatusSettings, setFilterByStatusSettings] = useState(initTypeFilterSettings)

  const [filterByKitchenIsOn, setFilterByKitchenIsOn] = useState(false)
  const [selectedKitchensIds, setSelectedKitchensIds] = useState([])

  // for first order from company/person
  const [filterByFirstOrderIsOn, setFilterByFirstOrderIsOn] = useState(false)
  const [filterByFirstOrder, setFilterByFirstOrder] = useState([false, false])


  const [filterByTriangleIsOn, setFilterByTriangleIsOn] = useState(false)
  const [filterBySelectionTriangleIsOn, setFilterBySelectionTriangleIsOn] = useState(false)

  useEffect(() => {
    setClearFilters(() => resetAllFilters)
  }, [])

  // set filter counter
  useEffect(() => {
    if (filterByKitchenIsOn) {
      setFilterCount(prevState => prevState === 0 ? 1 : prevState + 1)
    } else {
      setFilterCount(prevState => prevState === 0 ? 0 : prevState - 1)
    }
  }, [filterByKitchenIsOn])

  // set filter counter
  useEffect(() => {
    if (filterByStatusIsOn) {
      setFilterCount(prevState => prevState === 0 ? 1 : prevState + 1)
    } else {
      setFilterCount(prevState => prevState === 0 ? 0 : prevState - 1)
    }
  }, [filterByStatusIsOn])

  // set filter counter
  useEffect(() => {
    if (filterBySubstrIsOn) {
      setFilterCount(prevState => prevState === 0 ? 1 : prevState + 1)
    } else {
      setFilterCount(prevState => prevState === 0 ? 0 : prevState - 1)
    }
  }, [filterBySubstrIsOn])

  // set filter counter
  useEffect(() => {
    if (filterByFirstOrderIsOn) {
      setFilterCount(prevState => prevState === 0 ? 1 : prevState + 1)
    } else {
      setFilterCount(prevState => prevState === 0 ? 0 : prevState - 1)
    }
  }, [filterByFirstOrderIsOn])

  // set filter counter
  useEffect(() => {
    if (filterByTriangleIsOn) {
      setFilterCount(prevState => prevState === 0 ? 1 : prevState + 1)
    } else {
      setFilterCount(prevState => prevState === 0 ? 0 : prevState - 1)
    }
  }, [filterByTriangleIsOn])

  // set filter counter
  useEffect(() => {
    if (filterBySelectionTriangleIsOn) {
      setFilterCount(prevState => prevState === 0 ? 1 : prevState + 1)
    } else {
      setFilterCount(prevState => prevState === 0 ? 0 : prevState - 1)
    }
  }, [filterBySelectionTriangleIsOn])

  // torn off setFilterByStatusIsOn flag
  useEffect(() => {
    if (!Object.values(filterByStatusSettings).includes(true)) {
      setFilterByStatusIsOn(false)
    }
  }, [filterByStatusSettings])

  // torn off setFilterBySubstrIsOn flag
  useEffect(() => {
    if (!searchSubstrDebounced) {
      setFilterBySubstrIsOn(false)
    }
  }, [searchSubstrDebounced])

  // torn off selectedKitchenIsOn flag
  useEffect(() => {
    if (!selectedKitchensIds.length) {
      setFilterByKitchenIsOn(false)
    }
  }, [selectedKitchensIds])

  useEffect(() => {
    setFilterByFirstOrderIsOn(filterByFirstOrder.includes(true))
  }, [filterByFirstOrder])

  // main filter function
  useEffect(() => {
    if (
      filterByStatusIsOn ||
      searchSubstrDebounced ||
      filterByKitchenIsOn ||
      filterByFirstOrderIsOn ||
      filterByTriangleIsOn ||
      filterBySelectionTriangleIsOn
    ) {

      dispatch(setFiltering(true))

      let filterPoints = [...coloredPoints]

      // filter by status
      if (filterByStatusIsOn) {
        filterPoints = filterPoints.filter(el => {
          // return if delivered
          if (filterByStatusSettings.del) return el.deliveryTime && el.courierId

          // return if no delivered
          if (filterByStatusSettings.noDel) return !el.deliveryTime && el.courierId

          // return if courier direction
          if (filterByStatusSettings.dir) return el.courierId

          // return if courier no direction
          if (filterByStatusSettings.noDir) return !el.courierId
        })
      }

      // filter by search substr
      if (searchSubstrDebounced) {
        filterPoints = filterPoints.filter(el => {
          setFilterBySubstrIsOn(true)
          const searchStr = searchSubstrDebounced.toLowerCase()
          if ((el.companyId + '').toLowerCase().includes(searchStr)) return 1
          if ((el.companyName + '').toLowerCase().includes(searchStr)) return 1
          const findPhone = el.phones.reduce((acc, el) => {
            if (el.includes(searchStr)) {
              acc = true
            }
            return acc
          }, false)
          if (findPhone) return 1
        })
      }

      // filter by kitchens substr
      if (filterByKitchenIsOn) {
        filterPoints = filterPoints.filter(dp => {
          return dp.kitchens.reduce((acc, el) => {
            if (selectedKitchensIds.includes(el)) {
              acc = true
            }
            return acc
          }, false)
        })
      }

      //filter by first order of clients
      if (filterByFirstOrderIsOn) {
        if (filterByFirstOrder[0]) {
          filterPoints = filterPoints.filter(dp => +dp.newClientsCount !== 0)
        }
        if (filterByFirstOrder[1]) {
          filterPoints = filterPoints.filter(dp => !!dp.newCompanyMarker)
        }
      }

      //filter by map bounds
      if (filterByTriangleIsOn) {
        if (map) {
          const bounds = map.getBounds()
          filterPoints = filterPoints.filter(el => {
            const pos = {lat: +el.lat, lng: +el.lng}
            return bounds.contains(pos)
          })
        }
      }

      if (filterBySelectionTriangleIsOn) {
        if (polygon) {
          filterPoints = findPointsInBounds(polygon, coloredPoints)
        }
      }

      //return filtered to store
      dispatch(setFilteredDeliveryPoints(filterPoints))
    } else {
      dispatch(setFilteredDeliveryPoints([]))
      dispatch(setFiltering(false))
    }
  }, [
    filterByStatusIsOn,
    filterByKitchenIsOn,
    filterByKitchenIsOn,
    filterByTriangleIsOn,
    filterBySelectionTriangleIsOn,
    selectedKitchensIds,
    filterByStatusSettings,
    filterByFirstOrder,
    searchSubstrDebounced,
    polygon,
  ])

  // click handler
  const selectTypeFilter = (selectedType) => {
    switch (selectedType) {
      case 'delivered':
        setFilterByStatusSettings({...initTypeFilterSettings, del: !filterByStatusSettings.del})
        setFilterByStatusIsOn(true)
        return true
      case 'noDelivered':
        setFilterByStatusSettings({...initTypeFilterSettings, noDel: !filterByStatusSettings.noDel})
        setFilterByStatusIsOn(true)
        return true
      case 'direction':
        setFilterByStatusSettings({...initTypeFilterSettings, dir: !filterByStatusSettings.dir})
        setFilterByStatusIsOn(true)
        return true
      case 'noDirection':
        setFilterByStatusSettings({...initTypeFilterSettings, noDir: !filterByStatusSettings.noDir})
        setFilterByStatusIsOn(true)
        return true
      default :
        // torn off filterByStatusIsOn flag
        setFilterByStatusIsOn(false)
        return false
    }
  }

  const resetAllFilters = () => {
    setFilterBySubstrIsOn(false)
    setFilterByKitchenIsOn(false)
    setFilterByStatusIsOn(false)
    setFilterByFirstOrderIsOn(false)
    setFilterByTriangleIsOn(false)
    setFilterBySelectionTriangleIsOn(false)

    setFilterByStatusSettings(initTypeFilterSettings)
    setSelectedKitchensIds([])
    setFilterByFirstOrder([false, false])
  }

  // click handler
  function selectKitchenFilter(el) {
    setFilterByKitchenIsOn(true)
    setSelectedKitchensIds(prev => {
      if (prev.includes(el.id)) {
        return prev.filter(f => f !== el.id)
      } else {
        return [...prev, el.id]
      }
    })
  }

  // click handler
  const selectFilterFirstOrderPerson = () => {
    setFilterByFirstOrderIsOn(true)
    const temp = [...filterByFirstOrder]
    temp[0] = !temp[0]
    setFilterByFirstOrder(temp)
  }
  // click handler
  const selectFilterFirstOrderCompany = () => {
    setFilterByFirstOrderIsOn(true)
    const temp = [...filterByFirstOrder]
    temp[1] = !temp[1]
    setFilterByFirstOrder(temp)
  }
  // click handler
  const resetTypeFilter = () => {
    setFilterByStatusIsOn(false)
    setFilterByStatusSettings(initTypeFilterSettings)
  }
  // click handler
  const resetKitchenFilter = () => {
    setFilterByKitchenIsOn(false)
    setSelectedKitchensIds([])
  }
  // click handler
  const resetFirstOrderFilter = () => {
    setFilterByFirstOrderIsOn(false)
    setFilterByFirstOrder([false, false])
  }
  //
  // const pointsTriangulationFilter = () => {
  //   if (map) {
  //     const bounds = map.getBounds()
  //     const x = coloredPoints.filter(el => {
  //       const pos = {lat: +el.lat, lng: +el.lng}
  //       return bounds.contains(pos)
  //     })
  //   }
  // }

  return showFilters && (
    <Filters>
      <FilterByTriangleOrder>
        <FilterHeader>
          <p>Фильтр по позиции точек</p>
          {
            filterByFirstOrderIsOn && (
              <div onClick={resetFirstOrderFilter}>
                <Cross/>
              </div>
            )
          }
        </FilterHeader>
        <FiltersWrapper>
          <FilterButton
            isSelect={filterByTriangleIsOn}
            onClick={() => setFilterByTriangleIsOn(prevState => !prevState)}>Треангуляция общего плана</FilterButton>
          <FilterButton
            isSelect={filterBySelectionTriangleIsOn}
            onClick={() => setFilterBySelectionTriangleIsOn(prevState => !prevState)}>Треангуляция по
            выделению</FilterButton>
        </FiltersWrapper>
      </FilterByTriangleOrder>
      <FilterByStatus>
        <FilterHeader>
          <p>Фильтр по статусу</p>
          {
            filterByStatusIsOn && (
              <div onClick={resetTypeFilter}>
                <Cross/>
              </div>
            )
          }
        </FilterHeader>
        <FiltersWrapper>
          <FilterButton
            isSelect={filterByStatusSettings.del}
            onClick={() => selectTypeFilter('delivered')}>Доставленные</FilterButton>
          <FilterButton
            isSelect={filterByStatusSettings.noDel}
            onClick={() => selectTypeFilter('noDelivered')}>Не доставленные</FilterButton>
          <FilterButton
            isSelect={filterByStatusSettings.dir}
            onClick={() => selectTypeFilter('direction')}>Назаначенные</FilterButton>
          <FilterButton
            isSelect={filterByStatusSettings.noDir}
            onClick={() => selectTypeFilter('noDirection')}>Не назначенные</FilterButton>
        </FiltersWrapper>
      </FilterByStatus>
      <FilterByKitchen>
        <FilterHeader>
          <p>Фильтр по Кухням</p>
          {
            filterByKitchenIsOn && (
              <div onClick={resetKitchenFilter}>
                <Cross/>
              </div>
            )
          }
        </FilterHeader>
        <FiltersWrapper>
          {
            kitchens.map((el) => (
              <FilterButton
                key={nanoid()}
                isSelect={selectedKitchensIds.includes(el.id)}
                onClick={() => selectKitchenFilter(el)}>{el.name}</FilterButton>
            ))
          }
        </FiltersWrapper>
      </FilterByKitchen>
      <FilterByFirstOrder>
        <FilterHeader>
          <p>Фильтр по новым заказам</p>
          {
            filterByFirstOrderIsOn && (
              <div onClick={resetFirstOrderFilter}>
                <Cross/>
              </div>
            )
          }
        </FilterHeader>
        <FiltersWrapper>
          <FilterButton
            isSelect={filterByFirstOrder[0]}
            onClick={selectFilterFirstOrderPerson}>Новые клиенты</FilterButton>
          <FilterButton
            isSelect={filterByFirstOrder[1]}
            onClick={selectFilterFirstOrderCompany}>Новые компании</FilterButton>
        </FiltersWrapper>
      </FilterByFirstOrder>
    </Filters>
  )
}

// <FilterByMapViewportBounds
//   onClick={() => pointsTriangulationFilter()}
// >Показать по границам карты</FilterByMapViewportBounds>


export default memo(InfoSidebarFilter);

const Filters = styled.div`
  position: sticky;
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 20px;
  overflow-y: auto;
  min-height: calc(100vh - 64px - 89px);
`
const FilterInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 10px;
  border-bottom: 3px solid silver;
  border-radius: 3px;
`
const FilterByStatus = styled(FilterInnerWrapper)`

`
const FilterByKitchen = styled(FilterInnerWrapper)`

`
const FilterByFirstOrder = styled(FilterInnerWrapper)`

`

const FilterByTriangleOrder = styled(FilterInnerWrapper)``

const FilterHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;

  > p {
    margin: 0;
    text-align: left;
  }

  > div {
    cursor: pointer;
    width: 18px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #1890ff;
    border-radius: 50%;

    > svg {
      fill: #fff;
    }

    :hover {
      background: #9fd2ff;
    }
  }
`

const FiltersWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`

const FilterButton = styled.div`
  cursor: pointer;
  padding: 3px 5px;
  background: ${({isSelect}) => isSelect ? "#1890ff" : "#fff"};
  color: ${({isSelect}) => isSelect ? "#fff" : "#000"};
  border: 1px solid silver;
  border-radius: 6px;
`

// const FilterByMapViewportBounds = styled(Button)`
// `
