import React from "react";
import styled from "styled-components";
import moment from "moment";
import {useDispatch} from "react-redux";
import {getMenu, rollingBackChangesEditMode, setActivePeriod} from "../../actions/MenuByDayActions";

// image
import period_ban_red from '../../images/menu_by_day/period_ban_red.svg'
import {nanoid} from "nanoid"


//Card component
const MenuCard = ({date, isToday, periodIsDefined, editMode, periods, activeRest}) => {
  const dispatch = useDispatch();

  const clickHandler = (prop, e) => {
    if (editMode) {
      dispatch(rollingBackChangesEditMode())
    }
    //resetSelect
    Object.values(document.querySelectorAll("." + Row.styledComponentId)).map(el => {
      el.setAttribute('style', "border: 2px solid rgba(255,255,255,0);");
    });
    e.target.setAttribute('style', "border: 2px solid #3D425E;");
    if (periodIsDefined) {
      let checkActivePeriod = periodIsDefined[prop.selectPeriod];
      if (periodIsDefined.length) {
        checkActivePeriod = periodIsDefined.reduce((acc, el) => {
          // console.log(prop.selectPeriod + 1);
          if (+el.periodId === prop.selectPeriod + 1) {
            acc = el;
          }
          return acc
        }, null);
      }
      // console.log(checkActivePeriod);
      dispatch(setActivePeriod({
        ...prop,
        status: checkActivePeriod ? checkActivePeriod.status : null,
        id: checkActivePeriod && checkActivePeriod.id,
        periodIsDefined: periodIsDefined,
        serviceSuspended: checkActivePeriod ? checkActivePeriod.serviceSuspended : null
      }));
    } else {
      dispatch(setActivePeriod({...prop}));
    }

    dispatch(getMenu({...prop}));
  };

  const testRef = React.createRef();

  const drawRow = (period, id) => {
    const timeFrom = period.timeFrom.split(":").slice(0, 2).join(":");
    const timeTo = period.timeTo.split(":").slice(0, 2).join(":");
    // const timeRestrictions = (timeFrom.split(":")[0] - 1) + ":" + timeFrom.split(":")[1];
    // console.log();
    const targetPeriodId = period.id;
    const periodIsSuspend = periodIsDefined
      ?
      periodIsDefined.reduce((acc, el) => {
        if (el.serviceSuspended !== "") {
          acc = [...acc, el.periodId];
        }
        return acc;
      }, [])
      :
      null;

    return (
      <Row
        key={nanoid()}
        ref={testRef}
        onClick={e => clickHandler({
            selectPeriod: id,
            activeRest: activeRest,
            date: date,
            period: timeFrom,
            periodRange: {timeFrom, timeTo},
            periodId: targetPeriodId
          },
          e
        )}
        periodIsDefined={periodIsDefined ? periodIsDefined.reduce((acc, el) => {
          if (el.periodId === targetPeriodId) {
            if (el.status === "publish") {
              acc = true;
            }
          }
          return acc;
        }, false) : null}
      >
        <div>
          <p> {
            periodIsSuspend.includes(period.id) && <img src={period_ban_red} alt="period_ban_red"/>
          }
            {timeFrom} - {timeTo}
          </p>
        </div>
        <div style={{
          textAlign: 'left',
          fontSize: '12px',
          display: 'flex',
          alignItems: "center"
        }}>
          {/*<img*/}
          {/*    src={shopping_bag}*/}
          {/*    alt="shopping bag"*/}
          {/*    style={{width: '9px'}}*/}
          {/*/>*/}
          {/*<p>234</p>*/}
        </div>
        <div style={{textAlign: 'right'}}>
          <p style={{width: '100%', color: 'transparent'}}>1</p>
        </div>
      </Row>
    )
  }

  const dateStr = moment(date).format('dddd D MMM').split(' ');

  return (
    <MenuCardWrapper>
      {
        isToday && (<TodayMark>Сегодня</TodayMark>)
      }
      <header>
        <DateOn>
          <p>{`${dateStr[0][0].toUpperCase() + dateStr[0].slice(1)}`}</p>
          <p>{`${dateStr[1]} ${dateStr[2]}`}</p>
        </DateOn>
      </header>
      <Content>
        {
          periods && (
            periods.map((el, id) => {
              return drawRow(el, id)
            }))
        }
      </Content>
    </MenuCardWrapper>
  )
}

const MenuCardWrapper = styled.div`
  margin-top: 20px;
  padding: 6px;
  background: #fffff9;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  border: 1px solid black;
  position: relative;

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
  }

  footer {
    display: flex;
    justify-content: space-between;
    padding-top: 8px;
    font-size: 12px;
    line-height: 14px;

    p {
      margin: 0;
    }

    div {
      display: flex;
      align-items: center;
    }
  }
`
const TodayMark = styled.p`
  position: absolute;
  top: -20px;
`
const DateOn = styled.div`
  position: relative;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  width: 100%;
  
  >p{
    text-align: left;
    margin: 0;
  }
`
const Content = styled.div`
`
const Row = styled.div`
  padding: 5px;
  display: grid;
  grid-template-columns: 1.2fr 1fr;
  border: 2px solid rgba(255, 255, 255, 0);
  background: #DDDDDD;
  border-radius: 6px;
  font-size: 12px;
  background: ${({periodIsDefined}) => periodIsDefined ? "#CDDC39" : "#DDDDDD"};

  @media (-webkit-min-device-pixel-ratio: 2),(max-width: 1200px) {
    grid-template-columns: 1fr;
  }

  :hover {
    border: 2px solid #3D425E;
    transition: border .2s ease;
  }

  div {
    display: flex;
    align-items: center;
    pointer-events: none;
  }

  p {
    margin: 0;
    pointer-events: none;
  }

  &:nth-child(n + 1) {
    margin-bottom: 10px;
  }

  &:last-child {
    margin-bottom: 0;
  }
`
export default MenuCard;
