import React, {memo} from "react";
import GoogleMapReact from "google-map-react"
import styled from "styled-components"
import {useDispatch} from "react-redux"
import {setCenter, setMap, setMaps, setZoom} from "../../reducers/toolkit/orderMapReducer"

const MapComponent = ({zoom, center}) => {
  const dispatch = useDispatch()
  return (
    <MapWrapper>
      <GoogleMapReact
        options={{
          disableDefaultUI: true,
        }}
        bootstrapURLKeys={{key: 'AIzaSyDZbiFFOqzJz-bW-YtxdJppFY-7M2Uf6Bk', language: 'ru'}}
        defaultCenter={center}
        defaultZoom={zoom}
        center={center}
        zoom={zoom}

        yesIWantToUseGoogleMapApiInternals
        onChange={(e) => {
          dispatch(setCenter(e.center))
          dispatch(setZoom(e.zoom))
        }}
        onGoogleApiLoaded={({map, maps}) => {
          dispatch(setMaps(maps))
          dispatch(setMap(map))
        }}
      >
      </GoogleMapReact>
    </MapWrapper>
  )
}

export default memo(MapComponent);

const MapWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: calc(100vh - 64px);
  z-index: 1;
`
