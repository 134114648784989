import {
    SEND_WAYBILL, SEND_WAYBILL_ERROR, SEND_WAYBILL_READY,
    TOGGLE_MODAL, TRY_CREATE_WAYBILL,
    TRY_EDIT_WAYBILL, UPDATE_WAYBILL, UPDATE_WAYBILL_STATUS
} from "../../actions/WarehouseActions";

const initState = {
    showModal: false,
    showModalReason: '',
    sendWaybillStart: false,
    sendWaybillReady: false,
    sendWaybillError: false,
    sendErrorMessage: '',

    updateWaybillStart: false,
    updateWaybillSuccess: false,
    updateWaybillError: '',
}

export const _modalControlReducer = (state = initState, action) => {
    switch (action.type) {
        case TRY_CREATE_WAYBILL:
            return {
                ...state,
                showModal: !state.showModal,
                showModalReason: 'CREATE',
            }
        case TRY_EDIT_WAYBILL:
            return {
                ...state,
                showModal: !state.showModal,
                showModalReason: 'EDIT',
            }
        case TOGGLE_MODAL:
            return {
                ...state,
                showModal: false,
                showModalReason: '',
                sendWaybillStart: false,
                sendWaybillReady: false,
                sendWaybillError: false,
                sendErrorMessage: '',
                updateWaybillStart: false,
                updateWaybillSuccess: false,
                updateWaybillError: '',
            }

        case SEND_WAYBILL:
            return {
                ...state,
                sendWaybillReady: false,
                sendWaybillError: false,
                sendErrorMessage: '',
                sendWaybillStart: true,
            }
        case SEND_WAYBILL_READY:
            let newCreateState
            // console.log(action.payload)
            if (action.payload.waybillId !== 0) {
                newCreateState = {
                    sendWaybillStart: false,
                    sendWaybillError: false,
                    sendErrorMessage: '',
                    sendWaybillReady: true,
                    newWaybillId: action.payload.waybillId,
                    newWaybillPrice: action.payload.productPriceSum,
                }
            } else {
                newCreateState = {
                    sendWaybillStart: false,
                    sendWaybillError: true,
                    sendErrorMessage: 'Накладная не добавлена',
                    sendWaybillReady: true,
                }
            }
            return {
                ...state,
                ...newCreateState
            }
        case SEND_WAYBILL_ERROR:
            return {
                ...state,
                sendWaybillStart: false,
                sendWaybillReady: false,
                sendErrorMessage: action.payload.message,
                sendWaybillError: true,
            }
        case UPDATE_WAYBILL:
            return {
                ...state,
                updateWaybillStart: true,
                updateWaybillSuccess: false,
                updateWaybillError: '',
                updateWaybillTarget: action.payload.newState,
                updateWaybillPrev: action.payload.prevState,
            }
        case UPDATE_WAYBILL_STATUS:
            // console.log(action.payload)
            let newUpdateState
            if (action.payload.status) {
                newUpdateState = {
                    updateWaybillStart: false,
                    updateWaybillSuccess: true,
                    sendErrorMessage: '',
                    newWaybillPrice: action.payload.productPriceSum,
                }
            } else {
                newUpdateState = {
                    updateWaybillStart: false,
                    updateWaybillSuccess: true,
                    updateWaybillError: action.payload.error,
                }
            }
            return {
                ...state,
                ...newUpdateState
            }
            return {
                ...state,
                updateWaybillStart: false,
                updateWaybillSuccess: true,
                updateWaybillError: '',
            }
        default:
            return state
    }
}