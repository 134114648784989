import React, { Component } from 'react';
import { Form, Icon, Input, Button } from 'antd';
import { Modal } from 'semantic-ui-react';
import {Redirect} from "react-router-dom";

class NormalLoginForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            params: {},
            redirectTrigger: false,
        }
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
                this.props.activateAccount(values, this.state.params)
            }
        });
    };
 

    componentDidMount() {
        let obj = {}
        let paramsArr = window.location.href.split("sys/signup&")[1].split("&")
        paramsArr.forEach(param => {
            if (param.includes('=')) {
                let splitedParam = param.split("=")
                obj[splitedParam[0]] = splitedParam[1]
            }
        })
        this.setState({params: obj})
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.registerStatus !== prevProps.registerStatus && this.props.registerStatus?.status === 'resolve') {
            setTimeout(() => {
                this.setState({redirectTrigger: true}, () => window.location.reload());
            }, 1000);
        }
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <Modal open={this.props.open} size='small' onClose={this.props.onClose}  >
                <Form onSubmit={this.handleSubmit} className="login-form" style={{padding:'22px'}}>
            <div style={{
                marginBottom:30,
                fontSize:20,
                fontWeight:600,
                color:'#2C2E32'
            }}>Регистрация</div>
                    <div
                    style={{
                        fontSize:14,
                        fontWeight:500,
                        color:'#2C2E32'
                    }}
                    >Введите свой новый пароль</div>
                    <Form.Item>
                        {getFieldDecorator('password', {
                            rules: [{ required: true, message: 'Пожалуйста введите новый пароль!' }],
                        })(
                            <Input
                                prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                type="password"
                                placeholder="Пароль"
                            />,
                        )}
                    </Form.Item>
                    <div
                    style={{
                        fontSize:14,
                        fontWeight:500,
                        color:'#2C2E32'
                    }}
                    >Введите email которы вы указывали при регистрации</div>
                    <Form.Item>
                        {getFieldDecorator('fio', {
                            rules: [{ required: true, message: 'Пожалуйста введите свой e-mail!' }],
                        })(
                            <Input
                                prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                placeholder="Логин"
                            />,
                        )}
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" className="login-form-button">
                            Регистрация
                        </Button>
                    </Form.Item>
                    {
                      this.state.redirectTrigger && (
                        <>
                          <Redirect exact to="/dashboard" />
                        </>
                      )
                    }
                </Form>
            </Modal>
        );
    }
}

const WrappedNormalLoginForm = Form.create({ name: 'normal_login' })(NormalLoginForm);
export default WrappedNormalLoginForm;
