import React, {memo} from "react";
import ProductListItem from "./ProductListItem"
import {nanoid} from "nanoid"
import {modalGrid} from "./AddWaybillModal"


const ProductList = ({products, showModalReason, setProducts}) => products.map(
  (el, i) => {
    return <ProductListItem
      key={nanoid()}
      style={modalGrid}
      {...{el}}
      id={i}
      showModalReason={showModalReason}
      products={products}
      setProducts={setProducts}
    />
  }
)

export default memo(ProductList);
