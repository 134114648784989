import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {toggleModal} from "../../../pages/Delivery/OrderMap"
import plural from "plural-ru"
import {useDispatch, useSelector} from "react-redux"
import {sendPushMessage} from "../../../actions/ReviewsActions"

let EXTERNAL_PROPS = {}

export const MODAL_NAME_PUSH_MESSAGE = 'pushMessageModal'

export function togglePushMessageModal(props = null) {
  toggleModal(MODAL_NAME_PUSH_MESSAGE)
  if(props) EXTERNAL_PROPS = {...props} = props
}

const PushMessageToClient = (props) => {

  const dispatch = useDispatch()
  const {selectedOrders} = EXTERNAL_PROPS
  const deliveryPoints = useSelector(store => store.orderMap.deliveryPoints)
  const point = plural(selectedOrders.length, 'адресатам', 'aдресату')
  const [textTitle, setTextTitle] = useState('')
  const [textBody, setTextBody] = useState('')


  function clearMessage(){
    setTextTitle('')
    setTextBody('')
  }
  
  const sendPushMessageHandler = () => {
    if(textTitle && textBody) {
      selectedOrders.forEach(el => {
        dispatch(sendPushMessage({
          message: textTitle,
          title: textBody,
          orderId: el,
        }))
      })
      
    }
  }
  return (
    <>
    {
    <Wrapper>
      <p>Отправить сообщение {point}:</p>
      <MessageTextarea  placeholder="Заголовок" value={textBody} onChange={e=> setTextBody(e.target.value)}/>
      <MessageTextareaInside placeholder= "Сообщение" value={textTitle} onChange={e => setTextTitle(e.target.value)}/>
      <Controls>
        <Send 
        isActive={!!textTitle && !!textBody} 
        onClick={() => !!textTitle && !!textBody && sendPushMessageHandler()}
        >Отправить</Send>
        <Clear onClick={clearMessage}>Очистить</Clear>
      </Controls>
    </Wrapper>
}
    </>
  )
}

export default PushMessageToClient;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const MessageTextareaInside = styled.textarea`
  height: 100px;
  resize: none;
`

const MessageTextarea = styled.textarea`
  width: 100%;
  height: 50px;
  resize: none;
`

const Controls = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
`
const btn = styled.button`
  color: #fff;
  padding: 5px 10px;
  border-radius: 6px;
  border: 2px solid transparent;
  cursor: pointer;
`
const Send = styled(btn)`
  background: ${({isActive}) => isActive ? '#3498DB' : 'rgba(0,0,0,0.36)'};

  :hover {
    background: ${({isActive}) => isActive ? '#51a1d7' : 'rgba(0,0,0,0.36)'};
    border: 2px solid ${({isActive}) => isActive ? '#0b86d8' : 'rgba(185,185,185,0.36)'};
  }
`
const Clear = styled(btn)`
  background: #ff6368;

  :hover {
    background: #f52026;
    border: 2px solid #f00209;
  }
`
