import React, {memo, useEffect, useState} from "react";
import styled from "styled-components";
import redStar from '../../../images/redStar.svg'
import mapPoint from '../../../images/mapPoint.svg'
import {resetInfoUint, selectInfoUint} from "../../../reducers/toolkit/orderMapReducer"
import {useDispatch, useSelector} from "react-redux"
import {MoneyIco, PersonIco} from "../../../images/svgs"
import AboutOrder from "../../share/OrderMapCommonComponents/AboutOrder"

const animationCloseButton = {
  init: {
    scale: 0,
    right: 0,
    top: 0,
  },
  anim: {
    scale: 1,
    right: -8,
    top: -8,
  }
}

const InfoUnit = ({companyData, id}) => {

  const dispatch = useDispatch()
  const selectionInfoUnit = useSelector(store => store.orderMap.selectionInfoUnit)
  const [isSelect, setIsSelect] = useState(false)
  const {companyId, companyName, deliveryTime, marker, companyAddress, newClientsCount, newCompanyMarker} = companyData

  const time = deliveryTime ? deliveryTime.split(' ')[1].slice(0, 5) : ""

  useEffect(() => {
    if (selectionInfoUnit?.ordId === companyData.ordId) {
      setIsSelect(true)
    } else {
      setIsSelect(false)
    }
  }, [selectionInfoUnit])

  return (
    <Wrapper isSelect={isSelect} onClick={() => {
      dispatch(selectInfoUint({...companyData, localId: id}))
    }}>
      <Header>
        <CompanyId>
          ID:{companyId}
        </CompanyId>
        {
          newCompanyMarker && <NewCompanyMarker src={redStar}/>
        }
        {
          newClientsCount && (
            <FirstOrderedClientsCounter>
              {newClientsCount}
            </FirstOrderedClientsCounter>
          )
        }
      </Header>
      <ShortInfo>
        <CompanyName>
          {companyName}
        </CompanyName>
        <DeliveryInfo>
          <DeliveryTime>{time}</DeliveryTime>
          {
            marker.sideIcon ? (
              <FromToRedirect>
                <DeliveryStatus url={marker.nativeIcon}/>
                <p> > </p>
                <DeliveryStatus url={marker.sideIcon}/>
              </FromToRedirect>
            ) : (
              <DeliveryStatus url={marker.nativeIcon}/>
            )
          }
        </DeliveryInfo>
      </ShortInfo>
      <AddressInfo>
        <AddressMarker src={mapPoint}/>
        <p>{companyAddress}</p>
      </AddressInfo>
      <AboutOrder {...{companyData}}/>
    </Wrapper>
  )
}


export default memo(InfoUnit);

const Wrapper = styled.div`
  cursor: pointer;
  position: relative;
  display: flex;
  padding: 10px;
  flex-direction: column;
  border-bottom: 1px solid #DDDDDD;
  text-align: left;
  background: ${({isSelect}) => isSelect ? '#DDDDDD' : '#fff'};

  :hover {
    background: #EEF2F4;
  }
  
  p{
    margin: 0;
  }
`
// ShortInfo children
const ShortInfo = styled.div`
  display: flex;
  align-items: baseline;
  gap: 3px;

  > p {
    margin: 0;
  }
`
const CompanyName = styled.div`
  font-size: 13px;
  color: #3498DB;
`
const NewCompanyMarker = styled.img`
  width: 12px;
`
const FirstOrderedClientsCounter = styled.p`
  margin: 0;
  padding: 2px 8px;
  border-radius: 50%;
  background: #FFD954;
`
const DeliveryInfo = styled.div`
  display: flex;
  gap: 5px;
  margin-left: auto;

  > p {
    margin: 0;
  }
`

const DeliveryTime = styled.p`
  margin-left: auto;
`
const FromToRedirect = styled.div`
  display: flex;
  gap: 2px;

  > p {
    margin: 0;
  }
`
const DeliveryStatus = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: url("${({url}) => url}");
  background-repeat: no-repeat;
  background-position: center -0.5px;
  background-size: 100%;
`
const Header = styled.div`
  gap: 9px;
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
`
const CompanyId = styled.p`
  margin: 0;
  font-size: 20px;
  max-width: 70%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
// ---
// AddressInfo children
const AddressInfo = styled.div`
  display: flex;
  align-items: baseline;
  gap: 3px;
  color: #999;
  font-size: 11px;
  line-height: 13px;
`
const AddressMarker = styled(NewCompanyMarker)`
  width: 10px;
  height: 10px;
`
