import {createAction, createSlice} from "@reduxjs/toolkit"

const initialState = {
  getPermissionsStarted: false,
  getPermissionsSuccess: false,
  getPermissionsError: false,
  getKitchensStarted:false,
  getKitchensSucces:false,
  getKitchensError:false,
  permissions: {},
  kitchens:[],
  addNewKitchensModal:false,
  selectedKitchens:{id:1,name:'Не выбрано'},
  cities: null,
  distributionCenters: null,
}

export const getPermissionsAction = createAction('appMain/getPermissions')
export const getKitchensAction = createAction('appMain/getKitchens')

const mainAppReducer = createSlice({
  name: 'appMain',
  initialState,
  reducers: {
    getPermissionsHandler(state, action) {
      if(typeof action.payload !== 'object') {
        switch (action.payload){
          case 'pending':
            state.getPermissionsStarted = true
            break
          case 'rejected':
            state.getPermissionsStarted = false
            state.getPermissionsSuccess = false
            state.getPermissionsError = true
            state.permissions = action.payload
            break
        }
      } else {
        state.getPermissionsStarted = false
        state.getPermissionsSuccess = true
        state.getPermissionsError = false
        state.permissions = action.payload

        state.distributionCenters = action.payload.centers.reduce((acc, el) => {
          const {centerId, name, cityId} = el
          acc.push({centerId, name, cityId})
          return [...acc]
        }, [])

        const cityIds = []
        state.cities = action.payload.centers.reduce((acc, el) => {
          const {cityName, cityId} = el
          if(!cityIds.includes(cityId)){
            cityIds.push(cityId)
            acc.push({cityId, cityName})
          }
          return [...acc]
        }, [])
      }
    },
    permissionsClear(state) {
      state.permissions = initialState.permissions
      state.getPermissionsSuccess = initialState.getPermissionsSuccess
    },
    getKitchensHandler(state, action) {
      if(typeof action.payload !== 'object') {
        switch (action.payload){
          case 'pending':
            state.getKitchensStarted = true
            break
          case 'rejected':
            state.getKitchensStarted = false
            state.getKitchensSucces = false
            state.getKitchensError = true
            state.kitchens = 'error'
            break
        }
      } else {
        state.getKitchensStarted = false
        state.getKitchensSucces = true
        state.getKitchensError = false
        state.kitchens = action.payload

        state.kitchens = [...action.payload]
      }
    },
    setSelectedKitchens(state,action){
        localStorage.setItem('kitchens',JSON.stringify(action.payload))
        state.selectedKitchens = action.payload
    },
    setModalView(state,action){
      state.addNewKitchensModal = action.payload
    },
    addNewKitchens(state,action){
      state.kitchens = [...state.kitchens,action.payload]
    }
  }
})

export const {
  getPermissionsHandler,
  getKitchensHandler,
  permissionsClear,
  setSelectedKitchens,
  setModalView,
  addNewKitchens
} = mainAppReducer.actions
export default mainAppReducer.reducer
