import React from "react";
import styled from "styled-components";
import {useSelector} from "react-redux"
import CouriersOnLineList from "./ListHandlers/CouriersOnLineList"
import CouriersOffLineList from "./ListHandlers/CouriersOffLineList"
import {gridLayout} from "./CourierSideBar"

export const cutTime = (timeStr) => {
  if(timeStr){
    return timeStr.split(':').filter((el, id) => id < 2).join(':')
  } else {
    return '--:--'
  }
}

const CouriersStatistic = ({
                             setShowCourierDetails,
                             showCourierDetails,
                             setShowCourierSidebar,
                           }) => {
  const totalOrders = useSelector(store => store.orderMap.totalOrders)
  const totalTimes = useSelector(store => store.orderMap.totalTimes)
  const totalDistance = useSelector(store => store.orderMap.totalDistance)
  const totalSum = useSelector(store => store.orderMap.totalSum)
  const totalCash = useSelector(store => store.orderMap.totalCash)
  const totalSalary = useSelector(store => store.orderMap.totalSalary)
  const distanceFormat = totalDistance && (+totalDistance / 1000).toFixed(1)
  const sumFormat = (totalSum + '').split(/([0-9]{3}$)/).join(' ')
  const cashFormat = (totalCash + '').split(/([0-9]{3}$)/).join(' ')
  const salaryFormat = (totalSalary + '').split(/([0-9]{3}$)/).join(' ')

  return (
    <>
      <CloseButtonMobile onClick={() => setShowCourierSidebar(false)}>
        Закрыть
      </CloseButtonMobile>
      <Header showDetails={showCourierDetails}  >
        <p>Итого</p>
        {
          showCourierDetails ? (
            <>
              <p>{distanceFormat}</p>
              <p>{sumFormat}</p>
              <p>{cashFormat}</p>
              <p>{salaryFormat}</p>
              
            </>
          ) : (
            <>
              <p>{totalOrders[1]}/{totalOrders[0]}</p>
              <p>{cutTime(totalTimes[0])} --> {cutTime(totalTimes[1])}</p>
            </>
          )
        }
        <DetailedInfoSelectorWrapper>
          <DetailedInfoSelector onClick={() => setShowCourierDetails(prevState => !prevState)} {...{showCourierDetails}}/>
        </DetailedInfoSelectorWrapper>
      </Header>
      <Content showDetails={showCourierDetails}>
        {/*два списка, с назначеными и не назначеными курьерами*/}
        <CouriersOnLineList {...{showCourierDetails}}/>
        <CouriersOffLineList />
      </Content>
    </>
  )
}

export default CouriersStatistic;

const Header = styled(gridLayout())`
  position: relative;
  padding: 10px;
  border-bottom: 2px solid silver;
  
  >p{
    margin: 0;
    
    @media (max-width: 480px) {
      font-size: 8px;
      line-height: 11px;
    }
  }
  p:nth-child(1){
    text-align: left;
  }
`

const DetailedInfoSelectorWrapper = styled.div`
  position: absolute;
  right: -10px;
  width: 20px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const DetailedInfoSelector = styled.div`
  cursor: pointer;
  width: 90%;
  height: 50%;
  background: ${({showCourierDetails}) => showCourierDetails ? '#30b2db' : '#fff'};
  border: ${({showCourierDetails}) => showCourierDetails ? '1px solid #30b2db' : '1px solid #555'};
  border-radius: 6px;
`

const Content = styled.div`
  
`

const CloseButtonMobile = styled.p`
  display: none;
  margin-bottom: 0;
  margin-top: 10px !important;
  color: #3498DB;
  
  @media (max-width: 480px) {
    display: block;
  }
`
