import React, { useEffect, useState, memo } from "react";
import styled from "styled-components";
import Categories from "./Categories";
import RestSelector from "./RestSelector";
import { useDispatch, useSelector } from "react-redux";
import { sr } from "../../utils";
import {
  getKitchens,
  getCategory,
  getKithensStarted,
  getCategoryStarted,
  saveKithensId,
} from "../../actions/AssortmentAction";
import { getPermissionsAction } from "../../reducers/toolkit/mainAppReducer";

const Aside = ({
  setAddProductFieldShow,
  addProductFieldShow,
  openModal,
  setOpenModal,
  scrollsRef,
  mainScrollRef,
}) => {
  const [activeRest, setActiveRest] = useState(null);
  const [moderatorMode, setModeratorMode] = useState([]);
  const SelectedKitchens = useSelector(
    (store) => store.appMain.selectedKitchens
  );
  const categoryList = useSelector((store) => store.assortment.categoryList);
  const dispatch = useDispatch();

  const changeContract = (value) => {
    setActiveRest(value);
  };

  useEffect(() => {
    dispatch(getCategoryStarted(SelectedKitchens.id));
  }, [SelectedKitchens]);

  useEffect(() => {
    dispatch(getKithensStarted());
  }, [moderatorMode]);

  const mainSettings = useSelector((store) => store.appMain);
  const { permissions } = mainSettings;

  return (
    <AsideWrapper>
      {/* <RestSelector activeRest={activeRest} setActiveRest={changeContract}/> */}
      <Categories
        scrollsRef={scrollsRef}
        setAddProductFieldShow={setAddProductFieldShow}
        categoryList={categoryList}
        addProductFieldShow={addProductFieldShow}
        openModal={openModal}
        setOpenModal={setOpenModal}
        mainScrollRef={mainScrollRef}
      />
    </AsideWrapper>
  );
};

export default memo(Aside);

const AsideWrapper = styled.aside`
  grid-area: aside;
`;
