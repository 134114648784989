import React,{useState} from "react";
import styled from "styled-components";
import avatarPlug from '../images/accountView/profile_avatar_plug.svg'
import dropdownArrow from '../images/accountView/dropdown_arrow.svg'
import DropdownMenu from "./DorpdownMenu";
const AccountView = () => {
    return (
        <AccountViewWrapper>
            {/*<UserName>*/}
            {/*    Константин*/}
            {/*</UserName>*/}
            <UserProfile className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                <Avatar>
                    <img src={avatarPlug} alt="avatar"/>
                </Avatar>
                <DropdownMenu>
                    <DropdownArrow>
                        <img src={dropdownArrow} alt="arrow"/>
                    </DropdownArrow>
                </DropdownMenu>
            </UserProfile>
        </AccountViewWrapper>
    )
}

export default AccountView;

const AccountViewWrapper = styled.div`
  display: flex;
  align-items: center;
`
const UserName = styled.p`
  margin: 0;
  padding-right: 8px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
`
const UserProfile = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`
const Avatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  padding: 2px;
  border-radius: 50%;
  border: 1.5px solid #3D425D;
  box-sizing: border-box;
  overflow: hidden;
  
  > img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
`
const DropdownArrow = styled.div`
  padding-left: 8px;
`
const PseudoSelect=styled.div`
display:flex;
align-items: center;
justify-content: flex-start;
height:40px;
width:200px;
position:relative;
background: #FFFFFF;
border: 1px solid #CECECE;
border-radius: 8px;
font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 130%;
letter-spacing: -0.442px;
padding-left: 10px;
color: #2C2E32;
  :hover{
    box-shadow: 0px 0px 8px 5px rgba(24, 239, 255, 0.2);
  }
`
const WrapperPseudo = styled.div`
padding-right: 30px;
`
const WrapperModal = styled.div`
width:250px;
background: #FFFFFF;
border: 1px solid #CECECE;
border-radius: 8px;
position:absolute;
z-index:9999;
top: 58px;
`