import React, {Fragment} from 'react';
import {connect} from "react-redux";
import '../App.css';
import styled from "styled-components";
import AddAny from "./AddAny";
import moment from "moment";
import DatePicker, {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ru from 'date-fns/locale/ru';
import {sr} from "../utils";
import {Switch, Select} from "antd";
import plural from "plural-ru";
import {deliveryCycleInfoMessage} from "../actions/ModalActions";

const {Option} = Select;

registerLocale('ru', ru);

class AddPromocodeModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            activeTab: 'settings',
            eateries: [],
            selectedPeriods: [],
            selectedCategory: 0,

            selectedDefaultCategory: '',

            promocode: {},
            promocode_name: '',
            onlyForFirstOrder: '',
            maxCount: '',
            maxUsing: '',
            discountPerc: '',
            discountMax: '',
            walletAmount: '',
            validFrom: null,
            validTo: null,
            walletValidFrom: null,
            walletValidTo: null,
            message: '',
            walletName: '',
            status: false,
            orderMin: '',
            orderMax: '',
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.data !== prevProps.data) {
            if (this.props.data) {
                // console.log('periods props', this.props.data.periods)
                //  console.log('category props', this.props.category)
            //    console.log(this.props.data.periods)
            //     console.log(this.props.category)
                this.setState({
                    eateries: this.props.data.accounts.length > 0 ? this.props.data.accounts : [],
                    promocode: this.props.data,
                    promocode_name: this.props.data.promocode,
                    onlyForFirstOrder: this.props.data.OnlyForFirstOrder,
                    selectedPeriods: this.props.data.periods,
                    //////////////////////////////////////////
                    selectedDefaultCategory: this.props.data.category,
                    selectedCategory: this.props.data.categoryId,
                    //////////////////////////////////////////
                    maxCount: this.props.data.maxCount,
                    maxUsing: this.props.data.maxUsing,
                    discountPerc: this.props.data.discountPerc,
                    discountMax: this.props.data.discountMax,
                    walletAmount: this.props.data.walletAmount,
                    validFrom: this.props.data.validFrom && moment(this.props.data.validFrom, moment.defaultFormat).toDate(),
                    validTo: this.props.data.validTo && moment(this.props.data.validTo, moment.defaultFormat).toDate(),
                    walletValidFrom: this.props.data.walletValidFrom && moment(this.props.data.walletValidFrom, moment.defaultFormat).toDate(),
                    walletValidTo: this.props.data.walletValidTo && moment(this.props.data.walletValidTo, moment.defaultFormat).toDate(),
                    message: this.props.data.message,
                    walletName: this.props.data.walletName,
                    status: !!+this.props.data.status,
                    orderMin: this.props.data.orderMin,
                    orderMax: this.props.data.orderMax,
                })
            } else {
                this.setState({
                    eateries: [],
                    promocode: {},
                    promocode_name: '',
                    onlyForFirstOrder: '',
                    maxCount: '',
                    maxUsing: '',
                    discountPerc: '',
                    discountMax: '',
                    walletAmount: '',
                    validFrom: null,
                    validTo: null,
                    walletValidFrom: null,
                    walletValidTo: null,
                    message: '',
                    walletName: '',
                    status: false,
                })
            }
        }

        if (this.state.selectedCategory !== prevState.selectedCategory) {
            // console.log(this.state.selectedCategory, 'this.state.selectedCategory')
        }
    }

    onSettings = () => {
        this.setState({activeTab: 'settings'})
    }

    onTransactions = () => {
        this.setState({activeTab: 'transactions'})
    }

    add = (type, value) => {
        switch (type) {
            case 'users':
                // sr("WalletsController", "AddUserWallet", {
                //     client: value,
                //     wallet: this.state.wallet_id,
                // }).then((res) => {
                //     console.log('AddUserWallet', res);
                //     if (res.result === 1) {
                //         this.props.refreshData();
                //         this.props.deliveryCycleInfoMessage('something_added');
                //     } else {
                //         this.props.deliveryCycleInfoMessage('adding_error');
                //     }
                // });
                break;
            case 'companies':
                // sr("WalletsController", "AddCompanyWallet", {
                //     company: value,
                //     wallet: this.state.wallet_id,
                // }).then((res) => {
                //     console.log('AddCompanyWallet', res);
                //     if (res.result === 1) {
                //         this.props.refreshData();
                //         this.props.deliveryCycleInfoMessage('something_added');
                //     } else {
                //         this.props.deliveryCycleInfoMessage('adding_error');
                //     }
                // });
                break;
            default:
                sr("WalletsController", "AddAccountPromocode", {
                    account: value,
                    promocodeId: this.state.promocode.id,
                }).then((res) => {
                    // console.log('AddAccountPromocode', res);
                    if (res.result === 1) {
                        this.props.refreshData();
                        this.props.deliveryCycleInfoMessage('something_added');
                    } else {
                        this.props.deliveryCycleInfoMessage('adding_error');
                    }
                });
        }
    }

    del = (type, id) => {
        switch (type) {
            case 'users':
                // console.log(id,'id')
                sr("WalletsController", "DelUserWallet", {
                    id: id,
                }).then((res) => {
                    // console.log('AddUserWallet', res);
                    if (res.data.deleted && res.result === 1) {
                        this.props.refreshData();
                        this.props.deliveryCycleInfoMessage('something_removed');
                    } else {
                        this.props.deliveryCycleInfoMessage('removing_error');
                    }
                });
                break;
            case 'companies':
                sr("WalletsController", "DelCompanyWallet", {
                    id: id,
                }).then((res) => {
                    // console.log('AddCompanyWallet', res);
                    if (res.data.deleted && res.result === 1) {
                        this.props.refreshData();
                        this.props.deliveryCycleInfoMessage('something_removed');
                    } else {
                        this.props.deliveryCycleInfoMessage('removing_error');
                    }
                });
                break;
            default:
                sr("WalletsController", "DelAccountWallet", {
                    id: id,
                }).then((res) => {
                    // console.log('AddAccountWallet', res);
                    if (res.data.deleted && res.result === 1) {
                        this.props.refreshData();
                        this.props.deliveryCycleInfoMessage('something_removed');
                    } else {
                        this.props.deliveryCycleInfoMessage('removing_error');
                    }
                });
        }
    }

    // addTransaction = () => {
    //     if (this.state.sumValue && this.state.sumValue !== '0' && this.state.commentValue) {
    //
    //         sr("WalletsController", "CreateTransaction", {
    //             walletFrom: this.state.sumValue < 0 ? this.state.wallet_id : 0,
    //             walletTo: this.state.sumValue < 0 ? 0 : this.state.wallet_id,
    //             amount: Math.abs(this.state.sumValue),
    //             comment: this.state.commentValue,
    //         }).then((res) => {
    //             console.log('res', res);
    //             if (res.result === 1) {
    //                 this.props.refreshData();
    //                 this.props.deliveryCycleInfoMessage('transaction_added');
    //             } else {
    //                 this.props.deliveryCycleInfoMessage('transaction_adding_error');
    //             }
    //         });
    //
    //         this.setState({sumValue: '', commentValue: ''});
    //     }
    // }

    // handleChange = (event) => {
    //     this.setState({sumValue: event.target.value});
    // }

    // handleChange2 = (event) => {
    //     this.setState({commentValue: event.target.value});
    // }

    handleChange3 = (event) => {
        this.setState({promocode_name: event.target.value});
    }

    handleChange5 = (event) => {
        this.setState({discountPerc: event.target.value});
    }

    // handleChange4 = (event) => {
    //     if (+event.target.value >= 0) {
    //         this.setState({limitAmountPerDay: String(+event.target.value)});
    //     }
    // }

    handleChange6 = (event) => {
        this.setState({discountMax: event.target.value});
    }

    handleChange7 = (event) => {
        this.setState({maxUsing: event.target.value});
    }

    handleChange71 = (event) => {
        this.setState({maxCount: event.target.value});
    }

    handleChange8 = (event) => {
        this.setState({onlyForFirstOrder: event.target.value});
    }

    handleChange9 = (event) => {
        this.setState({walletAmount: event.target.value});
    }

    saveEdited = () => {
        if (this.state.promocode_name) {
            sr("WalletsController", "UpdatePromocode", {
                // id: this.state.wallet_id,
                // name: this.state.wallet_name,
                // holderId: 146923,
                // //wType: this.state.type || "private",
                // validFrom: this.state.startTime ? moment(this.state.startTime).format("YYYY-MM-DD 00:00:00") : null, //"2020-07-01" || null
                // validTo: this.state.endTime ? moment(this.state.endTime).format("YYYY-MM-DD 23:59:59") : null, //"2020-12-30" || null,
                // limitAmountPerDay: this.state.limitAmountPerDay ? this.state.limitAmountPerDay : "0.00",
                // contractId: this.state.contractId,

                id: this.state.promocode.id,
                promocode: this.state.promocode_name,
                status: +this.state.status + '',
                validFrom: this.state.validFrom ? moment(this.state.validFrom).format("YYYY-MM-DD 00:00:00") : null, //"2020-07-01" || null
                validTo: this.state.validTo ? moment(this.state.validTo).format("YYYY-MM-DD 23:59:59") : null, //"2020-12-30" || null,
                OnlyForFirstOrder: this.state.onlyForFirstOrder,
                periods: this.state.selectedPeriods,
                ///
                categoryId: this.state.selectedCategory,
                ///
                maxCount: this.state.maxCount,
                maxUsing: this.state.maxUsing,
                message: this.state.message,

                discountPerc: this.state.discountPerc,
                discountMax: this.state.discountMax.replace(',', '.'),
                
                orderMax: this.state.orderMax === null || this.state.orderMax === '' ? null : this.state.orderMax.replace(',', '.'),
                orderMin: this.state.orderMin === null || this.state.orderMin === '' ? null : this.state.orderMin.replace(',', '.'),
                walletName: this.state.walletName,
                walletValidFrom: this.state.walletValidFrom ? moment(this.state.walletValidFrom).format("YYYY-MM-DD 00:00:00") : null, //"2020-07-01" || null
                walletValidTo: this.state.walletValidTo ? moment(this.state.walletValidTo).format("YYYY-MM-DD 23:59:59") : null, //"2020-12-30" || null,
                walletAmount: this.state.walletAmount.replace(',', '.'),
            }).then((res) => {
                // console.log('updateWallet res 1111', res);
                if (res.data.updated && res.result === 1) {
                    this.props.deliveryCycleInfoMessage('saved');
                    //this.props.refreshData();
                    this.props.handleSubmit(this.props.inputValue);
                }
            });
        } else {
            this.props.deliveryCycleInfoMessage('promocode_empty');
        }
    }

    createWallet = () => {
        if (this.state.promocode_name) {
            sr("WalletsController", "CreatePromocode", {
                promocode: this.state.promocode_name,
            }).then(res => {
                // console.log('created res', res);
                if (res.data.id && res.result) {
                    this.props.deliveryCycleInfoMessage('promocode_created');
                    this.props.updateWallet(res.data.id);
                }
                if (!res.data.id) {
                    this.props.deliveryCycleInfoMessage('promocode_created_error');
                }
            });
        } else {
            this.props.deliveryCycleInfoMessage('promocode_empty');
        }
    }
    

    // changeContract = (value) => {
    //     this.setState({contractId: value} , () => console.log(`selected ${value}`))
    // }

    // onChange = (e) => {
    //     let files = e.target.files;
    //     let reader = new FileReader();
    //     reader.readAsDataURL(files[0]);
    //
    //     reader.onload = (e) => {
    //         sr("WalletsController", "VerificationWalletsList", {csv: e.target.result, contractId: this.state.contractId}).then((res) => {
    //             const message = () => {
    //
    //                 let string = '';
    //                 let errors = '';
    //                 if (res.data.errorCount) {
    //                     res.data.errors.forEach(v => {
    //                         errors += `${v}\n`
    //                     })
    //                     string += `${plural(res.data.errorCount, '%d строка', '%d строки', '%d строк')} с ошибками:\n${errors}\n`
    //                 }
    //                 if (res.data.resultCount) {
    //                     string += `${plural(res.data.resultCount, '%d кошелёк будет создан', '%d кошелька будут созданы', '%d кошельков будут созданы')} на общую сумму: ${res.data.resultSum}р\n\n`
    //                 }
    //                 return string + 'Загрузить?'
    //             }
    //
    //             if(window.confirm(message())) {
    //                 sr("WalletsController", "CreateWalletsList", {csv: e.target.result, contractId: this.state.contractId}).then((res2) => {
    //                     this.props.deliveryCycleInfoMessage('file_uploaded', `Кошельков: ${res2.data.resultCount}`);
    //                     document.getElementById("control").value = "";
    //                 });
    //             } else {
    //                 document.getElementById("control").value = "";
    //             }
    //
    //         });
    //     }
    // }

    render() {
        return (
            <Fragment>
                {this.props.showOrders ? (
                    <Wrapper className="files_wrapper" style={{right: !this.props.isShow ? -649 : 0, zIndex: 10, overflowY: 'scroll'}}>
                        <div className="flex_row_start_center">
                            <CloseButton onClick={this.props.close}>Закрыть</CloseButton>
                        </div>
                    <div>
                            <AddTransactionTitle>Статистика промокода {this.state.promocode.promocode}</AddTransactionTitle>
                            <TransactionsColumns className="flex_row_sb">
                                <p style={{width: '12%'}}>Дата и время</p>
                                <p style={{width: '13%'}}>Телефон</p>
                                <p style={{width: '16%'}}>Имя</p>
                                <p style={{width: '35%'}}>Адрес доставки</p>
                                <p style={{width: '12%'}}>Сумма заказа</p>
                                <p style={{width: '12%'}}>Сумма скидки</p>
                            </TransactionsColumns>
                            <Transactions>
                                {(this.state.promocode.orders && this.state.promocode.orders.length > 0) && this.state.promocode.orders.map((item, index) => {
                                    return (
                                        <AddTransactionsItem className="flex_row_start_center" key={index} style={{alignItems: 'flex-start'}}>
                                            <p style={{width: '12%', paddingRight: 8}}>
                                                {moment(item.updated).format('DD.MM.YYYY HH:mm')}
                                            </p>
                                            <p style={{
                                                width: '13%',
                                                paddingRight: 8,
                                                overflow: 'hidden',
                                                whiteSpace: 'nowrap',
                                                textOverflow: 'ellipsis',
                                            }}>
                                                {item.phone}
                                            </p>
                                            <p style={{
                                                width: '16%',
                                                paddingRight: 8,
                                                overflow: 'hidden',
                                                whiteSpace: 'nowrap',
                                                textOverflow: 'ellipsis',
                                            }}>
                                                {item.name}
                                            </p>
                                            <p style={{
                                                width: '35%',
                                                paddingRight: 8,
                                                overflow: 'hidden',
                                                whiteSpace: 'nowrap',
                                                textOverflow: 'ellipsis',
                                            }}>
                                                {item.street + ' ' + item.house + (item.flat ? ', оф./кв. ' + item.flat : '')}
                                            </p>
                                            <p style={{width: '12%', paddingRight: 8}}>{+item.priceResult}</p>
                                            <p style={{width: '12%', paddingRight: 8}}>{+item.discount}</p>
                                        </AddTransactionsItem>
                                    )
                                })}
                            </Transactions>
                        </div>
                    </Wrapper>
                ) : (
                    <Wrapper className="wallets_wrapper" style={{right: !this.props.isShow ? -649 : 0, zIndex: 10, overflowY: 'scroll'}}>
                        <div className="flex_row_start_center">
                            <SaveButton onClick={this.props.data ? this.saveEdited : this.createWallet}>
                                Сохранить
                            </SaveButton>
                            <CloseButton onClick={this.props.close}>Закрыть</CloseButton>
                        </div>
                        <AddName className="flex_row_start_center">
                            <p style={{marginRight: 201}}>Название</p>
                            <AddNameInput value={this.state.promocode_name} type="text" placeholder="Текст промокода" onChange={this.handleChange3}/>
                        </AddName>

                        {this.props.data && (
                            <>
                                <AddName className="flex_row_start_center">
                                    <p style={{marginRight: 158}}>Процент скидки</p>
                                    <AddNameInput value={this.state.discountPerc === null ? '' : this.state.discountPerc} type="number" placeholder="Введите целое число" onChange={this.handleChange5}/>
                                </AddName>
                                <AddName className="flex_row_start_center">
                                    <p style={{marginRight: 115}}>Максимальная скидка</p>
                                    <AddNameInput value={this.state.discountMax === null ? '' : this.state.discountMax} type="number" placeholder="Введите целое число" onChange={this.handleChange6}/>
                                </AddName>
                                <AddName className="flex_row_start_center">
                                    <p style={{marginRight: 75}}>Макс. кол-во использований</p>
                                    <AddNameInput value={this.state.maxCount === null ? '' : this.state.maxCount} type="number" placeholder="Введите целое число" onChange={this.handleChange71}/>
                                </AddName>
                                <AddName className="flex_row_start_center">
                                    <p style={{marginRight: 54}}>Макс. использований клиентом</p>
                                    <AddNameInput value={this.state.maxUsing === null ? '' : this.state.maxUsing} type="number" placeholder="Введите целое число" onChange={this.handleChange7}/>
                                </AddName>
                                <AddName className="flex_row_start_center">
                                    <p style={{marginRight: 19}}>Макс. кол-во уже сделанных заказов</p>
                                    <AddNameInput value={this.state.onlyForFirstOrder === null ? '' : this.state.onlyForFirstOrder} type="number" placeholder="Введите целое число" onChange={this.handleChange8}/>
                                </AddName>
                                <AddName className="flex_row_start_center">
                                    <p style={{marginRight: 78}}>Минимальная сумма заказа</p>
                                    <AddNameInput
                                        value={this.state.orderMin === null ? '' : this.state.orderMin}
                                        type="number"
                                        placeholder="Введите сумму"
                                        onChange={e => this.setState({orderMin: e.target.value})}
                                    />
                                </AddName>
                                <AddName className="flex_row_start_center">
                                    <p style={{marginRight: 71}}>Максимальная сумма заказа</p>
                                    <AddNameInput
                                        value={this.state.orderMax === null ? '' : this.state.orderMax}
                                        type="number"
                                        placeholder="Введите сумму"
                                        onChange={e => this.setState({orderMax: e.target.value})}
                                    />
                                </AddName>
                                <AddName className="flex_row_start_center">
                                    <p style={{marginRight: 124}}>Сумма расходования</p>
                                    <AddNameInput value={this.state.walletAmount === null ? '' : this.state.walletAmount} type="number" placeholder="Введите целое число" onChange={this.handleChange9}/>
                                </AddName>
                                <AddName className="flex_row_start_center">
                                    <p style={{marginRight: 124}}>Описание промокода</p>
                                    <AddNameInput
                                        value={this.state.message === null ? '' : this.state.message}
                                        type="text"
                                        onChange={e => this.setState({message: e.target.value})}
                                    />
                                </AddName>
                                <AddName className="flex_row_start_center">
                                    <p style={{marginRight: 168}}>Имя кошелька</p>
                                    <AddNameInput
                                        value={this.state.walletName === null ? '' : this.state.walletName}
                                        type="text"
                                        onChange={e => this.setState({walletName: e.target.value})}
                                    />
                                </AddName>
                                <AddName style={{height: 36}} className="flex_row_start_center">
                                    <p style={{marginRight: 140}}>Промокод активен</p>
                                    <Switch checked={this.state.status} onChange={() => this.setState({status: !this.state.status})} />
                                </AddName>
                                <Tabs className="flex_row_start_center">
                                    <TabsButton onClick={this.onSettings} style={
                                        this.state.activeTab === 'settings' ?
                                            {backgroundColor: '#3498db', color: '#fff', fontWeight: 700, marginRight: 16} :
                                            {backgroundColor: '#fff', color: '#3498db', marginRight: 16}
                                    }>Настройки</TabsButton>
                                    <TabsButton onClick={this.onTransactions} style={
                                        this.state.activeTab === 'transactions' ?
                                            {backgroundColor: '#3498db', color: '#fff', fontWeight: 700} :
                                            {backgroundColor: '#fff', color: '#3498db'}
                                    }>Заказы</TabsButton>
                                </Tabs>

                                {this.state.activeTab === 'settings' ? (
                                    <div>
                                        <AddTime className="flex_row_sb_center add_time">
                                            <p>Время действия промокода</p>
                                            <div className="flex_row_start_center">
                                                <p style={{marginLeft: 8, marginRight: 8}}>с</p>
                                                <DatePicker
                                                    selected={this.state.validFrom}
                                                    startDate={this.state.validFrom}
                                                    endDate={this.state.validTo}
                                                    selectsStart
                                                    onChange={date => this.setState({validFrom: date})}
                                                    dateFormat="d MMMM yyyy"
                                                    locale={ru}
                                                    style={{textAlign: 'center'}}
                                                />
                                                <p style={{marginLeft: 8, marginRight: 8}}>по</p>
                                                <DatePicker
                                                    selected={this.state.validTo}
                                                    startDate={this.state.validFrom}
                                                    endDate={this.state.validTo}
                                                    minDate={this.state.validFrom}
                                                    selectsEnd
                                                    onChange={date => this.setState({validTo: date})}
                                                    dateFormat="d MMMM yyyy"
                                                    popperModifiers={{
                                                        preventOverflow: {
                                                            enabled: true,
                                                            escapeWithReference: false,
                                                            boundariesElement: "viewport"
                                                        },
                                                        offset: {
                                                            enabled: true,
                                                            offset: '-28px, 0px'
                                                        },
                                                    }}
                                                    locale={ru}
                                                    style={{textAlign: 'center'}}
                                                />
                                            </div>
                                        </AddTime>
                                        <AddTime className="flex_row_sb_center add_time">
                                            <p>Действует на дни</p>
                                            <div className="flex_row_start_center">
                                                <p style={{marginLeft: 8, marginRight: 8}}>с</p>
                                                <DatePicker
                                                    selected={this.state.walletValidFrom}
                                                    startDate={this.state.walletValidFrom}
                                                    endDate={this.state.walletValidTo}
                                                    selectsStart
                                                    onChange={date => this.setState({walletValidFrom: date})}
                                                    dateFormat="d MMMM yyyy"
                                                    locale={ru}
                                                    style={{textAlign: 'center'}}
                                                />
                                                <p style={{marginLeft: 8, marginRight: 8}}>по</p>
                                                <DatePicker
                                                    selected={this.state.walletValidTo}
                                                    startDate={this.state.walletValidFrom}
                                                    endDate={this.state.walletValidTo}
                                                    minDate={this.state.walletValidFrom}
                                                    selectsEnd
                                                    onChange={date => this.setState({walletValidTo: date})}
                                                    dateFormat="d MMMM yyyy"
                                                    popperModifiers={{
                                                        preventOverflow: {
                                                            enabled: true,
                                                            escapeWithReference: false,
                                                            boundariesElement: "viewport"
                                                        },
                                                        offset: {
                                                            enabled: true,
                                                            offset: '-28px, 0px'
                                                        },
                                                    }}
                                                    locale={ru}
                                                    style={{textAlign: 'center'}}
                                                />
                                            </div>
                                        </AddTime>
                                        <AddTime className="flex_row_sb_center add_time">
                                            <p>Действует на периоды</p>
                                            <div className="flex_row_start_center">
                                                <Select
                                                    mode="multiple"
                                                    value={this.state.selectedPeriods}
                                                    onChange={(value) => {
                                                        // console.log(value)
                                                        this.setState({selectedPeriods: value})
                                                    }}
                                                    style={{width: 332}}
                                                >
                                                    {this.props.periods.map((v, id) => {
                                                        return (
                                                            <Option key={id + "wsfwadf"} value={v.id}>
                                                                {v.timeFrom.slice(0, -3) + ' - ' + v.timeTo.slice(0, -3)}
                                                            </Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        </AddTime>
                                       {/******************Category******************/}
                                       <AddTime className="flex_row_sb_center add_time">
                                            <p>Выбор категории</p>
                                            <div className="flex_row_start_center"></div>
                                            <Select
                                            value={this.state.selectedCategory}
                                            onChange={(value)=>{
                                                // console.log(value,'change`s categories')
                                                this.setState({selectedCategory: value})
                                            }}
                                            style={{width:200}}
                                            >
                                             {this.props.category.map((v, id) => {
                                                 return (
                                                    <Option   key={id + "wsfwadf"} value={v.id} >
                                                                 {v.name} 
                                                    </Option>
                                                 )
                                                 })}
                                            </Select>
                                       </AddTime>
                                       {/******************EndCategory******************/}
                                        <AddAnyBox>
                                            {/*<AddAny title="Пользователи" add={this.add} data={this.state.users} del={this.del}/>*/}
                                            {/*<AddAny title="Компании" add={this.add} data={this.state.companies} del={this.del}/>*/}
                                            <AddAny title="Столовые" add={this.add} data={this.state.eateries} del={this.del}/>
                                        </AddAnyBox>
                                    </div>
                                ) : (
                                    <div>
                                        {/*<AddTransactionTitle>Добавить транзакцию</AddTransactionTitle>*/}
                                        {/*<AddTransactionsInputs className="flex_row_sb_center">*/}
                                        {/*    <AddTransactionsInput type="number" placeholder="Сумма" value={this.state.sumValue} onChange={this.handleChange}/>*/}
                                        {/*    <AddTransactionsInput type="text" placeholder="Комментарий" value={this.state.commentValue} onChange={this.handleChange2}/>*/}
                                        {/*    <AddTransactionsButton onClick={this.addTransaction}>Добавить</AddTransactionsButton>*/}
                                        {/*</AddTransactionsInputs>*/}
                                        <TransactionsColumns className="flex_row_sb">
                                            <p style={{width: '12%'}}>Дата и время</p>
                                            <p style={{width: '13%'}}>Телефон</p>
                                            <p style={{width: '16%'}}>Имя</p>
                                            <p style={{width: '35%'}}>Адрес доставки</p>
                                            <p style={{width: '12%'}}>Сумма заказа</p>
                                            <p style={{width: '12%'}}>Сумма скидки</p>
                                        </TransactionsColumns>
                                        <Transactions style={{height: 'calc(100vh - 610px)'}}>
                                            {(this.state.promocode.orders && this.state.promocode.orders.length > 0) && this.state.promocode.orders.map((item, index) => {
                                                return (
                                                    <AddTransactionsItem className="flex_row_start_center" key={index} style={{alignItems: 'flex-start'}}>
                                                        <p style={{width: '12%', paddingRight: 8}}>
                                                            {moment(item.updated).format('DD.MM.YYYY HH:mm')}
                                                        </p>
                                                        <p style={{
                                                            width: '13%',
                                                            paddingRight: 8,
                                                            overflow: 'hidden',
                                                            whiteSpace: 'nowrap',
                                                            textOverflow: 'ellipsis',
                                                        }}>
                                                            {item.phone}
                                                        </p>
                                                        <p style={{
                                                            width: '16%',
                                                            paddingRight: 8,
                                                            overflow: 'hidden',
                                                            whiteSpace: 'nowrap',
                                                            textOverflow: 'ellipsis',
                                                        }}>
                                                            {item.name}
                                                        </p>
                                                        <p style={{
                                                            width: '35%',
                                                            paddingRight: 8,
                                                            overflow: 'hidden',
                                                            whiteSpace: 'nowrap',
                                                            textOverflow: 'ellipsis',
                                                        }}>
                                                            {item.street + ' ' + item.house + (item.flat ? ', оф./кв. ' + item.flat : '')}
                                                        </p>
                                                        <p style={{width: '12%', paddingRight: 8}}>{+item.priceResult}</p>
                                                        <p style={{width: '12%', paddingRight: 8}}>{+item.discount}</p>
                                                    </AddTransactionsItem>
                                                )
                                            })}
                                        </Transactions>
                                    </div>
                                )}
                            </>
                        )}
                    </Wrapper>
                )}
            </Fragment>
        );
    }
}

const mapStateToProps = store => ({

});

const mapDispatchToProps = dispatch => ({
    deliveryCycleInfoMessage: (actionUser, product, timeOut) => dispatch(deliveryCycleInfoMessage(actionUser, product, timeOut)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddPromocodeModal);

const Transactions = styled.div`
    height: calc(100vh - 258px);
    overflow-y: scroll;
`;

const Wrapper = styled.div`
    position: fixed;
    top: 60px;
    width: 649px;
    height: calc(100vh - 60px);
    padding: 30px 35px 30px 26px;
    background-color: #fff;
    box-shadow: 0 0 62px rgba(0, 0, 0, 0.1);
    transition: .5s cubic-bezier(0.645, 0.045, 0.355, 1);
`;

const SaveButton = styled.button`
    width: 200px;
    height: 42px;
    margin-right: 55px;
    outline: 0;
    border: 0;
    border-radius: 66px;
    background: #1720D8;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    line-height: 17px;
    color: #fff;
    cursor: pointer;
`;

const CloseButton = styled.button`
    width: 140px;
    height: 42px;
    margin-right: 96px;
    outline: 0;
    border: 1px solid #999;
    border-radius: 66px;
    background: #fff;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    line-height: 17px;
    color: #999;
    cursor: pointer;
`;

// const DelButton = styled.button`
//     width: 55px;
//     height: 16px;
//     padding: 0;
//     outline: 0;
//     border: 0;
//     background: #fff;
//     font-family: 'Roboto', sans-serif;
//     font-size: 14px;
//     line-height: 17px;
//     color: #999;
//     cursor: pointer;
// `;

// const AddWalletTitle = styled.div`
//     margin-top: 30px;
//     font-weight: 700;
//     font-size: 16px;
//     line-height: 19px;
// `;

const AddName = styled.div`
    margin-top: 10px;
`;

const AddNameInput = styled.input`
    width: 218px;
    
    &:focus {
        background: #fff;
    }
`;

const Tabs = styled.div`
    margin-top: 30px;
`;

const TabsButton = styled.button`
    width: 118px;
    height: 36px;
    outline: 0;
    border: 1px solid #3498db;
    border-radius: 4px;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    line-height: 17px;
    cursor: pointer;
`;

const AddTime = styled.div`
    margin-top: 10px;
`;

// const AddLimit = styled.div`
//     margin-top: 10px;
// `;
//
// const AddType = styled.div`
//     height: 36px;
//     margin-top: 10px;
// `;
//
// const AddLimitInput = styled.input`
//     width: 150px;
//
//     &:focus {
//         background: #fff;
//     }
// `;

const AddAnyBox = styled.div`
    margin-top: 36px;
`;

const AddTransactionTitle = styled.p`
    margin-top: 20px !important;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
`;

// const AddTransactionsInputs = styled.div`
//     margin-top: 10px;
// `;
//
// const AddTransactionsInput = styled.input`
//     &:first-child {
//         width: 152px;
//     }
//
//     &:nth-child(2) {
//         width: 274px;
//     }
//
//     &:focus {
//         background: #fff;
//     }
// `;
//
// const AddTransactionsButton = styled.button`
//     width: 130px;
//     height: 36px;
//     outline: 0;
//     border: 0;
//     border-radius: 66px;
//     background: #ff1744;
//     font-family: 'Roboto', sans-serif;
//     font-size: 14px;
//     line-height: 17px;
//     color: #fff;
//     cursor: pointer;
// `;

const TransactionsColumns = styled.div`
    margin-top: 20px;
    font-weight: 700;
    font-size: 9px;
    line-height: 11px;
    height: 36px;
    padding-right: 10px;
`;

const AddTransactionsItem = styled.div`
    margin-bottom: 14px;
    font-size: 9px;
    line-height: 11px;
    
    &:last-child {
        margin-bottom: 0;
    }
`;
