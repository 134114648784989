import React from 'react';
import styled from "styled-components";
import PermissionsImg from '../images/permissions-error.png';

const NoPermissions = (props) => {
    return (
        <Container isMap={props.isMap}>
            <PermissionsImage src={PermissionsImg}/>
            <PermissionsText>
                У Вас недостаточно прав для работы с данным разделом<br/>
                Пожалуйста, обратитесь к администратору
            </PermissionsText>
        </Container>
    )
}

export default NoPermissions;

const Container = styled.div`
    width: 1400px;
    margin: 0 auto;
    padding: ${({isMap}) => isMap ? '110px 0' : '50px 0'};
`;

const PermissionsImage = styled.img`
    display: block;
    width: 200px;
    height: 200px;
    margin: 100px auto 0;
`;

const PermissionsText = styled.p`
    font-family: 'Roboto', sans-serif;
    font-size: 24px;
    line-height: 40px;
    text-align: center;
    margin-top: 50px !important;
`;
