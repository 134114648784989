import React,{useState,useEffect} from 'react'
import { Chart as ChartJS, registerables } from 'chart.js';
import { Chart,Line} from 'react-chartjs-2'
import moment from 'moment';
ChartJS.register(...registerables);

const GraphsRaiting = ({data,fives}) => {
const [fiveTeen,setFiveTeen] = useState([])
const datas = fives?.map(el=>el.rating)
const max = fives?.find(el=>Math.max(el?.rating))?.rating 
const diffs = 5 - +max
const min = fives?.find(el=>Math.min(el?.rating))?.rating - diffs


// console.log(max,min,'PPPP')
const oneMoreHelper = () =>{
  const nowDay = +moment().format('DD')
  const dayInM = moment().daysInMonth() + 1
  const forNewNowDay = moment().subtract(15,'day').format('YYYY.MM.DD')
    const sss = +moment(forNewNowDay).format('DD')
  let bb = []
      // for(let a = sss ;a !== nowDay ;a++){
      //    bb.push(String(a))
      // }
  return bb
  }
  var ctx = document.getElementById("temp-chart")?.getContext("2d");
  // console.log(ctx,'CTX')
  var gradient = ctx?.createLinearGradient(0, 0, 0, 100);
  useEffect(() => {
    if(gradient){
    gradient.addColorStop(1, '#79CAD6 ');   
    gradient.addColorStop(1, '#5596E1');
    gradient.addColorStop(1, '#488DDF');
    gradient.addColorStop(0, '#9C3BE8 ');
    }
  }, [datas])

  function getRange(startDate, endDate, type) {
    let fromDate = moment(startDate)
    let toDate = moment(endDate)
    let diff = toDate.diff(fromDate, type)
    // console.log(diff,'DIFF')
    let range = []
    for (let i = 0; i < diff; i++) {
      range.push(moment(startDate).add(i, type))
    }
    return range
  }
 
  const jj = getRange(moment().subtract(14,'day').format('YYYY-MM-DD'),moment().add(1,'day').format('YYYY-MM-DD'),'days')
  const diff = jj.map(el=>{
    return moment(el).format('DD-MM')
  })
    console.log(diff,"DIFF")
  // console.log(diff,'DDDD');


    const lineChartData = {
        labels:diff,
        datasets: [
          {
            data: datas,
            // label: true,
            borderColor:gradient,
            fill: false,
            lineTension:0.3,
          },
        ]
      };
    
      return (
        <Line
        id='temp-chart'
          // type="line"
          width={120}
          //120
          height={55}
          options={{
            responsive: true,
            plugins: {
              legend: {
                display:false,
              },
            },
            elements:{
              point:{
                backgroundColor:'#F4F5F9',
                pointRadius: 3.5,
                pointHoverRadius: 3.5
              }
            },
            scales:{
              yAxis:{
                // min: min ,
                // max: 5.0 ,
                suggestedMin:min,
                suggestedMax:5.0,
                // ticks:{
                //   callback:(value)=>{
                //      return value + '₽'
                //   }
                //   // stepSize:0.2
                // },
                // autoSkip:true
              },
              xAxis:{
               
                // ticks:{
                // maxRotation: 45,
                // minRotation: 45
                // }
                
                // position: 'right'
              }
            }
            // title: {
            //   display: true,
            //   text: "COVID-19 Cases of Last 6 Months",
            //   fontSize: 20
            // },
          }}
          data={lineChartData}
        />
      )
}

export default GraphsRaiting