import React, {useEffect, useState} from "react";
import styled from "styled-components";
import AddWaybillModal from "./WaybillModal/AddWaybillModal";
import {useDispatch, useSelector} from "react-redux";
import {
    getWaybill,
    resetCreatedWaybill, resetDeleteFlag,
    resetSelectedWaybill,
    resetWaybillData, selectDateRange
} from "../../../actions/WarehouseActions";
import WaybillTable from "./WaybillTable";
import {deliveryCycleInfoMessage} from "../../../actions/ModalActions"

function requestBuilder(page, limit, selectedSender, selectedRecipient, dateRange) {
    if( dateRange?.from && dateRange?.to ) {
        return {
            page: page,
            limit: limit,
            senderId: selectedSender && selectedSender.id || "",
            recipientId: selectedSender && selectedRecipient.id || "",
            dateFrom: dateRange.from,
            dateTo: dateRange.to,
        }
    } else {
        return {
            page: page,
            limit: limit,
            senderId: selectedSender && selectedSender.id || "",
            recipientId: selectedSender && selectedRecipient.id || "",
        }
    }
}

// aligner for table
const grid = {
    display: 'grid',
    gridTemplateColumns: ".5fr 1fr 3fr 1fr .3fr 1fr",
}

const Waybills = () => {

    const dispatch = useDispatch()

    const selectedSender = useSelector(store => store.warehouse.senderState.selectedSender)
    const selectedRecipient = useSelector(store => store.warehouse.recipientState.selectedRecipient)
    const dateRange = useSelector(store => store.warehouse.dateRange)

    const getWaybillReady = useSelector(store => store.warehouse.getWaybillReady)
    const waybillsDataCount = useSelector(store => store.warehouse.waybillsDataCount)
    const waybillsData = useSelector(store => store.warehouse.waybillsData)
    const createdWaybill = useSelector(store => store.warehouse.createdWaybill)

    const deleteWaybillStart = useSelector(store => store.warehouse.deleteWaybillStart)
    const deleteWaybillSuccess = useSelector(store => store.warehouse.deleteWaybillSuccess)
    const deleteWaybillErrorReason = useSelector(store => store.warehouse.deleteWaybillErrorReason)
    const deleteWaybillTarget = useSelector(store => store.warehouse.deleteWaybillTarget)

    const updateWaybillTarget = useSelector(store => store.warehouse.modalControl.updateWaybillTarget)
    const updateWaybillPrev = useSelector(store => store.warehouse.modalControl.updateWaybillPrev)
    const updateWaybillSuccess = useSelector(store => store.warehouse.modalControl.updateWaybillSuccess)
    const updateWaybillError = useSelector(store => store.warehouse.modalControl.updateWaybillError)
    // this state is replies for passing new price in local waybill instance after updating
    const newWaybillPriceOnUpdate = useSelector(store => store.warehouse.modalControl.newWaybillPrice)

    //      *** support state ***
    const [limit] = useState( 20 ) //data limit per query
    const [page, setPage] = useState( 1 ) //pagination
    const [intersect, setIntersect] = useState( false ) //intersection observer

    //      *** main state ***
    const [waybills, setWaybills] = useState([])

    //support functions
    const dateRangeIsSet = () => {
        return !!(dateRange?.from && dateRange?.to)
    }

    // reset waybills in redux
    useEffect(() => {
        return () => {
            dispatch(resetWaybillData())
            dispatch(resetSelectedWaybill())
            dispatch(selectDateRange({from: null, to: null}))
        }
    }, [])

    //delete waybill handler
    useEffect(() => {
        if( deleteWaybillSuccess ) {
            //if all ok, delete waybill from local state
            setWaybills(prev => {
                const tempArr = [...prev]
                tempArr.splice(prev.indexOf(deleteWaybillTarget), 1)
                return [...tempArr]
            })
            dispatch(deliveryCycleInfoMessage('waybill_deleted', '', 4000))
            dispatch(resetDeleteFlag())
        } else {
            //error handler
            if (deleteWaybillErrorReason) {
                // if reason is set, show error modal
                dispatch(deliveryCycleInfoMessage('cant_delete_waybill', deleteWaybillErrorReason, 4000))
                dispatch(resetDeleteFlag())
            }
        }
    }, [deleteWaybillStart])

    // try get waybills / reset waybills
    useEffect(() => {
        setWaybills([]) // if selectors change, reset local waybills state
        setPage(1) // if selectors change, reset page state
        const r = {
            page,
            limit,
        }
        if(selectedSender) {
            r.senderId = selectedSender.id
        }
        if(selectedRecipient) {
            r.recipientId = selectedRecipient.id
        }
        console.log(dateRange)
        if(dateRange) {
            r.dateFrom = dateRange.from
            r.dateTo = dateRange.to
        }
        console.log(r)
        dispatch( getWaybill(r) )
    }, [selectedSender, selectedRecipient, dateRange])

    // request if intersect
    useEffect(() => {
        // request, if both selectors is set
        if (selectedSender && selectedRecipient) {
            const r = requestBuilder( page, limit, selectedSender, selectedRecipient, dateRange )
            dispatch( getWaybill(r) )
        }
    }, [page])

    // change page, if intersect observer true
    useEffect(() => {
        if (
            selectedSender &&
            selectedRecipient &&
            getWaybillReady &&
            intersect &&
            (waybills.length < waybillsDataCount)
        ){
            setPage(prev => prev + 1)
        }
    }, [intersect])

    // build local waybills state
    useEffect(() => {
        if (getWaybillReady) {
            setWaybills(prev => [...prev, ...waybillsData])
        }
    }, [getWaybillReady])

    //push created waybill in local state
    useEffect(() => {
        if (createdWaybill) {
            const waybillTime = new Date(createdWaybill.waybillDate).getTime()
            if (dateRangeIsSet()) {
                if( waybillTime >= new Date(dateRange.from).getTime() &&
                    waybillTime <= new Date(dateRange.to).getTime()
                ) {
                    setWaybills(prev => [createdWaybill, ...prev])
                }
            } else {
                setWaybills(prev => [createdWaybill, ...prev])
            }
            dispatch(resetCreatedWaybill())
        }
    }, [createdWaybill])

    //update waybills in local state in update request in done
    useEffect(() => {
        if (updateWaybillSuccess&& !updateWaybillError && updateWaybillTarget) {
            const target = waybills.reduce((acc, waybill) => {
                if(waybill.id === updateWaybillPrev.id){
                    acc = waybill
                }
                return acc
            }, 0)
            const newUpdatedWaybills = [...waybills]
            newUpdatedWaybills[waybills.indexOf(target)] = {
                id: updateWaybillTarget.id,
                waybill: updateWaybillTarget.waybill,
                productPriceSum: newWaybillPriceOnUpdate,
                counterpartieId: updateWaybillTarget.counterpartieId,
                recipientId: updateWaybillTarget.recipientId,
                waybillDate: updateWaybillTarget.date
            }
            setWaybills([...newUpdatedWaybills])
        }
    }, [updateWaybillSuccess, updateWaybillTarget])

    return (
        <WaybillsWrapper>
            <Header style={grid}>
                <p>№</p>
                <p>Дата</p>
                <p>Поставщик</p>
                <p>Получатель</p>
                <p>Сумма</p>
                <p />
            </Header>
            {
                waybills.length
                    ?<WaybillTable {...{
                        grid,
                        senderName: selectedSender?.name,
                        recipientName: selectedRecipient?.name,
                        waybills,
                        setIntersect,

                    }} />
                    : null
            }

        </WaybillsWrapper>
    )
}

export default Waybills;

const WaybillsWrapper = styled.div`
  height: 85%;
`
const Header = styled.div`
  text-align: left;
  top: 0;
  left: 0;
  width: 100%;
  background: #14142a;
  color: white;
  font-weight: 600;
  padding: 0 20px;
  border-radius: 6px 6px 0 0;
  p {
    width: 100%;
    margin: 0;
    padding: 15px 0;
  }
`
