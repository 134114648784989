import React, { memo,useEffect,useState } from "react";
import styled from "styled-components";
import {checkRole} from "../App";
import {Link, useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import nav from "../path";
import LinksUI from "./components/LinksUI";
import Arrow from '../images/header/rightArrow.svg'
import { useCallback } from "react";

const HeaderNav = ({path,setPath,testPath}) => {
    const location = useLocation()
    const isAuth = useSelector(store => store.profile.isAuth)
    const permissions = useSelector(store => store.appMain.permissions)
    const getPermissionsSuccess = useSelector(store => store.appMain.getPermissionsSuccess)
    const URLS = location.pathname.slice(0,-1)

    const HelperUrls = testPath.find(el=>el.path.includes(location.pathname))

    const memoURLS = useCallback(()=>HelperUrls,[path,location])
    const forQueryParam = () =>{
       const symb = testPath.find(el=>el.path.includes(URLS))
        return symb
    }
    const Helper = () =>{
      return nav.map((navs)=>{
          if(checkRole(isAuth,permissions,navs.screen)){
              if(path){
                  if(path.path === navs.paths){
                   return <LinksUI key={navs.id} {...{navs,testPath}}/>
                  }
              }if(forQueryParam()){
                  if(forQueryParam()?.name === navs.paths){
                    return <LinksUI key={navs.id} {...{navs,testPath}}/>
                  }
              }else{
                    if(HelperUrls?.name === navs.paths){
                      return <LinksUI key={navs.id} {...{navs,testPath}}/>
                    }
              }
          }
      })
    }

    console.log(path?.text,'forQueryParam')
    return (

        <HeaderNavWrapper  >
            {
                getPermissionsSuccess && (
                    <>
                    {window.location.pathname !== '/' && path &&
                    <HelpHeadSide>{ path ?  path?.text : forQueryParam()?.nameText || HelperUrls()?.nameText }<img src={Arrow}/></HelpHeadSide>  
                    }
                    {
                      // nav.map(navs=>{
                      //     if(checkRole(isAuth,permissions,navs.screen )){
                      //       return (path ?  (path.path === navs.paths) : (forQueryParam().name  === navs.paths) ? <LinksUI key={navs.id} {...{navs,testPath}}/> : 'qwdqqqdqdqdqd')
                      //     }
                      // })
                      window.location.pathname !== '/' && Helper()
                    }
                    </>
                )
            }
        </HeaderNavWrapper>
    )
}

export default memo(HeaderNav);

const HeaderNavWrapper = styled.nav`
  height: 100%;
  display: flex;
  align-items: center;
  font-family: 'Roboto';
  font-style: normal;

  > a {
  margin-right: 19px;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  letter-spacing: -0.442px;
  color: #2C2E32;
  padding: 5px 7px;
    :hover{
      font-weight: 400;
      font-size: 16px;
      color: #FFFFFF;
      background: #7882A7;
      border-radius: 8px;
    }
  }
`
const HelpHeadSide = styled.div`
display:flex;
font-weight: 500;
font-size: 20px;
line-height: 130%;
letter-spacing: -0.442px;
color: #2C2E32;  
`