import React from "react";
import styled from "styled-components";
import {useDispatch} from "react-redux";
import {
    toggleActiveCategory,
} from "../../actions/MenuByDayActions";
//image
import FoodListItem from "./FoodListItem";

const FoodList = ({foodListState, editMode}) => {
    const dispatch = useDispatch();
    console.log(foodListState,'foodListState')
    const setActiveCategoryHandle = (idx) => {
        dispatch(toggleActiveCategory({idx}))
    }; 

    return (
        <FoodListWrapper>
            <Container>
                <FoodListInnerWrapper>
                    {
                        foodListState.map((el, idx) => {
                            return (
                                <ListBody editMode={editMode} isActive={el.isActive} key={idx + "keyakdsfjapsodifhpasidufhapsidlufb3i94hf83rf8h9wh384f"}>
                                    {
                                        editMode
                                            ?
                                            <header onClick={() => setActiveCategoryHandle(idx)}>
                                                <h3>{el.header}</h3>
                                                <p>{el.activeCount ? el.activeCount : null}</p>
                                            </header>
                                            :
                                            <header>
                                                <h3>{el.header}</h3>
                                                <p>{el.activeCount ? el.activeCount : null}</p>
                                            </header>
                                    }
                                    <ul>
                                        {
                                            el.elements
                                                .sort((a, b) => {
                                                    if(a.name > b.name) {
                                                        return 1;
                                                    }
                                                    return -1;
                                                })
                                                .map((el, id) => <FoodListItem key={id + "_key_asdfasdfwe32"} listId={idx} dishId={id} {...el} editMode={editMode}/>)
                                        }
                                    </ul>
                                </ListBody>
                            )
                        })
                    }
                    {/*{*/}
                    {/*    x ?*/}
                    {/*        <Plug periodIsSet/>*/}
                    {/*        : null*/}
                    {/*}*/}
                </FoodListInnerWrapper>

            </Container>
        </FoodListWrapper>
    )
}

const FoodListWrapper = styled.div`
  
`
const Container = styled.div`
  padding: 0 25px;
`
const FoodListInnerWrapper = styled.div`
  margin-top: 10px;
  columns: 4;
  column-gap: 10px
`;
const ListBody = styled.div`
  display: grid;
  grid-template-rows: auto;
  align-content: start; 
  grid-gap: 12px;
  /* break-inside: avoid-column; */
  padding: 0 10px 10px 30px;
  & header {
    text-decoration: underline;
    height: 80px;
    background: ${({isActive, editMode}) => !isActive || !editMode ? "#fff" : "#3D425E"};
    color: ${({isActive, editMode}) => !isActive || !editMode ? "#000" : "#fff"};
    border-radius: 6px;
    h3 {
      margin: 0;
      color: ${({isActive, editMode}) => !isActive || !editMode ? "#000" : "#fff"};
    }
    padding: 13px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  ul {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 12px;
    padding: 0;
  }
`

export default FoodList;
