import React from "react";
import styled from "styled-components";

const CheckBox = props => {
    const {isActive, onClick1, title} = props;

    return (
        <Container onClick={() => onClick1()}>
            <Box isActive={isActive} />
            {title ? <Title>{title}</Title> : null}
        </Container>
    )
};

const Container = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    font-family: "Roboto", sans-serif;
    text-align: left;
    color: #111111;
    
    &:not(:last-child) {
        margin-bottom: 16px;
    }
`;

const Box = styled.div`
    width: 16px;
    height: 16px;
    background-color: ${({isActive}) => isActive ? 'rgb(52, 152, 219)' : 'rgb(204, 209, 213)'};
    border-radius: 2px;
`;

const Title = styled.p`
    margin-left: 10px;
    font-size: 14px;
    line-height: 14px;
    color: #666666;
`;

export default CheckBox;
