import React from 'react';
import '../App.css';
import styled from "styled-components";

export default class AddAny extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            value: '',
        }
    }

    handleChange = (event) => {
        this.setState({value: event.target.value});
    }

    add = () => {
        let type = this.props.title === 'Пользователи' ? 'users' :
            this.props.title === 'Компании' ? 'companies' : 'eateries';

        this.state.value && this.props.add(type, this.state.value);

        this.setState({value: ''});
    }

    del = id => {
        let type = this.props.title === 'Пользователи' ? 'users' :
            this.props.title === 'Компании' ? 'companies' : 'eateries';

        this.props.del(type, id)
    }

    render() {
        ///console.log('value', this.props.data)

        return (
            <AddAnyBox>
                <AddAnyTitle className="flex_row_sb_center">
                    <p>{this.props.title}</p>
                    <div>
                        <AddAnyInput type="number"
                               placeholder={'Введите ID'}
                               value={this.state.value} onChange={this.handleChange}/>
                        <AddAnyButton onClick={this.add}>Добавить</AddAnyButton>
                    </div>
                </AddAnyTitle>
                {this.props.data.length > 0 && this.props.data.map((item, index) => {
                    return (
                        <AddAnyItem className="flex_row_start_center" key={index}>
                            <AddAnyItemString>{index + 1}.</AddAnyItemString>
                            <AddAnyItemString>ID {item.screenId}</AddAnyItemString>
                            <AddAnyItemString>{item.name}</AddAnyItemString>
                            <AddAnyItemButton onClick={() => this.del(item.id)}>удалить</AddAnyItemButton>
                        </AddAnyItem>
                    )
                })}
            </AddAnyBox>
        );
    }
}

const AddAnyBox = styled.div`
    margin-bottom: 20px;
`;

const AddAnyTitle = styled.div`
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
`;

const AddAnyInput = styled.input`
    width: 152px;
    margin-right: 51px;
    
    &:focus {
        background: #fff;
    }
`;

const AddAnyButton = styled.button`
    width: 130px;
    height: 36px;
    outline: 0;
    border: 0;
    border-radius: 66px;
    background: #ff1744;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    line-height: 17px;
    color: #fff;
    cursor: pointer;
`;

const AddAnyItem = styled.div`
    margin-top: 10px;
    
    &:nth-child(2) {
        margin-top: 14px;
    }
`;

const AddAnyItemString = styled.p`
    &:first-child {
        margin-right: 5px;
        color: #999;
    }
    
    &:nth-child(2) {
        margin-right: 5px;
        padding-top: 1px;
        border-bottom: 1px dashed #2196f3;
        color: #2196f3;
        white-space: nowrap;
    }
    
    &:nth-child(3) {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
`;

const AddAnyItemButton = styled.button`
    width: 53px;
    height: 19px;
    margin: 0 214px 0 auto;
    padding: 0;
    outline: 0;
    border: 0;
    border-bottom: 1px dashed #999;
    background: #fff;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    line-height: 17px;
    color: #999;
    cursor: pointer;
`;
