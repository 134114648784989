import React from "react";
import styled from "styled-components";

const TextInput = props => {
    const {value, onChange1, title, placeholder, width} = props;

    return (
        <Container>
            {title ? <Title>{title}</Title> : null}
            <Input
                placeholder={placeholder}
                width={width}
                value={value}
                onChange={e => onChange1(e.target.value)}
            />
        </Container>
    )
};

const Container = styled.div`
    width: 100%;
    font-family: "Roboto", sans-serif;
    text-align: left;
    color: #111111;
    
    &:not(:last-child) {
        margin-bottom: 24px;
    }
`;

const Title = styled.p`
    margin-bottom: 4px;
    color: #666666;
`;

const Input = styled.input`
    display: block;
    width: ${({width}) => width ? width + 'px' : '100%'};
    height: 36px;
    padding: 0 10px;
    border-radius: 2px;
    border: 1px solid #DADADA;
    outline: 0;
    border-top: 1px solid #c0c0c0;
    font-size: 14px;
    line-height: 18px;
    color: #111111;
`;

export default TextInput;
