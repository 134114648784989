import {
    GET_WAYBILL_ERROR,
    GET_WAYBILL_READY,

    ADD_CREATED_WAYBILL,
    APPLY_EDIT_WAYBILL,
    RESET_SELECT_WAYBILL,
    RESET_WAYBILL,
    GET_WAYBILL,
    GET_WAYBILL_DETAILS_READY,
    GET_WAYBILL_DETAILS,
    RESET_CREATED_WAYBILL,
    RESET_DELETE_FLAG,
    DELETE_WAYBILL_STATUS,
    DELETE_WAYBILL,
    UPDATE_WAYBILL,
    UPDATE_WAYBILL_STATUS,
    SELECT_DATE_RANGE,
    RESET_DATE_RANGE,
    GET_DOWNLOAD_LINK_SUCCESS,
    RESET_DOWNLOAD_LINK,
} from "../../actions/WarehouseActions";
import {_getRecipientReducer} from "./_getRecipientReducer";
import {getSenderReducer} from "./_getSendersReducer";
import {_debounceProductListReducer} from "./_debounceProductListReducer"

const initState = {
    getWaybillStart: false,
    getWaybillReady: false,
    getWaybillError: false,

    deleteWaybillStart: false,
    deleteWaybillSuccess: false,
    deleteWaybillError: '',
    deleteWaybillTarget: null,

    waybillsData: [],
    createdWaybill: null,
    waybillsDataCount: 0,
    waybillDetails: null,
    getWaybillDetailsReady: false,
    dateRange: {from: null, to: null},

    exelLink: '',
}

export function _warehouseCommonReducer(state = initState, action) {
    //support reducers
    state.recipientState = {..._getRecipientReducer(state.recipientState, action)};
    state.senderState = {...getSenderReducer(state.senderState, action)};
    state.debounceProductList = {..._debounceProductListReducer(state.debounceProductList, action)}

    switch (action.type) {
        case GET_WAYBILL:
            return {
                ...state,
                getWaybillStart: true,
                getWaybillReady: false,
                getWaybillError: false,
            }
        case GET_WAYBILL_READY:
            return {
                ...state,
                getWaybillStart: false,
                getWaybillReady: true,
                waybillsData: action.payload?.waybills || [],
                waybillsDataCount:action.payload?.waybillsCount || 0,
            }
        case GET_WAYBILL_ERROR:
            return {
                ...state,
                getWaybillStart: false,
                getWaybillReady: false,
                getWaybillError: true,
            }
        case RESET_WAYBILL:
            return {
                ...state,
                waybillsData: [],
            }
        case GET_WAYBILL_DETAILS:
            return {
                ...state,
                getWaybillDetailsReady: false,
            }
        case GET_WAYBILL_DETAILS_READY:
            return {
                ...state,
                waybillDetails: action.payload,
                getWaybillDetailsReady: true,
            }
        case RESET_SELECT_WAYBILL:
            return {
                ...state,
                waybillDetails: null
            }
        case ADD_CREATED_WAYBILL:
            const newWaybill = {
                id: action.payload.id,
                waybill: action.payload.waybill,
                productPriceSum: action.payload.productPriceSum,
                counterpartieId: action.payload.counterpartieId,
                recipientId: action.payload.recipientId,
                waybillDate: action.payload.waybillDate,
            }
            return {
                ...state,
                createdWaybill: newWaybill,
                waybillsDataCount: state.waybillsDataCount + 1,
            }
        case DELETE_WAYBILL:
            return {
                ...state,
                deleteWaybillTarget: action.payload,
                deleteWaybillStart: true,
                deleteWaybillSuccess: false,
                deleteWaybillErrorReason: '',
            }
        case DELETE_WAYBILL_STATUS:
            return {
                ...state,
                deleteWaybillStart: false,
                deleteWaybillSuccess: action.payload.status,
                deleteWaybillErrorReason: action.payload.error
            }
        case RESET_DELETE_FLAG: {
            return {
                ...state,
                deleteWaybillTarget: null,
                deleteWaybillSuccess: false,
                deleteWaybillErrorReason: ''
            }
        }
        case RESET_CREATED_WAYBILL:
            return {
                ...state,
                createdWaybill: null
            }
        case SELECT_DATE_RANGE:
            return {
                ...state,
                dateRange: {from: action.payload.from, to: action.payload.to}
            }
        case RESET_DATE_RANGE:
            return {
                ...state,
                dateRange: {from: null, to: null}
            }
        case GET_DOWNLOAD_LINK_SUCCESS:
            return {
                ...state,
                exelLink: action.payload?.link
            }
        case RESET_DOWNLOAD_LINK:
            return {
                ...state,
                exelLink: ''
            }
        default:
            return state
    }
}
