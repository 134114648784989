import {takeEvery} from "redux-saga/effects";
import {
    registerService,
} from "./registerServiceWorkers";
import {
    activateAccountAction,
    activatePasswordAction,
    recoveryPasswordAction,
    registrationByEmailAction
} from "../../reducers/toolkit/profileReducer"

function* authWatcher() {
    yield takeEvery(registrationByEmailAction, registerService)
    yield takeEvery(recoveryPasswordAction, registerService)
    yield takeEvery(activateAccountAction, registerService)
    yield takeEvery(activatePasswordAction, registerService)
}

export default authWatcher;
