import React, {memo, useEffect, useState} from "react";
import styled from "styled-components";
import {
  getCourierRoute,
  getCourierTrack,
  resetCourierRoute, setCourierRoutePlan,
  toggleShowCourierInfo, toggleShowCourierTrack
} from "../../reducers/toolkit/orderMapReducer"
import {useDispatch, useSelector} from "react-redux"
import BurgerMenu from "../share/UI/BurgerMenu"
import {LeftBackArrow, MoneyIco, Point, Speedometer} from "../../images/svgs"
import PayVariant from "../share/OrderMapCommonComponents/PayVariant"
import {cutTime} from "./CouriersStatistic"
import CourierRouteListItem from "./ListItemsComponents/CourierRouteListItem"
import {nanoid} from "nanoid"
import {toggleRedirectOrdersModal} from "./Modals/RedirectOrdersFromCourierToCourier"
import {togglePushMessageModal} from "./Modals/PushMessageToClient"

export function moneyFormat(str) {
  return (str + '').split(/([0-9]{3}$)/).join(' ')
}


// на вид сложный но по факту довольно простой компонент
//
const CourierInfo = ({coloredPoints}) => {

  const dispatch = useDispatch()

  const date = useSelector(store => store.orderMap.date)
  const deliveryPointsFilter = useSelector(store => store.orderMap.deliveryPointsFilter)
  const courier = useSelector(store => store.orderMap.courierInfoTargetCourier)
  const getCourierRouteStatus = useSelector(store => store.orderMap.getCourierRouteStatus)
  const courierRoute = useSelector(store => store.orderMap.courierRoute)
  const targetCourierForTrackDraw = useSelector(store => store.orderMap.targetCourierForTrackDraw)

  const {selectedPeriodId} = deliveryPointsFilter
  const {
    id,
    name,
    phone,
    finishOrdersCount = 0,
    ordersCount,
    timeToFinish = 0,
    timeToStart,
    distance,
    salary = 0
  } = courier

  const timeToStartCut = timeToStart ? cutTime(timeToStart.split(' ')[1]) : '--:--'
  const timeToFinishCut = timeToFinish ? cutTime(timeToFinish.split(' ')[1]) : '--:--'
  const percentOfFinishedOrders = (100 / ordersCount) * finishOrdersCount
  const distanceInKm = (+distance / 1000).toFixed(1)
  const formatSalary = moneyFormat(((+salary).toFixed(0) + ''))

  const [assembledData, setAssembledData] = useState({
    routeArr: [],
    totalPay: 0,
    totalPayCash: 0,
  })
  const [selectedOrders, setSelectedOrders] = useState([])

  useEffect(() => {
    if (assembledData.routeArr.length) {
      dispatch(setCourierRoutePlan(assembledData.noDeliveredPoints))
    }
  }, [assembledData])

  useEffect(() => {
    dispatch(getCourierRoute({
      date: date.format('YYYY-MM-DD'),
      periodId: selectedPeriodId,
      courierId: id
    }))
    return () => {
      dispatch(setCourierRoutePlan([]))
      dispatch(resetCourierRoute())
    }
  }, [])

  useEffect(() => {
    if (getCourierRouteStatus) {
      const buildedObj = buildCourierRouteData(courierRoute)
      setAssembledData({...buildedObj})
    }
  }, [getCourierRouteStatus])

  const buildCourierRouteData = (courierRoute) => {
    let totalPay = null
    let totalPayCash = null
    let noDeliveredPoints = []
    const data = {}

    data.routeArr = courierRoute.reduce((acc, el) => {
      const {companyId} = el
      const companyInfo = coloredPoints.reduce((acc, company) => {
        if (+company.companyId === +companyId) {
          acc = company
          if (el.timeActual === null) {
            noDeliveredPoints.push({lat: +company.lat, lng: +company.lng})
          }
        }
        return acc
      }, null)

      totalPay += companyInfo?.sumPriceResult ? +(+companyInfo.sumPriceResult).toFixed(0) : 0
      totalPayCash += companyInfo?.sumPriceResultCash ? +(+companyInfo.sumPriceResultCash).toFixed(0) : 0

      if (companyInfo) {
        acc = [
          ...acc,
          {
            companyInfo,
            courierRouteData: el,
          }]
      }
      return acc
    }, [])

    data.totalPay = totalPay
    data.totalPayCash = totalPayCash
    data.noDeliveredPoints = noDeliveredPoints

    return data
  }

  const toggleCourierInfoHandler = () => {
    dispatch(toggleShowCourierInfo())
  }

  const showCourierTrack = () => {
    if (targetCourierForTrackDraw?.id === id) {
      dispatch(toggleShowCourierTrack())
    } else {
      dispatch(toggleShowCourierTrack(courier))
      dispatch(getCourierTrack({
        personIds: [id],
        date: date.format('YYYY-MM-DD'),
        periodId: selectedPeriodId
      }))
    }
  }

  const toggleOrder = (companyId) => {
    selectedOrders.includes(companyId)
      ? setSelectedOrders(prevState => [...prevState.filter(el => el !== companyId)])
      : setSelectedOrders(prevState => [...prevState, companyId])
  }

  return (
    <Wrapper>
      <Header>
        <BurgerMenu
          toggleHandler={toggleCourierInfoHandler}
          content={<LeftBackArrow/>}
        />
      </Header>
      <Content>
        <CourierStatus>
          <CourierBio>
            <Name>
              <p>{name}</p>
              <Status color={courier.color || 'white'}/>
            </Name>
            <p>{phone}</p>
          </CourierBio>
          <MoneyStatus>
            {
              assembledData.totalPayCash !== null && assembledData.totalPay !== null && (
                <PayVariant sumPriceResultCash={assembledData.totalPayCash} sumPriceResult={assembledData.totalPay}/>
              )
            }
            <TotalMoney>
              <MoneyIco/>
              <p>{moneyFormat(assembledData.totalPay || 0)}₽</p>
            </TotalMoney>
          </MoneyStatus>
          <MovementStatus>
            <Points>
              <Point/>
              <p>{finishOrdersCount}/{ordersCount}</p>
            </Points>
            <Distance>
              <Speedometer/>
              <p>{distanceInKm}км</p>
            </Distance>
            <Timer
              onClick={showCourierTrack}
              {...{percentOfFinishedOrders}}>
              {timeToStartCut} --> {timeToFinishCut}
            </Timer>
          </MovementStatus>
          <SalaryStatus>
            <p>{formatSalary} = 300.00р + 13.00 р х {finishOrdersCount} заказов</p>
          </SalaryStatus>
        </CourierStatus>
        <RouteList>
          {
            assembledData.routeArr.length > 0 ? (
              assembledData.routeArr.map((el, id) =>
                <CourierRouteListItem
                  key={nanoid()}
                  data={el}
                  counter={id + 1}
                  checkedExternalVal={selectedOrders.includes(el.companyInfo.ordId)}
                  {...{toggleOrder}}/>)
            ) : (
              (getCourierRouteStatus && assembledData.routeArr.length < 0) &&
              <PlugText>Маршрут еще не построен.</PlugText>
            )
          }
        </RouteList>
        {
          assembledData.routeArr.length > 0 && (
            <Controls>
              <ControlElement
                disable={!selectedOrders.length}
                onClick={() => !!selectedOrders.length && toggleRedirectOrdersModal({courierId: id, selectedOrders})}
              >
                Переназначить
              </ControlElement>
              <ControlElement
                disable={!selectedOrders.length}
                onClick={() => !!selectedOrders.length && togglePushMessageModal({selectedOrders})}
              >Push-уведомление</ControlElement>
            </Controls>
          )
        }
      </Content>
    </Wrapper>
  )
}

export default memo(CourierInfo);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  p {
    margin: 0;
  }
`
const Header = styled.div`
  padding: 10px;
  display: flex;
`
const GoBack = styled.div`
  padding: 5px 9px;
  border: 1px solid silver;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 10px;
  max-height: calc(100vh - (64px + 56px));
`
const CourierStatus = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`
const RouteList = styled.div`
  display: flex;
  max-height: 100%;
  flex-direction: column;
  overflow-y: auto;
  margin-top: 20px;
  gap: 10px;
`
const Controls = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 20px 10px;
  margin-top: auto;
`
const ControlElement = styled.button`
  cursor: pointer;
  padding: 3px 5px;
  background: ${({disable}) => disable ? 'rgba(0,0,0,0.17)' : '#30b2db'};
  border: none;
  border-radius: 6px;
  color: #fff;
  font-size: 16px;

  :hover {
    background: ${({disable}) => disable ? 'rgba(0,0,0,0.25)' : '#2792b5'};
  }
`

const PlugText = styled.p`
  text-align: center;
`
const CourierBio = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const Name = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

const MoneyStatus = styled.div`
  display: flex;
  justify-content: space-between;
`
const TotalMoney = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 5px;
`
const MovementStatus = styled.div`
  display: flex;
  justify-content: space-between;
`
const SalaryStatus = styled.div`
  padding: 5px;
  background: rgba(0, 0, 0, 0.04);
  border-radius: 4px;
`

const Points = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`

const Distance = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`

const Status = styled.div`
  background: ${({color}) => color};
  border: 1px solid silver;
  border-radius: 50%;
  width: 20px;
  height: 20px;
`

const CourierDetailed = styled.div`

`

const Timer = styled.div`
  padding: 2px 5px;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
  border: 1px solid silver;
  font-size: 14px;
  cursor: pointer;

  :before {
    content: '';
    position: absolute;
    left: ${({percentOfFinishedOrders}) => percentOfFinishedOrders ? `${-100 + percentOfFinishedOrders}%` : '-100%'};
    top: 0;
    width: 100%;
    height: 100%;
    background: #7AFF8F;
    z-index: -1;
  }
`
