import React, {Fragment} from 'react';
import {connect} from "react-redux";
import '../App.css';
import styled from "styled-components";
import AddAny from "./AddAny";
import moment from "moment";
import DatePicker, {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ru from 'date-fns/locale/ru';
import {sr} from "../utils";
import {Switch, Select} from "antd";
import plural from "plural-ru";
import {deliveryCycleInfoMessage} from "../actions/ModalActions";

const {Option} = Select;

registerLocale('ru', ru);

class AddWalletModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            wallet_id: '',
            wallet_sum: '0',
            wallet_name: '',
            wallet_phone: '',
            wallet_validFrom: '',
            wallet_validTo: '',
            activeTab: 'settings',
            //startTime: new Date(),
            //endTime: new Date(new Date().getFullYear(), 11, 31),
            startTime: null,
            endTime: null,
            limitAmountPerDay: '',
            limitPercent: '',
            users: [],
            companies: [],
            eateries: [],
            transactions: [],
            sumValue: '',
            commentValue: '',
            type: '',
            valueSwitch:false,
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.data !== prevProps.data) {
            if (this.props.data) {
                this.setState({
                    wallet_id: this.props.data.wallet.id,
                    wallet_sum: this.props.data.wallet.amount,
                    wallet_name: this.props.data.wallet.name,
                    //type: this.props.data.wallet.wType,
                    startTime: this.props.data.wallet.validFrom && moment(this.props.data.wallet.validFrom, moment.defaultFormat).toDate(),
                    endTime: this.props.data.wallet.validTo && moment(this.props.data.wallet.validTo, moment.defaultFormat).toDate(),
                    limitAmountPerDay: this.props.data.wallet.limitAmountPerDay === '0.00' ? '' : this.props.data.wallet.limitAmountPerDay,
                    limitPercent: this.props.data.wallet.maxPercPay === '0.00' ? '' : this.props.data.wallet.maxPercPay,
                    wallet_phone: '',
                    users: this.props.data.clients.length > 0 ? this.props.data.clients : [],
                    companies: this.props.data.companies.length > 0 ? this.props.data.companies : [],
                    eateries: this.props.data.accounts.length > 0 ? this.props.data.accounts : [],
                    transactions: this.props.data.transaction.length > 0 ? this.props.data.transaction : [],
                    contractId: this.props.data.wallet.contractId,
                })
            } else {
                this.setState({
                    wallet_id: '',
                    wallet_sum: "0",
                    wallet_name: '',
                    //type: '',
                    startTime: null,
                    endTime: null,
                    limitAmountPerDay: '',
                    limitPercent: '',
                    wallet_phone: '',
                    users: [],
                    companies: [],
                    eateries: [],
                    transactions: [],
                    contractId: 'non-selected',
                })
            }
        }
    }


    onSettings = () => {
        this.setState({activeTab: 'settings'})
    }

    onTransactions = () => {
        this.setState({activeTab: 'transactions'})
    }

    add = (type, value) => {
        switch (type) {
            case 'users':
                sr("WalletsController", "AddUserWallet", {
                    client: value,
                    wallet: this.state.wallet_id,
                }).then((res) => {
                    console.log('AddUserWallet', res);
                    if (res.result === 1) {
                        this.props.refreshData();
                        this.props.deliveryCycleInfoMessage('something_added');
                    } else {
                        this.props.deliveryCycleInfoMessage('adding_error');
                    }
                });
                break;
            case 'companies':
                sr("WalletsController", "AddCompanyWallet", {
                    company: value,
                    wallet: this.state.wallet_id,
                }).then((res) => {
                    // console.log('AddCompanyWallet', res);
                    if (res.result === 1) {
                        this.props.refreshData();
                        this.props.deliveryCycleInfoMessage('something_added');
                    } else {
                        this.props.deliveryCycleInfoMessage('adding_error');
                    }
                });
                break;
            default:
                sr("WalletsController", "AddAccountWallet", {
                    account: value,
                    wallet: this.state.wallet_id,
                }).then((res) => {
                    // console.log('AddAccountWallet', res);
                    if (res.result === 1) {
                        this.props.refreshData();
                        this.props.deliveryCycleInfoMessage('something_added');
                    } else {
                        this.props.deliveryCycleInfoMessage('adding_error');
                    }
                });
        }
    }

    del = (type, id) => {
        switch (type) {
            case 'users':
                // console.log(id,'id')
                sr("WalletsController", "DelUserWallet", {
                    id: id,
                }).then((res) => {
                    // console.log('AddUserWallet', res);
                    if (res.data.deleted && res.result === 1) {
                        this.props.refreshData();
                        this.props.deliveryCycleInfoMessage('something_removed');
                    } else {
                        this.props.deliveryCycleInfoMessage('removing_error');
                    }
                });
                break;
            case 'companies':
                sr("WalletsController", "DelCompanyWallet", {
                    id: id,
                }).then((res) => {
                    // console.log('AddCompanyWallet', res);
                    if (res.data.deleted && res.result === 1) {
                        this.props.refreshData();
                        this.props.deliveryCycleInfoMessage('something_removed');
                    } else {
                        this.props.deliveryCycleInfoMessage('removing_error');
                    }
                });
                break;
            default:
                sr("WalletsController", "DelAccountWallet", {
                    id: id,
                }).then((res) => {
                    // console.log('AddAccountWallet', res);
                    if (res.data.deleted && res.result === 1) {
                        this.props.refreshData();
                        this.props.deliveryCycleInfoMessage('something_removed');
                    } else {
                        this.props.deliveryCycleInfoMessage('removing_error');
                    }
                });
        }
    }

    addTransaction = () => {
        if (this.state.sumValue && this.state.sumValue !== '0' && this.state.commentValue) {

            sr("WalletsController", "CreateTransaction", {
                walletFrom: this.state.sumValue < 0 ? this.state.wallet_id : 0,
                walletTo: this.state.sumValue < 0 ? 0 : this.state.wallet_id,
                amount: Math.abs(this.state.sumValue),
                comment: this.state.commentValue,
            }).then((res) => {
                // console.log('res', res);
                if (res.result === 1) {
                    this.props.refreshData();
                    this.props.deliveryCycleInfoMessage('transaction_added');
                } else {
                    this.props.deliveryCycleInfoMessage('transaction_adding_error');
                }
            });

            this.setState({sumValue: '', commentValue: ''});
        }
    }

    handleChange = (event) => {
        this.setState({sumValue: event.target.value});
    }

    handleChange2 = (event) => {
        this.setState({commentValue: event.target.value});
    }

    handleChange3 = (event) => {
        this.setState({wallet_name: event.target.value});
    }

    handleChange5 = (event) => {
        this.setState({wallet_phone: event.target.value});
    }

    handleChange4 = (event) => {
        if (+event.target.value >= 0) {
            this.setState({limitAmountPerDay: String(+event.target.value)});
        }
    }

    limitPercentInputHandle = event => {
        if (+event.target.value >= 0) {
            this.setState({limitPercent: event.target.value});
        }
    }

    saveEdited = () => {
        if (this.state.wallet_name) {
            if (this.state.contractId === 'non-selected') {
                this.props.deliveryCycleInfoMessage('contract_empty');
            } else {
                sr("WalletsController", "UpdateWallet", {
                    id: this.state.wallet_id,
                    name: this.state.wallet_name,
                    holderId: 146923,
                    //wType: this.state.type || "private",
                    validFrom: this.state.startTime ? moment(this.state.startTime).format("YYYY-MM-DD 00:00:00") : null, //"2020-07-01" || null
                    validTo: this.state.endTime ? moment(this.state.endTime).format("YYYY-MM-DD 23:59:59") : null, //"2020-12-30" || null,
                    limitAmountPerDay: this.state.limitAmountPerDay ? this.state.limitAmountPerDay : "0.00",
                    contractId: this.state.contractId,
                    maxPercPay: this.state.limitPercent ? this.state.limitPercent : 0,
                }).then((res) => {
                    // console.log('updateWallet res', res);
                    res.data.updated && res.result && this.props.deliveryCycleInfoMessage('saved');
                });
            }
        } else {
            this.props.deliveryCycleInfoMessage('name_empty');
        }
    }

    createWallet = () => {
        if (this.state.wallet_name) {
            if (this.state.contractId === 'non-selected') {
                this.props.deliveryCycleInfoMessage('contract_empty');
            } else {
                sr("WalletsController", "CreateWallet", {
                    name: this.state.wallet_name,
                    holderId: 146923,
                    //wType: this.state.type || "private",
                    validFrom: this.state.startTime ? moment(this.state.startTime).format("YYYY-MM-DD 00:00:00") : null, //"2020-07-01" || null
                    validTo: this.state.endTime ? moment(this.state.endTime).format("YYYY-MM-DD 23:59:59") : null, //"2020-12-30" || null,
                    limitAmountPerDay: this.state.limitAmountPerDay ? this.state.limitAmountPerDay : "0.00",
                    phone: this.state.wallet_phone,
                    contractId: this.state.contractId,
                    maxPercPay: this.state.limitPercent ? this.state.limitPercent : 0,
                }).then((res) => {
                    // console.log('created res', res);
                    if (res.data.id && res.result) {
                        this.props.deliveryCycleInfoMessage('wallet_created');
                        this.props.updateWallet(res.data.id);
                    }
                    if (!res.data.id) {
                        this.props.deliveryCycleInfoMessage('wallet_created_error');
                    }
                });
            }
        } else {
            this.props.deliveryCycleInfoMessage('name_empty');
        }
    }

    changeContract = (value) => {
        this.setState({contractId: value} , () => console.log(`selected ${value}`))
    }
    handleSwitch=()=>{
        this.setState({valueSwitch:!this.state.valueSwitch})
    }
    onChange = (e) => {
        let files = e.target.files;
        let reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = (e) => {
            if(!this.state.valueSwitch){
            sr("WalletsController", "VerificationWalletsList", {csv: e.target.result, contractId: this.state.contractId}).then((res) => {
                const message = () => {

                    let string = '';
                    let errors = '';
                    if (res.data.errorCount) {
                        res.data.errors.forEach(v => {
                            errors += `${v}\n`
                        })
                        string += `${plural(res.data.errorCount, '%d строка', '%d строки', '%d строк')} с ошибками:\n${errors}\n`
                    }
                    if (res.data.resultCount) {
                        string += `${plural(res.data.resultCount, '%d кошелёк будет создан', '%d кошелька будут созданы', '%d кошельков будут созданы')} на общую сумму: ${res.data.resultSum}р\n\n`
                    }
                    return string + 'Загрузить?'
                }

                if(window.confirm(message())) {
                    sr("WalletsController", "CreateWalletsList", {csv: e.target.result, contractId: this.state.contractId}).then((res2) => {
                        this.props.deliveryCycleInfoMessage('file_uploaded', `Кошельков: ${res2.data.resultCount}`);
                        document.getElementById("control").value = "";
                    });
                } else {
                    document.getElementById("control").value = "";
                }
            });
        }else{
            sr("WalletsController", "VerificationWalletsListByDefault", {csv: e.target.result, contractId: this.state.contractId}).then((res) => {
                const message = () => {

                    let string = '';
                    let errors = '';
                    if (res.data.errorCount) {
                        res.data.errors.forEach(v => {
                            errors += `${v}\n`
                        })
                        string += `${plural(res.data.errorCount, '%d строка', '%d строки', '%d строк')} с ошибками:\n${errors}\n`
                    }
                    if (res.data.resultCount) {
                        string += `${plural(res.data.resultCount, '%d кошелёк будет пополнен', '%d кошелька будут пополнены', '%d кошельков будут пополнены')} на общую сумму: ${res.data.resultSum}р\n\n`
                    }
                    return string + 'Загрузить?'
                }

                if(window.confirm(message())) {
                    sr("WalletsController", "CreateWalletsListByDefault", {csv: e.target.result, contractId: this.state.contractId}).then((res2) => {
                        this.props.deliveryCycleInfoMessage('file_uploaded', `Кошельков: ${res2.data.resultCount}`);
                        document.getElementById("control").value = "";
                        this.setState({valueSwitch:false})
                    });
                } else {
                    document.getElementById("control").value = "";
                }
            });
        }
        
        }
    }

  
    render() {
        return (
            <Fragment>
                {this.props.addFile ? (
                    <Wrapper className="files_wrapper" style={{right: !this.props.isShow ? -649 : 0, zIndex: 10, overflowY: 'scroll'}}>
                        <div className="flex_row_start_center">
                            <CloseButton onClick={this.props.close}>Закрыть</CloseButton>
                        </div>
                        <AddName style={{marginTop: 30}}>
                            <p style={{marginRight: 145, marginBottom: 10}}>Номер договора</p>
                            <Select
                                style={{width: '100%'}}
                                value={this.state.contractId}
                                onChange={this.changeContract}
                            >
                            
                                <Option value={"non-selected"}>Не выбрано</Option>
                                <Option value={"0"}>Без договора</Option>
                                {this.props.contracts.map((v) => {
                                    return (
                                        <Option key={v.id} value={v.id}>
                                            {`Договор №${v.contractNum} от ${moment(v.contractDate, 'YYYY-MM-DD').format('DD.MM.YYYY')} (${v.counterpartiesName})`}
                                        </Option>
                                    )
                                })}
                            </Select>
                        </AddName>
                        <AddName className="flex_row_start_center" style={{marginTop: 20}}>
                            <input
                                className="input_file"
                                id="control"
                                type="file"
                                name="file"
                                onChange={(e) => {
                                    if (this.state.contractId === 'non-selected') {
                                        if(this.state.valueSwitch){
                                            this.onChange(e);
                                        }else{
                                            this.props.deliveryCycleInfoMessage('contract_empty');                         
                                        }
                                    } else {                                   
                                        this.onChange(e);
                                    }
                                }}
                                style={{marginLeft: 10}}/>
                            <label htmlFor="control">Добавить файл</label>
                           
                        </AddName>
                        <SwitchWrapper>
                        <Switch
                        checked={this.state.valueSwitch}
                        onChange={this.handleSwitch}
                        />
                            <p style={this.state.valueSwitch ?{paddingLeft:20,color:'#34A5FF'} : {paddingLeft:20,color:'#161616'}} >Пополнение дефолтных кошельков</p>
                        </SwitchWrapper>
                    </Wrapper>
                ) : (
                    <Wrapper className="wallets_wrapper" style={{right: !this.props.isShow ? -649 : 0, zIndex: 10, overflowY: 'scroll'}}>
                        <div className="flex_row_start_center">
                            <SaveButton onClick={this.props.data ? this.saveEdited : this.createWallet}>
                                Сохранить
                            </SaveButton>
                            <CloseButton onClick={this.props.close}>Закрыть</CloseButton>
                        </div>
                        <AddWalletTitle className="flex_row_start_center">
                            <p style={{marginRight: 187}}>Кошелёк</p>
                            <p style={{marginRight: 60}}>ID {this.state.wallet_id}</p>
                            <p>Сумма: {this.state.wallet_sum} ₽</p>
                        </AddWalletTitle>
                        <AddName className="flex_row_start_center">
                            <p style={{marginRight: 191}}>Название</p>
                            <AddNameInput value={this.state.wallet_name} type="text" placeholder="Например имя компании" onChange={this.handleChange3}/>
                        </AddName>
                        {!this.props.data && (
                            <AddName className="flex_row_start_center">
                                <p style={{marginRight: 145}}>Номер телефона</p>
                                <AddNameInput value={this.state.wallet_phone} type="tel" placeholder="+7XXXXXXXXXX" onChange={this.handleChange5}/>
                            </AddName>
                        )}
                        <AddName>
                            <p style={{marginRight: 145, marginBottom: 10}}>Номер договора</p>
                            <Select
                                style={{width: '100%'}}
                                value={this.state.contractId}
                                onChange={this.changeContract}
                            >
                                <Option value={"non-selected"}>Не выбрано</Option>
                                <Option value={"0"}>Без договора</Option>
                                {this.props.contracts.map((v) => {
                                    return (
                                        <Option key={v.id} value={v.id}>
                                            {`Договор №${v.contractNum} от ${moment(v.contractDate, 'YYYY-MM-DD').format('DD.MM.YYYY')} (${v.counterpartiesName})`}
                                        </Option>
                                    )
                                })}
                            </Select>
                        </AddName>
                        <Tabs className="flex_row_start_center">
                            <TabsButton onClick={this.onSettings} style={
                                this.state.activeTab === 'settings' ?
                                    {backgroundColor: '#3498db', color: '#fff', fontWeight: 700, marginRight: 16} :
                                    {backgroundColor: '#fff', color: '#3498db', marginRight: 16}
                            }>Настройки</TabsButton>
                            {this.props.data && (
                                <TabsButton onClick={this.onTransactions} style={
                                    this.state.activeTab === 'transactions' ?
                                        {backgroundColor: '#3498db', color: '#fff', fontWeight: 700} :
                                        {backgroundColor: '#fff', color: '#3498db'}
                                }>Транзакции</TabsButton>
                            )}
                        </Tabs>
                        {this.state.activeTab === 'settings' ? (
                            <div>
                                <AddTime className="flex_row_sb_center add_time">
                                    <p>Время действия кошелька</p>
                                    <div className="flex_row_start_center">
                                        <p style={{marginLeft: 8, marginRight: 8}}>с</p>
                                        <DatePicker
                                            selected={this.state.startTime}
                                            startDate={this.state.startTime}
                                            endDate={this.state.endTime}
                                            selectsStart
                                            onChange={date => this.setState({startTime: date})}
                                            dateFormat="d MMMM yyyy"
                                            locale={ru}
                                            style={{textAlign: 'center'}}
                                        />
                                        <p style={{marginLeft: 8, marginRight: 8}}>по</p>
                                        <DatePicker
                                            selected={this.state.endTime}
                                            startDate={this.state.startTime}
                                            endDate={this.state.endTime}
                                            minDate={this.state.startTime}
                                            selectsEnd
                                            onChange={date => this.setState({endTime: date})}
                                            dateFormat="d MMMM yyyy"
                                            popperModifiers={{
                                                preventOverflow: {
                                                    enabled: true,
                                                    escapeWithReference: false,
                                                    boundariesElement: "viewport"
                                                },
                                                offset: {
                                                    enabled: true,
                                                    offset: '-28px, 0px'
                                                },
                                            }}
                                            locale={ru}
                                            style={{textAlign: 'center'}}
                                        />
                                    </div>
                                </AddTime>
                                <AddLimit className="flex_row_start_center">
                                    <p style={{marginRight: 91}}>Лимит расходов в день</p>
                                    <AddLimitInput type="number" placeholder="0 ₽" value={this.state.limitAmountPerDay || ''} onChange={this.handleChange4} min="0"
                                                   pattern={`^[ 0-9]+$`}/>
                                </AddLimit>
                                <AddPercent className="flex_row_start_center">
                                    <p style={{marginRight: 123}}>Лимит % от заказа</p>
                                    <AddLimitInput type="number" placeholder="0 %" value={this.state.limitPercent || ''} onChange={this.limitPercentInputHandle} min="0"
                                                   pattern={`^[ 0-9]+$`}/>
                                </AddPercent>
                                {/*<AddType className="flex_row_start_center">*/}
                                {/*    <p style={{marginRight: 16}}>Корпоративный кошелёк</p>*/}
                                {/*    <Switch checked={this.state.type === 'corporate'} onChange={() => this.setState({type: this.state.type !== 'corporate' ? 'corporate' : 'private'})} />*/}
                                {/*</AddType>*/}
                                {this.props.data && (
                                    <AddAnyBox>
                                        <AddAny title="Пользователи" add={this.add} data={this.state.users} del={this.del}/>
                                        <AddAny title="Компании" add={this.add} data={this.state.companies} del={this.del}/>
                                        <AddAny title="Столовые" add={this.add} data={this.state.eateries} del={this.del}/>
                                    </AddAnyBox>
                                )}
                            </div>
                        ) : (
                            <div>
                                <AddTransactionTitle>Добавить транзакцию</AddTransactionTitle>
                                <AddTransactionsInputs className="flex_row_sb_center">
                                    <AddTransactionsInput type="number" placeholder="Сумма" value={this.state.sumValue} onChange={this.handleChange}/>
                                    <AddTransactionsInput type="text" placeholder="Комментарий" value={this.state.commentValue} onChange={this.handleChange2}/>
                                    <AddTransactionsButton onClick={this.addTransaction}>Добавить</AddTransactionsButton>
                                </AddTransactionsInputs>
                                {this.state.transactions.length > 0 &&
                                <TransactionsColumns className="flex_row_sb">
                                    <p style={{marginRight: 100}}>Дата</p>
                                    <p style={{marginRight: 177}}>Название</p>
                                    <p style={{marginRight: 33}}>Операция</p>
                                    <p style={{marginRight: 40}}>Сумма</p>
                                </TransactionsColumns>}
                                <Transactions>
                                    {this.state.transactions.length > 0 && this.state.transactions.map((item, index) => {
                                        return (
                                            <AddTransactionsItem className="flex_row_start_center" key={index}>
                                                <p style={{width: 137, paddingRight: 17}}>
                                                    {moment(item.created).format('DD.MM.YYYY в HH:mm')}
                                                </p>
                                                <p style={{
                                                    width: 250,
                                                    paddingRight: 17,
                                                    overflow: 'hidden',
                                                    whiteSpace: 'nowrap',
                                                    textOverflow: 'ellipsis',
                                                }}>
                                                    {item.comment}
                                                </p>
                                                <p style={{width: 107, paddingRight: 17}}>{item.walletTo > 0 ? 'Зачисление' : 'Списание'}</p>
                                                <p style={{width: 91}}>{item.walletTo > 0 ? '+' : '-'}{item.amount} ₽</p>
                                            </AddTransactionsItem>
                                        )
                                    })}
                                </Transactions>
                            </div>
                        )}
                    </Wrapper>
                )}
            </Fragment>
        );
    }
}

const mapStateToProps = store => ({

});

const mapDispatchToProps = dispatch => ({
    deliveryCycleInfoMessage: (actionUser, product, timeOut) => dispatch(deliveryCycleInfoMessage(actionUser, product, timeOut)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddWalletModal);

const Transactions = styled.div`
    height: calc(100vh - 437px);
    overflow-y: scroll;
`;

const Wrapper = styled.div`
    position: fixed;
    top: 60px;
    width: 649px;
    height: calc(100vh - 60px);
    padding: 30px 35px 30px 26px;
    background-color: #fff;
    box-shadow: 0 0 62px rgba(0, 0, 0, 0.1);
    transition: .5s cubic-bezier(0.645, 0.045, 0.355, 1);
`;

const SaveButton = styled.button`
    width: 200px;
    height: 42px;
    margin-right: 55px;
    outline: 0;
    border: 0;
    border-radius: 66px;
    background: #ff1744;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    line-height: 17px;
    color: #fff;
    cursor: pointer;
`;

const CloseButton = styled.button`
    width: 140px;
    height: 42px;
    margin-right: 96px;
    outline: 0;
    border: 1px solid #999;
    border-radius: 66px;
    background: #fff;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    line-height: 17px;
    color: #999;
    cursor: pointer;
`;

// const DelButton = styled.button`
//     width: 55px;
//     height: 16px;
//     padding: 0;
//     outline: 0;
//     border: 0;
//     background: #fff;
//     font-family: 'Roboto', sans-serif;
//     font-size: 14px;
//     line-height: 17px;
//     color: #999;
//     cursor: pointer;
// `;

const AddWalletTitle = styled.div`
    margin-top: 30px;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
`;

const AddName = styled.div`
    margin-top: 10px;
`;

const AddNameInput = styled.input`
    width: 218px;
    
    &:focus {
        background: #fff;
    }
`;

const Tabs = styled.div`
    margin-top: 30px;
`;

const TabsButton = styled.button`
    width: 118px;
    height: 36px;
    outline: 0;
    border: 1px solid #3498db;
    border-radius: 4px;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    line-height: 17px;
    cursor: pointer;
`;

const AddTime = styled.div`
    margin-top: 10px;
`;

const AddLimit = styled.div`
    margin-top: 10px;
`;
const AddPercent = styled.div`
    margin-top: 10px;
`

const AddType = styled.div`
    height: 36px;
    margin-top: 10px;
`;

const AddLimitInput = styled.input`
    width: 150px;
    
    &:focus {
        background: #fff;
    }
`;

const AddAnyBox = styled.div`
    margin-top: 36px;
`;

const AddTransactionTitle = styled.p`
    margin-top: 20px !important;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
`;

const AddTransactionsInputs = styled.div`
    margin-top: 10px;
`;

const AddTransactionsInput = styled.input`
    &:first-child {
        width: 152px;
    }
    
    &:nth-child(2) {
        width: 274px;
    }
    
    &:focus {
        background: #fff;
    }
`;

const AddTransactionsButton = styled.button`
    width: 130px;
    height: 36px;
    outline: 0;
    border: 0;
    border-radius: 66px;
    background: #ff1744;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    line-height: 17px;
    color: #fff;
    cursor: pointer;
`;

const TransactionsColumns = styled.div`
    margin-top: 20px;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    height: 36px;
`;

const AddTransactionsItem = styled.div`
    margin-bottom: 14px;
`;
const SwitchWrapper = styled.div`
display: flex;
flex-direction: row;
align-items: center;
margin-top: 10px;
`