import {sr} from "../../utils";
import {call, put, debounce} from "redux-saga/effects";
import {
    DELETE_WAYBILL_ERROR,
    DELETE_WAYBILL_STATUS, GET_DOWNLOAD_LINK_ERROR, GET_DOWNLOAD_LINK_SUCCESS,
    GET_PRODUCT_LIST_BY_RECIPIENT_ERROR,
    GET_PRODUCT_LIST_BY_RECIPIENT_READY,
    GET_RECIPIENT_ERROR,
    GET_RECIPIENT_READY,

    GET_SENDER_ERROR,
    GET_SENDER_READY,
    GET_WAYBILL_DETAILS_ERROR,
    GET_WAYBILL_DETAILS_READY,

    GET_WAYBILL_ERROR,
    GET_WAYBILL_READY,
    SEND_WAYBILL_ERROR,
    SEND_WAYBILL_READY,
    TRY_EDIT_WAYBILL,
    UPDATE_WAYBILL_ERROR,
    UPDATE_WAYBILL_STATUS,
} from "../../actions/WarehouseActions";

export function* getRecipientWorker() {
    const send = () => {
        return sr("DepotController", "GetRecipients", {})
    }

    try {
        const res = yield call(send);
        yield put({type: GET_RECIPIENT_READY, payload: res.data});
    } catch (err) {
        yield put({type: GET_RECIPIENT_ERROR, payload: err});
    }
}

export function* getSenderWorker() {
    const send = () => {
        return sr("DepotController", "GetCounterparties", {})
    }

    try {
        const res = yield call(send);
        yield put({type: GET_SENDER_READY, payload: res.data});
    } catch (err) {
        yield put({type: GET_SENDER_ERROR, payload: err});
    }
}

export function* getWaybills(action) {
    const send = (payload) => {
        return sr("DepotController", "GetWaybills", {
            page: payload.page,
            limit: payload.limit,
            counterpartieId: payload.senderId,
            recipientId: payload.recipientId,
            //not necessary
            dateFrom: payload.dateFrom,
            dateTo: payload.dateTo,
        })
    }

    try {
        const res = yield call(send, action.payload);
        yield put({type: GET_WAYBILL_READY, payload: res.data});
    } catch (err) {
        yield put({type: GET_WAYBILL_ERROR, payload: err});
    }
}

export function* sendWaybill(action) {
    const send = () => {
        return sr("DepotController", "CreateWaybill", {
            waybill: action.payload.waybill,
            counterpartieId: action.payload.counterpartieId,
            recipientId: action.payload.recipientId,
            waybillDate: action.payload.waybillDate,
            orderSum: 1000,
            waybillProducts: action.payload.waybillProducts
        })
    }

    try {
        const res = yield call(send);
        yield put({type: SEND_WAYBILL_READY, payload: res.data});
    } catch (err) {
        yield put({type: SEND_WAYBILL_ERROR, payload: err});
    }
}

export function* updateWaybill(action) {
    const send = () => {
        return sr("DepotController", "UpdateWaybill", {
            waybillId: action.payload.newState.id,
            waybill: action.payload.newState.waybill,
            counterpartieId: action.payload.newState.counterpartieId,
            recipientId: action.payload.newState.recipientId,
            waybillDate: action.payload.newState.date,
            orderSum: 1000,
            waybillProducts: action.payload.newState.products,
        })
    }

    try {
        const res = yield call(send);
        yield put({type: UPDATE_WAYBILL_STATUS, payload: res.data});
    } catch (err) {
        yield put({type: UPDATE_WAYBILL_ERROR, payload: err});
    }
}

export function* deleteWaybillOnServer(action) {
    const send = () => {
        return sr("DepotController", "DelWaybill", {
            waybillId: action.payload.id,
        })
    }

    try {
        const res = yield call(send);
        yield put({type: DELETE_WAYBILL_STATUS, payload: res.data});
    } catch (err) {
        yield put({type: DELETE_WAYBILL_ERROR, payload: err});
    }
}

export function* getWProductsOfWaybill(action) {
    const send = (payload) => {
        return sr("DepotController", "GetProducts", {
            waybillId: payload
        })
    }

    try {
        const res = yield call(send, action.payload);
        yield put({type: GET_WAYBILL_DETAILS_READY, payload: res.data.waybill});
        yield put({type: TRY_EDIT_WAYBILL})
    } catch (err) {
        yield put({type: GET_WAYBILL_DETAILS_ERROR, payload: err});
    }
}

export function* getProducts(action) {
    const send = () => {
        return sr("DepotController", "GetProductsDebounce", {
            filterText: action.payload.text,
            recipientId: action.payload.recipientId,
        })
    }

    try {
        const res = yield call(send);
        yield put({type: GET_PRODUCT_LIST_BY_RECIPIENT_READY, payload: res.data});
    } catch (err) {
        yield put({type: GET_PRODUCT_LIST_BY_RECIPIENT_ERROR, payload: err});
    }
}

export function* getStorageDownloadLink(action) {
    const send = () => {
        return sr("DepotController", "GetStorageAsExcel", {
            counterpartieId: action.payload.counterpartieId,
            recipientId: action.payload.recipientId,
            dateFrom: action.payload.dateFrom,
            dateTo: action.payload.dateTo
        })
    }

    try {
        const res = yield call(send);
        yield put({type: GET_DOWNLOAD_LINK_SUCCESS, payload: res.data});
    } catch (err) {
        yield put({type: GET_DOWNLOAD_LINK_ERROR, payload: err});
    }
}
