import React from "react";
import styled from "styled-components"


const DefaultBtn = ({style, handler, type = 'button', href = '#', disabled, children}) => {
    return (
        <>
            {
                type === 'button' && (
                    <CustomButton style={style} onClick={handler} disabled={disabled}>
                        { children }
                    </CustomButton>
                )
            }
            {
                type === 'link' && (
                    <CustomLink as="a" href={href} target="_blank" style={style} disabled={disabled}>
                        { children }
                    </CustomLink>
                )
            }
        </>
    )
}

export default DefaultBtn;

const CustomButton = styled.button`
  cursor:pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 20px;
  min-width: 200px;
  border-radius: 50px;
  border: none;
  background: ${({disabled}) => disabled ? "#4f4f4f" : "#14142a" };
  color: white;
  transition: background-color .2s ease;
  
  :hover {
    background: #383881;
    color: white;
  }
  :active {
    background: #6866a1;
  }
`

const CustomLink = styled(CustomButton)`
  color: ${({disabled}) => disabled ? "rgb(255,255,255)" : "#fff"};
`
