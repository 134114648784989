import {sr} from "../../utils"
import {put, call} from "redux-saga/effects"
import {
  getCenterInfo,
  getDeliveryPointsError, setCenter, setCenterInfo, setCourierRoute,
  setCouriers, setCourierTrack,
  setDeliveryPoints, setDetailedOrderInfo, setKitchens,
  setNewMarkerPosition,
  setRedirectCouriersResult
} from "../../reducers/toolkit/orderMapReducer"
import moment from "moment"

export function* getDeliveryPointsAndCouriers(action) {
  // console.log(action.payload)
  try {
    const deliveryPoints = yield sr('WinchController', 'GetDeliveryPoints', action.payload)

    yield put(setDeliveryPoints(deliveryPoints.data.points));
    yield put(setCouriers(deliveryPoints.data.couriers));
    yield put(setKitchens(deliveryPoints.data.kitchens));
  } catch (e) {
    yield put(getDeliveryPointsError(e.message));
  }
}

export function* redirectCouriersWorker(action) {
  try {
    const request = yield sr('AppSOKitchenService', 'SetOrderCourier', {...action.payload})

    //console.log(request)

    if(request.result === 1) {
      yield put(setRedirectCouriersResult())
    }
  } catch (e) {
    yield put(setRedirectCouriersResult(e));
  }
}

export function* updateCenter(action){
  try {
    const req = yield sr('WinchController','UpdateCentersForOrder',{...action.payload})
    if(req.result === 1){
      yield put(setCenterInfo({data:req.data,status:'resolved'}));
    }
  } catch (e) {
      //yield put(setCenterInfoStatus({data: e.message, status:'rejected'}))
  }
}

export function* getDetailedOrderInfoWorker(action) {
  try {
    const request = yield sr('WinchController', 'GetOrdersInfo', {...action.payload})
    if(request.result === 1) {
      yield put(setDetailedOrderInfo({data: request.data, status: 'resolved'}))
    }
  } catch (e) {
    yield put(setDetailedOrderInfo({data: e.message, status: 'rejected'}));
  }
}

export function* sendUpdatedComments(action) {
  try {
    if(action.payload.commentType === 'addr') yield sr('AppSOCourierService', 'SetOrderComment', {...action.payload})
    if(action.payload.commentType === 'order') yield sr('AppDinnerController', 'SaveCompany', {...action.payload})
  } catch (e) {
    console.log(e)
  }
}

export function* getTracks(action) {
  try {
    //input {personIds : Array, date: 'YYYY-MM-DD', periodId: str}
    const response = yield sr('WinchController', 'GetCourierTracks', {...action.payload})

    const payload = {
      data: response.data.tracks,
      status: 'resolved'
    }

    yield put(setCourierTrack(payload))
  } catch (e) {}
}

export function* getCourierRouteWorker(action) {
  try {
    //input {date, periodId, courierId}
    const response = yield sr('WinchController', 'GetCourierCompanyTime', {...action.payload})

    const payload = {
      data: response.data.time,
      status: 'resolved'
    }

    yield put(setCourierRoute(payload))
  } catch (e) {}
}

export function* sendNewMarkerPosition(action) {
  try {
    //input {company: {latitude, longitude}}
    const response = yield sr('AppSOKitchenService', 'SetCompanyCoords', {...action.payload})
  } catch (e) {}
}
