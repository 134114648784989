import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import ProductListItem from "./ProductListItem";
import { useDispatch, useSelector } from "react-redux";
import { nanoid } from "nanoid";
import {
  modalInfo,
  ProdMod,
  saveNewProductStarted,
} from "../../actions/AssortmentAction";
import AddCategory from "./AddCategoty";
import FadeInOut_Y_ShiftHOC from "./common/FadeInOut_Y_ShiftHOC";
import "../../App.css";
import Close from "../../images/Vector.svg";
import { Modal } from "antd";
import ModalInfo from "./Components/ModalItems/ModalInfo";
import { useMemo } from "react";
import { AnimatePresence } from "framer-motion";
import NoKitc from "../../images/assort/Ассортимент/NoKitchens.png";
import {
  AutoSizer,
  List,
  CellMeasurer,
  CellMeasurerCache,
} from "react-virtualized";
import "../../App.css";
import { useRequest } from "../../hooks/useRequest";
import axios from "axios";
import moment from "moment";
function ProductList({
  addProductFieldShow,
  addCategoryFieldShow,
  setAddCategoryFieldShow,
  setAddProductFieldShow,
  setProductsInSelectedCategory,
  tegs,
  // openModal,
  setOpenModal,
  scrollsRef,
  refForAddProd,
}) {
  const categoryList = useSelector((store) => store.assortment.categoryList);
  const productList = useSelector((store) => store.assortment.productList);
  const openModal = useSelector((store) => store.assortment.modalInfo);
  const statusSaveNew = useSelector((store) => store.assortment.saveProduct);
  const saveProductChanges = useSelector(
    (store) => store.assortment.saveProductChanges
  );
  const forAlone = useSelector((store) => store.assortment.forAlone);

  const [result, Request] = useRequest();

  const selectedCategory = useSelector(
    (store) => store.assortment.selectedCategory
  );
  const dispatch = useDispatch();
  const getMenu = useSelector((store) => store.assortment.getMenuAssort);
  const productsAffiliationToCategory = [];
  const adminMode = useSelector((store) => store.assortment.ModerationMode);
  const SelectedKitchens = useSelector(
    (store) => store.appMain.selectedKitchens
  );

  const [allProducts, setAllProducts] = useState([]);
  const [itemsList, setItemsList] = useState(null);
  const [isModal, setModal] = useState(false);
  const [allProdMod, setAllProdMod] = useState([]);
  const [aloneProduct, setAloneProduct] = useState({});
  const [aloneProductActual, setAloneProductActual] = useState({});
  const [delStart, setDelStart] = useState(false);
  const [modProdHead, setModProdHead] = useState([]);
  const refForItemList = useRef(null);
  const [stat, setStat] = useState(null);
  const [countProd, setCountProd] = useState([]);
  const [idForNewProduct, setIdForNewProduct] = useState(null);
  useEffect(() => {
    if (openModal) {
      productList.map((el) => {
        if (el?.id) {
          if (+el?.id === +aloneProduct?.id_product) {
            setAloneProductActual(el);
          }
        } else {
          if (+el?.moderation_id === +aloneProduct?.moderation_id) {
            setAloneProductActual(el);
          }
        }
      });
    }
    return () => setAloneProductActual({});
  }, [aloneProduct, openModal, productList]);

  useEffect(() => {
    if (productsAffiliationToCategory.length > 0) {
      setProductsInSelectedCategory(productsAffiliationToCategory);
    }
  }, [productsAffiliationToCategory]);

  function gets() {
    // setProdStatus('pending')
    const plNew = productList
      .map((el) => {
        if (String(el.categoryId) === selectedCategory.id) {
          if (el.historyModeration && el.historyModeration.length > 0) {
            if (el.id === false) {
              // console.log('COUNT'
              return { ...el.historyModeration.slice(-1)[0], isMOD: true };
            } else {
              if (el.isEnabled === "1") {
                return { ...el.historyModeration.slice(-1)[0], isEnabled: "1" };
              } else {
                return { ...el.historyModeration.slice(-1)[0], isEnabled: "0" };
              }
            }
          } else {
            return el;
          }
        }
      })
      .filter((i) => i);
    setAllProducts(plNew);

    setStat("resolved");
  }

  const cache = new CellMeasurerCache({
    fixedWidth: true,
    defaultHeight: 124,
    minHeight: 124,
  });

  const productForMod = () => {
    const b = productList
      .map((items) => {
        let modProd =
          items.historyModeration.slice(-1)[0]?.moderate_status === "3" ||
          items.historyModeration.slice(-1)[0]?.moderate_status === "1";
        if (items.id) {
          if (modProd) {
            return items.historyModeration.slice(-1)[0];
          }
        } else {
          if (modProd) {
            return items.historyModeration.slice(-1)[0];
          }
        }
      })
      .filter((i) => i);
    setAllProdMod(b);
    if ((aloneProduct && b) || forAlone) {
      setAloneProduct(
        b.find((el) => {
          if (!forAlone) {
            if ("id_product" in aloneProduct) {
              if (saveProductChanges) {
                if ("moderate_status" in aloneProduct) {
                  if (
                    +el?.id_product === +aloneProduct?.id_product &&
                    !aloneProduct.isMOD
                  ) {
                    return +el?.id_product === +aloneProduct?.id_product;
                  } else {
                    return +el?.moderation_id === +aloneProduct?.moderation_id;
                  }
                }
              } else {

                return +el?.id === +aloneProduct?.id;
              }
            } else {
              if (+el.id_product === +aloneProduct?.id && !aloneProduct.isMOD) {
                return +el.id_product === +aloneProduct?.id;
              } else {
                return +el?.moderation_id === +aloneProduct?.moderation_id;
              }
            }
          } else {
            // return +el.moderation_id === +forAlone.moderation_id
            return +el.moderation_id === +forAlone.moderation_id
              ? +el.moderation_id === +forAlone.moderation_id
              : +el?.id === +aloneProduct?.id;
          }
        })
      );
    }
  };


  useEffect(() => {
    productForMod();
  }, [productList, adminMode, saveProductChanges, statusSaveNew]);

  useEffect(() => {
    if (allProdMod.length > 0) {
      dispatch(ProdMod(allProdMod));
    }
  }, [adminMode, allProdMod]);

  const productWithHeader = () => {
    const a = categoryList.map((i) => {
      i.productItem = allProdMod.filter((el) => el.categoryId === i.id);
      return i;
    });
    setModProdHead(a);
  };
  useEffect(() => {
    if (adminMode) {
      productWithHeader();
    }
  }, [adminMode, allProdMod]);

  // console.log(allProdMod,'allProdModallProdMod')

  useEffect(() => {
    if (!adminMode) {
      if (productList.length > 0) {
        gets();
      }
    }
  }, [
    productList,
    selectedCategory,
    saveProductChanges,
    statusSaveNew,
    aloneProduct,
    aloneProductActual,
  ]);

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        setModal(false);
      }
    };
    window.addEventListener("keyup", close);
    return () => window.removeEventListener("keyup", close);
  }, []);

  function ActualVersion(ids) {
    if (typeof ids === "object") {
      if (ids.flag === "forMod") {
        setModal(true);
        const urlForPhoto = `https://menuforme.online/files/moderation/${ids.medias}.jpg`;
        setItemsList(urlForPhoto);
      }
      if (ids.flag === "forNew") {
        setModal(true);
        const urlForPhoto = ids.medias[0];
        setItemsList(urlForPhoto);
      } else if (ids.flag === "forSimplePhoto") {
        setModal(true);
        const urlForPhoto = `https://menuforme.online/img/cache/product_standard/${ids.medias}.jpg`;
        setItemsList(urlForPhoto);
      }
    }
  }
  const countProdHandler = () => {
    const count = allProducts?.map((el) => {
      if (+el?.categoryId === +selectedCategory?.id) {
        return true;
      }
    });
    setCountProd(count);
  };

  useEffect(() => {
    countProdHandler();
  }, [productList, selectedCategory, allProducts, allProdMod]);

  const openModalHandler = () => {
    dispatch(modalInfo(true));
    setAddProductFieldShow(true);
  };
  const renderRow = ({ index, parent, key, style }) => {
    const DATA = allProducts[index];
    return (
      <CellMeasurer
        key={nanoid()}
        cache={cache}
        parent={parent}
        columnIndex={0}
        rowIndex={index}
      >
        <ProductListItem
          {...{
            categoryList,
            productItem: DATA,
            selectedCategory,
            // categoryNameList,
            addProductFieldShow,
            ActualVersion,
            itemsList,
            isModal,
            tegs,
            openModal,
            setOpenModal,
            setAloneProduct,
            aloneProduct,
            refForItemList,
            scrollsRef,
            styleList: style,
          }}
        />
      </CellMeasurer>
    );
  };
  // const categoryNameList = Object.values(categoryList).map((el) => el.name);
  return (
    <>
      <Modal
        className="modalStyle"
        onCancel={() => setModal(false)}
        visible={isModal}
        width={"auto"}
        footer={null}
        closable={false}
        centered={true}
        destroyOnClose={true}
      >
        <img
          src={Close}
          style={{
            position: "absolute",
            top: -100,
            left: "calc((100% - 36px) / 2)",
            cursor: "pointer",
          }}
          onClick={() => setModal(false)}
        />
        {/* {typeof itemsList === "string" ? ( */}
        <div className="modalPhoto">
          <img
            src={itemsList}
            style={{ height: 400, width: 600, margin: 25 }}
            onClick={() => setModal(false)}
          />
        </div>
        {/* )  */}
        {/* } */}
      </Modal>

      <ProductListWrapper>
        {adminMode ? (
          modProdHead.map((el, id) => {
            if (el.productItem.length > 0) {
              return (
                <>
                  <div
                    style={{
                      marginRight: "auto",
                      marginBottom: 16,
                      fontSize: 24,
                      fontWeight: 700,
                      color: " #161616",
                    }}
                  >
                    {el.name.ru}
                  </div>
                  {el.productItem.map((productItem) => {
                    return (
                      <ProductListItem
                        key={nanoid()}
                        {...{
                          id,
                          productItem,
                          // categoryNameList,
                          addProductFieldShow,
                          categoryList,
                          selectedCategory,
                          ActualVersion,
                          isModal,
                          tegs,
                          isModal,
                          itemsList,
                          openModal,
                          setOpenModal,
                          setAloneProduct,
                          aloneProduct,
                        }}
                      />
                    );
                  })}
                </>
              );
            }
          })
        ) : (countProd?.length > 0 && countProd?.includes(true)) ||
          adminMode ? (
          // <AutoSizer >
          // {({height,width})=>(
          //   <List  deferredMeasurementCache={cache} rowHeight={cache.rowHeight}  className="list"  width={width} height={height} rowCount={allProducts?.length} rowRenderer={renderRow}
          //    />
          //    )}
          // </AutoSizer>

          allProducts.map((productItem) => {
            return (
              <ProductListItem
                {...{
                  categoryList,
                  productItem,
                  selectedCategory,
                  // categoryNameList,
                  addProductFieldShow,
                  ActualVersion,
                  itemsList,
                  isModal,
                  tegs,
                  openModal,
                  setOpenModal,
                  setAloneProduct,
                  aloneProduct,
                  refForItemList,
                  scrollsRef,
                  // styleList:style
                }}
              />
            );
          })
        ) : (
          <NoProd>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <img
                style={{ width: 147, height: 98, alignSelf: "center" }}
                src={NoKitc}
              />
              <div
                style={{
                  fontSize: 24,
                  fontWeight: 800,
                  color: "#2C2E32",
                  marginTop: 25,
                }}
              >
                В этой категории пока нет товаров
              </div>
              <div
                style={{
                  fontSize: 16,
                  fontWeight: 400,
                  color: "#A6A6A6",
                  alignSelf: "center",
                  marginTop: 1,
                }}
              >
                Добавьте первый товар,
                <br /> это не займёт много времени
              </div>
              <button onClick={openModalHandler}>
                <span>+</span> Добавить товар
              </button>
            </div>
          </NoProd>
        )}

        {
          <AnimatePresence initial={false}>
            openModal &&
            <ModalInfo
              {...{
                tegs,
                aloneProduct,
                selectedCategory,
                aloneProductActual,
                addProductFieldShow,
                setAddProductFieldShow,
                productList,
                refForItemList,
                scrollsRef,
                refForAddProd,
                ActualVersion,
                isModal,
                setIdForNewProduct,
              }}
            />
          </AnimatePresence>
        }
      </ProductListWrapper>
    </>
  );
}

export default memo(ProductList);

const ProductListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
const NoProd = styled.div`
  display: flex;
  align-self: center;
  justify-content: center;
  height: 100%;
  align-items: start;
  justify-self: center;
  button {
    cursor: pointer;
    padding: 14px 20px;
    background: #7882a7;
    border-radius: 8px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    border: none;
    color: #ffffff;
    align-self: center;
    width: 40%;
    margin-top: 16px;

    :hover {
      background: #606690;
    }
    :active {
      background: #858ec8;
    }
  }
`;

const Text = styled.div`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #999999;
`;
