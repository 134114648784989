import React, {
  Suspense,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import "./App.css";
import styled from "styled-components";
import * as _ from "underscore";
import moment from "moment";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { BrowserRouter as Router, Link, useLocation } from "react-router-dom";
import ActivateAccount from "./components/ActivateAccount";
import RecoveryPass from "./components/RecoveryPass";
import LoginForm from "./components/LoginForm";
import Header from "./layout/Header";
import Sidebar from "./layout/Sidebar";
import Content from "./layout/Content";
import AddKitchenModal from "./AddKitchenModal";
import {
  getKitchensAction,
  getPermissionsAction,
} from "./reducers/toolkit/mainAppReducer";
import {
  activateAccountAction,
  activatePasswordAction,
  createTokenByPasswordAction,
  getClientProfile,
  recoveryPasswordAction,
  registrationByEmailAction,
} from "./reducers/toolkit/profileReducer";
import { incomingCallMessage, hideCallMessage } from "./actions/ModalActions";
import PhoneImg from "./images/callNotification/phone_number.svg";
import BagImg from "./images/callNotification/orders_count.svg";
import GreyPinImg from "./images/callNotification/address.svg";
import GreenPinImg from "./images/callNotification/address_active.svg";
import Close from "./images/close.svg";
import ErrorBoundary from "./ErrorBoundary";

//utils funcs
export const checkIsSuperAdm = (isAuth, permissions) => {
  if (isAuth && permissions.staff) {
    if (
      permissions.staff.role === "admin" &&
      permissions.permissions.length === 0
    ) {
      return true;
    }
  }
  return false;
};

export const checkRole = (isAuth, permissions, param) => {
  if (isAuth && permissions.staff) {
    if (permissions.staff.role === "admin" && !permissions.permissions.length) {
      return true;
    } else {
      return permissions.permissions.includes(param);
    }
  } else {
    return false;
  }
};

const testPath = [
  {
    name: "Kitchens",
    path: [
      "/dashboard",
      "/assortment",
      "/financial",
      "/reviews",
      "/complex",
      "/assembly",
      "/orders",
      "/menu_by_day",
      "/setting",
    ],
    nameText: "Кухни",
  },

  {
    name: "Settings",
    path: [
      "/mainSetting",
      "/stocks",
      "/startnotif",
      "/aboutKitchen",
      "/additional",
      "/reportsSetting",
    ],
    nameText: "Настройки",
  },

  {
    name: "Delivery",
    path: ["/map", "/delManagment"],
    nameText: "Управление доставкой",
  },

  {
    name: "Clients",
    path: ["/client-card", "/findClients", "/addresses", "/corpClients"],
    nameText: "Клиенты",
  },

  {
    name: "Financial",
    path: ["/wallets", "/promocodes", "/push"],
    nameText: "Маркетинг/Финансы",
  },

  {
    name: "Partners",
    path: ["/statistics", "/financialRep", "/reports"],
    nameText: "Партнеры",
  },

  { name: "Support", path: ["/supports"], nameText: "Клиентская поддержка" },

  { name: "Moderation", path: ["/moderation"], nameText: "Модерация товаров" },

  {
    name: "Service",
    path: ["/workweek", "/managment", "/version"],
    nameText: "Настройки сервиса",
  },
];

const App = () => {
  const dispatch = useDispatch();

  const {isAuth, registerStatus} = useSelector((store) => store.profile);
  const tempArrayModal = useSelector(
    (store) => store.modal.temporaryStorageFacility
  );
  const addNewKitchensModal = useSelector(
    (store) => store.appMain.addNewKitchensModal
  );

  const callsArrayModal = useSelector(
    (store) => store.modal.temporaryStorageFacility2
  );
  const getPermissionsSuccess = useSelector(
    (store) => store.appMain.getPermissionsSuccess
  );

  const [isWsPaused, setIsWsPaused] = useState(false);
  const [wsStatus, setWsStatus] = useState("");

  // const [path, setPath] = useState({});

  const [path, setPath] = useState((prev) => {
    //   if (window.location.pathname === "/dashboard" ) {
    //     return { path: "Kitchens", text: "Кухни" };
    //   }
    //   // if (window.location.pathname === "/") {
    //   //   return { path: "Kitchens", text: "Кухни" }
    //   // }
    let pathLocal = testPath.find((el) =>
      el.path.includes(window.location.pathname)
    );
    if (pathLocal) {
      return { path: pathLocal.name, text: pathLocal.nameText };
    }
  });

  const ws = useRef(null);
  useEffect(() => {
    dispatch(getClientProfile());
    if (isAuth && !getPermissionsSuccess) {
      dispatch(getPermissionsAction());
    }
  }, [isAuth]);
  
  useEffect(() => {
    dispatch(getClientProfile());
    if (isAuth && !getPermissionsSuccess) {
      dispatch(getKitchensAction());
    }
  }, [isAuth]);
 

  useEffect(() => {
    if (!isWsPaused) {
      const token = window.localStorage.getItem("token") || "null";
      ws.current = new WebSocket(`wss://menuforme.online:8001/?token=${token}`); // создаем ws соединение
      ws.current.onopen = () => setWsStatus("Соединение открыто"); // callback на ивент открытия соединения
      ws.current.onclose = () => setWsStatus("Соединение закрыто"); // callback на ивент закрытия соединения

      gettingData();
    }

    return () => ws.current.close(); // когда меняется isWsPaused - соединение закрывается
  }, [ws, isWsPaused]);

  const gettingData = useCallback(() => {
    if (!ws.current) return;

    ws.current.onmessage = (e) => {
      //подписка на получение данных по вебсокету
      if (isWsPaused) return;
      const message = JSON.parse(e.data);

      if (message && message.incomingCall) {
        dispatch(incomingCallMessage(message.incomingCall));
      }
    };
  }, [isWsPaused]);

  return (
    <>
      {/*main layout*/}
      <Router>
        <AppWrapper>
          {isAuth ? (
            <>
              <Header {...{ path, setPath, testPath }} />
              <Sidebar {...{ path, setPath, testPath }} />
              <Content {...{ isAuth, path }} />
              {addNewKitchensModal && <AddKitchenModal />}
            </>
          ) : (
            <>
              {window.location.href.includes("sys/signup") ? (
                <ActivateAccount
                  open={!isAuth}
                  registerStatus={registerStatus}
                  onClose={() => {}}
                  activateAccount={(values, params) =>
                    dispatch(activateAccountAction({ ...values, ...params }))
                  }
                />
              ) : window.location.href.includes("sys/recoverypass") ? (
                <RecoveryPass
                  open={!isAuth}
                  onClose={() => {}}
                  activatePassword={(value) =>
                    dispatch(activatePasswordAction(value))
                  }
                />
              ) : (
                <LoginForm
                  open={!isAuth}
                  onClose={() => {}}
                  createTokenByPassword={(values) =>
                    dispatch(createTokenByPasswordAction(values))
                  }
                  registrationByEmail={(value) =>
                    dispatch(registrationByEmailAction(value))
                  }
                  recoveryPassword={(value) =>
                    dispatch(recoveryPasswordAction(value))
                  }
                />
              )}
            </>
          )}
        </AppWrapper>

        {/*modal*/}

        <PreviewActionUser2 id="callNotifs">
          {_.map(callsArrayModal, (item) => {
            return (
              <CSSTransition
                key={parseInt(item.actionId)}
                timeout={300}
                classNames="calls"
                in={true}
              >
                <WrapperCallNotif>
                  <CloseModal
                    onClick={() => dispatch(hideCallMessage(item.actionId))}
                  >
                    <img
                      width={11}
                      height={11}
                      style={{ color: "#000000" }}
                      src={Close}
                      alt="x"
                    />
                  </CloseModal>
                  <PhoneRow>
                    <PhoneNumberImg src={PhoneImg} />
                    <PhoneNumber clickable={item.client}>
                      {item.client ? (
                        <Link
                          to={"/client-card/?=" + item.phone}
                          target={"_blank"}
                        >
                          {item.phone}
                        </Link>
                      ) : (
                        item.phone
                      )}
                    </PhoneNumber>
                    <CallTime>{item.time}</CallTime>
                  </PhoneRow>
                  <ClientRow>
                    {item.client ? (
                      <ClientName>{item.clientName}</ClientName>
                    ) : (
                      <NotClient>Не пользователь Сытого</NotClient>
                    )}
                    <OrdersImg src={BagImg} />
                    <OrdersCount>
                      {_.size(item.ordersCount) !== 0
                        ? item.ordersCount.reduce((acc, cur) => {
                            return acc + +cur.ordersCount;
                          }, 0)
                        : "0"}
                    </OrdersCount>
                  </ClientRow>
                  {_.size(item.Address) !== 0 ? (
                    <Addresses>
                      {_.map(item.Address, (i) => {
                        return (
                          <Address key={"address_" + i.id}>
                            <PinImg
                              src={
                                i.id === item.activeCompanyId
                                  ? GreenPinImg
                                  : GreyPinImg
                              }
                            />
                            <AddressText active={i.id === item.activeCompanyId}>
                              {i.id} • {i.name ? i.name.trim() + " • " : ""}
                              {i.cityName}, {i.street}, {i.house}
                            </AddressText>
                          </Address>
                        );
                      })}
                    </Addresses>
                  ) : null}
                  {_.size(item.orders) !== 0 ? (
                    <Orders>
                      {_.map(item.orders, (i) => {
                        return (
                          <Order key={"order_" + i.id}>
                            <OrderInfo>
                              <RestName>{i.restName}</RestName>
                              <PriceResult>{+i.priceResult} ₽</PriceResult>
                            </OrderInfo>
                            <OrderTime done={i.status === "67108864"}>
                              {i.status === "67108864"
                                ? moment(i.timeDelivery).format(
                                    "DD.MM.YYYY • HH:mm"
                                  )
                                : moment(i.dateTo).format("DD.MM.YYYY")}
                            </OrderTime>
                          </Order>
                        );
                      })}
                    </Orders>
                  ) : (
                    <NoOrders>Ещё нет заказов</NoOrders>
                  )}
                </WrapperCallNotif>
              </CSSTransition>
            );
          })}
        </PreviewActionUser2>
      </Router>

      {/*modal*/}
      <PreviewActionUser id="previewActionUser">
        {_.map(tempArrayModal, (item) => {
          return (
            <CSSTransition
              key={parseInt(item.actionId)}
              timeout={300}
              classNames="invite_users"
              in={true}
            >
              <WrapperPreview color={item.color}>
                <PreviewTitle messTitle={item.messTitle}>
                  {item.messTitle}
                </PreviewTitle>
                <PreviewText>{item.messText}</PreviewText>
              </WrapperPreview>
            </CSSTransition>
          );
        })}
      </PreviewActionUser>
    </>
  );
};

export default App;

const AppWrapper = styled.div`
  display: grid;
  height: 100vh;
  position: relative;
  /* grid-template:
    "sidebar header" 64px
    "sidebar content" 1fr / 64px 1fr; */
`;

const PreviewActionUser = styled(TransitionGroup)`
  position: fixed;
  bottom: 40px;
  left: 40px;
  display: flex;
  flex-direction: column-reverse;
  z-index: 9999;
  user-select: none;

  @media (max-width: 992px) {
    top: 13px;
    left: 19px;
    right: 19px;
    bottom: unset;
  }
`;

const PreviewActionUser2 = styled(TransitionGroup)`
  position: fixed;
  bottom: 40px;
  right: 40px;
  display: flex;
  flex-direction: column-reverse;
  z-index: 9999;
  user-select: none;

  @media (max-width: 992px) {
    bottom: 13px;
    left: 19px;
    right: 19px;
  }
`;

const WrapperPreview = styled.div`
  width: 240px;
  height: auto;
  margin-bottom: 5px;
  cursor: pointer;
  color: #fff;
  background: ${({ color }) => (color ? color : "rgba(0,0,0,0.74)")};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 5px;
  user-select: none;
  text-align: center;
  padding: 7px;

  @media (max-width: 992px) {
    width: 100%;
    min-height: 51px;
    margin-bottom: 13px;
    background: ${({ color }) => (color ? color : "rgba(38, 50, 56, 0.96)")};
    align-items: flex-start;
    border-radius: 10px;
    text-align: left;
    padding: 12px 19px;
  }

  &.invite_users-enter {
    opacity: 0;
    transform: translate(0, 75px);
    transition: max-height 200ms, opacity 200ms, transform 200ms;
    max-height: 0;

    @media (max-width: 992px) {
      transform: translate(0, 51px);
    }
  }

  &.invite_users-enter.invite_users-enter-active {
    opacity: 1;
    transform: translateX(0);
    max-height: 75px;
    transition: max-height 200ms, opacity 200ms, transform 200ms;

    @media (max-width: 992px) {
      max-height: auto;
    }
  }

  &.invite_users-exit {
    opacity: 1;
  }

  &.invite_users-exit.invite_users-exit-active {
    opacity: 0;
    transform: translate(0, -75px);
    transition: all 200ms linear;
    margin-top: -75px;
    margin-bottom: 0;

    @media (max-width: 992px) {
      margin-top: -51px;
      transform: translate(0, -51px);
    }
  }
`;

const PreviewTitle = styled.div`
  font-size: 17px;
  font-weight: 600;
  font-family: "Roboto", sans-serif;

  @media (max-width: 992px) {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    margin-bottom: ${({ messTitle }) => (messTitle ? "8px" : "0")};
  }
`;

const PreviewText = styled.div`
  font-size: 14px;
  font-family: "Arsenal", sans-serif;

  @media (max-width: 992px) {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 15px;
  }
`;

const WrapperCallNotif = styled.div`
  position: relative;
  width: 320px;
  height: auto;
  margin-bottom: 5px;
  /*cursor: pointer;*/
  background: #ffffff;
  padding: 16px;
  border-radius: 8px;
  user-select: none;
  box-shadow: 0px 0px 4px;

  /*@media(max-width: 992px){
		width: 100%;
		min-height: 51px;
		margin-bottom: 13px;
		background: ${({ color }) => (color ? color : "rgba(38, 50, 56, 0.96)")};
		align-items: flex-start;
		border-radius: 10px;
		text-align: left;
		padding: 12px 19px;
  	}*/

  &.calls-enter {
    opacity: 0;
    transform: translate(0, 75px);
    transition: max-height 200ms, opacity 200ms, transform 200ms;
    max-height: 0;

    @media (max-width: 992px) {
      transform: translate(0, 51px);
    }
  }

  &.calls-enter.calls-enter-active {
    opacity: 1;
    transform: translateX(0);
    max-height: 75px;
    transition: max-height 200ms, opacity 200ms, transform 200ms;

    @media (max-width: 992px) {
      max-height: auto;
    }
  }

  &.calls-exit {
    opacity: 1;
  }

  &.calls-exit.calls-exit-active {
    opacity: 0;
    transform: translate(0, -75px);
    transition: all 200ms linear;
    margin-top: -75px;
    margin-bottom: 0;

    @media (max-width: 992px) {
      margin-top: -51px;
      transform: translate(0, -51px);
    }
  }
`;

const PhoneRow = styled.div`
  display: flex;
  align-items: center;
`;

const PhoneNumberImg = styled.img`
  margin-right: 12px;
`;

const PhoneNumber = styled.p`
  margin-bottom: 0;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #40a9ff;
  cursor: ${({ clickable }) => (clickable ? "pointer" : "auto")};
`;

const CallTime = styled.p`
  margin-left: auto;
  margin-bottom: 0;
  font-family: "Roboto", sans-serif;
  font-size: 10px;
  line-height: 13px;
  color: #a4a4a4;
`;

const ClientRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
`;

const NotClient = styled.p`
  margin-right: auto;
  margin-bottom: 0;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  line-height: 18px;
  color: #a4a4a4;
`;

const ClientName = styled.p`
  margin-right: auto;
  margin-bottom: 0;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  line-height: 18px;
  color: #2c2e32;
`;

const OrdersImg = styled.img`
  margin-right: 5px;
`;

const OrdersCount = styled.p`
  margin-bottom: 0;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  line-height: 18px;
  color: #2c2e32;
`;

const Addresses = styled.div`
  margin-top: 16px;
`;

const Address = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const PinImg = styled.img`
  margin-right: 10px;
  margin-top: 4px;
`;

const AddressText = styled.p`
  margin-bottom: 0;
  font-family: "Roboto", sans-serif;
  font-weight: ${({ active }) => (active ? "500" : "400")};
  font-size: 14px;
  line-height: 18px;
  color: ${({ active }) => (active ? "#54933F" : "#2C2E32")};
`;

const Orders = styled.div`
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid #f1f1f1;
`;

const NoOrders = styled.p`
  margin-bottom: 0;
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid #f1f1f1;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  line-height: 18px;
  color: #a4a4a4;
`;

const Order = styled.div`
  margin-bottom: 6px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const OrderInfo = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
`;

const OrderTime = styled.p`
  margin-bottom: 0;
  font-family: "Roboto", sans-serif;
  font-weight: ${({ done }) => (done ? "500" : "400")};
  font-size: 10px;
  line-height: 13px;
  color: ${({ done }) => (done ? "#54933F" : "#A4A4A4")};
`;

const RestName = styled.p`
  max-width: calc(100% - 60px);
  margin-bottom: 0;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  line-height: 18px;
  color: #2c2e32;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const PriceResult = styled.p`
  margin-bottom: 0;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #54933f;
`;
const CloseModal = styled.div`
  position: absolute;
  top: -16px;
  left: 305px;
  width: 30px;
  height: 30px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  box-shadow: 0px 0px 2px;
  cursor: pointer;
`;
