import React,{useState,useEffect,useRef} from 'react'
import styled from "styled-components";
import { sr } from '../../../utils';
import moment from "moment";
import { useDispatch, useSelector } from 'react-redux';
import {deliveryCycleInfoMessage} from '../../../actions/ModalActions'
import { Btn } from '../UI/Btn';
export const ReportsItem = ({infoKithens,selectedKithens}) => {
    const [UrName,setUrName] = useState('')
    const [Inn,setInn] = useState('')
    const [Kpp,setKpp] = useState('')
    const [UrAddres,setUrAddres] = useState('')
    const [FactAddres,setFactAddres] = useState('')
    const [Initial,setInitial] = useState('')
    const [Email,setEmail] = useState('')
    const [Phone,setPhone] = useState('')
    const [PayAcc,setPayAcc] = useState('')
    const [Bic,setBic] = useState('')
    const [CorpAcc,setCorpAcc] = useState('')
    const [Bank,setBanc] = useState('')
    const [NumbAcc,setNumbAcc] = useState('')
    const [DateAcc,setDateAcc] = useState('')
    const SelectedKitchens = useSelector(store => store.appMain.selectedKitchens)

    const dispatch = useDispatch()
    const testRef = useRef(null)
    useEffect(()=>{
        if(infoKithens){
            if('INN' in infoKithens){
     infoKithens.UrName &&(setUrName(infoKithens.UrName))
     infoKithens.INN &&(setInn(infoKithens.INN))
     infoKithens.KPP &&(setKpp(infoKithens.KPP))
     infoKithens.UrAddres && (setUrAddres(infoKithens.UrAddres))
     infoKithens.FactAddres && (setFactAddres(infoKithens.FactAddres))
     infoKithens?.contactPerson?.name &&  (setInitial(infoKithens.contactPerson.name))
     infoKithens?.contactPerson?.email  && (setEmail(infoKithens.contactPerson.email ))
     infoKithens?.contactPerson?.phone  && (setPhone(infoKithens.contactPerson.phone ))
     infoKithens?.bank?.accAt && (setPayAcc(infoKithens.bank.accAt))
     infoKithens?.bank?.RCBIC && (setBic(infoKithens.bank.RCBIC ))
     infoKithens?.bank?.corrAcc && (setCorpAcc(infoKithens.bank.corrAcc))
     infoKithens.Bank && (setBanc(infoKithens.Bank))
     infoKithens.NumbAcc && (setNumbAcc(infoKithens.NumbAcc))
     infoKithens.DateAcc && (setDateAcc(infoKithens.DateAcc))
            }else{
    infoKithens.name &&(setUrName(infoKithens.name))
    infoKithens.inn &&(setInn(infoKithens.inn))
    infoKithens.kpp &&(setKpp(infoKithens.kpp))
    infoKithens.addres && (setUrAddres(infoKithens.addres))
    infoKithens.FactAddres && (setFactAddres(infoKithens.FactAddres))
    infoKithens?.contactPerson?.name &&  (setInitial(infoKithens.contactPerson.name))
    infoKithens?.contactPerson?.email  && (setEmail(infoKithens.contactPerson.email ))
    infoKithens?.contactPerson?.phone  && (setPhone(infoKithens.contactPerson.phone ))
    infoKithens?.bank?.accAt && (setPayAcc(infoKithens.bank.accAt))
    infoKithens?.bank?.RCBIC && (setBic(infoKithens.bank.RCBIC ))
    infoKithens?.bank?.corrAcc && (setCorpAcc(infoKithens.bank.corrAcc))
    infoKithens.Bank && (setBanc(infoKithens.Bank))
    infoKithens.NumbAcc && (setNumbAcc(infoKithens.NumbAcc))
    infoKithens.DateAcc && (setDateAcc(infoKithens.DateAcc))
            }
        }
    },[])
    const saveReq=()=>{
        sr('SettingsServiceSO','SaveRequisites',{
        account:SelectedKitchens.id,
        requisites:{
             INN:Inn,
             KPP:Kpp,
             bank:{accAt:PayAcc,RCBIC:Bic,corrAcc:CorpAcc},
             contactPerson:{
                 name:Initial,
                 email:Email,
                 phone:Phone,
             },
             UrName:UrName,
             UrAddres:UrAddres,
             FactAddres:FactAddres,
             Bank:Bank,
             NumbAcc:NumbAcc,
             DateAcc:moment(DateAcc).format('YYYY-MM-DD')
            },
        }).then(res=>{
            if(res.result){
                dispatch(deliveryCycleInfoMessage('succesDelete_ClientCard'))
                console.log(res)
            }else{
                dispatch(deliveryCycleInfoMessage('error_ClientCard'))
                console.log(res,'ERROR')

            }
        })
    }
 
  return (
      <>
    <ContentBlock>
    <ItemsBlock>
    <TextItems>Юридическое название</TextItems>  
    <InputItems ><input value={UrName} onChange={e=>setUrName(e.target.value)} type='text' size={20}  /></InputItems>
    </ItemsBlock>
    <ItemsBlock>
   <TextItems> ИНН</TextItems> 
   <InputItems>
       <input value={Inn} onChange={e=>setInn(e.target.value)} type='number'/>
  </InputItems>
   </ItemsBlock>
    <ItemsBlock>
    <TextItems>КПП</TextItems> 
        <InputItems>
            <input value={Kpp} onChange={e=>setKpp(e.target.value)} type='number'/>
        </InputItems>
    </ItemsBlock>
    <ItemsBlock>
        <TextItems>Юридический адрес</TextItems> 
        <InputItems>
        <input value={UrAddres} onChange={e=>setUrAddres(e.target.value)} type='text'/></InputItems>
    </ItemsBlock>
    <ItemsBlock>
    <TextItems> Адрес фактический</TextItems> 
    <InputItems>
        <input value={FactAddres} onChange={e=>setFactAddres(e.target.value)} type='text'/>
    </InputItems>
    </ItemsBlock>
    <ItemsBlock>
    <TextItems> ФИО контактного лицa</TextItems> 
    <InputItems>
        <input value={Initial} onChange={e=>setInitial(e.target.value)} type='text'/>
    </InputItems>
    </ItemsBlock>
    <ItemsBlock>
    <TextItems>Email контактного лица</TextItems> 
    <InputItems>
        <input value={Email} onChange={e=>setEmail(e.target.value)}/>
    </InputItems>
    </ItemsBlock>
    <ItemsBlock>
      <TextItems>Tелефон контактного лица</TextItems> 
    <InputItems>
        <input value={Phone} onChange={e=>setPhone(e.target.value)} />
    </InputItems>  
    </ItemsBlock>
    
    <ItemsBlock>
    <TextItems>Расчетный счет</TextItems> 
        <InputItems>
            <input value={PayAcc} onChange={e=>setPayAcc(e.target.value)} type='number' size={20}/>
        </InputItems>
    </ItemsBlock>
    <ItemsBlock>
    <TextItems>
        БИК
    </TextItems> 
        <InputItems>
            <input value={Bic} onChange={e=>setBic(e.target.value)}/>
        </InputItems>
    </ItemsBlock>
    
    <ItemsBlock>
      <TextItems>Корр.счет</TextItems> 
      <InputItems>
          <input value={CorpAcc} onChange={e=>setCorpAcc(e.target.value)} type='number'/>
        </InputItems>
    </ItemsBlock>
    <ItemsBlock>
    <TextItems>Название банк партнёра</TextItems> 
    <InputItems>
        <input value={Bank} onChange={e=>setBanc(e.target.value)}/></InputItems>
    </ItemsBlock>
    <ItemsBlock>
   <TextItems> № договора</TextItems> 
   <InputItems>
       <input value={NumbAcc} onChange={e=>setNumbAcc(e.target.value)}/></InputItems>    
    </ItemsBlock>
    <ItemsBlock>
     <TextItems>Дата договора </TextItems> 
    <InputItems>
         <input value={moment(DateAcc).format('YYYY-MM-DD')}   onChange={e=>setDateAcc(e.target.value)} type='date' />
      </InputItems>
    </ItemsBlock>
</ContentBlock>
 {/* <WrappBtn  onClick={saveReq}>
 Сохранить изменения
</WrappBtn> */}
<Btn
handler={saveReq}
>
Сохранить изменения
</Btn>
</>
  )
}

const ContentBlock = styled.div`
display: grid;
grid-template-rows: auto;
margin-top: 25px;
`
const ItemsBlock = styled.div`
display: grid;
grid-template-columns: 1fr auto;
`
const TextItems = styled.div`
text-align: start;
margin-bottom:10px;
margin-right: 25px;
font-weight: 600;
font-size: 18px
`
const InputItems = styled.div`
margin-bottom:20px;
 input{
     min-width: 450px;
     min-height: 30px;
     border-radius: 6px;
     border: none;
     box-shadow: 0px 0px 2px 2px rgba(206, 206, 206,0.5);
 }
`
const NavItem = styled.div`
border: 2px solid #7882A7;
background-color: ${({isSelect})=>isSelect ? '#7882A7': '#FFFF'};
/* background-color: #FFFF; */
color:${({isSelect})=>isSelect ?'#FFFF':'#111111'};
border-radius: 6px;
margin-right: 10px;
display: flex;
justify-content: center;
align-items: center;
font-weight: 600;
font-size: 16px;
height: 50px;
cursor: pointer;
    :hover{
        background-color: #7882A7;
        color:#FFFF;
    }
`
const WrappBtn = styled(NavItem)`
background-color:#7882A7;
margin-bottom: 15px;
align-items: flex-start;
justify-self: flex-start;
color:#FFFF;
padding: 15px;
align-items: center;
 :hover{
     color:#111111;
 }
`