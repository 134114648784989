// import '../../../../wdyr'
import { DatePicker, Input, Switch, TimePicker, Upload } from "antd";
import React, {
  memo,
  useEffect,
  useState,
  useMemo,
  useRef,
  useCallback,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import Close from "../../../../images/close.svg";
import { useBase } from "../../../../hooks/useBase";
import { deliveryCycleInfoMessage } from "../../../../actions/ModalActions";
import UploadSucces from "../../../../images/assort/Done_ring_round_light.svg";
import Pack2 from "../../../../images/assort/Frame 2008.svg";
import ImageContainer from "../ImageUI/ImageContainer";
import Lock from "../../../../images/assort/Lock_light.svg";
import WithErr from "../../../../images/assort/Ассортимент/Alarm_light.svg";
import Check from "../../../../images/assort/Ассортимент/ORANGEsvgCheck.svg";
import GRAY from "../../../../images/assort/Ассортимент/GRAY.svg";

import moment from "moment";
import {
  addProductToSelect,
  changeStateProduct,
  deleteProduct,
  modalInfo,
  removeProductFromSelect,
  saveNewProductStarted,
  saveProductChangesStarted,
} from "../../../../actions/AssortmentAction";
import HeaderModalUI from "./UI/HeaderModalUI";
import { sr } from "../../../../utils";
import { motion } from "framer-motion";
const ModalInfo = ({
  // openModal,
  setOpenModal,
  aloneProduct,
  tegs,
  aloneProductActual,
  addProductFieldShow,
  setAddProductFieldShow,
  productList,
  refForItemList,
  scrollsRef,
  refForAddProd,
  ActualVersion,
  isModal,
  setDelStart,
  setIdForNewProduct,
}) => {
  const [selectedParams, setSelectedParams] = useState("Main");
  const [files, setFiles] = useBase();
  const [tagId, setTegId] = useState([]);
  const [chooseVariant, setChooseVariant] = useState("NEW");
  const [productName, setProductName] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [productPrice, setProductPrice] = useState("");
  const [productCalories, setProductCalories] = useState("");
  const [productFats, setProductFats] = useState("");
  const [productCarbo, setProductCarbo] = useState("");
  const [productProteins, setProductProteins] = useState("");
  const [productWeight, setProductWeight] = useState("");
  const [getsPhoto, setGetsPhoto] = useState();
  const [disButton, setDisButton] = useState(false);
  const [temporaryPic, setTemporaryPic] = useState();
  const [stateBase, setStateBase] = useState();
  const [stateMedia, setStateMedia] = useState();
  const [chooseDel, setChooseDel] = useState(false);
  const openModal = useSelector((store) => store.assortment.modalInfo);

  const [switchLocal, setSwitchLocal] = useState(false);
  const [combination, setCombination] = useState([]);
  const [stateForEvent, setForEvent] = useState(false);
  const refForModal = useRef(null);
  const [customSel, setCustomSel] = useState(false);
  const ALONE = useMemo(
    () =>
      chooseVariant === "NEW" || !chooseVariant
        ? aloneProduct
        : aloneProductActual,
    [chooseVariant, openModal, aloneProduct, aloneProductActual, switchLocal]
  );
  // const [EquialAlone,setEquialAlone] = useState()
  const SelectedKitchens = useSelector(
    (store) => store.appMain.selectedKitchens
  );
  const selectedCategory = useSelector(
    (store) => store.assortment.selectedCategory
  );
  const dispatch = useDispatch();
  const tegList = useSelector((store) => store.assortment.tegList);

  const Err = ALONE?.moderate_status === "3";
  const ACTUALMODE = chooseVariant === "ACTUAL";
  const EquialAlone = (eq) =>
    !addProductFieldShow &&
    String(aloneProduct[eq]) === String(aloneProductActual[eq]);

  function closeEvent(e){
    if(openModal){
      if(!e.path.includes(refForModal?.current) && !e.path.includes(scrollsRef?.current) && !e.path.includes(refForAddProd.current)){
        closeModal()
        }
    }
  }

  useEffect(()=>{
    document.addEventListener('mousedown',closeEvent)
    return ()=> document.removeEventListener('mousedown',closeEvent)
    },[disButton])

  useEffect(()=>{
    closeModal()
  },[stateForEvent])

  const closeModal = () => {
    const close = () => {
      setAddProductFieldShow(false);
      // setOpenModal(false);
      dispatch(modalInfo(false));
    };
    if (disButton) {
      if (window.confirm("Есть не сохраненные изменения. Закрыть?")) {
        close();
      }
    } else {
      close();
    }
  };

  const checkChangeHandler = () => {
    const diffTags = tagId?.map((el) => {
      if (aloneProduct?.tegs_product && !addProductFieldShow) {
        if (!aloneProduct?.tegs_product?.includes(el)) {
          return true;
        }
      }
    });
    if (openModal && chooseVariant === "NEW" && !addProductFieldShow) {
      if (
        (aloneProduct?.name &&
          String(aloneProduct?.name) !== String(productName)) ||
        (aloneProduct?.description &&
          String(aloneProduct?.description) !== String(productDescription)) ||
        (aloneProduct?.calories &&
          String(aloneProduct?.calories) !== String(productCalories)) ||
        (aloneProduct?.fats &&
          String(aloneProduct?.fats) !== String(productFats)) ||
        (aloneProduct?.carbo &&
          String(aloneProduct?.carbo) !== String(productCarbo)) ||
        (aloneProduct?.weight &&
          String(aloneProduct?.weight) !== String(productWeight)) ||
        (aloneProduct?.price &&
          String(aloneProduct?.price) !== String(productPrice)) ||
        (aloneProduct?.proteins &&
          String(aloneProduct?.proteins) !== String(productProteins)) ||
        diffTags.includes(true) ||
        getsPhoto
      ) {
        setDisButton(true);
      }
    }
  };
  useEffect(() => {
    setSwitchLocal(ALONE?.isEnabled === "0");
  }, [chooseVariant, ALONE, aloneProductActual]);

  useEffect(() => {
    refForModal.current.scrollTo(0, 0);
  }, [ALONE]);

  useEffect(() => {
    if (ALONE && openModal) {
      checkChangeHandler();
      // && !aloneProduct.historyModeration
    }
    return () => setDisButton(false);
  }, [
    productName,
    productDescription,
    productCalories,
    productFats,
    productCarbo,
    productProteins,
    tagId,
    getsPhoto,
    // files,
    productPrice,
    productWeight,
    openModal,
  ]);

  useEffect(() => {
    if (addProductFieldShow) {
      setDisButton(true);
    }
  }, [addProductFieldShow]);

  useEffect(() => {
    if (openModal) {
      ALONE?.base64 && setStateBase(addProductFieldShow ? "" : ALONE?.base64);
      ALONE?.media && setStateMedia(addProductFieldShow ? "" : ALONE?.media);
      ALONE?.temporary_picture &&
        setTemporaryPic(
          addProductFieldShow ? "" : ALONE.temporary_picture[0]?.name
        );
    }
    return () => clearPhtotState();
  }, [aloneProduct, aloneProductActual, openModal, chooseVariant]);

  useEffect(() => {
    if (openModal) {
      setProductName(addProductFieldShow ? "" : ALONE?.name);
      setProductPrice(addProductFieldShow ? "" : ALONE?.price);
      setProductWeight(addProductFieldShow ? "" : ALONE?.weight);
      setProductCalories(addProductFieldShow ? "" : ALONE?.calories);
      setProductProteins(addProductFieldShow ? "" : ALONE?.proteins);
      setProductFats(addProductFieldShow ? "" : ALONE?.fats);
      setProductCarbo(addProductFieldShow ? "" : ALONE?.carbo);
      setProductDescription(addProductFieldShow ? "" : ALONE?.description);
    }
  }, [
    aloneProduct,
    aloneProductActual,
    openModal,
    chooseVariant,
    addProductFieldShow,
    switchLocal,
  ]);

  useEffect(() => {
    if (ALONE?.tegs_product && !addProductFieldShow) {
      if (!tagId.includes(ALONE?.tegs_product)) {
        setTegId(ALONE?.tegs_product);
      }
    } else {
      setTegId([]);
    }
  }, [ALONE?.tegs_product, addProductFieldShow]);


  function disAndEnProduct(val) {
    const setStore = (mod, enab, data) => {
      if (mod === "forMod") {
        dispatch(
          changeStateProduct({
            ids: data,
            enables: val.enab,
            mod: "forMod",
            id_mod: ALONE.id_product,
          })
        );
        dispatch(
          deliveryCycleInfoMessage(
            "modeCustom",
            enab === 0 ? "Товар включен" : "Товар выключен"
          )
        );
        setSwitchLocal(enab === 0 ? true : false);
      } else {
        dispatch(
          changeStateProduct({
            ids: data,
            enables: val.enab,
            mod: "NotMod",
          })
        );
        dispatch(
          deliveryCycleInfoMessage(
            "modeCustom",
            enab === 0 ? "Товар включен" : "Товар выключен"
          )
        );
        setSwitchLocal(enab === 0 ? true : false);
      }
    };
    // res.data.data.id
    if (val.flags === "mod") {
      // console.log('1');
      if (val.enab === "0") {
        // console.log('2');
        sr("MenuSOKitchenService", "enableProduct", {
          id:
            ALONE.moderate_status === "1" || ALONE.moderate_status === "2"
              ? ALONE.id_product
              : ALONE.id,
          account: SelectedKitchens.id,
        }).then((res) =>
          res.result
            ? setStore("forMod", 0, res.data.data.id)
            : console.log("disAndEnProduct err")
        );
      } else {
        // console.log('3');
        if (ALONE.moderate_status === "2") {
          sr("MenuSOKitchenService", "disableProduct", {
            id: ALONE.id_product,
            account: SelectedKitchens.id,
          }).then((res) =>
            res.result
              ? setStore("forMod", 1, res.data.data.id)
              : console.log("disAndEnProduct err")
          );
        }
      }
      if (ALONE.moderate_status === "1" || "moderate_status" in ALONE) {
        // console.log('4');
        sr("MenuSOKitchenService", "disableProduct", {
          id: ALONE.id,
          account: SelectedKitchens.id,
        }).then((res) =>
          res.result
            ? setStore("forMod", 1, res.data.data.id)
            : console.log("disAndEnProduct err")
        );
      } else {
        // console.log('5');
        sr("MenuSOKitchenService", "disableProduct", {
          id: ALONE.id,
          account: SelectedKitchens.id,
        }).then((res) =>
          res.result
            ? setStore("forMod", 1, res.data.data.id)
            : console.log("disAndEnProduct err")
        );
      }
    } else if (val.flags === "notMod") {
      // console.log('6');
      if (val.enab === "0") {
        // console.log('7');

        sr("MenuSOKitchenService", "enableProduct", {
          id: ALONE.moderate_status === "1" ? ALONE.id_product : ALONE.id,
          account: SelectedKitchens.id,
        }).then((res) =>
          res.result
            ? setStore("NotMod", 0, res.data.data.id)
            : console.log("disAndEnProduct err")
        );
      }
      if (val.enab === "1") {
        // console.log('8');
        if (ALONE.moderate_status === "2") {
          sr("MenuSOKitchenService", "disableProduct", {
            id: ALONE.id_product,
            account: SelectedKitchens.id,
          }).then((res) =>
            res.result
              ? setStore("NotMod", 1, res.data.data.id)
              : console.log("disAndEnProduct err")
          );
        }
        if (ALONE.moderate_status === "1" || "moderate_status" in ALONE) {
          // console.log('9');
          sr("MenuSOKitchenService", "disableProduct", {
            id: ALONE.id,
            account: SelectedKitchens.id,
          }).then((res) =>
            res.result
              ? setStore("NotMod", 1, res.data.data.id)
              : console.log("disAndEnProduct err")
          );
        } else {
          // console.log('10');
          sr("MenuSOKitchenService", "disableProduct", {
            id: ALONE.id,
            account: SelectedKitchens.id,
          }).then((res) =>
            res.result
              ? setStore("NotMod", 1, res.data.data.id)
              : console.log("disAndEnProduct err")
          );
        }
      }
    }
  }
  console.log(addProductFieldShow, "addProductFieldShow");
  const disAndEnable = () => {
    if ("id_product" in ALONE) {
      disAndEnProduct({
        enab: ALONE.isEnabled === "1" ? "0" : "1",
        flags: "mod",
      });
    } else {
      disAndEnProduct({
        enab: ALONE.isEnabled === "1" ? "0" : "1",
        flags: "notMod",
      });
    }
    // closeModal()
  };

  useEffect(() => {
    setGetsPhoto(files);
  }, [files]);

  const temps = () => {
    if (aloneProduct?.temporary_picture && aloneProduct?.base64) {
      return aloneProduct?.base64;
    }
    if (getsPhoto && aloneProduct?.base64) {
      return [getsPhoto];
    }
    if (aloneProduct?.temporary_picture) {
      return getsPhoto && [getsPhoto];
    }
    if (getsPhoto) {
      return [getsPhoto];
    }
    if (getsPhoto && aloneProduct?.media) {
      return [getsPhoto];
    }
    if (aloneProduct?.base64) {
      return aloneProduct?.base64;
    }
  };
  const forTemps = () => Boolean(aloneProduct?.temporary_picture);

  useEffect(() => {
    temps();
  }, [aloneProduct, getsPhoto]);

  function KeyHandler(e) {
    if (e.key === "Alt") {
      setCombination([e.key]);
    }
    if (e.key === "Enter") {
      setCombination((prev) => {
        if (prev[1] !== "Enter") {
          return [...prev, e.key];
        } else {
          return [];
        }
      });
    }
  }
  useEffect(() => {
    document.addEventListener("keydown", KeyHandler);
    return () => document.removeEventListener("keydown", KeyHandler);
  }, []);

  useEffect(() => {
    if (combination.length > 0 && openModal) {
      if (combination[0] === "Alt" && combination[1] === "Enter") {
        saveHandler();
      }
    }
  }, [combination]);

  const drawCategorySettingsList = () => {
    const categorySettingsList = tegs?.map((teg) => {
      const categoryIsActive = tagId.includes(teg.id);
      return (
        <div key={teg.id} style={{ display: "flex", paddingBottom: 25 }}>
          <input
            className="CHECKBOX"
            style={{ height: 18, width: 18, cursor: "pointer" }}
            type="checkbox"
            tabIndex={-1}
            checked={categoryIsActive}
            disabled={ACTUALMODE}
            onChange={() =>
              setTegId((prev) => {
                if (prev.includes(teg.id)) {
                  return prev.filter((el) => el !== teg.id);
                } else {
                  return [...prev, teg.id];
                }
              })
            }
          />
          <p
            style={{
              marginLeft: 8,
              color: chooseVariant === "ACTUAL" ? "#A6A6A6" : "#2C2E32",
            }}
          >
            {teg.location.ru_RU}
          </p>
        </div>
      );
    });
    return (
      <TagsProduct
        marginState={
          chooseVariant === "ACTUAL" ||
          addProductFieldShow ||
          (aloneProduct && !"id_product" in aloneProduct)
        }
      >
        {categorySettingsList}
      </TagsProduct>
    );
  };

  function deleteHandler() {
    sr("MenuSOKitchenService", "deleteModerationElement", {
      id: aloneProduct.moderation_id,
    }).then((res) => console.log(res, "DATADELETE"));
    dispatch(deliveryCycleInfoMessage("modeCustom", "Продукт удален"));
    closeModal();
    dispatch(deleteProduct(aloneProduct));
    setChooseDel(false);
  }

  const helpers = (name, id, media, enabled, tempPic, oldImg, isMod, base) => {
    return {
      account: SelectedKitchens.id,
      [name]: id,
      name: productName,
      description: productDescription,
      categoryId: selectedCategory ? selectedCategory.id : ALONE.categoryId,
      price: productPrice,
      weight: productWeight,
      calories: productCalories,
      proteins: productProteins,
      // raiting: ALONE.raiting ? ALONE.raiting : 0,
      // sold: ALONE.sold ? ALONE.sold : 0,
      fats: productFats,
      carbo: productCarbo,
      moderate_status: "1",
      [media.name ? media.name : ""]: media.path,
      [tempPic.name ? tempPic.name : ""]: tempPic.path,
      product_type: "product",
      date_and_time_of_dispatch: moment().format("YYYY-MM-DD-hh:mm:ss"),
      base64: base,
      [isMod ? "isMOD" : "isMOD"]: isMod ? true : false,
      [oldImg.name ? oldImg.name : ""]: oldImg.path,
      [enabled.name ? enabled.name : ""]: enabled.path,
      tegs_product: tagId,
      is_enabled: 0,
      localizationName: [{ languageCode: "ru_RU", text: productName }],
      localizationDescription: [
        { languageCode: "ru_RU", text: productDescription },
      ],
    };
  };

  const saveProductChangesHandle = () => {
    if (ALONE?.id_product) {
      console.log('CREATE 1')

      dispatch(
        saveProductChangesStarted({
          newInstance: helpers(
            "id_product",
            ALONE?.id_product,
            { name: "media", path: ALONE?.media },
            "",
            "",
            "",
            false,
            temps()
          ),
        })
      );
      // setAddProductFieldShow(false)
      dispatch(deliveryCycleInfoMessage("assortmentProductCheck"));
    } else if (ALONE?.isMOD) {
      console.log('CREATE 2')

      dispatch(
        saveProductChangesStarted({
          newInstance: helpers(
            "moderation_id",
            +ALONE?.moderation_id,
            "",
            { name: "isEnabled", path: 0 },
            { name: "temporary_picture", path: ALONE?.temporary_picture },
            { name: "oldImg", path: forTemps() },
            false,
            temps()
          ),
        })
      );
      // setAddProductFieldShow(false)
      dispatch(deliveryCycleInfoMessage("assortmentProductCheck"));
    } else {
      console.log('CREATE 3')
      dispatch(
        saveProductChangesStarted({
          newInstance: helpers(
            "id_product",
            ALONE?.moderate_status === "1" ? ALONE?.id_product : ALONE?.id,
            { name: "media", path: ALONE?.media },
            { name: "isEnabled", path: 0 },
            "",
            "",
            false,
            temps()
          ),
        })
      );
      // setAddProductFieldShow(false)
      dispatch(deliveryCycleInfoMessage("assortmentProductCheck"));
    }
  };
  const saveNewProductHandle = () => {
    dispatch(
      saveNewProductStarted(
        helpers(
          "id",
          false,
          "",
          { name: "isEnabled", path: 0 },
          "",
          "",
          true,
          getsPhoto ? [getsPhoto] : null
        )
      )
    );
    setAddProductFieldShow(false);
    dispatch(deliveryCycleInfoMessage("assortmentProductCheck"));
  };

  function getPhotos() {
    if (openModal) {
      const child = (isActualVersion = false) => {
        return (
          <ImageContainer
            base64={ALONE?.base64 ? stateBase : null}
            getsPhoto={getsPhoto ? getsPhoto : null}
            media={ALONE?.media ? stateMedia : null}
            tempPic={ALONE?.temporary_picture ? temporaryPic : null}
            style={{ width: "90px", height: "90px", borderRadius: "6px" }}
            // clear={memoClear}
            enabled={isEnabled()}
            ActualVersion={isActualVersion ? ActualVersion : null}
            newPhoto={
              aloneProduct?.temporary_picture || (aloneProduct?.base64 && true)
            }
          />
        );
      };
      const isEnabled = () => {
        if (ALONE?.isEnabled) {
          return ALONE?.isEnabled;
        } else {
          return null;
        }
      };
      if (ALONE?.isEnabled === "1") {
        return child();
      } else if (stateBase || stateMedia || temporaryPic || getsPhoto) {
        return child(true);
      } else {
        return (
          <Upload fileList={""} onChange={setFiles}>
            {child()}
          </Upload>
        );
      }
    }
  }
  const memoPhoto = useMemo(() => {
    return getPhotos();
  }, [
    stateBase,
    stateMedia,
    temporaryPic,
    getsPhoto,
    files,
    ALONE,
    addProductFieldShow,
  ]);

  //   const memoizedPhotos = useMemo(
  //     () => getPhotos(),
  //     [stateBase || stateMedia || temporaryPic || getsPhoto]
  //   );

  const clearPhtotState = () => {
    setStateMedia("");
    setStateBase("");
    setGetsPhoto();
    setTemporaryPic("");
  };
  useEffect(() => {
    if (ALONE?.historyModeration) {
      setChooseVariant("NEW");
    }
    if (!ALONE) {
      setChooseVariant("NEW");
    }
  }, [openModal, aloneProduct, aloneProductActual]);

  const UIheader = () => {
    if (!addProductFieldShow) {
      if (aloneProductActual?.historyModeration || ALONE?.historyModeration) {
        if (
          aloneProductActual?.historyModeration?.length > 0 &&
          aloneProductActual?.id &&
          aloneProduct?.moderate_status !== "2"
        ) {
          return (
            <HeaderModalUI
              {...{
                chooseVariant,
                setChooseVariant,
                aloneProduct,
                aloneProductActual,
              }}
            />
          );
        }
        if (!aloneProductActual?.id || ALONE?.id) {
          if (aloneProductActual?.historyModeration?.length > 1) {
            return (
              <HeaderModalUI
                {...{
                  chooseVariant,
                  setChooseVariant,
                  aloneProduct,
                  aloneProductActual,
                }}
              />
            );
          } else {
            return (
              <HeaderNewProduct>
                <div className="NewHeadCol1">
                  <div className="tittleNew">Новый товар</div>
                  <div className="StateStyle">
                    {ALONE?.moderate_status === "3" ? (
                      <p style={{ marginBottom: 0 }} className="errorStatus">
                        Есть ошибки <img src={WithErr} />
                      </p>
                    ) : (
                      <p style={{ marginBottom: 0 }} className="status">
                        На проверке{" "}
                        <img style={{ marginLeft: 8 }} src={Check} />
                      </p>
                    )}
                    {ALONE.date_and_time_of_dispatch ? (
                      <p className="dateNew">
                        c{" "}
                        {moment(
                          ALONE.date_and_time_of_dispatch,
                          "YYYY-MM-DD-hh:mm:ss"
                        ).format("DD.MM.YY / hh:mm ")}
                      </p>
                    ) : (
                      <p className="dateNew">Дата не найдена</p>
                    )}
                  </div>
                </div>
                {Err && ALONE.note && (
                  <div className="ErrNote">{ALONE.note}</div>
                )}
              </HeaderNewProduct>
            );
          }
        }
      }
    }
  };
  const saveHandler = () => {
    if (
      !disButton &&
      !addProductFieldShow &&
      !ALONE?.isMOD &&
      aloneProduct &&
      "id_product" in aloneProduct
      ||
      !disButton &&
      !addProductFieldShow &&
      !ALONE?.isMOD &&
      aloneProduct &&
      !ALONE.id
    ) {
      setChooseDel(true);
    } else {
      if (addProductFieldShow) {
        saveNewProductHandle();
      } else {
        saveProductChangesHandle();
      }
    }
  };
  const NoCopy = () => false;
  const switchUI = () => (
    <OnOffWrapps>
      <Switch
        style={{ width: 51, height: 31 }}
        checked={switchLocal}
        onChange={disAndEnable}
        className="switch"
      />
      <label style={{ paddingLeft: 18 }}>Товар включен</label>
    </OnOffWrapps>
  );
  const UIswitch = () => {
    if (!addProductFieldShow && aloneProduct) {
      if ("moderate_status" in aloneProduct && chooseVariant === "ACTUAL") {
        if (
          aloneProductActual?.moderate_status !== "1" &&
          aloneProductActual?.moderate_status !== "3"
        ) {
          return switchUI();
        }
      } else if (aloneProduct?.historyModeration) {
        return switchUI();
      }
    }
  };
  // console.log('id_product' in aloneProduct ,'asdsadsasadadsassa')
  return (
    <>
      <WrapperModal
        ref={refForModal}
        style={{ right: !openModal ? -900 : 0, opacity: openModal ? 1 : 0 }}
      >
        <Main>
          <>
            {UIheader()}
            {!chooseDel && (
              <HeaderMain onCopy={NoCopy}>
                <div
                  className={
                    selectedParams === "Main"
                      ? "HeaderItemsDark"
                      : "HeaderItems"
                  }
                  onClick={() => setSelectedParams("Main")}
                >
                  Основные параметры
                </div>
                <div
                  // style={{color:'#A6A6A6'}}
                  className={
                    selectedParams === "Dops"
                      ? "HeaderItemsDark"
                      : "HeaderItems"
                  }
                  onClick={() => setSelectedParams("Dops")}
                >
                  Дополнительные
                </div>
                <div
                  // style={{color:'#A6A6A6'}}
                  className={
                    selectedParams === "Modif"
                      ? "HeaderItemsDark"
                      : "HeaderItems"
                  }
                  onClick={() => setSelectedParams("Modif")}
                >
                  Модификаторы
                </div>
              </HeaderMain>
            )}
            {chooseDel ? (
              <DelProd>
                <div style={{ display: "grid", justifyItems: "center" }}>
                  <img src={Pack2} />
                  <div className="tittleDel">
                    <p className="Maintittle">Вы уверены?</p>
                    <p className="Subtittle">
                      Это действие откатит все внесенные изменения до
                      утвержденной модератором версии
                    </p>
                  </div>
                  <div className="BtnChooseDel">
                    <div className="NoBTN" onClick={() => setChooseDel(false)}>
                      Нет
                    </div>
                    <div className="YesBTN" onClick={deleteHandler}>
                      Да
                    </div>
                  </div>
                </div>
              </DelProd>
            ) : (
              <ContentMain
                styles={
                  ALONE?.moderate_status === "2" ||
                  ALONE?.moderate_status === "1"
                }
              >
                {selectedParams === "Main" ? (
                  <>
                    <NameWithDesc>
                      <input
                        placeholder="Введите название"
                        value={productName}
                        style={{
                          backgroundColor:
                            openModal &&
                            !addProductFieldShow &&
                            aloneProduct &&
                            "id_product" in aloneProduct
                              ? EquialAlone("name")
                                ? "#ffffff"
                                : "#FAF5EA"
                              : null,
                          border:
                            openModal &&
                            !addProductFieldShow &&
                            aloneProduct &&
                            "id_product" in aloneProduct
                              ? EquialAlone("name")
                                ? null
                                : "1px solid #FCD379"
                              : null,
                        }}
                        onChange={(e) => setProductName(e.target.value)}
                        disabled={ACTUALMODE}
                      />
                      <textarea
                        placeholder="Состав"
                        style={{
                          backgroundColor:
                            openModal &&
                            !addProductFieldShow &&
                            aloneProduct &&
                            "id_product" in aloneProduct
                              ? EquialAlone("description")
                                ? "#ffffff"
                                : "#FAF5EA"
                              : null,
                          border:
                            openModal &&
                            !addProductFieldShow &&
                            aloneProduct &&
                            "id_product" in aloneProduct
                              ? EquialAlone("description")
                                ? null
                                : "1px solid #FCD379"
                              : null,
                        }}
                        value={productDescription}
                        onChange={(e) => setProductDescription(e.target.value)}
                        disabled={ACTUALMODE}
                      />
                    </NameWithDesc>
                    <InfoProduct>
                      <div className="firstColsInfo">
                        <Cols>
                          <p>Цена,₽</p>
                          <input
                            placeholder="0"
                            style={{
                              width: 83,
                              backgroundColor:
                                openModal &&
                                !addProductFieldShow &&
                                aloneProduct &&
                                "id_product" in aloneProduct
                                  ? EquialAlone("price")
                                    ? "#ffffff"
                                    : "#FAF5EA"
                                  : null,
                              border:
                                openModal &&
                                !addProductFieldShow &&
                                aloneProduct &&
                                "id_product" in aloneProduct
                                  ? EquialAlone("price")
                                    ? null
                                    : "1px solid #FCD379"
                                  : null,
                            }}
                            value={productPrice}
                            onChange={(e) => setProductPrice(e.target.value)}
                            disabled={ACTUALMODE}
                          />
                        </Cols>
                        <Cols style={{ marginLeft: 43 }}>
                          <p style={{ width: "38%", textAlign: "start" }}>
                            Мин. кол-во в заказе
                          </p>
                          <input
                            placeholder="0"
                            style={{ width: 60 }}
                            disabled={ACTUALMODE}
                          />
                        </Cols>
                      </div>

                      <div className="SecondColsInfo">
                        <Cols>
                          <p>Вес, г</p>
                          <input
                            placeholder="0"
                            style={{
                              width: 83,
                              backgroundColor:
                                openModal &&
                                !addProductFieldShow &&
                                aloneProduct &&
                                "id_product" in aloneProduct
                                  ? EquialAlone("weight")
                                    ? "#ffffff"
                                    : "#FAF5EA"
                                  : null,
                              border:
                                openModal &&
                                !addProductFieldShow &&
                                aloneProduct &&
                                "id_product" in aloneProduct
                                  ? EquialAlone("weight")
                                    ? null
                                    : "1px solid #FCD379"
                                  : null,
                            }}
                            value={productWeight}
                            onChange={(e) => setProductWeight(e.target.value)}
                            disabled={ACTUALMODE}
                          />
                        </Cols>
                        <Cols style={{ marginLeft: 27 }}>
                          <p>К</p>
                          <input
                            placeholder="0"
                            style={{
                              width: 83,
                              backgroundColor:
                                openModal &&
                                !addProductFieldShow &&
                                aloneProduct &&
                                "id_product" in aloneProduct
                                  ? EquialAlone("calories")
                                    ? "#ffffff"
                                    : "#FAF5EA"
                                  : null,
                              border:
                                openModal &&
                                !addProductFieldShow &&
                                aloneProduct &&
                                "id_product" in aloneProduct
                                  ? EquialAlone("calories")
                                    ? null
                                    : "1px solid #FCD379"
                                  : null,
                            }}
                            value={productCalories}
                            onChange={(e) => setProductCalories(e.target.value)}
                            disabled={ACTUALMODE}
                          />
                        </Cols>
                        <Cols style={{ marginLeft: 27 }}>
                          <p>Б</p>
                          <input
                            placeholder="0"
                            style={{
                              width: 83,
                              backgroundColor:
                                openModal &&
                                !addProductFieldShow &&
                                aloneProduct &&
                                "id_product" in aloneProduct
                                  ? EquialAlone("proteins")
                                    ? "#ffffff"
                                    : "#FAF5EA"
                                  : null,
                              border:
                                openModal &&
                                !addProductFieldShow &&
                                aloneProduct &&
                                "id_product" in aloneProduct
                                  ? EquialAlone("proteins")
                                    ? null
                                    : "1px solid #FCD379"
                                  : null,
                            }}
                            value={productProteins}
                            onChange={(e) => setProductProteins(e.target.value)}
                            disabled={ACTUALMODE}
                          />
                        </Cols>
                        <Cols style={{ marginLeft: 27 }}>
                          <p>Ж</p>
                          <input
                            placeholder="0"
                            style={{
                              width: 83,
                              backgroundColor:
                                openModal &&
                                !addProductFieldShow &&
                                aloneProduct &&
                                "id_product" in aloneProduct
                                  ? EquialAlone("fats")
                                    ? "#ffffff"
                                    : "#FAF5EA"
                                  : null,
                              border:
                                openModal &&
                                !addProductFieldShow &&
                                aloneProduct &&
                                "id_product" in aloneProduct
                                  ? EquialAlone("fats")
                                    ? null
                                    : "1px solid #FCD379"
                                  : null,
                            }}
                            value={productFats}
                            onChange={(e) => setProductFats(e.target.value)}
                            disabled={ACTUALMODE}
                          />
                        </Cols>
                        <Cols style={{ marginLeft: 27 }}>
                          <p>У</p>
                          <input
                            placeholder="0"
                            style={{
                              width: 83,
                              backgroundColor:
                                openModal &&
                                !addProductFieldShow &&
                                aloneProduct &&
                                "id_product" in aloneProduct
                                  ? EquialAlone("carbo")
                                    ? "#ffffff"
                                    : "#FAF5EA"
                                  : null,
                              border:
                                openModal &&
                                !addProductFieldShow &&
                                aloneProduct &&
                                "id_product" in aloneProduct
                                  ? EquialAlone("carbo")
                                    ? null
                                    : "1px solid #FCD379"
                                  : null,
                            }}
                            value={productCarbo}
                            onChange={(e) => setProductCarbo(e.target.value)}
                            disabled={ACTUALMODE}
                          />
                        </Cols>
                      </div>
                    </InfoProduct>
                    <PhotoProduct>
                      <div style={{ width: 90, height: 90 }}>{memoPhoto}</div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          textAlign: "start",
                        }}
                      >
                        {stateBase ||
                        stateMedia ||
                        temporaryPic ||
                        getsPhoto ? (
                          <div className="photoHave">
                            <p
                              style={{
                                color:
                                  chooseVariant === "ACTUAL"
                                    ? "#A6A6A6"
                                    : "#2C2E32",
                              }}
                            >
                              Фото загружено <img src={UploadSucces} />
                            </p>
                            {chooseVariant === "NEW" && (
                              <p
                                style={{ color: "#EE2B00", cursor: "pointer",maxHeight:150 }}
                                onClick={clearPhtotState}
                              >
                                Удалить
                              </p>
                            )}
                          </div>
                        ) : (
                          <div className="DontHavePhoto">
                            <p>Добавьте фото товара</p>
                            <p style={{ color: "#A6A6A6" }}>
                              файл не должен превышать 2 мб
                            </p>
                          </div>
                        )}
                      </div>
                      {/* <CustomTegs>
                    <div className="select" data-isselect={customSel} onClick={clickHandler}/>
                </CustomTegs> */}
                    </PhotoProduct>
                    {drawCategorySettingsList()}
                    {/* THIS WILL BE PHOTO */}
                    {UIswitch()}
                  </>
                ) : (
                  <div style={{ height: "calc(100vh - 270px)" }}></div>
                )}
              </ContentMain>
            )}
            {!chooseDel && selectedParams === "Main" && (
              <BtnMain>
                {chooseVariant === "NEW" && ALONE?.isMOD && disButton ? (
                  <div
                    className={
                      addProductFieldShow ||
                      disButton ||
                      ALONE?.isMOD ||
                      ALONE?.historyModeration?.length === 0
                        ? "sendBTN"
                        : "sendBTNWhite"
                    }
                    onClick={saveHandler}
                  >
                    <div>
                      {addProductFieldShow ||
                      ALONE?.isMOD ||
                      ALONE?.historyModeration?.length === 0 ? (
                        <img src={GRAY} />
                      ) : !!disButton ? (
                        <img src={GRAY} />
                      ) : (
                        <div></div>
                      )}
                      {addProductFieldShow ||
                      ALONE?.isMOD ||
                      ALONE?.historyModeration?.length === 0
                        ? "Отправить на проверку"
                        : !!disButton
                        ? "Отправить на проверку"
                        : "Отменить проверку"}
                    </div>
                    {addProductFieldShow ||
                    ALONE?.isMOD ||
                    ALONE?.historyModeration?.length === 0 ? (
                      <div
                        style={{ fontSize: 11, fontWeight: 400, marginTop: 5 }}
                      >
                        Alt + Enter
                      </div>
                    ) : !!disButton ? (
                      <div
                        style={{ fontSize: 11, fontWeight: 400, marginTop: 5 }}
                      >
                        Alt + Enter
                      </div>
                    ) : null}
                  </div>
                ) : (
                  chooseVariant === "NEW" &&
                  !ALONE?.isMOD && (
                    <div>
                      <div
                        className={
                          addProductFieldShow ||
                          disButton ||
                          ALONE?.isMOD ||
                          ALONE?.historyModeration?.length === 0
                            ? "sendBTN"
                            : "sendBTNWhite"
                        }
                        onClick={saveHandler}
                      >
                        <div>
                          {addProductFieldShow ||
                          ALONE?.isMOD ||
                          ALONE?.historyModeration?.length === 0 ? (
                            <img src={GRAY} />
                          ) : !!disButton ? (
                            <img src={GRAY} />
                          ) : null}
                          {addProductFieldShow ||
                          ALONE?.isMOD ||
                          ALONE?.historyModeration?.length === 0
                            ? `Отправить на проверку`
                            : !!disButton
                            ? "Отправить на проверку"
                            : "Отменить проверку"}
                        </div>
                        {addProductFieldShow ||
                        ALONE?.isMOD ||
                        ALONE?.historyModeration?.length === 0 ? (
                          <div
                            style={{
                              fontSize: 11,
                              fontWeight: 400,
                              marginTop: 5,
                            }}
                          >
                            Alt + Enter
                          </div>
                        ) : !!disButton ? (
                          <div
                            style={{
                              fontSize: 11,
                              fontWeight: 400,
                              marginTop: 5,
                            }}
                          >
                            Alt + Enter
                          </div>
                        ) : null}
                      </div>
                    </div>
                  )
                )}
                <div
                  style={{
                    marginLeft:
                      chooseVariant === "ACTUAL" ? 20 : disButton ? 20 : 20,
                  }}
                  className={
                    chooseVariant === "ACTUAL"
                      ? "sendBTN"
                      : disButton || addProductFieldShow
                      ? "cancelBTNwHITE"
                      : "sendBTN"
                  }
                  onClick={closeModal}
                >
                  {chooseVariant === "NEW"
                    ? disButton || addProductFieldShow
                      ? "Отмена"
                      : "Закрыть"
                    : "Закрыть"}
                </div>
                {chooseVariant === "ACTUAL" && (
                  <>
                    <div className="changeInst">
                      Отмечены внесенные изменения в новую версию
                    </div>
                    <div className="ForRead">
                      Только просмотр <img src={Lock} />
                    </div>
                  </>
                )}
              </BtnMain>
            )}
          </>
        </Main>
      </WrapperModal>
    </>
  );
};
export default memo(ModalInfo);

// ModalInfo.displayName= 'DDDDDDDDDDDDDDDDDD'

const WrapperModal = styled.div`
  display: grid;
  position: fixed;
  justify-content: center;
  top: 64px;
  /* max-width: 730px;
  min-width: 730px; */
  /* width: auto; */
  height: 100%;
  background: #ffffff;
  box-shadow: -12px 4px 34px rgba(0, 0, 0, 0.04);
  overflow: scroll;
  /* transform:scale(100%); */
  transition: all 0.6s ease-in-out;
  font-family: Roboto;
  font-style: normal;
`;
const Main = styled.div`
  /* display: grid; */
  grid-template-rows: auto 2fr auto;
  justify-content: center;
  /* border: 1px solid black; */
`;
const HeaderMain = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding-left: 53px;
  padding-right: 53px;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #455385;
  margin-top: 40px;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  .HeaderItems {
    padding-bottom: 15px;
    border-bottom: 1px solid #e2e2e2;
    color: #a6a6a6;
    cursor: pointer;
  }

  .HeaderItemsDark {
    padding-bottom: 15px;
    border-bottom: 4px solid #455385;
    cursor: pointer;
  }
`;
const ContentMain = styled.div`
  margin-top: 32px;
  margin-bottom: 100px;
  /* margin-top: ${({ styles }) => (styles ? "32px" : "0px")}; */
`;
const BtnMain = styled.div`
  display: flex;
  position: fixed;
  bottom: 0px;
  background: #ffffff;
  padding-top: 24px;
  padding-bottom: 24px;
  box-shadow: -12px 4px 34px rgba(0, 0, 0, 0.04);
  width: 100%;
  padding-left: 53px;
  padding-right: 53px;
  .ForRead {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #a6a6a6;
    margin-left: 70px;
  }
  .changeInst {
    background: #faf5ea;
    border: 1px solid #fcd379;
    box-shadow: inset 0px 1px 5px rgba(116, 116, 116, 0.25);
    border-radius: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #2c2e32;
    padding: 12px;
    width: 15%;
    background: #faf5ea;
    margin-left: 20px;
    text-align: start;
  }
  .sendBTN {
    height: 56px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #7882a7;
    border-radius: 8px;
    padding: 15px;
    color: #ffffff;
    font-weight: 500;
    font-size: 15px;

    cursor: pointer;
  }
  .sendBTNWhite {
    width: 219px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #7882a7;
    padding: 15px;
    color: #2c2e32;
    border-radius: 8px;
    cursor: pointer;
  }
  .cancelBTN {
    width: 102px;
    height: 55px;
    justify-content: center;
    margin-left: 20px;
    display: flex;
    align-items: center;
    border: 2px solid #7882a7;
    padding: 15px;
    color: #2c2e32;
    border-radius: 8px;
    cursor: pointer;
  }
  .cancelBTNwHITE {
    width: 102px;
    height: 55px;
    justify-content: center;
    margin-left: 20px;
    display: flex;
    align-items: center;
    border: 2px solid #7882a7;
    border-radius: 8px;
    padding: 15px;
    color: #2c2e32;
    font-weight: 500;
    font-size: 15px;
    cursor: pointer;
  }
`;
const NameWithDesc = styled.div`
  padding-left: 53px;
  padding-right: 53px;
  textarea {
    padding: 16px;
    resize: none;
    width: 100%;
    min-height: 226px;
    border: none;
    background: #ffffff;
    box-shadow: inset 0px 1px 5px rgba(116, 116, 116, 0.25);
    border-radius: 8px;
    margin-top: 16px;
  }
  input {
    padding: 16px;
    width: 100%;
    height: 50px;
    border: none;
    background: #ffffff;
    box-shadow: inset 0px 1px 5px rgba(116, 116, 116, 0.25);
    border-radius: 8px;
  }
`;
const InfoProduct = styled.div`
  padding-left: 53px;
  padding-right: 53px;
  display: grid;
  grid-template-rows: 1fr 1fr;
  padding-bottom: 30px;
  width: 100%;
  .firstColsInfo {
    display: flex;
    padding-top: 10px;
    padding-bottom: 20px;
  }
  .SecondColsInfo {
    display: flex;
  }
  input {
    padding: 15px 20px 16px 16px;
    border: none;
    background: #ffffff;
    box-shadow: inset 0px 1px 5px rgba(116, 116, 116, 0.25);
    border-radius: 8px;
    height: 50px;
    margin-left: 10px;
  }
  p {
    margin-bottom: 0px;
  }
`;
const TagsProduct = styled.div`
  padding-left: 53px;
  padding-right: 53px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding-bottom: 33px;
  /* margin-bottom: ${({ marginState }) => (marginState ? "0px" : "100px")}; */
  /* margin-bottom: 100px; */
  .CHECKBOX::before {
    background: red;
    border: 1px solid red;
  }
`;

const CustomTegs = styled.div`
  height: 18px;
  width: 18px;
  border: 1px solid gray;
  border-radius: 6px;
  cursor: pointer;
  .select[data-isselect="true"] {
    width: 100%;
    height: 100%;
    background-color: red;
  }
`;

const PhotoProduct = styled.div`
  padding-left: 53px;
  padding-right: 53px;
  display: flex;
  align-items: center;
  padding-bottom: 32px;
  .photoHave {
    padding-left: 16px;
  }
  .DontHavePhoto {
    padding-left: 16px;
  }
`;

const OnOffWrapps = styled.div`
  padding-left: 53px;
  padding-right: 53px;
  display: flex;
  align-items: center;
  margin-bottom: 60px;
  padding-bottom: 18px;
  border-bottom: 1px solid #e2e2e2;

  button[aria-checked="true"] {
    background-color: #7882a7;
  }
  .switch::after {
    height: 27px;
    width: 27px;
  }
`;
const Cols = styled.div`
  display: flex;
  align-items: center;
`;

const HeaderNewProduct = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 53px;
  padding-right: 53px;
  .tittleNew {
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    color: #455385;
  }
  .StateStyle {
    padding-right: 15px;
    padding-top: 5px;
  }
  .status {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #e39115;
  }
  .NewHeadCol1 {
    margin-top: 46px;
    display: flex;
    justify-content: space-between;
  }
  .ErrNote {
    margin-top: 30px;
    border: 1px solid #ee2b00;
    text-align: start;
    color: #ee2b00;
    padding: 10px;
    border-radius: 8px;
  }
  .dateNew {
    padding-top: 8.5px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #2c2e32;
  }
  .errorStatus {
    font-size: 16px;
    line-height: 19px;
    color: #ee2b00;
    padding-bottom: 8.5;
  }
`;

const DelProd = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  margin-right: 53px;

  width: 100%;
  height: 100%;
  .tittleDel {
    display: flex;
    flex-direction: column;
    width: 60%;
  }
  .Maintittle {
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    color: #2c2e32;
    margin-bottom: 16px;
  }
  .Subtittle {
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    text-align: center;
    color: #2c2e32;
    margin-bottom: 24px;
  }
  .BtnChooseDel {
    display: flex;
    .NoBTN {
      border: 2px solid #7882a7;
      color: #2c2e32;
      padding: 15px;
      border-radius: 8px;
      width: 100px;
      margin-right: 30px;
      cursor: pointer;
    }
    .YesBTN {
      background-color: #7882a7;
      color: #ffffff;
      padding: 15px;
      border-radius: 8px;
      width: 100px;
      cursor: pointer;
    }
  }
`;
