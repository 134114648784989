import {sr} from "../utils";
import * as moment from "moment";

export const GET_KITCHENS = 'GET_KITCHENS';
export const GET_KITCHENS_STARTED = 'GET_KITCHENS_STARTED'
export const GET_KITCHENS_ERROR = "GET_KITCHENS_ERROR";
export const GET_MENU_ASSORT = 'GET_MENU';
export const GET_MENU_STARTED_ASSORT = 'GET_MENU_STARTED_ASSORT'
export const GET_MENU_ERROR_ASSORT = "GET_MENU_ERROR_ASSORT";
export const GET_CATEGORY = 'GET_CATEGORY';
export const GET_CATEGORY_STARTED = 'GET_CATEGORY_STARTED';
export const GET_CATEGORY_ERROR = "GET_CATEGORY_ERROR";
export const SAVE_PRODUCT_CHANGES = 'SAVE_PRODUCT_CHANGES'
export const SAVE_PRODUCT_CHANGES_STARTED = 'SAVE_PRODUCT_CHANGES_STARTED'
export const SAVE_PRODUCT_CHANGES_ERROR = 'SAVE_PRODUCT_CHANGES_ERROR'
export const SAVE_NEW_PRODUCT = 'SAVE_NEW_PRODUCT'
export const SAVE_NEW_PRODUCT_STARTED = 'SAVE_NEW_PRODUCT_STARTED'
export const SAVE_NEW_PRODUCT_ERROR = 'SAVE_NEW_ERROR'
export const GET_MODERATION_PRODUCT = 'GET_MODERATION_PRODUCT'
export const GET_MODERATION_PRODUCT_STARTED = 'GET_MODERATION_PRODUCT_STARTED '
export const GET_MODERATION_PRODUCT_ERROR = 'GET_MODERATION_PRODUCT_ERROR'
export const GET_TEG_LIST = 'GET_TEG_LIST'
export const GET_TEG_LIST_STARTED = 'GET_TEG_LIST_STARTED'
export const GET_TEG_LIST_ERROR = 'GET_TEG_LIST_ERROR'
export const TOGGLE_SELECT_ALL_PRODUCTS = 'TOGGLE_SELECT_ALL_PRODUCTS'
export const ADD_PRODUCT_ITEM_TO_SELECT = 'ADD_PRODUCT_ITEM_TO_SELECT'
export const REMOVE_PRODUCT_ITEM_FROM_SELECT = 'REMOVE_PRODUCT_ITEM_FROM_SELECT'
export const DELETE_SELECTED_PRODUCTS = 'DELETE_SELECTED_PRODUCTS'
export const SAVE_NEW_PRODUCT_WITH_MODERATION = 'SAVE_NEW_PRODUCT_WITH_MODERATION'
export const CHANGE_STATE_PRODUCT = 'CHANGE_STATE_PRODUCT'
export const SELECT_CATEGORY = 'SELECT_CATEGORY'
export const ADD_CATEGORY = 'ADD_CATEGORY'
export const SAVE_KITHENS_ID = 'SAVE_KTIHENS_ID'
export const MODERATION_MODE = 'MODERATION_MODE '
export const CHANGE_READY_PRODUCT = 'CHANGE_READY_PRODUCT'
export const PROD_MOD = 'PROD_MOD'
export const DEL_PRODUCT = 'DEL_PRODUCT'
export const DIS_BTN = 'DIS_BTN'
export const MODALINFO = 'MODALINFO' 

export const modalInfo = payload => ({type:MODALINFO,payload})
export const allKitchens = payload => ({type:'ALL_KITCHENS',payload})
export const disableBTN = payload => ({type:DIS_BTN,payload})
export const saveProductChanges = payload => ({type: SAVE_PRODUCT_CHANGES,payload})
export const saveProductChangesStarted = payload => ({type:SAVE_PRODUCT_CHANGES_STARTED,payload})
export const saveProductChangesError = () => ({type:SAVE_NEW_PRODUCT_ERROR})
export const deleteProduct = payload =>({type:DEL_PRODUCT,payload})
// export const saveNewProduct = payload => ({type: SAVE_NEW_PRODUCT, payload})
export const toggleToSelectAllProducts = payload => ({type: TOGGLE_SELECT_ALL_PRODUCTS, payload})
export const addProductToSelect = payload => ({type: ADD_PRODUCT_ITEM_TO_SELECT, payload})
export const removeProductFromSelect = payload => ({type: REMOVE_PRODUCT_ITEM_FROM_SELECT, payload})
export const deleteSelectedProducts = () => ({type: DELETE_SELECTED_PRODUCTS})
export const selectCategory = payload => ({type: SELECT_CATEGORY, payload})
export const addCategory = payload => ({type: ADD_CATEGORY, payload})
export const moderationMode = payload =>({type:MODERATION_MODE ,payload})
export const getKitchens = (payload) => ({type: GET_KITCHENS, payload})
export const getKithensStarted = () => ({type: GET_KITCHENS_STARTED})
export const getKithensError = () => ({type: GET_KITCHENS_ERROR})
export const getCategory = (payload) => ({type: GET_CATEGORY,payload})
export const getCategoryStarted = (payload) => ({type: GET_CATEGORY_STARTED,payload})
export const getCategoryError = () => ({type: GET_CATEGORY_ERROR,})
export const getMenuAssort = (payload) => ({type: GET_MENU_ASSORT,payload})
export const getMenuStartedAssort = (payload) => ({type: GET_MENU_STARTED_ASSORT,payload})
export const getMenuErrorAssort = () => ({type: GET_MENU_ERROR_ASSORT,})
export const saveNewProduct = (payload) => ({type:SAVE_NEW_PRODUCT,payload})
export const saveNewProductStarted = (payload) => ({type:SAVE_NEW_PRODUCT_STARTED,payload})
export const saveNewProductError = () => ({type:SAVE_NEW_PRODUCT_ERROR})
export const getModerationProduct = (payload) => ({type:GET_MODERATION_PRODUCT,payload})
export const getModerationProductStarted = (payload) => ({type:GET_MODERATION_PRODUCT_STARTED,payload})
export const getModerationProductError = () => ({type:GET_MODERATION_PRODUCT_ERROR})
export const getTegList = (payload) => ({type:GET_TEG_LIST,payload})
export const getTegListStarted = ()=>({type:GET_TEG_LIST_STARTED})
export const getTegListError = ()=>({type:GET_TEG_LIST_ERROR})
export const changeStateProduct = (payload) => ({type:CHANGE_STATE_PRODUCT,payload})
export const saveNewProductWithModeration=(payload)=>({type:SAVE_NEW_PRODUCT_WITH_MODERATION,payload})
export const saveKithensId = (payload) => ({type:SAVE_KITHENS_ID,payload})
export const changeReadyProduct = (payload) => ({type:CHANGE_READY_PRODUCT,payload})
export const ProdMod = (payload) => ({type:PROD_MOD,payload})