import {createAction, createSlice} from "@reduxjs/toolkit"

const initialState = {
  rests: [],
  allPeriodsByDate: null,
  allPeriodsByDateStatus: null,
  restsStatus: null,
  couriersStatus: null,
  couriersErrorMessage: null,
  couriers: [],
}

export const getOperatorPeriodsInit = createAction('common/getOperatorPeriods')

const commonReducer = createSlice({
  name: 'common',
  initialState,
  reducers: {
    getAllPeriodsHandler(state, action) {
      if(typeof action.payload !== typeof []) {
        state.allPeriodsByDateStatus = action.payload
      } else {
        state.allPeriodsByDateStatus = 'resolve'
        state.allPeriodsByDate = action.payload?.periods
      }
    },
    getAllCouriers(state) {
      state.couriers = []
      state.couriersErrorMessage = initialState.couriersErrorMessage
      state.couriersStatus = 'pending'
    },
    setAllCouriers(state, action) {
      state.couriers = action.payload
      state.couriersStatus = 'resolved'
    },
    getAllCouriersError(state, action) {
      state.couriers = []
      state.couriersErrorMessage = action.payload
      state.couriersStatus = 'rejected'
    }
  }
})

export const {
  getAllPeriodsHandler,
  getAllCouriers,
  setAllCouriers,
  getAllCouriersError,
} = commonReducer.actions
export default commonReducer.reducer
