import React from "react";
import styled from "styled-components";
import {Switch} from 'antd';

const SimpleSwitch = props => {
    const {isChecked, title, onChange1, style} = props;

    return (
        <Container style={style}>
            <Switch checked={isChecked} onChange={() => onChange1()} />
            {title ? <Title>{title}</Title> : null}
        </Container>
    )
};

const Container = styled.div`
    display: flex;
    align-items: center;
    font-family: "Roboto", sans-serif;
    text-align: left;
    color: #111111;
    
    /*&:not(:last-child) {
        margin-bottom: 16px;
    }*/
`;

const Title = styled.p`
    margin-left: 10px;
    font-size: 14px;
    line-height: 14px;
    color: #666666;
`;

export default SimpleSwitch;
