import {sr} from "../../utils";
import {
  activateAccountAction,
  activatePasswordAction,
  recoveryPasswordAction,
  recoveryPasswordStatusSwitch,
  registerStatusSwitch,
  registrationByEmailAction,
} from "../../reducers/toolkit/profileReducer"
import {put} from "redux-saga/effects"

export function* registerService(action) {
  let payload = action.payload
  let method

  // set method and response data method
  switch (action.type) {
    case registrationByEmailAction().type:
      method = 'SendActivationMail'
      payload.hostName = window.location.origin
      break

    case recoveryPasswordAction().type:
      method = 'RecoveryPassword'
      payload.hostName = window.location.origin
      break

    case activateAccountAction().type:
      method = 'ActivateAccount'
      payload.locale = payload.lang
      payload.email = payload.email.replace('%40', '@')
      break

    case activatePasswordAction().type:
      method = 'ActivateNewPassword'
      payload.email = payload.email.replace('%40', '@')
      break

    default:
      method = ''
  }
  console.log(method,"METHOD")
  if(!method) return

  //server response
  const resp = yield (() => sr('RegisterService', method, { ...payload }))()
  //error handling
  console.log(resp.resultCode,'RESPPPPPPp')
  switch (resp?.resultCode) {
    case 'rs203':
      yield put(registerStatusSwitch({status: 'reject', message:validator(resp)}))
      break
    case 'rs201':
      yield put(registerStatusSwitch({status: 'reject', message:validator(resp)}))
      break
    case "rs501d":
      yield put(recoveryPasswordStatusSwitch('notFoundByEmail'))
      break
    case 'rs501':
      yield put(recoveryPasswordStatusSwitch('invalid email'))
      break
    case 'rs200':
      yield put(registerStatusSwitch({status: 'inProgress', message: validator(resp)}))
      break
    case 'rs400':
      yield put(registerStatusSwitch({status: 'resolve', message: validator(resp)}))
      break
    case 'rs500':
      yield put(recoveryPasswordStatusSwitch('inProgress'))
      break
    case 'rs700':
      yield put(recoveryPasswordStatusSwitch('resolve'))
      break
    case 'rs702':
      yield put(recoveryPasswordStatusSwitch('reject'))
      break
    case 'rs701d':
      yield put(recoveryPasswordStatusSwitch('OldMail'))
      break
    default: return
  }

}

const validator = (code) =>{
  switch (code.resultCode) {
    case 'rs203':
      return 'Аккаунт с таким email уже зарегистрирован'
    case 'rs200':
      return 'Учетная запись создана. Сообщение для активации отправлено на email'
    case 'rs201':
      return 'Некорректно заполнено поле Email'
    default:
      return code.data.message
  }
}
