import React, {memo, useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux"
import ControlsDumb from "./ControlsDumb"
import {getDeliveryPoints, setCity, setDistributionCenter, setPeriod} from "../../reducers/toolkit/orderMapReducer"
import {setDate} from "../../actions/ReviewsActions"
import moment from "moment"
import {getOperatorPeriodsInit} from "../../reducers/toolkit/comonReducer"


//animation
const wrapperVariants = (isOpen) => ({
  hidden: {
    width: 36,
    height: 36,
    display: 'flex',
  },
  show: {
    width: isOpen ? 300 : 36,
    height: isOpen ? "max-content" : 36,
    display: isOpen ? "block" : 'flex',
    transition: {
      duration: .2,
      delayChildren: .2
    }
  }
})
const controlsDumb = {
  hidden: {opacity: 0},
  show: {opacity: 1}
}
const closeButton = {
  hidden: {opacity: 0},
  show: {
    left: -13,
    top: -5,
    opacity: 1
  }
}
//---

const Controls = (props) => {
  const dispatch = useDispatch()
  //common data used
  const periods = useSelector(store => store.common.allPeriodsByDate)
  const OptionCenter = useSelector(store => store.orderMap.OptionCenter)
  const distributionCenters = useSelector(store => store.appMain.distributionCenters)
  const cities = useSelector(store => store.appMain.cities)
  const date = useSelector(store => store.orderMap.date)


  const [filteredDistributionCenters, setFilteredDistributionCenters] = useState(null)

  //local data
  const deliveryPointsFilter = useSelector(store => store.orderMap.deliveryPointsFilter)
  const {selectedPeriodId, selectedDistributionCenterId, selectedCityId} = deliveryPointsFilter


  //console.log(selectedPeriodId, selectedDistributionCenterId, selectedCityId,"test")

  useEffect(() => {
    if(date && (selectedPeriodId !== null) && selectedDistributionCenterId) {
      dispatch(getDeliveryPoints({date:date.format('YYYY-MM-DD'),
        periodId: OptionCenter ? 0 : selectedPeriodId,
        centerIds: OptionCenter ? 0 : selectedDistributionCenterId}))   
    }
    dispatch(getOperatorPeriodsInit({dates:date,value:OptionCenter}))

  }, [date, selectedPeriodId, selectedDistributionCenterId,OptionCenter])

  //event handlers
  const toggleOpen = useCallback((e, toggle, setState) => {
    e.stopPropagation()
    setState(toggle)
  }, [])
  const selectCityHandler = useCallback(val => {
    localStorage.setItem('selectedCity', val)
    dispatch(setCity(val))
  }, [])

  // utils and data formatted functions
  const periodFormatStr = useCallback((period) => {
    const from = period.timeFrom.split(':')
    const to = period.timeTo.split(':')
    from.splice(-1)
    to.splice(-1)

    return `${from.join(':')} - ${to.join(':')}`
  }, [])

  //set default value
  useEffect(() => {
    if(!selectedPeriodId && periods) {
      dispatch(setPeriod(periods[0]?.id))
    }
  }, [periods]);

  useEffect(() => {
    if(filteredDistributionCenters) {
      dispatch(setDistributionCenter([filteredDistributionCenters[0]?.centerId]))
    }
  }, [filteredDistributionCenters])

  //filter
  useEffect(() => {
    if(distributionCenters && selectedCityId) {
      setFilteredDistributionCenters(distributionCenters.filter(el => el.cityId === selectedCityId))
    }
  }, [distributionCenters, selectedCityId])


  return (
    <ControlsDumb {...{
      toggleOpen,
      selectCityHandler,
      periodFormatStr,

      wrapperVariants,
      controlsDumb,
      closeButton,

      periods,
      filteredDistributionCenters,
      cities,

      date,
      selectedPeriodId,
      selectedDistributionCenterId,
      selectedCityId,
    }}/>
  )
}

export default memo(Controls);
