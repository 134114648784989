const nav = [
    {id:1,screen:'screen_dashboard',paths:'Kitchens',to:'/dashboard',text:'Главная',},
    {id:2,screen:'screen_assortment',paths:'Kitchens',to:'/assortment',text:'Ассортимент',},
    {id:3,screen:'screen_financial',paths:'Kitchens',to:"/financial",text:'Финансы',},
    {id:4,screen:'screen_reviews',paths:'Kitchens',to:"/reviews",text:'Отзывы',},
    {id:5,screen:'screen_complex',paths:'Kitchens',to:"/complex",text:'Комплексные товары',},
    {id:6,screen:'screen_assembly',paths:'Kitchens',to:"/assembly",text:'Сборка товаров',},
    {id:7,screen:'screen_orders',paths:'Kitchens',to:"/orders",text:'Заказы',},
    {id:8,screen:'screen_menu-by-day',paths:'Kitchens',to:"/menu_by_day",text:'Меню на день',},
    {id:9,screen:'screen_setting',paths:'Kitchens',to:"/setting",text:'Настройки',},
    {id:10,screen:'screen_setting',paths:'Settings',to:"/mainSetting",text:'Основные настройки',},
    {id:11,screen:'screen_setting',paths:'Settings',to:"/stocks",text:'Акции',},
    {id:12,screen:'screen_setting',paths:'Settings',to:"/startnotif",text:'Стартовые уведомления',},
    {id:13,screen:'screen_setting',paths:'Settings',to:"/aboutKitchen",text:'Точки продаж',},
    {id:14,screen:'screen_setting',paths:'Settings',to:"/additional",text:'Дополнительные настройки',},
    {id:15,screen:'screen_setting',paths:'Settings',to:"/reportsSetting",text:'Договоры',},
    {id:16,screen:'screen_map',paths:'Delivery',to:"/map",text:'Карта с курьерами',},
    {id:17,screen:'screen_delManagment',paths:'Delivery',to:"/delManagment",text:'Управления курьерами',},
    {id:19,screen:'screen_client-card',paths:'Clients',to:"/client-card",text:'Карточка клиента',},
    {id:18,screen:'screen_findClients',paths:'Clients',to:"/findClients",text:'Поиск клиентов',},
    {id:20,screen:'screen_addresses',paths:'Clients',to:"/addresses",text:'Адреса',},
    {id:21,screen:'screen_corpClients',paths:'Clients',to:"/corpClients",text:'Корпоративные клиенты',},
    {id:23,screen:'screen_wallets',paths:'Financial',to:"/wallets",text:'Кошельки',},
    {id:22,screen:'screen_promocode',paths:'Financial',to:"/promocodes",text:'Промокоды',},
    {id:24,screen:'screen_push',paths:'Financial',to:"/push",text:'PUSH-уведомления',},
    {id:25,screen:'screen_reportsStatistics',paths:'Partners',to:"/statistics",text:'Статистика по всем кухням',},
    {id:26,screen:'screen_financialRep',paths:'Partners',to:"/financialRep",text:'Финансовые отчёты',},
    {id:27,screen:'screen_reports',paths:'Partners',to:"/reports",text:'Отчеты',},
    {id:28,screen:'screen_supports',paths:'Support',to:"/supports",text:'Раздел для операторов линии поддержки',},
    {id:29,screen:'screen_moderation',paths:'Moderation',to:"/moderation",text:'Модерация',},
    {id:30,screen:'screen_workweek',paths:'Service',to:"/workweek",text:'Рабочие/выходные',},
    {id:31,screen:'screen_managment',paths:'Service',to:"/managment",text:'Управление правами админов',},
    {id:32,screen:'screen_version',paths:'Service',to:"/version",text:'Крайняя версия приложения',},
    
]

export default nav
    // {checkRole(isAuth, permissions,'screen_reports') && paths === 'Partners' ? (<Link className="header_button" to="/reports">Отчеты</Link>): null}