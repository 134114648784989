import React,{useState,useEffect,useRef} from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Content } from '../../components/NewReports/Content'
import NoPermissions from '../../components/NoPermissions'
import Reports from '../Settings/Reports'
export const NewReports = () => {
//   const [path,setPath] = useState('')
//   const permissions = useSelector(store => store.appMain.permissions);
//   const getPermissionsSuccess = useSelector(store => store.appMain.getPermissionsSuccess);
//   const testRefs = useRef(null)
//   const checkRole = (param) => {
//     if (permissions.staff) {
//         if (permissions.staff.role === 'admin' && !permissions.permissions.length) {
//             return true
//         } else {
//             return permissions.permissions.includes(param)
//         }
//     } else {
//         return false
//     }
// }

//     const handlePath=()=>{
//       switch (path) {
//         case 'Кухни':
//           return <Content {...{testRefs}}/>
//         case 'Корпоративные клиенты':
        
//           break;
//         case 'Финансы':

//           break;
//         case 'Старый отчет':
//            return <Reports/>
//         default:
//           break;
//       }
//     }
//   useEffect(()=>{
//     console.log(path,'PATH');
//   },[path])
  return (
    <WrapperMain>
      Здесь скоро будет раздел "Отчеты"
        {/* <NavMain>
          <ItemsNavCont>
                <ItemsNav onClick={()=>setPath('Кухни')}>
                Кухни
                </ItemsNav>
                <ItemsNav onClick={()=>setPath('Корпоративные клиенты')}>
                Корпоративные клиенты (скоро)
                </ItemsNav>
                <ItemsNav onClick={()=>setPath('Финансы')}>
                Финансы (скоро)
                </ItemsNav>
                <ItemsNav onClick={()=>setPath('Старый отчет')}>
                Старый отчет
                </ItemsNav>
          </ItemsNavCont>
        </NavMain>
        {
        checkRole('screen_reports') 
        ?
        handlePath()
        :
          getPermissionsSuccess && (
              <NoPermissions />
          )
        } */}
    </WrapperMain>
  )
}
const WrapperMain = styled.div`
display: grid;
height: calc(100vh - 64px);
justify-content: center;
align-items: center;
color:#161616;
font-size: 30px;
font-weight: 600;
`
// const WrapperMain = styled.div`
// display: grid;
// grid-template-columns: 1fr;
// grid-template-rows:40px 1fr;
// justify-items: center;
// align-items: center;
// min-width:100vh;
// `
const NavMain = styled.div`
min-width:100%;
align-items: center;
align-self:start;
height: 30px;
border-radius: 0px 0px 10px 10px;
background-color:  #7882A7;
`
const ItemsNavCont= styled.div`
display: grid;
margin-top: 5px;
width: 50%;
grid-template-columns: repeat(4,auto);
height: 30px;
`

const ItemsNav = styled.p`
font-family: 'Roboto';
font-style: normal;
font-weight: 500;
width: auto;
height:30px;
font-size: 15px;
line-height: 130%;
color: #FFFFFF;
cursor: pointer;
  :hover{
    color:#2196F3;
  }
`