import {takeEvery} from "redux-saga/effects";
import {
    getKitchens,
    getPermissions,
} from "./mainAppWorkers";
import {getKitchensAction, getPermissionsAction} from "../../reducers/toolkit/mainAppReducer"

function* mainAppWatcher() {
    yield takeEvery(getPermissionsAction, getPermissions)
    yield takeEvery(getKitchensAction, getKitchens)
}

export default mainAppWatcher;
