import React, { memo, useEffect } from "react";
import styled from "styled-components";
import SidebarNav from "./SidebarNav";
import Logo from "../components/Logo";
import { sr } from "../utils";

const Sidebar = ({ path, setPath, testPath }) => {
  return (
    <SidebarWrapper>
      <div style={{ marginBottom: 28 }}>
        <Logo {...{ path, setPath }} />
      </div>
      <SidebarNav {...{ path, setPath, testPath }} />
    </SidebarWrapper>
  );
};

export default memo(Sidebar);

const SidebarWrapper = styled.div`
  /* grid-area: sidebar; БАГАЕТСЯ */
  padding: 12px;
  background: white;
  /* position: fixed; */
  height: 100%;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  z-index: 100;
  position: fixed;
`;
