import React, {memo} from "react";
import InfoSidebarUnit from "../ListItemsComponents/InfoUnit"
import {nanoid} from "nanoid"
import {AnimatePresence, motion} from "framer-motion"
import InfoListLoader from "../InfoListLoader"
import {useSelector} from "react-redux"


const InfoList = ({data}) => {
  const deliveryPointsStatus = useSelector(store => store.orderMap.deliveryPointsStatus)

  return (
    <AnimatePresence>
      {
        deliveryPointsStatus !== 'resolved' && data.length === 0
          ? (
            <motion.div
              key="loader"
              initial={{opacity: 0}}
              animate={{opacity: 1}}
              exit={{opacity: 0}}
            >
              <InfoListLoader/>
            </motion.div>
          ) : (
            data.map((el, id) => el !== null && <InfoSidebarUnit key={nanoid()} companyData={el} {...{id}}/>)
          )
      }
    </AnimatePresence>
  )
}

export default memo(InfoList);
