import {
    GET_KITCHENS,
    GET_KITCHENS_SUCCESS,
    GET_KITCHENS_FAILED,

    GET_REVIEWS,
    GET_REVIEWS_SUCCESS,
    GET_REVIEWS_FAILED,

    CHANGE_POPUP_MESSAGE,
    HIDE_POPUP_WRAPPER,
    HIDE_PUSH_MESSAGE_POPUP,
    SET_DATE,
    SET_ACTIVE_REST,
    SET_ORDERS,
    SET_EVALUATION_FILTER,
    SET_ALL_EVALUATION,
    SET_WITHOUT_COMMENTS_FILTER,
    SET_WITH_CLIENT_COMMENTS_FILTER,
    SET_FILTERED_ORDERS,

    SEND_PUSH_MESSAGE,
    SEND_PUSH_MESSAGE_ERROR,
    SEND_PUSH_MESSAGE_SUCCESS,

    SHOW_POPUP_WRAPPER,
    SHOW_PUSH_MESSAGE_POPUP,
    RESET_SUCCESS_MESSAGE_STATE,

    SEND_ADMIN_COMMENT_SUCCESS,

} from "../actions/ReviewsActions";
import pushMessageReducer from "./reviewsReducers/pushMessageReducer";

const initialState = {
    getKitchensStarted: false,
    getKitchensError: "",
    kitchens: [],
    date: new Date(),
    activeRest: "",
    getReviewsStarted: false,
    getReviewsError: "",
    reviews: [],
    orders: {},
    evaluationFilter: [false, false, false, false, false, false],
    allEvaluation: true,
    withoutComments: false,
    withClientComments: false,
    filteredOrders: {},

    showPopupWrapper: false,
    pushMessageState: {},
};

export const reviewsReducer = (state = initialState, action) => {
    switch (action.type) {
            //get kitchen reducing
        case GET_KITCHENS:
            return {
                ...state,
                getKitchensStarted: true,
            };
        case GET_KITCHENS_SUCCESS:
            return {
                ...state,
                getKitchensStarted: false,
                kitchens: action.payload
            };
        case GET_KITCHENS_FAILED:
            return {
                ...state,
                getKitchensError: action.message
            };
            //get reviews reducing
        case GET_REVIEWS:
            return {
                ...state,
                getReviewsStarted: true,
            };
        case GET_REVIEWS_SUCCESS:
            return {
                ...state,
                getReviewsStarted: false,
                reviews: action.payload
            };
        case GET_REVIEWS_FAILED:
            return {
                ...state,
                getReviewsError: action.message,
            };
            //common reviews reducing
        case SET_DATE:
            return {
                ...state,
                date: action.payload
            };
        case SET_ACTIVE_REST:
            return {
                ...state,
                activeRest: action.payload,
            }
        case SET_ORDERS:
            return {
                ...state,
                orders: action.payload,
            }
        case SET_EVALUATION_FILTER:
            return {
                ...state,
                evaluationFilter: action.payload
            }
        case SET_ALL_EVALUATION:
            return {
                ...state,
                allEvaluation: action.payload
            }
        case SET_WITHOUT_COMMENTS_FILTER:
            return {
                ...state,
                withoutComments: action.payload
            }
        case SET_WITH_CLIENT_COMMENTS_FILTER:
            return {
                ...state,
                withClientComments: action.payload
            }
        case SET_FILTERED_ORDERS:
            return {
                ...state,
                filteredOrders: action.payload
            }
            //common popup settings
        case SHOW_POPUP_WRAPPER:
            return {
                ...state,
                showPopupWrapper: true,
            };
        case HIDE_POPUP_WRAPPER:
            return {
                ...state,
                showPopupWrapper: false,
            };
            //processing by a third-party reducer
        case SHOW_PUSH_MESSAGE_POPUP:
            return {
                ...state,
                showPopupWrapper: true,
                pushMessageState: pushMessageReducer(state.pushMessageState, action),
            };
        case HIDE_PUSH_MESSAGE_POPUP:
            return {
                ...state,
                showPopupWrapper: false,
                pushMessageState: pushMessageReducer(state.pushMessageState, action),
            };
        case CHANGE_POPUP_MESSAGE:
            return {
                ...state,
                pushMessageState: pushMessageReducer(state.pushMessageState, action),
            };
        case SEND_PUSH_MESSAGE:
            return {
                ...state,
                pushMessageState: pushMessageReducer(state.pushMessageState, action),
            };
        case SEND_PUSH_MESSAGE_SUCCESS:
            return {
                ...state,
                showPopupWrapper: false,
                pushMessageState: pushMessageReducer(state.pushMessageState, action),
            };
        case SEND_PUSH_MESSAGE_ERROR:
            return {
                ...state,
                pushMessageState: pushMessageReducer(state.pushMessageState, action),
            };
        case RESET_SUCCESS_MESSAGE_STATE:
            return {
                ...state,
                pushMessageState: pushMessageReducer(state.pushMessageState, action),
            };

        case SEND_ADMIN_COMMENT_SUCCESS:
            return {
                ...state
            };

        default:
            return state;

    }
};
