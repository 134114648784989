import React, {memo, useCallback, useEffect, useRef, useState} from "react";
import styled from "styled-components";
import BurgerMenu from "../share/UI/BurgerMenu"
import {AnimatePresence, motion} from 'framer-motion'
import {useDispatch, useSelector} from "react-redux"
import DetailedInfo from "./DetailedInfo"
import {
  toggleInfoSidebar
} from "../../reducers/toolkit/orderMapReducer"
import OrdersDestinationList from "./ListHandlers/OrdersDestinationList"
import InfoList from "./ListHandlers/InfoList"
import {useDebounce} from "../../hooks/useDebounce"
import InfoSidebarFilter from "./InfoSidebarFilter"
import {Cross, GlobalIcon, MoneyIco, StatisticSvg} from "../../images/svgs"
import KitchenStatisticListItem from "./ListItemsComponents/KitchenStatisticListItem"
import {nanoid} from "nanoid"
import {moneyFormat} from "./CourierInfo"
import  Money from '../../images/orderMap/Money_light.png'
const motionBlockStyle = {
  height: 'calc(100vh - 64px)',
  position: 'absolute',
  left: 0,
  top: 0,
  zIndex: 6,
}

const InfoSidebar = ({infoSidebarSize, coloredPoints}) => {
  const dispatch = useDispatch()
  const deliveryPoints = useSelector(store => store.orderMap.deliveryPoints)
  const filtering = useSelector(store => store.orderMap.filtering)
  const filteredDeliveryPoints = useSelector(store => store.orderMap.filteredDeliveryPoints)
  const selectionInfoUnit = useSelector(store => store.orderMap.selectionInfoUnit)
  const selectedCourier = useSelector(store => store.orderMap.selectedCourier)
  const selectedOrdersInstances = useSelector(store => store.orderMap.selectedOrdersInstances)
  const showInfoSidebar = useSelector(store => store.orderMap.showInfoSidebar)
  const kitchens = useSelector(store => store.orderMap.kitchens)
  const detailedOrderInfo = useSelector(store => store.orderMap.detailedOrderInfo)

  const [searchSubstr, setSearchSubstr] = useState('')
  const [showFilters, setShowFilters] = useState(false)
  const [filterCount, setFilterCount] = useState(0)
  const [clearFilters, setClearFilters] = useState(null)
  const [showKitchenStatistic, setShowKitchenStatistic] = useState(false)
  const [totalKitchenCash, setTotalKitchenCash] = useState(0)
  const [totalKitchenMoney, setTotalKitchenMoney] = useState(0)

  let searchSubstrDebounced = useDebounce(searchSubstr, 1000)

  const infoSidebarContentRef = useRef()

  const toggleKitchenStatistic = useCallback(
  ()=>{
    setShowKitchenStatistic(prevState => !prevState)
  },
  [showKitchenStatistic],
)

  console.log(kitchens,deliveryPoints,'deliveryPoints')

  const toggleHandler = () => {
    dispatch(toggleInfoSidebar())
  }
  const count = deliveryPoints.map(el=>el.kitchens).flat()
 
const ab = count.reduce(
function(acc, el) {
  acc[el] = (acc[el] || 0) + 1;
  return acc;
}, {});

console.log(ab,'COUNTTT')

  useEffect(() => {
    if (selectedOrdersInstances.length && infoSidebarContentRef.current) {
      infoSidebarContentRef.current.scrollTop = 0
    }
  }, [selectedOrdersInstances])

  useEffect(() => {
    if (!showInfoSidebar && showKitchenStatistic) {
      setTimeout(() => {
        setShowKitchenStatistic(false)
      }, 500)
    }
  }, [showInfoSidebar])

  useEffect(() => {
    if (clearFilters) {
      setShowKitchenStatistic(false)
      clearFilters()
      setSearchSubstr('')
    }
  }, [deliveryPoints])

  useEffect(() => {
    if(kitchens.length) {
      setTotalKitchenMoney(kitchens.reduce((acc, el) => acc += el.priceResult, 0))
      setTotalKitchenCash(kitchens.reduce((acc, el) => acc += el.priceCash, 0))
    }
  }, [kitchens])
  return (
    <Wrapper
      size={infoSidebarSize}
      style={motionBlockStyle}
      initial={{x: -infoSidebarSize}}
      animate={{x: showInfoSidebar ? 0 : -infoSidebarSize}}
      transition={{ease: "easeOut"}}
    >
      <MenuWrapper
        animate={{
          height: 'auto',
          right: showInfoSidebar ? 10 : -45
        }}
      >
        <BurgerMenu toggle={showInfoSidebar} {...{toggleHandler}}/>
      </MenuWrapper>
      {
        showInfoSidebar && (
          //переключение на статистику по кухням (кнопка с лесенкой)
          <KitchenStatisticWrapper
            animate={{
              height: 'auto',
              right: showInfoSidebar && -45,
              opacity: showInfoSidebar ? 1 : 0
            }}
          >
            <BurgerMenu toggle={showKitchenStatistic} toggleHandler={toggleKitchenStatistic} content={<StatisticSvg/>}/>
          </KitchenStatisticWrapper>
        )
      }
      <InfoSidebarHeader>
        <SearchField type="text" value={searchSubstr} onChange={e => setSearchSubstr(e.target.value)}/>
        <Controls>
          {
            !showKitchenStatistic ? (
              <>
                {/*свич на фильтры*/}
                <LinkButton onClick={() => setShowFilters(prevState => !prevState)}>
                  Фильтры
                  {
                    filterCount > 0 && (
                      <div>{filterCount}</div>
                    )
                  }
                </LinkButton>
                {
                  filterCount > 0 && (
                    <ClearFilters onClick={() => {
                      clearFilters()
                      setSearchSubstr('')
                    }}>
                      Очистить фильтры
                      <Cross/>
                    </ClearFilters>
                  )
                }
              </>
            ) : (
              <TotalCash>
                Итог:
                <CommonPayment>
                   <img src={Money} alt='Money'/>
                  {totalKitchenMoney}
                </CommonPayment>
                <CashPayment>
                  {totalKitchenCash}
                </CashPayment>
              </TotalCash>
            )
          }
        </Controls>
        {
          filtering && (
            // краткая статистика по отфильтрованным элементам
            <FilterInfo>
              <p>Найдено: <span>{filteredDeliveryPoints.length}</span></p>
              <p>Тотал: <span>{moneyFormat(filteredDeliveryPoints.reduce((acc, el) => acc += +el.sumPriceResult, 0))}₽</span></p>
            </FilterInfo>
          )
        }
      </InfoSidebarHeader>
      {
        !showKitchenStatistic  && showInfoSidebar ? (
          <>
            {/*основной компонент фильтрации*/}
            <InfoSidebarFilter {...{
              showFilters,
              searchSubstrDebounced,
              setFilterCount,
              setClearFilters,
              coloredPoints
            }} />
            <InfoSidebarContent ref={infoSidebarContentRef}>
              {
                selectedCourier && selectedOrdersInstances.length > 0 && (
                  <OrdersDestinationList {...{selectedOrdersInstances}}/>
                )
              }
              {
                coloredPoints.length <= 0 && filtering ? (
                  <p>Ничего не найдено</p>
                ) : (
                  //отрисовка массивов точек на сайдбаре (дублируется с картой)
                  <InfoList data={filtering ? filteredDeliveryPoints : coloredPoints}/>
                )
              }
            </InfoSidebarContent>
          </>
        ) : (
          //отображение статистики по кухням
          <KitchenStatistic>
            {
              kitchens.length > 0 && (
                [...kitchens].sort((a,b) => +a.priceResult < +b.priceResult ? 1 : -1).map(el => <KitchenStatisticListItem key={nanoid()} data={el} count={ab}/>)
              )
            }
          </KitchenStatistic>
        )
      }
      <AnimatePresence>
        {
          selectionInfoUnit && (
            // дополнительный сайдбар для отображения деталей по выбранному ордеру
            <DetailedInfo {...{selectionInfoUnit}}/>
          )
        }
      </AnimatePresence>
    </Wrapper>
  )
}

export default memo(InfoSidebar);

const Wrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 10px;
  padding: 5px 0;
  height: 100%;
  background: #ffffff;
  width: ${({size}) => size}px;
  z-index: 6;
`

const MenuWrapper = styled(motion.div)`
  position: absolute;
  top: 15px;
`

const KitchenStatisticWrapper = styled(MenuWrapper)``

export const InfoSidebarHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 10px;
`

const SearchField = styled.input`
  width: calc(100% - 46px);
  height: 36px;
  margin-right: auto;
  border-radius: 8px;
  border: 1px solid silver;
  padding: 2px;
`

const Controls = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`
const ShowTotals = styled.div`
  display: flex;
  gap: 20px
`
const TotalCash = styled.p`
  display: flex;
  gap: 10px;
  
  >span{
    display: flex;
    gap: 5px;
    align-items: center;
    font-weight: bold;
  }
`
const CommonPayment = styled.span``
const CashPayment = styled.span`
  color: #81B163;
`
const LinkButton = styled.button`
  display: flex;
  align-items: center;
  background: none;
  color: #1890ff;
  border: none;
  cursor: pointer;

  > div {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-left: 5px;
    background: rgba(24, 144, 255, 0.64);
    color: #fff;
  }

  :hover {
    color: rgba(24, 144, 255, 0.64)
  }
`
const FilterInfo = styled.div`
  display: flex;
  justify-content: space-between;
  background: rgba(0, 0, 0, 0.41);
  color: #fff;
  border-radius: 8px;
  margin-top:  10px;

  > p {
    padding: 1px 6px;
    margin: 0;
    
    >span{
      font-weight: 700;
    }
  }
`
const ClearFilters = styled(LinkButton)`
  display: flex;
  align-items: baseline;
  color: #888;

  > svg {
    margin-left: 5px;
    fill: #888;
  }

  :hover {
    color: rgba(24, 144, 255, 0.64);

    > svg {
      fill: rgba(24, 144, 255, 0.64);
    }
  }
`

const Button = styled.button`
  padding: 3px 5px;
  background: #fff;
  border: 1px solid silver;
  border-radius: 6px;
  cursor: pointer;

  :hover {
    background: #1890ff;
    color: #fff;
  }
`

const InfoSidebarContent = styled.div`
  position: relative;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 56px);
  padding-right: 5px;
`

const KitchenStatistic = styled.div`
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 5px;
`
