import React,{useEffect} from "react";
import styled from "styled-components"
import {useDispatch, useSelector} from "react-redux"
import {getDeliveryPoints, setOptionCenter} from "../../reducers/toolkit/orderMapReducer"
import moment from "moment"

const ReloadPointsButton = () => {

  const dispatch = useDispatch()
  const date = moment(useSelector(store => store.orderMap.date)).format('YYYY-MM-DD')
  const deliveryPointsFilter = useSelector(store => store.orderMap.deliveryPointsFilter)
  const OptionCenter = useSelector(store => store.orderMap.OptionCenter)


  const responseData = {
    date,
    centerIds: OptionCenter ? 0 : deliveryPointsFilter.selectedDistributionCenterId,
    periodId: OptionCenter ? 0 : deliveryPointsFilter.selectedPeriodId
  }
  useEffect(() => {
    // console.log(deliveryPointsFilter,'deliveryPointsFilterdeliveryPointsFilter')
  }, [deliveryPointsFilter])
  
  function reload(){
    dispatch(getDeliveryPoints(responseData))
}

  return (
    <ControlButtonWrapper onClick={() => reload()}>
      <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.66943 13.9607C10.4778 13.6462 12.7285 11.4012 13.0488 8.59277C13.4692 4.9207 10.6151 1.79487 7.0374 1.75483V0.107546C7.0374 0.0160301 6.92301 -0.0325876 6.84579 0.0246097L3.45399 2.51555C3.39679 2.55845 3.39679 2.64139 3.45399 2.68429L6.84579 5.17523C6.92301 5.23243 7.0374 5.18095 7.0374 5.0923V3.44787C9.55123 3.48791 11.5646 5.62709 11.393 8.18381C11.2471 10.3831 9.45399 12.1676 7.25475 12.3077C4.92396 12.4565 2.94207 10.792 2.58745 8.58991C2.52167 8.18095 2.16419 7.88353 1.75237 7.88353C1.24045 7.88353 0.84007 8.33825 0.920146 8.84444C1.41776 11.9817 4.31481 14.3354 7.66943 13.9607Z"/>
      </svg>
    </ControlButtonWrapper>
  )
}

export default ReloadPointsButton;

export const ControlButtonWrapper = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 8px;
  background: #fff;
  transition: background-color .2s ease;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  
  >svg{
    width: 60%;
    fill: black;
  }
  
  :hover{
    background: #1890ff;

    >svg{
      fill: white;
    }
  }
`
