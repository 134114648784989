import {update,slice} from 'immutability-helper'

import {
    ADD_PRODUCT_ITEM_TO_SELECT, DELETE_SELECTED_PRODUCTS,
    REMOVE_PRODUCT_ITEM_FROM_SELECT,
    SAVE_NEW_PRODUCT,
    SAVE_PRODUCT_CHANGES, TOGGLE_SELECT_ALL_PRODUCTS, 
    SELECT_CATEGORY, 
    GET_KITCHENS_STARTED,
    GET_KITCHENS_ERROR,GET_KITCHENS,
    GET_CATEGORY_ERROR,GET_CATEGORY,
    GET_MENU_ASSORT,GET_MENU_ERROR_ASSORT,GET_CATEGORY_STARTED,
    GET_MENU_STARTED_ASSORT,SAVE_KITHENS_ID,
    MODERATION_MODE,
    SAVE_NEW_PRODUCT_STARTED,
    SAVE_NEW_PRODUCT_ERROR,
    GET_MODERATION_PRODUCT,
    GET_MODERATION_PRODUCT_STARTED,
    SAVE_NEW_PRODUCT_WITH_MODERATION,
    GET_TEG_LIST,GET_TEG_LIST_STARTED,
    GET_TEG_LIST_ERROR,
    saveProductChanges,
    SAVE_PRODUCT_CHANGES_ERROR,
    SAVE_PRODUCT_CHANGES_STARTED,
    CHANGE_STATE_PRODUCT,
    CHANGE_READY_PRODUCT,
    PROD_MOD,
    DEL_PRODUCT,
    DIS_BTN,
    MODALINFO
} from "../actions/AssortmentAction";
const initialState = {
    getKitchensStarted: false,
    getKitchensError: "",
    getKitchens: false,

    getMenuStartedAssort: false,
    getMenuError: "",
    getMenuAssort: false,

    ModerationMode:false,

    getCategoryStarted: false,
    getCategoryError: "",
    getCategory: false,

    getModerationProductStarted:false,
    getModerationProductError:'',
    getModerationProduct:false,

    accessibleKitchens: [],
    selectKithens:null,

    saveNewProductStarted:false,
    saveNewProductError:"",
    saveProduct:false,

    saveProductChanges:false,
    saveProductChangesStarted:false,
    saveProductChangesError:'',

    deleteProduct:false,

    disBTN:false,
    categoryList:[],
    selectedCategory: [{id:'', name: ''}],
    tegList:[],
    productList: [],
    productListWithModeration:[], 
    productListModeration:[],
    prodMod:[],
    forAlone:null,
    productListSorted: [],
    selectedProducts: [],
    selectAllToggle: false,
    modalInfo:false,
    allKitch:true,
}
export const assortmentReducer = (state = initialState, action) => {
    switch (action.type) {
        case  SAVE_PRODUCT_CHANGES_STARTED:
            return{
                ...state,
                saveProductChanges:false,
                saveProductChangesStarted:true,
            }
        case SAVE_PRODUCT_CHANGES:
            
            const newProductsArr = [...state.productList]
            // newProductsArr[action.payload.id] = {...action.payload}
            const prod = action.payload.result
            const NewProd = {...action.payload.data,id:prod,moderation_id:prod,base64:action.payload.data.base64,media:action.payload.data.media && action.payload.data.media !== '0' ? action.payload.data.media : null,isEnabled:'0'}
            const NewProdMod = {...action.payload.data,moderation_id:action.payload.data.moderation_id,id:prod,isMOD:false,media:null,base64:action.payload.data.base64,isEnabled:'0'}
            newProductsArr.map(el=>{
                if(action.payload.data.id_product){
                if(el.id === Number(action.payload.data.id_product)){
                    el.historyModeration.push(NewProd)
                    // update(el.historyModeration,{$push:[NewProd]}) 

                }
              }else if(Number(el.moderation_id) === action.payload.data.moderation_id){
                    el.historyModeration.push(NewProdMod)
            }
            })
            
            return {
                ...state,
                saveProductChanges:true,
                saveProductChangesStarted:false,
                productList: [...newProductsArr],

            }
        case SAVE_PRODUCT_CHANGES_ERROR:
            return{
                ...state,
                saveProductChangesError:action.message
            }
        case 'ALL_KITCHENS':
            return{
                ...state,
                allKitch:action.payload
            }
        case SAVE_NEW_PRODUCT:
            const Prod = {...action.payload.data,moderation_id:action.payload.result}
            Prod.historyModeration = [{...action.payload.data,moderation_id:Number(action.payload.result),id:action.payload.result,isMOD:false,media:null}]
           
            // const b = delete Prod['historyModeration']
            // const ps = Prod.historyModeration.push(b)
            return {
                ...state,
                saveProduct:true,
                saveNewProductStarted:false,
                productList: [Prod, ...state.productList],
                forAlone:Prod

            }
        case TOGGLE_SELECT_ALL_PRODUCTS:
            return {
                ...state,
                selectAllToggle: !state.selectAllToggle,
                selectedProducts: state.selectAllToggle ? [] : [...action.payload],
            }
        case ADD_PRODUCT_ITEM_TO_SELECT:
            return {
                ...state,
                selectAllToggle: false,
                selectedProducts: [...state.selectedProducts, action.payload],
            }
        case REMOVE_PRODUCT_ITEM_FROM_SELECT:
            const tempSelectedProducts = [...state.selectedProducts]
            tempSelectedProducts.splice(state.selectedProducts.indexOf(action.payload), 1)
            return {
                ...state,
                selectAllToggle: false,
                selectedProducts: [...tempSelectedProducts],
            }
        case DELETE_SELECTED_PRODUCTS:
            const tempProductList = [...state.productList]
            state.selectedProducts.map((selectedId) => {
                tempProductList[selectedId] = false
            })
            return {
                ...state,
                selectAllToggle: false,
                productList: [...tempProductList.filter(el => el !== false)],
                selectedProducts: []
            }
        case SELECT_CATEGORY:
            return {
                ...state,
                selectedCategory: state.categoryList[action.payload],
                selectAllToggle: false,
                selectedProducts: []
            }
        case GET_KITCHENS_STARTED:
                return {
                    ...state,
                    getKitchens:false,
                    getKitchensStarted: true,
                };
        case GET_KITCHENS:
            // console.log(action.payload,'sddsdsdsdsd')
            return {
                ...state,
                getKitchensStarted: false,
                getKitchens:true,
                accessibleKitchens: action.payload
            };
        case GET_KITCHENS_ERROR:
            return {
                ...state,
                getKitchensError: action.message
            };
        case GET_CATEGORY_STARTED:
            return {
                ...state,
                getCategory:false,
                getCategoryStarted: true,
            };
        case GET_CATEGORY:
                return {
                    ...state,
                    getCategoryStarted: false,
                    getCategory:true,
                    categoryList: action.payload
                };
            case GET_CATEGORY_ERROR:
                return {
                    ...state,
                    getCategoryError: action.message
                };  
            case GET_MENU_STARTED_ASSORT:
                return {
                    ...state,
                    getMenuAssort:false,
                    getMenuStartedAssort: true,
                };
            case GET_MENU_ASSORT:
               
                return {
                    ...state,
                    getMenuStartedAssort: false,
                    getMenuAssort:true,
                    productList: action.payload
                };
            case GET_MENU_ERROR_ASSORT:
                return {
                    ...state,
                    getMenuError: action.message
                };  
            case SAVE_NEW_PRODUCT_STARTED:
                return{
                    ...state,
                    saveProduct:false,
                    saveNewProductStarted:true
                };
            case SAVE_NEW_PRODUCT:
                return{
                    ...state,
                    productList: [action.payload, ...state.productList],
                }
            case SAVE_NEW_PRODUCT_ERROR:
                return{
                    ...state,
                    saveNewProductError:action.message
                }
             case GET_MODERATION_PRODUCT:
                //  console.log(action.payload,'POPA')
                return{
                     ...state,
                    getModerationProductStarted:false,
                    getModerationProduct:true,
                    productListModeration:action.payload
                    };
            case GET_MODERATION_PRODUCT_STARTED:
                return{
                    ...state,
                    getModerationProduct:false,
                    getModerationProductStarted:true,
                    }
            case SAVE_NEW_PRODUCT_ERROR:
                return{
                    ...state,
                    getModerationProductError:action.message
                    }       

            case GET_TEG_LIST_STARTED:
                return{
                    ...state,
                    getTegListStarted:true,
                    getTegList:false
                }
            case GET_TEG_LIST:
                return{
                    ...state,
                    getTegListStarted:false,
                    getTegList:true,
                    tegList:action.payload
                }
            case GET_TEG_LIST_ERROR:
                return{
                    ...state,
                    getTegListError:action.message
                }
            case CHANGE_STATE_PRODUCT:
                const ids = action.payload.ids
                const enables = action.payload.enables
                // const HmProd = state.productList.filter(els=> els.id === els.historyModeration.slice(-1)[0].id_product)
                const newProducts = state.productList.map(i=>{
                if(action.payload.mod === 'forMod'){
                    if(Number(action.payload.id_mod) === i.id){
                        return {...i,historyModeration:i.historyModeration,isEnabled:action.payload.enables}
                    }else{
                        return i
                    }
                }else{
                    if(i.id === ids){
                        return {...i,isEnabled:enables}
                    }else{
                        return i
                    }   
                 }
                }
                )
                return{
                    ...state,
                    productList:[...newProducts]
                }
                // el.historyModeration.slice(-1)[0]
            case SAVE_KITHENS_ID:
                return {
                    ...state,
                    selectKithens: action.payload
                }
            case MODERATION_MODE:
                return{
                    ...state,
                    ModerationMode:action.payload
                }
            case PROD_MOD:
                return{
                    ...state,
                    prodMod:action.payload
                }
            case CHANGE_READY_PRODUCT:
             const prods = action.payload.prods
             const pr = state.productListModeration.filter(els=>els[0].id !== prods)
                return{
                    ...state,
                    productListModeration:pr

                }
            case DEL_PRODUCT:
                const ind = state.productList.findIndex(el=>el.id === +action.payload.id_product)
                const AllProd = [...state.productList]
                const newsProd = AllProd[ind].historyModeration.pop(-1)
                    return{
                        ...state,
                        productList:AllProd
                    }
            case DIS_BTN:
                return {
                    ...state,
                    disBTN:action.payload
                }
            case MODALINFO:
                return {
                    ...state,
                    modalInfo:action.payload
                }
            default:
                return state;
    }
}
