import React, {useEffect, useState} from "react";
import styled from "styled-components";

const moneyFormat = str => {
  str = str + ''
  return str.split(/([0-9]{3}$)/).join(' ')
}

const PayVariant = ({sumPriceResultCash, sumPriceResult, fullPaymentData = {}}) => {

  const cashPay = +sumPriceResultCash ? (+sumPriceResultCash).toFixed(0) : 0
  const noCashPay = +sumPriceResult ? (+sumPriceResult - +sumPriceResultCash).toFixed(0) : 0

  const [simpleDataView, setSimpleDataView] = useState(false)
  const [firstDelimiter, setFirstDelimiter] = useState(false)
  const [secondDelimiter, setSecondDelimiter] = useState(false)

  useEffect(() => {
    if(Object.keys(fullPaymentData).length) {
      setSimpleDataView(true)
      const cashAndCard = (+fullPaymentData.card !== 0) && (+fullPaymentData.cash !== 0)
      setFirstDelimiter(cashAndCard)
      const cardAndPoints = fullPaymentData.card && fullPaymentData.points
      setSecondDelimiter(cashAndCard && cardAndPoints)
    } else {
      setSimpleDataView(false)
    }
  }, [fullPaymentData]);


  return (
    <>
      <PayVariantWrapper>
        {
          simpleDataView ? (
            <SimpleView>
              {
                fullPaymentData.card && +fullPaymentData.card !== 0 && (
                  <PayVariantCard>
                    {fullPaymentData.card}
                  </PayVariantCard>
                )
              }
              {
                firstDelimiter && <Delimiter/>
              }
              {
                fullPaymentData.cash && +fullPaymentData.cash !== 0 && (
                  <PayVariantCash>
                    {fullPaymentData.cash}
                  </PayVariantCash>
                )
              }
              {
                secondDelimiter && <Delimiter/>
              }
              {
                fullPaymentData.points && +fullPaymentData.points !== 0 && (
                  <PayVariantPoints>
                    {fullPaymentData.points}
                  </PayVariantPoints>
                )
              }
            </SimpleView>
          ) : (
            <>
              { +noCashPay !== 0 && <PayVariantCard>{moneyFormat(noCashPay)}</PayVariantCard> }
              { ((+cashPay !== 0) && (+noCashPay !== 0)) && <Delimiter/> }
              { +cashPay !== 0 && <PayVariantCash>{moneyFormat(cashPay)}</PayVariantCash> }
            </>
          )
        }

      </PayVariantWrapper>
    </>
  )
}

export default PayVariant;

const PayVariantWrapper = styled.div`
  display: flex;
  align-items: center;
  
  >p{
    font-size: 13px;
    margin: 0;
  }
`

const SimpleView = styled.div`
  display: flex;
  gap: 5px
`

const PayVariantCash = styled.p`
  color: #2196F3;
`
const PayVariantCard = styled.p`
  color: #4CAF50;
`
const PayVariantPoints = styled.p`
  color: #999999;
`
const Delimiter = styled.div`
  width: 3px;
  height: 3px;
  background: #999;
  margin: 0 4px;
`
