import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import logo from '../images/logo.svg'

const Logo = ({path,setPath}) => {

    return (
        <Link to={'/'}>
        <span style={{display:'block'}}>
        <LogoWrapper>
            <img src={logo} alt="logo"/>
        </LogoWrapper>
        </span>
        </Link>
    )
}

export default Logo;

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: #3D425D;
  border-radius: 4.08675px;
  cursor: pointer;
`
