import {createAction, createSlice} from "@reduxjs/toolkit"

const initialState = {
  isAuth: localStorage.getItem('token') || false,
  authError: "",
  errorMessage: null,
  recoveryPasswordStatus: null,
  registerStatus: null,
}

export const registrationByEmailAction = createAction('profile/registrationByEmail')
export const recoveryPasswordAction = createAction('profile/recoveryPassword')
export const createTokenByPasswordAction = createAction('profile/createTokenByPassword')
export const activateAccountAction = createAction('profile/activateAccount')
export const activatePasswordAction = createAction('profile/activatePassword')

const profileReducer = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    getClientProfile(state) {
      state.isAuth = localStorage.getItem('token') || false
    },
    auth(state) {
      //look in auth saga
      state.errorMessage = null
    },
    tokenCreationSuccessful(state) {
      state.isAuth = true
    },
    tokenCreationRejected(state, action) {
      state.errorMessage = action.payload
    },
    deleteToken(state) {
      state.isAuth = false
    },
    recoveryPasswordStatusSwitch(state, action) {
      state.recoveryPasswordStatus = action.payload
    },
    resetRecoveryPasswordStatus(state) {
      state.recoveryPasswordStatus = initialState.recoveryPasswordStatus
    },
    registerStatusSwitch(state, action) {
      state.registerStatus = action.payload
    },
    resetStatus(state) {
      state.recoveryPasswordStatus = initialState.recoveryPasswordStatus
      state.registerStatus = initialState.registerStatus
    },
  }
})

export const {
  getClientProfile,
  auth,
  tokenCreationSuccessful,
  tokenCreationRejected,
  deleteToken,
  recoveryPasswordStatusSwitch,
  resetRecoveryPasswordStatus,
  registerStatusSwitch,
  resetStatus,
} = profileReducer.actions
export default profileReducer.reducer
