import React from "react";
import styled from "styled-components";
import HeaderNav from "./HeaderNav";
import AccountView from "../components/AccountView"
import GlobalSelectKitchen from "../components/GlobalSelectKitchen";

const Header = ({path,setPath,testPath}) => {

    return (
        <HeaderWrapper>
            <div>
                <HeaderNav {...{path,setPath,testPath}}/>
            </div>
            <div style={{marginLeft:'auto'}}>
                <GlobalSelectKitchen/>
            </div>
            <div >
                <AccountView {...{path,setPath,testPath}} />
            </div>

        </HeaderWrapper>
    )
}

export default Header;

const HeaderWrapper = styled.header`
  /* position: fixed; */
  border: 1px solid #DDDDDD;
  background: #F4F5F9;
  display: flex;
  align-items: center;
  /* grid-area: header; БАГАЕТСЯ */
  padding: 10px 10px;
  z-index: 100;
  position: fixed;
  left: 64px;
  width: calc(100% - 64px);
  height: 64px;
  /* left: 64px; */
`
