import React, {memo} from "react";
import styled from "styled-components";
import Courier from "../Courier"


const CourierOffLineListItem = ({courierData}) => {

  return <Courier {...{courierData}} />
}

export default memo(CourierOffLineListItem);
