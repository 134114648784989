import { DatePicker, TimePicker } from 'antd'
import React from 'react'
import styled from 'styled-components'
import CheckBox from '../../../uikit/CheckBox'
const CourierDel = ({week}) => {
  return (
    <Wrapper>
        <Content>
            <ItemsContent>
                <CheckBox/>
            </ItemsContent>
            <ItemsContent>
                {week.name}
            </ItemsContent>
            <ItemsContent>
            <TimePicker
                style={{width:300}}
                format='HH:mm'
            />
            </ItemsContent>

            <ItemsContent>
                <TimePicker
                style={{width:300}}
                format='HH:mm'
                />
            </ItemsContent>
        </Content>
    </Wrapper>
  )
}

export default CourierDel

const Wrapper= styled.div`
 
`

const Content = styled.div`
display: grid;
grid-template-columns: repeat(4,1fr); 
justify-items: start;
margin-top: 10px;
border-bottom: 1px solid rgb(169,169,169,0.5);
`
const ItemsContent = styled.div`
padding: 10px;
`