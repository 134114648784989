import React, { useEffect, useState } from "react";
import {useDispatch, useSelector} from 'react-redux';
import styled, {keyframes} from "styled-components";
import moment from "moment";
import {
    clearStore,
    getDays,
    getRests,
    hideBlockPopup,
    resetActivePeriod,
    rollingBackChangesEditMode,
    setActiveRestToStore,
    sliderShiftBack,
    sliderShiftForward
} from "../../actions/MenuByDayActions";
import FoodList from "../../components/MenuByDay/FoodList";
//image
import arrowLeft from '../../images/menu_by_day/arrowLeft.svg'
import arrowRight from '../../images/menu_by_day/arrowRight.svg'
import {Select} from "antd";
import {deliveryCycleInfoMessage} from "../../actions/ModalActions";
import MenuCard from "../../components/MenuByDay/MenuCard";
import PublicBar from "../../components/MenuByDay/PublicBar";
import BlockPopup from "../../components/MenuByDay/BlockPopup";
import CopyPeriodsControl from "../../components/MenuByDay/CopyPeriodsControl";
import Plug from "../../components/MenuByDay/Plug";
import {getPermissionsAction} from "../../reducers/toolkit/mainAppReducer"

//Main component
const MenuByDay = (props) => {
    // get main state
    const mainSettings = useSelector(store => store.appMain);
    const {
        permissions,
    } = mainSettings;
    const state = useSelector(store => store.menuByDay);
    const SelectedKitchens = useSelector(store => store.appMain.selectedKitchens)

    //get parts
    const {
        getRestsIsStarted,
        getRestsIsSuccess,
        getDaysPeriodsIsStarted,
        getDaysPeriodsIsSuccess,
        getMenuIsStarted,
        getMenuIsSuccess,
        today,
        accessibleRests,
        sliderState,
        publicBarState,
        editMode,
        loadPopupArea,
        foodListState,
        activeFoodList,
        preparedFoodListData,
        showWarning,
        warningMessage,
        showSendPreloader,
        copyMenuData,
        copyMenuSendingStarted,
        copyMenuSendingSuccess,
        menuSendSuccess,
        suspendSendingSuccess,
    } = state;
    //to take apart
    const {
        dayRange,
        currentShowDays,
        minVal,
        maxVal,
        menuInfoPeriod,
        periods
    } = sliderState;
    const {
        activePeriod,
        status,
        amountRestrictionsChecker,
    } = publicBarState;

    // set dispatcher
    const dispatch = useDispatch();
    const {Option} = Select;

    const [activeRest, setActiveRest] = useState(null);
    const [dateRange, setDateRange] = useState(null);
    const changeContract = (value) => {
        setActiveRest(value)
    };

    const dateFrom = moment(currentShowDays[0]).subtract(28, 'days').format("YYYY-MM-DD");
    const dateTo = moment(currentShowDays[currentShowDays.length]).add(28, 'days').format("YYYY-MM-DD");

    const getPeriodsByDay = (activeRest) => {
        setDateRange({
            dateFrom: dateFrom,
            dateTo: dateTo,
        })

        return getDays(dateFrom, dateTo, SelectedKitchens.id);
    };
    const forwardClickHandle = () => {
        if(editMode) {
            dispatch(rollingBackChangesEditMode());
        }
        dispatch(sliderShiftForward());
        getPeriodsByDay(SelectedKitchens.id);
    };
    const backClickHandle = () => {
        if(editMode){
            dispatch(rollingBackChangesEditMode());
        }
        dispatch(sliderShiftBack());
        dispatch(getPeriodsByDay(SelectedKitchens.id));
    };
    const preloader = () => {
        return (
            <PreloaderWrapper>
                <Preloader />
            </PreloaderWrapper>
        )
    };
    const closePopupHandle = () => {
        dispatch(hideBlockPopup());
    };
    useEffect(() => {
        if( menuSendSuccess ) {
            dispatch(deliveryCycleInfoMessage('saved', ))
        }
    }, [menuSendSuccess])
    useEffect(() => {
        return function cleanup() {
            dispatch(clearStore())
        }
    }, []);
    useEffect(() => {
        if( !Object.values(mainSettings.permissions).length ) {
            dispatch(getPermissionsAction());
        }
    }, []);

    useEffect(() => {
        dispatch(getRests());
    }, [permissions]);

    useEffect(() => {
        if(SelectedKitchens.name !== 'Не выбрано') {
            dispatch(resetActivePeriod());
            dispatch(getPeriodsByDay(SelectedKitchens.id));
            dispatch(setActiveRestToStore(SelectedKitchens.id));
        }
    }, [SelectedKitchens]);
    useEffect(() => {
        if( suspendSendingSuccess ) {
            dispatch(getPeriodsByDay(SelectedKitchens.id))
        }
    }, [suspendSendingSuccess]);
    const drawSelector = () => {
        return (
            <SelectorWrapper>
                <header>
                    {/* <h2>
                        {
                            getRestsIsSuccess
                                ? "Выбор кухни:"
                                : "Загрузка..."
                        }

                    </h2> */}
                    {/* {
                        getRestsIsStarted
                            ?
                            preloader()
                            :
                            null
                    } */}
                </header>
                {/* {
                    getRestsIsSuccess
                        ?
                        <Select
                            style={{width: 300, marginLeft: 20}}
                            value={activeRest}
                            onChange={changeContract}
                        >
                            {accessibleRests.map((v) => {
                                return (
                                    <Option key={v.id} value={v.id}>
                                        {v.name}
                                    </Option>
                                )
                            })}
                        </Select>
                        : <div style={{height: 34}} />

                } */}
            </SelectorWrapper>
        )
    };
    const drawSlider = () => {
        return (
            <WeeklySlider>
                <Arrow
                    direction="left"
                    onClick={() => {
                        if(minVal > 1) {
                            backClickHandle();
                        }
                    }}
                >
                    <img src={arrowLeft} alt="left"/>
                </Arrow>
                <Slider>
                    {
                        currentShowDays ?
                            currentShowDays.map(el => {
                                const elementDate = moment(el).format("YYYY-MM-DD");
                                const periodIsDefined = menuInfoPeriod.reduce((acc, el) => {
                                    if(el.day === elementDate) {
                                        acc.push({...el})
                                    }
                                    return acc;
                                }, []);
                                return (
                                    <MenuCard
                                        key={el + "123dsfnwjebf3iu2h2idn"}
                                        date={el}
                                        isToday={el === moment(today.date).format()}
                                        periodIsDefined={periodIsDefined}
                                        menuInfoPeriod={menuInfoPeriod}
                                        editMode={editMode}
                                        periods={periods}
                                        activeRest={SelectedKitchens.id}
                                    />
                                )
                            }) :
                            null
                    }
                </Slider>
                <Arrow
                    direction="right"
                    onClick={() => {
                        if (maxVal < dayRange.length){
                            forwardClickHandle();
                        }
                    }}
                >
                    <img src={arrowRight} alt="right"/>
                </Arrow>
            </WeeklySlider>
        )
    };
    const drawPublicBar = () => {
        return (
            <>
                {
                    Object.values(activePeriod).length && SelectedKitchens.name !== 'Не выбрано'
                        ?
                        <PublicBar
                            permissions={permissions}
                            activePeriod={activePeriod}
                            editMode={editMode}
                            amountRestrictionsChecker={amountRestrictionsChecker}
                            preparedFoodListData={preparedFoodListData}
                            showSendPreloader={showSendPreloader}
                            dateRange={dateRange}
                            dateFrom={dateFrom}
                            dateTo={dateTo}
                            getDaysPeriodsIsSuccess={getDaysPeriodsIsSuccess}
                        />
                        :
                        SelectedKitchens.name !== 'Не выбрано' && getDaysPeriodsIsSuccess
                            ?
                            <Plug type="SELECT_PERIOD"/>
                            :
                            null
                }
            </>
        )
    };
    const drawFoodList = () => {
        return (
            <>
                {
                    Object.values(activePeriod).length && activeFoodList.length === 0 && !editMode
                        ?
                        <>
                            <Plug type="EDIT_MODE"/>
                            <CopyPeriodsControl
                                copyMenuSendingSuccess={copyMenuSendingSuccess}
                                copyMenuSendingStarted={copyMenuSendingStarted}
                                activePeriod={activePeriod}
                                copyMenuData={copyMenuData}
                                dayRange={dayRange}
                                periods={periods}
                                dateRange={dateRange}
                            />
                        </>
                        : null

                }
                {
                    Object.values(activePeriod).length && SelectedKitchens.name !== 'Не выбрано'
                        ?
                        editMode
                            ? <FoodList foodListState={foodListState} editMode={editMode}/>
                            : <FoodList foodListState={activeFoodList} editMode={editMode}/>
                        :
                        null
                }
            </>
        )
    };

    return (
        <MenuByDayWrapper>
            {
                showWarning
                    ?
                    <Warning>
                        <p>{warningMessage}</p>
                    </Warning>
                    :
                    null
            }
            {
                loadPopupArea
                    ?
                        <PopupSection onClick={closePopupHandle}>
                            <BlockPopup
                                currentShowDays={currentShowDays}
                                closePopupHandle={closePopupHandle}
                                activePeriod={activePeriod}
                                getPeriodsByDay={getPeriodsByDay}
                                dateFrom={dateFrom}
                                dateTo={dateTo}
                            />
                        </PopupSection>
                    :
                    null
            }
            <Container>
                {
                    drawSelector()
                }
                {
                    getDaysPeriodsIsSuccess 
                        ?
                        drawSlider()
                        :
                        getDaysPeriodsIsStarted
                            ? <SliderPlug style={{marginTop: 25}}/>
                            : null

                }
            </Container>

            {
                drawPublicBar()
            }
            {
                getMenuIsStarted
                    ?
                    <FoodListPlug>
                        <SliderPlug style={{width: '90%'}}/>
                        <SliderPlug style={{width: '90%'}}/>
                        <SliderPlug style={{width: '90%'}}/>
                        <SliderPlug style={{width: '90%'}}/>
                    </FoodListPlug>
                    : getMenuIsSuccess
                        ? drawFoodList()
                        : null

            }

        </MenuByDayWrapper>
    )
}

const MenuByDayWrapper = styled.div`
`
const PopupSection = styled.div`
  position:absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  background: rgba(255, 255, 255, 0.7);
`
const Container = styled.div`
  padding: 10px 25px 0;
`
const SelectorWrapper = styled.div`
  align-self: flex-start;
  margin-right: auto;
  width: 300px;
  header {
    margin: 0 0 10px 20px;
    width: 100%;
    display: flex;
    align-items: center;
    h2 {
      margin-right: 10px;
      text-align: left;
      margin-bottom: 0;
      text-wrap: none;
    }
  }
`
const WeeklySlider = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 5px 5px 5px;
`
const Arrow = styled.div`
  user-select: none;
  width: 36px;
  height: 36px;
  background: #C4C4C4;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  
  :hover {
    background: #6e6e6e;
    transition: background-color .2s ease;
  }
`
const Slider = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-column-gap: 5px;
  padding: 5px 10px;
  
  ${MenuCard}:first-child {
    opacity: 0;
  }
`
const sliderPlugAnimation = keyframes`
  100% {
    background-position: 100% 100%;
  }
`
const FoodListPlug = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 0 25px;
`
const SliderPlug = styled.div`
  width: 100%;
  margin: 0 auto;
  border-radius: 6px;
  height: 195px;
  background: linear-gradient(45deg,
      rgba(229,229,229,1) 0%, 
      rgba(229,229,229,1) 20%, 
      rgba(255,255,255,1) 30%, 
      rgba(229,229,229,1) 45%, 
      rgba(229,229,229,1) 62%, 
      rgba(255,255,255,1) 80%, 
      rgba(229,229,229,1) 90%, 
      rgba(229,229,229,1) 100%
  );
  background-size: 300% 200%;
  animation: ${sliderPlugAnimation} 4s ease infinite alternate;
`
const slideWarningDown = keyframes`
  from {
    top: -100px;
  }

  to {
    top: 0;
  }
`;
const Warning = styled.div`
  position: fixed;
  top: -100px;
  left: calc(80% - 150px);
  width: 300px;
  height: 100px;
  background: #fff;
  z-index: 999;
  border: 1px solid lightskyblue;
  border-radius: 0 0 5px 5px;
  animation: ${slideWarningDown} .4s ease-out forwards;
`
const PreloaderWrapper = styled.div`
  position:relative;
  width: 25px;
  height: 25px;
  box-sizing: border-box;
`
const preloaderAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  

  to {
    transform: rotate(360deg);
  }
`
const preloaderAnimationBA = keyframes`
  from {
    box-shadow: black 0px 0px 0px;
  }

  to {
    box-shadow: black 0px 0px 10px;
  }
`
const Preloader = styled.div`
  animation: ${preloaderAnimation} 1s ease infinite;
  position: relative;
  width: 100%;
  height: 100%;
  :before {
    position:absolute;
    left: 0;
    top: calc(50% - 2.5px);
    content: '';
    width: 5px;
    height: 5px;
    background: #000;
    border-radius: 50%;
    animation: ${preloaderAnimationBA} .5s ease infinite alternate;
  }
  :after{
    position:absolute;
    right: 0;
    top: calc(50% - 2.5px);
    content: '';
    width: 5px;
    height: 5px;
    background: #000;
    border-radius: 50%;
    animation: ${preloaderAnimationBA} .5s ease infinite alternate;
  }
`

export default MenuByDay;
