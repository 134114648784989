import React, {memo, useCallback} from "react";
import styled from "styled-components";

const BurgerMenu = ({toggleHandler, toggle, content}) => {
  return (
    <BurgerWrapper onClick={toggleHandler} {...{toggle}}>
      {
        content ? (
          content
        ) : (
          <InnerWrapper className={toggle ? 'animateMargin' : ""}>
            <span className={toggle ? 'animateRotate' : ""}/>
            <span className={toggle ? 'animateHide' : ""}/>
            <span className={toggle ? 'animateRotateReverse' : ""}/>
          </InnerWrapper>
        )
      }
    </BurgerWrapper>
  )
}

export default memo(BurgerMenu);

const BurgerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  background: #fff;
  border-radius: 6px;
  transition: all .2s ease;
  cursor: pointer;
  
  :hover {
    background: #3498DB;
    
    >svg{
      fill: #fff;
    }
    
    >div>span{
      background: #fff;
    }
  }
`
const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 16px;
  height: 16px;

  .animateHide{
    opacity: 0;
  }
  .animateRotate{
    width: 134%;
    transform: rotate(45deg);
  }
  .animateRotateReverse{
    width: 134%;
    transform: rotate(-45deg);
  }
  
  >span {
    width: 100%;
    height: 2px;
    background: #222222;
    border-radius: 6px;
    transition: all .2s ease;
    transform-origin: 1px 1px;
  }
`
