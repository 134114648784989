import React from "react";
import StorageHeader from "./Storage/StorageHeader";
import WaybillsHeader from "./Waybills/WaybillsHeader";
import styled from "styled-components";
import {Select} from "antd";


const columnFlex = {
    display: 'flex',
    flexDirection: 'column',
}
const rowFlex = {
    display: 'flex',
    flexDirection: 'row',
}
export const selectStyles = {
    width: 200,
}

const Header = ({targetPath, setTargetPath}) => {
    const renderHeaderByPath = () => {
        const storageHeader = <StorageHeader {...{
            rowFlex,
            columnFlex
        }}/>

        const waybillHeader = <WaybillsHeader {...{
            targetPath,
            columnFlex,
            rowFlex,
        }}/>

        switch (targetPath) {
            case 'storage':
                return storageHeader
            case 'waybills':
                return waybillHeader
            default:
                return storageHeader
        }
    }

    return (
        <div>
            <InnerNav targetPath={targetPath}>
                <a onClick={() => setTargetPath('storage')}>Склад</a>
                <a onClick={() => setTargetPath('waybills')}>Накладные</a>
            </InnerNav>
            {
                renderHeaderByPath()
            }
        </div>
    )
}

export default Header;

const InnerNav = styled.nav`
  display: flex;
  
  a {
    font-weight: 800;
    text-decoration: none;
    font-size: 20px;
    color: #888;
    margin-right: 20px;
  }
  a:nth-child(1) {
    color: ${({targetPath}) => targetPath === 'storage' && '#6167ff'};
  }
  a:nth-child(2) {
    color: ${({targetPath}) => targetPath === 'waybills' && '#6167ff'};
  }
  a:last-child{
    margin-right: 0;
  }
  
`
