import React,{useState,useEffect} from 'react'
import styled from 'styled-components'
import { DatePickReports } from './DatePickReports'
import { NavReports } from './NavReports'

export const HeaderReports = ({
  startDate,setStartDate,endDate,
  setEndDate,cities,setCities,selectedCity,
  setSelectedCity,handleInfo,setSelectedBank,selectedBank,selectInfo,
   statusFields,setStatusField,info,resState,actDate,setActDate}) => {
    const [totalsFin,setTotalFin] = useState({})

    
   
  
  
    return (
    <WrapperHeader>
        <DatePickReports {...{startDate,setStartDate,endDate,setEndDate,cities,setCities,selectedCity,setSelectedCity,handleInfo,setSelectedBank,selectedBank,selectInfo, statusFields,setStatusField,actDate,setActDate}}/>
    </WrapperHeader>
  )
}

const WrapperHeader = styled.div`
display: grid;
grid-template-rows: 1fr;
`

const TotalFinance = styled.div`
width: auto;
margin-left: 30px;
margin-right: 163px;
margin-bottom: 20px;
background: #FFFFFF; 
box-shadow: 0px 4px 6px rgba(206, 206, 206, 0.25);
display: flex;
flex-direction: column;
border-radius: 8px;
font-family: 'Roboto';
font-style: normal;
`
const HeaderFinance = styled.div`
display:flex;
flex-direction: row;
justify-content: space-around;
`
const ContentFinance = styled.div`
display:flex;
flex-direction: row;
justify-content: space-around;
`
const Items = styled.p`
height: auto;
`