import React, {useEffect, useState,useRef}  from 'react'
import styled from "styled-components";
import {
    DEFAULT_INPUT_STYLE_FOR_AREA,
    DEFAULT_INPUT_STYLE_MOD,
    GIRD_CONSISTENCY_SETTINGS_MOD,
    PRODUCT_LIST_ELEMENT_CONTAINERMOD
} from "../Assort/commonStyles";
import {nanoid} from "nanoid";
import {useDispatch, useSelector} from "react-redux";
import {
    changeReadyProduct,
} from "../../actions/AssortmentAction";
import {sr} from '../../utils'
import { Aside } from './Aside';
import ProductItemMod from './ProductItemMod';
import { UIcomponents } from './UIcomponents';
import ImageContainer from '../Assort/Components/ImageUI/ImageContainer';
import { deliveryCycleInfoMessage } from '../../actions/ModalActions';
import {Input} from 'antd'
import Time from '../../images/Time_light.png'
import { motion } from 'framer-motion';
import AdminCheckBox from '../../UI_ADMIN/AdminCheckBox';
import Delete from '../../images/Trash_light.png'

function ProductItem({
    selectedCategory,
    addNewMode,
    productItem = [],
    id,
    accessibleKitchens,
    tegs,
    productItemWithActive,
    productItemMod,
    kitchens,
    setActions
}) 

    {
    
    const prodItem = (Wfind) =>{
    
        if(productItemWithActive.dataItemProduct){
            return  productItemWithActive.dataItemProduct[Wfind]  || '-'
        }else{
            return  productItemMod[0] &&  productItemMod[0][Wfind]  || '-'
        }
    }
    const dispatch = useDispatch()
    const tegList = useSelector(store=>store.assortment.tegList)
    const [productStateNew,setProductStateNew]=useState({
        name:productItem[0].name ,
        price:productItem[0].price ,
        weight: productItem[0].weight ,
        calories: productItem[0].calories ,
        proteins:productItem[0].proteins ,
        fats:productItem[0].fats ,
        carbo:productItem[0].carbo ,
        productPhoto:productItem[0].productPhoto ,
        description:productItem[0].description ,
    })
 

    const [productCategoriesState, setProductCategoriesState] = useState([])
    const [tagId,setTegId] = useState([])
    const [cause,setCause] = useState('')
    const [jopaHeight, setJopaHeight] = useState(0);
    console.log(kitchens,'kitchenskitchens')
    const jopaRef = useRef(0);

    useEffect(() => {
        if ( selectedCategory ) {
            if( !productCategoriesState.includes(selectedCategory) ) {
                setProductCategoriesState([selectedCategory])
            }
        }
    }, [selectedCategory])

  console.log(productItemWithActive,'productItemWithActive')
  
    useEffect(() => {
         if (productItem.tegs_product) {
             if( !tagId.includes(productItem.tegs_product) ) {
                setTegId(productItem.tegs_product)
             }
         }
     }, [tegList])


    useEffect(() => {
        setProductStateNew()
        if (addNewMode) {
            setProductCategoriesState([selectedCategory.name])
        } 

    }, [])

    useEffect(() => {
        setJopaHeight(jopaRef.current ? jopaRef.current.clientHeight : 0)
    }, [])

    function negativeHandler(){
        if(cause.length > 0){
        sr('MenuSOKitchenService','negative',{
            moderation_id:productItemMod[0].id,
            note:cause
        }).then(res=>setActions(true))
        console.log(productItemMod[0].id)
        setActions(false)

        // dispatch(changeReadyProduct({prods:productItemMod[0].moderation_id}))
        dispatch(deliveryCycleInfoMessage('modeCustom','Продукт отправлен на доработку'))
    }else{
        dispatch(deliveryCycleInfoMessage('empty_fields'))
    }
    } 
    function positiveHandler(){
        sr('MenuSOKitchenService','positive',{
            moderation_id:productItemMod[0].id,
        }).then(res=>setActions(true))

        console.log(productItemMod[0].id)
        setActions(false)

        // dispatch(changeReadyProduct({prods:productItemMod[0].moderation_id}))
        dispatch(deliveryCycleInfoMessage('modeCustom','Продукт одбрен'))

    }
    function deleteHandler(){
        sr('MenuSOKitchenService','deleteModerationElement',{
            id:productItemMod[0].id
        }).then(res=>setActions(true))
        setActions(false)

        // dispatch(changeReadyProduct({prods:productItemMod[0].moderation_id}))
        dispatch(deliveryCycleInfoMessage('modeCustom','Продукт удален'))

    }  
    const drawCategoryList = () => {
        if(productItemWithActive.dataItemProduct){
            if(productItemWithActive.dataItemProduct.tegs_product){
        const categoryList = Object.values(productItemWithActive.dataItemProduct.tegs_product).length
            ? tegs.map(category => {if(productItemWithActive.dataItemProduct.tegs_product.includes(category.id)){
                return (
                    <ProductCategory key={nanoid()}>
                        <AdminCheckBox
                        />
                        <p style={{marginLeft:8}}>{category.location.ru_RU}</p>
                    </ProductCategory>
                )
            }
        })
            :
            null
        return (
            <ProductCategoryWrapper>
                {categoryList}
            </ProductCategoryWrapper>
        )
    }}else{
        if(productItemMod[0]){
        const categoryList = Object.values(productItemMod[0].tegs_product).length
            ? tegs.map(category => {if(productItemMod[0].tegs_product.includes(category.id)){
                return (
                    <ProductCategory key={nanoid()}>
                        <AdminCheckBox
                        />
                        <p style={{marginLeft:8}}>{category.location.ru_RU}</p>
                    </ProductCategory>
                )
            }
        })
            :
            null
        return (
            <ProductCategoryWrapper>
                {categoryList}
            </ProductCategoryWrapper>
        )
    }}
    }
    
const  getPhotoWrapp=(vals)=>{

    if(vals){
    if(typeof vals === 'number' ){
     return   <ImageContainer
        media={vals}
        style = {{width:'90px',height:'90px',borderRadius:'6px'}}
        />
      }if (typeof vals === 'object'){
    return    <ImageContainer
        tempPic={vals[0] ? vals[0].name : null}
        style = {{width:'90px',height:'90px',borderRadius:'6px'}}
        border = {'mod'}
        />
}
    }
}
    return ( 
    // <MainOpen onClick={toggleOpen}>
    <Wrapper>
        <ProdWrapp>
            <InfoProdTime>
                {
                  productItemWithActive.dataItemProduct ?
                  <>
                  <StateProd active={true}>
                     Активный
                  </StateProd>
                  <div
                  style={{
                      display:'flex',
                      alignItems:'center',
                      marginLeft:16
                  }}
                  >
                  <img width={24} height={24} src={Time}/>
                  <div style={{fontSize:16,fontWeight:400,color:'#2C2E32',marginLeft:8}} >{productItemWithActive.dataItemProduct.date_and_time_changed? productItemWithActive.dataItemProduct.date_and_time_changed : '--:--'}</div>
                  </div>
                  </>
                  :
                  productItemMod ?
                  <>
                  <StateProd  active={false}>
                      Новый
                  </StateProd>
                  <div
                  style={{
                    display:'flex',
                    alignItems:'center',
                    marginLeft:16
                }}
                  >
                      <img width={24} height={24} src={Time}/>
                      <div style={{fontSize:16,fontWeight:400,color:'#2C2E32',marginLeft:8}}>{productItemMod[0].date_and_time_of_dispatch ? productItemMod[0].date_and_time_of_dispatch : '--:--'}</div>
                  </div>
                  </>
                :
                ''
                }
                {productItemMod ?
                <div style={{marginLeft:'auto',alignSelf:'center',fontSize:17,fontWeight:600}}>
                  {kitchens.map(el=>el.id === productItemMod[0].account ?  el.name : null)}
                </div>
                :
                <div style={{marginLeft:'auto',alignSelf:'center',fontSize:17,fontWeight:600}}>
                    {kitchens.map(el=>el.id === productItemWithActive[0].account ?  el.name : null)}
                </div>
                }

                
            </InfoProdTime>

            <MainInfoProd>
            {
             productItemMod &&
            <>
                    <div>
                     <InfoHeader
                     >
                        Название
                     </InfoHeader>
                     <InfoItem
                     style={{
                         fontSize:20,
                         fontWeight:500,
                         color:'#2C2E32',
                         padddingLeft:76
                     }}
                     >
                        {prodItem('name')}
                     </InfoItem>
                    </div>
                    <div>
                     <InfoHeader>
                     Состав
                     </InfoHeader>
                     <InfoItem
                     style={{
                        fontSize:14,
                        fontWeight:400,
                        color:'#2C2E32'
                    }}
                     >
                        {prodItem('description')}
                     </InfoItem>
                    </div>
                    <div>
                     <InfoHeader>
                     Цена, ₽
                     </InfoHeader>
                     <InfoItem
                     style={{
                        fontSize:20,
                        fontWeight:500,
                        color:'#2C2E32'
                    }}
                     >
                        {`${prodItem('price')} ₽`}
                     </InfoItem>
                    </div>
                    <div>
                     <InfoHeader>
                     Фото
                     </InfoHeader>
                     <InfoItem>
                        {productItemWithActive 
                        ? getPhotoWrapp(productItemWithActive.dataItemProduct.media)
                        : getPhotoWrapp(productItemMod[0].temporary_picture)
                        }    
                     </InfoItem>
                    </div>
                    <div>
                     <InfoHeader>
                     Вес
                     </InfoHeader>
                     <InfoItem
                     style={{
                        fontSize:16,
                        fontWeight:500,
                        color:'#2C2E32'
                    }}
                     >
                        {prodItem('weight') !== '-' ? `${prodItem('weight')} г` : prodItem('weight')}
                     </InfoItem>
                    </div>
                    <div>
                     <InfoHeader>
                     К
                     </InfoHeader>
                     <InfoItem
                     style={{
                        fontSize:16,
                        fontWeight:500,
                        color:'#2C2E32'
                    }}
                     >
                     {prodItem('calories')} 
                     </InfoItem>
                    </div>
                    <div>
                     <InfoHeader>
                     Б
                     </InfoHeader>
                     <InfoItem
                     style={{
                        fontSize:16,
                        fontWeight:500,
                        color:'#2C2E32'
                    }}
                     >
                        {prodItem('proteins')}
                     </InfoItem>
                    </div>
                    <div>
                     <InfoHeader>
                     Ж
                     </InfoHeader>
                     <InfoItem
                     style={{
                        fontSize:16,
                        fontWeight:500,
                        color:'#2C2E32'
                    }}
                     >
                     {prodItem('fats')} 
                     </InfoItem>
                    </div>
                    <div>
                     <InfoHeader>
                     У
                     </InfoHeader>
                     <InfoItem
                     style={{
                        fontSize:16,
                        fontWeight:500,
                        color:'#2C2E32'
                    }}
                     >
                        {prodItem('carbo')}
                     </InfoItem>
                     
                    </div>
            </>
            }
            </MainInfoProd>
                    <div
                    style={{borderBottom:'1px solid #CECECE',display:'flex',alignItems:'center',justifyContent:'space-between',padding:16}}
                    >
                    {drawCategoryList() ? drawCategoryList()  
                    : 
                    <div>
                    </div> 
                    }
                    {productItemMod &&
                     <motion.div whileHover={{ scale: 0.9 }} style={{display:'flex',cursor:'pointer'}} onClick={deleteHandler}>
                         <div style={{color:'#EE2B00',fontSize:16,fontWeight:400,marginRight:4}}>Удалить</div>
                         <img width={24} height={24} src={Delete}/>
                    </motion.div>
                    }
                    </div>
            {productItemWithActive  ?
                    <>
                    <ProductItemMod key={nanoid()} {...{productItemWithActive,tegs}} productItemWithActive={productItemWithActive ? productItemWithActive: ''} setActions={setActions}/>
                    </>
                    :
                    ''
                    }
            {productItemMod &&
            <ManagmentProd>
                     <CauseInput>
                    <Input
                    placeholder='Введите причину отказа'
                    onChange={e=>setCause(e.target.value)}
                    value={cause}
                    />
                </CauseInput>

                <ButtonManag>
                <SendCheck whileHover={{ scale: 0.9 }} onClick={negativeHandler} >
                    Отправить на дороботку
                </SendCheck>    
                <SendGood  whileHover={{ scale: 0.9 }} onClick={positiveHandler}>
                    Одобрить
                </SendGood>
                </ButtonManag>
                
                    
            </ManagmentProd>
            }
            </ProdWrapp>
    </Wrapper>
    )
}

export default ProductItem;


const Wrapper = styled.div`
font-family: 'Roboto';
font-style: normal;
max-width: 1100px;

`
const ProdWrapp = styled.div`
display:grid;
width: 100%;
grid-template-rows: auto 1fr auto;
background: #FFFFFF;
box-shadow: 0px 4px 6px rgba(206, 206, 206, 0.25);
border-radius: 8px;  
margin-bottom:16px;
`
const InfoProdTime = styled.div`
padding: 16px;
display: flex;

`
const MainInfoProd = styled.div`
display: grid;
padding:16px;
gap:20px;
/* grid-template-columns: 1fr 2fr .7fr 90px  repeat(5,.4fr); */
grid-template-columns: .4fr .5fr .2fr 90px .2fr repeat(4,.1fr);

`
const ManagmentProd = styled.div`
display: flex;
justify-content: space-around;
margin-top: 27px;
margin-bottom: 24px;
`
const ColsProd = styled.div`   
border-bottom: 1px solid #CECECE;

`

const InfoItem = styled.div`
margin-top:19px;
text-align: start;
word-break: break-all;
`
const InfoHeader = styled.div`
font-weight: 400;
font-size: 12px;
line-height: 14px;
text-align: start;
color: #A6A6A6;
`
const CauseInput = styled.div`
width:60%;
    input{
        height: 50px;
        background: #FFFFFF;
        box-shadow: inset 0px 1px 5px rgba(116, 116, 116, 0.25);
        border-radius: 8px;
    }
`
const ButtonManag = styled.div`
display:flex;
`

const SendCheck = styled(motion.div)`
display:flex;
align-items: center;
padding-left:40px;
padding-right:40px;
justify-content: center;
border: 2px solid #7882A7;
border-radius: 8px;  
font-weight: 500;
font-size: 15px;
line-height: 130%;
letter-spacing: -0.442px;
color: #2C2E32;  
cursor: pointer;
`
const SendGood = styled(motion.div)`
display:flex;
align-items: center;
justify-content: center;
background: #7882A7;
border-radius: 8px;
padding-left: 16px;
padding-right: 16px;
font-weight: 500;
font-size: 15px;
line-height: 130%;
letter-spacing: -0.442px;
color: #FFFFFF;
margin-left: 16px;
cursor: pointer;
`
const StateProd = styled.div`
display: flex;
align-items: center;
justify-content: center;
background:${({active})=> active && '#79AD7E' };
border: ${({active})=> !active && '1px solid #000000'};
padding: 6px 16px;
border-radius: 8px;
color:${({active})=> active ? '#FFFFFF' : '#2C2E32'};
font-weight: 400;
font-size: 16px;
line-height: 130%;
letter-spacing: -0.442px;
`
// const WrapperProduct = styled.div`
//    display:grid ;
//    width: calc(100% - 30px);
// `

// const ProductListItemWrapper = styled.div`
//   display: flex;
//   height: auto;
// border-radius: 6px;
// width: 100%;

//   /* height: auto;  */

//   /* margin-bottom: 12px; */
//   box-sizing: border-box;
//   overflow: hidden;
//   flex-direction: row;
// `
// const Cols = styled.div`
//     ${PRODUCT_LIST_ELEMENT_CONTAINERMOD};
//     align-items: flex-start;
//     flex-direction: column;
//     border-radius: 6px;
//     margin-bottom: 84px;
//     width: 100%;
// `

// const MainName = styled.div`
// display: flex;
// flex-direction: column;
// `
// const ProductInfo = styled.div`
// display: flex;
//   /* border-radius: 6px; */
//   /* align-items: start; */
//   padding: 8px 12px;

//   margin-top: 10px;
//   width:100%;
//   .items{
//     div{
//         border-radius: 6px;
//     }
// }
//   /* ${({gridSetting}) => gridSetting ? {...gridSetting} : null}; */
// `
const ProductCategory = styled.div`
  display: flex;
`

// const ProductCategoryInfo = styled.div`
// border-bottom: 1px solid #DDDDDD ;
// `

const ProductCategoryWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  > div{
    margin-right: 23px;
  } 
  > div:last-child{
    margin-right: 0;
  } 
`
const MarkerProductCategory = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: linear-gradient(180deg, #3E89DF 0%, #2C79D0 100%);
box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.237602);
`

// const ImageWrapper = styled.div`
// justify-items: center;
// align-items: center;
//   > img {
//       margin-right: 6px;
//   }
//   > img:last-child{
//       margin-right: 0;
//   }
// `

// const Info = styled.div`
//     display: flex;
//     flex-direction: row;
//     height: 100px;
//     margin-top: 20px;
// `
// const InfoMOD = styled.div`
//     display: flex;
//     /* flex-direction: row; */
//     height: 100px;
//     width: auto;
//     margin-top: 40px;
// `
// const WrapperInfo = styled.div`
// display: grid;
// height: 70px;
// margin-left: 50px;
// /* width: 243px; */
// `
// const WrappKithens =styled.div`
// text-align: left;
// margin-bottom: 10px;
// `
