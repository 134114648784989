import { el } from 'date-fns/locale'
import React,{useEffect,useState,useReducer,useRef,useCallback, useLayoutEffect} from 'react'
import styled from 'styled-components'
import moment from 'moment';
import { sr } from '../../../utils';
import {deliveryCycleInfoMessage} from "../../../actions/ModalActions";
import {useDispatch, useSelector} from 'react-redux';
import { NavReports } from '../NavReports'
import { InfoItem } from './InfoItem'
import Preloader from '../../Preloader';
import Arrow from '../../../images/arrowForFin.svg'
import { isArray, uniq } from 'underscore';
import { Select, Switch } from 'antd';

const banks = [
  {id: 1, name: 'АльфаБанк'},
  {id: 2, name: 'СовКомБанк'},
  {id: 3, name: 'СберБанк'},
];
const {Option} = Select;

export const InfoList = ({
  selectInfo,setSelectInfo,info,paramsInfo,
  startDate,endDate,resState,setSelectedBank,selectedBank,setRes,
  statusFields,setStatusField,actDate,setActDate,selectedCity}) => {
  const testRefs = useRef(null)
  const downloadRefs = useRef(null)
  const [widthRef,setWidthRef] = useState('')
  const [totalsFin,setTotalFin] = useState({})
  const [totalsBetwens,setTotalBetwen] = useState({})
  const [allSelectInfo,setAllSelectInfo] = useState([])
  const [path,setPath] =useState('')
  const [results,setResults]=useState('pending')
  const [valueCheck,setValueCheck]=useState(true)
  const [groupBy,setGroupBy] = useState(false)
  const [moreGroup,setGroupByMore]=useState([])
  const dispatch = useDispatch()

  const changeHandle=(value)=>{ 
    setStatusField('resolved') 
    setSelectedBank(value) }

    useEffect(()=>{
      // console.log(selectInfo,'selectInfoselectInfoselectInfo')
    },[selectInfo])
  const handleInfo=()=>{
    if(startDate && endDate){
      return info.map(el=>{
      if(selectInfo.includes(el.id)){
          if(el.finance){
          return {[el.id]:el?.finance?.betweenCustomerContractor}
        }
      }
    }).filter(i=>i)
  }
  }

  const totalFinanceHandler=()=>{
    const a = info.reduce((acc,el)=>{
        acc.betweenCustomerContractor += +(el.finance?.betweenCustomerContractor || 0)
        acc.favorContractor += +(el.finance?.favorContractor || 0)
        acc.favorCustomer += +(el.finance?.favorCustomer || 0)
        acc.total += +(el.finance?.total || 0)
        // console.log(kek,el,'PPPPPPP')
       return acc
      }
       ,{
        betweenCustomerContractor:0,
        favorContractor:0,
        favorCustomer:0,
        total:0,
        })
          setTotalFin(a)
    }
    useEffect(()=>{
      if(resState === 'resolved'){
        totalFinanceHandler()
    }
    },[info])

    const totalsBetween=()=>{
      // console.log(groupBy)
      const kitchens = info.filter(i=>selectInfo.includes(i.id))
      const forGroup = kitchens.map(n => n.INN).reduce((acc, n, i, a) => (acc.includes(n) || (i !== a.indexOf(n) && acc.push(n)), acc), []).filter(i=>i)
      const uniqGroup = kitchens.filter(el=>forGroup.includes(el.INN))
      const uniqId =  uniqGroup.map(el=>el.id)
      const main = uniqGroup.filter((elem, index, self) => self.findIndex(
        (t) => {return (t.INN === elem.INN)}) === index)
    
      const orig = kitchens.filter(el=> !uniqId.includes(el.id))
      const mainArr = [...orig,...main]
      setGroupByMore(mainArr)
     
        const totals = kitchens.reduce((acc,el)=>{
          if(el.finance){
            acc.between += +(el.finance.betweenCustomerContractor || 0)
          }
          return acc;
        },{between:0})
         setTotalBetwen(totals)
  
    }
    useEffect(()=>{
      if(resState === 'resolved' || resState ==='getNodata'){
          totalsBetween()
    }
    },[info,selectInfo,groupBy])

    const handleSwitch=()=>{
      setGroupBy(prev=> !prev)
    }

    // const groupByEntity=()=>{

    // }

  const handeMultipleInfo=()=>{
      getXML(handleInfo())
  }

  const GetPdfActMultipe=()=>{
    let orderStatus = true
    OffsettingPeriod(startDate,endDate,selectInfo,orderStatus,1)
  }

  const GetPdfReportsMultipe=()=>{
    let orderStatus = true
    OffsettingPeriod(startDate,endDate,selectInfo,orderStatus,2)
  }

  useEffect(()=>{
    handleInfo()
  },[selectInfo])

  const OffsettingPeriod = (dateFrom, dateTo, account, orderStatus,type) => {
    // console.log(type,'TYPE');
    if (dateFrom,dateTo,actDate) {
        sr('Reports', 'ActAcceptance', {
            dateFrom: moment(dateFrom).format('YYYY-MM-DD'),
            dateTo: moment(dateTo).format('YYYY-MM-DD'),
            actDate: moment(actDate).format('YYYY-MM-DD'),
            [Array.isArray(account) ? 'accounts' : 'account']: account,
            type: type,
            ORDER_STATUS_SUCCESS_OF_DELIVERY: orderStatus
        }).then(res => {
            // console.log(res, 'ActAcceptance')
            if (res.result === 0) {
                dispatch(deliveryCycleInfoMessage('error_from_server', res.resultCode + '\n' + res.data.message))
            } else {
                setResults('finish') 
                setStatusField('resolved')
                setPath(`https://menuforme.online${res.data.FilePath}`)
                window.open("http://dev.menu4me.ru" + res.data.FilePath);
            }
            //https://menuforme.online production
            // }http://dev.menu4me.ru FOR SERGEY TESTING URL 
        })
    } else {
      dispatch(deliveryCycleInfoMessage('need_to_fill_dates'))
    }
}


const getXML = (datas)=>{
  // console.log(datas,'CONT');
  if(selectedBank && startDate && endDate){
    sr('Reports','formationOfPaymentCard',{
      accounts:selectInfo,
      idWithBettwen:Array.isArray(datas) ?datas:[datas],
      bank:selectedBank,
      dateFrom: moment(startDate).format('YYYY-MM-DD'),
      dateTo: moment(endDate).format('YYYY-MM-DD'),
      // betweenCustomerContractor:customerCont
    }).then(res=>{
      // console.log(res, 'getXML')
      if(!res.result){
        dispatch(deliveryCycleInfoMessage('error_from_server', res.resultCode + '\n' + res.data.message))
      }else{
        setPath(`http:${res.data.link}`)
        window.open(window.location.protocol+ res.data.link);
        setStatusField('resolved')
        setResults('finish')
      }
    })
  }else{
    setStatusField('rejectField')
  }
}

const handleSelect=()=>{
  setValueCheck(prev=>!prev)
  if(valueCheck === false){
    setSelectInfo([])
  }else{
    setSelectInfo(info.map(el=>el.id))   
  }
}
let mainColumn = groupBy ? moreGroup : info
  return (
    <>
    <WrapperList ref={testRefs}>
      <div style={{height:'auto',width:'auto',marginTop:50,position:'absolute',left:5}}>
        <MainCheckbox type='checkbox' value={valueCheck} onChange={handleSelect}/>
          </div>
         
      <HeaderWraps  >
          <Items style={{marginLeft:6}}>Комм.название / Юр.лицо</Items>
          <Items>Город</Items>
          <Items >ИНН + КПП / Банковские реквизиты</Items>
          <Items>№ и дата договора</Items>
          <Items>Контактное лицо</Items>
          <Items style={{marginBottom:15}}>Финансы</Items>
      </HeaderWraps>
     
      <WrappInfo >
        
      {resState === 'resolved' || resState === 'getNodata'
      ?
      mainColumn.map(el=>{
      
          if(resState === 'resolved'){
              if(+el?.finance?.total || +el?.finance?.betweenCustomerContractor  ||
                +el?.finance?.favorContractor  || +el?.finance?.favorCustomer  
                ){
                  return (
                      <>
                    <InfoItem key={el.id} {...{el,selectInfo,setSelectInfo,info,paramsInfo,OffsettingPeriod,startDate,endDate,getXML,selectedBank}}/>
                    </>
                )
              }
           }else if(resState === 'getNodata'){
                return (
                    <>
                  <InfoItem key={el.id} {...{el,selectInfo,setSelectInfo,info,paramsInfo,OffsettingPeriod,startDate,endDate,getXML,selectedBank}}/>
                  </>
                )
           }
        })
      :
      resState === 'start'
      ?
      <Preloader dotH={'20px'} dotW={'20px'} style={{height:'300px',width:'200px',paddingLeft:'50%'}}/>
      :
      ''
      }
      {resState === 'resolved' 
        ?
          <TotalFinance>
            <div style={{alignSelf:'flex-start',marginLeft:30,fontSize:'20px',fontWeight:600}} >Итого</div>
            <HeaderFinance>
              <Items2 style={{width:'20%',fontWeight:600}} >Объём продаж всего</Items2>
              <Items2 style={{width:'20%',fontWeight:600}}>Сумма исчисленная в пользу Заказчика</Items2>
              <Items2 style={{width:'20%',fontWeight:600}}>Сумма исчисленная в пользу Исполнителя</Items2>
              <Items2 style={{width:'30%',fontWeight:600}}>Разница суммы в пользу Заказчика и Исполнителя</Items2>
            </HeaderFinance>
          
            <ContentFinance>
              <Items2 style={{width:'20%',fontWeight:600}}>{totalsFin.total ? Math.floor(totalsFin.total):0 }</Items2>
              <Items2 style={{width:'20%',fontWeight:600}}>{totalsFin.favorCustomer  ? Math.floor(totalsFin.favorCustomer) : 0  }</Items2>
              <Items2 style={{width:'20%',fontWeight:600}}>{totalsFin.favorContractor ? Math.floor(totalsFin.favorContractor) : 0}</Items2>
              <Items2 style={{width:'30%',fontWeight:600}}>{totalsFin.betweenCustomerContractor ? Math.floor(totalsFin.betweenCustomerContractor) : 0 }</Items2>
            </ContentFinance>
          </TotalFinance>
          :
          ''
      }
      
            </WrappInfo>
            {selectInfo.length > 0
              ?
            <Panels>
            {selectInfo.length > 0
            ?
            <div style={{display:'flex',flexDirection:'row',marginLeft:30,}} >
              <Item onClick={GetPdfReportsMultipe}>
                Отчет
            </Item>
              <Item onClick={GetPdfActMultipe}   >
                Акт
              </Item>
              <Item onClick={handeMultipleInfo} id='save' download>
                  Платежка 
              </Item>
            </div>
            :
            ''
            }

            {selectInfo.length > 0
            ?
            <div  style={{display:'flex',flexDirection:'column',alignItems:'flex-start',marginBottom:15}} >
            <div style={{paddingRight:15,color:'#2C2E32',fontSize:16,fontWeight:400,paddingTop:5}} ><p style={statusFields==='rejectField' ? {color:'red'} :{color:'#2C2E32'}}>Выберите банк</p></div>
              <Select
            style={{width:201}}
            value={selectedBank}
            onChange={(value) => changeHandle(value)}
            >
            {banks ?
            banks?.map(v => {
                              return (
                                  <Option key={v.id} value={v.name}>
                                      {v.name}
                                  </Option>
                              )
                          })
            :
            ''
            }
            </Select>
            </div>
            :
            ''
            }
            <div style={{display:'flex',flexDirection:'column',paddingLeft:15,color:'#2C2E32',fontSize:17,fontWeight:600,alignItems:'flex-start'}} >
          <div>Количество отмеченных кухонь: 
           {
           groupBy 
           ?
           moreGroup.length > 0 ? moreGroup.length : 0 
           :
           selectInfo.length > 0 ? selectInfo.length : 0
           }
           </div>
          <div>Общая сумма: {totalsBetwens?.between ? Math.floor(totalsBetwens.between) : 0}</div>  
            </div>
          <div style={{display:'flex',color:'#2C2E32',fontSize:17,fontWeight:600,marginLeft:'auto',marginRight:30}}>
            <p style={{padding:'0px 10px 0px 10px'}}>Группировать по юр.лицу:</p>
            <Switch
            onChange={handleSwitch}
            checked={groupBy}
            />
          </div>
      </Panels>
      :
      null
      }
    </WrapperList>
      </>
  )
}

const WrapperList = styled.div`
display: grid;
grid-template-rows: .3fr auto ;
width: auto;
position: relative;
`
const Panels = styled.div`
position: fixed;
background-color:#E6E9F0;
height: auto;
width: calc(100vw - 64px);
bottom: 0px;
right: 0px;
color:#3D425E ;
display: flex;
flex-direction: row;
align-items: center;
box-shadow: 0px -10px 8px 0px rgba(34, 60, 80, 0.2);
`
const HeaderWraps = styled.div`
display: grid;
grid-template-columns:17% 10% 25% 15% 15% 20% ;
/* width: calc(100% - 215px); */
width: auto;
margin-right: 163px;
margin-left: 30px;
/* width: ${({widths})=>widths ? `calc(100% - ${widths}px)` : 'calc(100% - 215px)' };  */
/* grid-template-columns:repeat(6,auto); */

align-items: center;
margin-top:44px;
margin-bottom: 20px;
height:59px;
background: #FFFFFF; 
box-shadow: 0px 4px 6px rgba(206, 206, 206, 0.25);
border-radius: 8px;
font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 19px;
color: #2C2E32;
`
const Items = styled.p`
height: auto;
`
const WrappInfo = styled.div`
margin-top:24px;

max-height:400px;
 
`
const MainCheckbox = styled.input`
width: 18px;
height: 18px;
border: 1px solid #A4A4A4;
border-radius: 4px;
background: #E5E5E5;
`


const Item = styled.p`
margin-right: 20px;
height: 30px;
height: auto;
width: auto;
color: #FFFFFF;
padding: 10px;
border-radius: 6px;
background-color:#7882A7;
font-family: 'Roboto';
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 130%;
border:none;
/* margin-bottom: 25px; */
cursor: pointer;
  :hover{
    color:#2196F3;
  }
  :last-child{
    margin-bottom: 15px;
  }
`
const Arrows = styled.div`
  position:fixed;
  top:130px;
  right:40px;
  z-index:9999px;
  width:20px;
  height:10px;
  transform:${({scrolls})=>scrolls > 500 ?'none' :' rotate(180deg)'};
`
const TotalFinance = styled.div`
width: auto;
margin-top: 30px;
margin-left: 30px;
margin-right: 163px;
margin-bottom: 20px;
background: #FFFFFF; 
box-shadow: 0px 4px 6px rgba(206, 206, 206, 0.25);
display: flex;
flex-direction: column;
border-radius: 8px;
font-family: 'Roboto';
font-style: normal;
`
const HeaderFinance = styled.div`
display:flex;
flex-direction: row;
justify-content: space-around;
`
const ContentFinance = styled.div`
display:flex;
flex-direction: row;
justify-content: space-around;
`
const Items2 = styled.p`
height: auto;
`
const Dsss = styled.div`
position: fixed;
width: 100%;
height: 100px;
background-color: gray;
`