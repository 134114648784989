import React, {useState, useEffect} from 'react';
import '../../App.css';
import styled from "styled-components";
import DatePicker, {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ru from 'date-fns/locale/ru';
import moment from 'moment';
import _ from "underscore";
import {Select, Popover} from "antd";
import plural from "plural-ru";
import {ClockLoader} from "react-spinners";
import { sr } from '../../utils';
import {deliveryCycleInfoMessage} from "../../actions/ModalActions";
import {useDispatch, useSelector} from 'react-redux';
import NoPermissions from "../../components/NoPermissions";
import {getPermissionsAction} from "../../reducers/toolkit/mainAppReducer"

const {Option} = Select;
registerLocale('ru', ru);

const Assembly = () => {
  const [date, setDate] = useState(new Date())
  const [periods, setPeriods] = useState([])
  const [activePeriod, setActivePeriod] = useState('1')
  const [rests, setRests] = useState([])
  const [activeRest, setActiveRest] = useState(null)
  const [couriers, setCouriers] = useState([])
  const [orders, setOrders] = useState([])
  const [filteredOrders, setFilteredOrders] = useState([])
  const [checkedOrdersToPrint, setCheckedOrdersToPrint] = useState([])
  const [ordersToPrint, setOrdersToPrint] = useState([])
  const [showSpinner, setShowSpinner] = useState(false);
  const [count, setCount] = useState(0);
  const dispatch = useDispatch();
  const getPermissionsSuccess = useSelector(store => store.appMain.getPermissionsSuccess);
  const permissions = useSelector(store => store.appMain.permissions);
  const SelectedKitchens = useSelector(store => store.appMain.selectedKitchens)

  const orderStatus = {
    'new': {str: 'Новый', color: '#afffd9'},
    'printed': {str: 'Напечатан', color: '#fffa9d'},
    'assembled': {str: 'Собран', color: '#6085ff'},
    'dismissed': {str: 'Отменен', color: '#ff7c7c'},
  }
  const filterStatus = {
    new: 'new',
    printed: 'printed',
    ready: 'assembled',
    dismissed: 'dismissed',
  }
  const [selectedFilter, setSelectedFilter] = useState(filterStatus.new);

  useEffect(() => {
    setCheckedOrdersToPrint([])
    setOrdersToPrint([])
  }, [selectedFilter]);

  useEffect(() => {
    if (!getPermissionsSuccess) {
      dispatch(getPermissionsAction())
    }
  }, [])

  useEffect(() => {
    if (checkRole('screen_assembly')) {
      getAccounts();
    }
  }, [permissions])

  useEffect(() => {
    if (count) {
      getPeriods();
    }
  }, [date, count])

  useEffect(() => {
    if (count && SelectedKitchens?.id) {
      getOrders()
    }
  }, [activePeriod, SelectedKitchens])

  useEffect(() => {
    if (rests.length) {
      setActiveRest(_.some(rests, rest => rest.id === '224725') ? '224725' : rests[0].id)
    }
  }, [rests.length])

  useEffect(() => {
    setCheckedOrdersToPrint([])
    setOrdersToPrint([])
    let filtered
    switch (selectedFilter) {
      case filterStatus.ready:
        filtered = orders.filter(el => el.priceResult !== '0.00' && el.packedStatus === filterStatus.ready)
        break
      case filterStatus.dismissed:
        filtered = orders.filter(el => el.priceResult === '0.00')
        break
      default:
        filtered = orders.filter(el => el.priceResult !== '0.00' && (el.packedStatus === filterStatus.new || el.packedStatus === filterStatus.printed))
    }
    setFilteredOrders(filtered.sort((a) => a.packedStatus !== 'new' ? 1 : -1))
  }, [selectedFilter, orders])

 

  const getAccounts = () => {
    sr('AppSOKitchenService', 'GetRestaurantsForOperator', {}).then(res => {
      setRests(res.data.items || []);
      setCount(1);
    })
  }

  const getPeriods = () => {
    let dateString = moment(date).format("YYYY-MM-DD");

    sr("SettingsService", "GetOperatorPeriods", {
      date: dateString
    }).then(res => {
      if (res.result) {
        setPeriods(res.data.periods)

        if (activeRest) {
          getOrders()
        }
      }
    })
  }

  const getOrders = (afterPack = false, newPackStatus = false) => {
    setShowSpinner(true)
    let dateString = moment(date).format("YYYY-MM-DD");
    sr('AppSOKitchenService', 'GetDataForDate', {
      date: dateString,
      periodId: activePeriod,
      accountsIds: [SelectedKitchens.id],
      receiveDeletedOrders: true,
    }).then((res) => {
      if (res.data.couriers) {
        setCouriers(res.data.couriers)
      }
      if (res.data.orders) {
        let orders = res.data.orders.filter(v => v.accountId !== '146923');
        let sortedByDate = _.sortBy(orders, order => moment(order.updated).valueOf());
        let sortedByPacked = _.sortBy(sortedByDate, order => order.packed);
        setOrders(sortedByPacked);

      }

      setCheckedOrdersToPrint([]);
      setShowSpinner(false)

      if (afterPack) {
        if (newPackStatus) {
          dispatch(deliveryCycleInfoMessage('packed'))
        } else {
          dispatch(deliveryCycleInfoMessage('unpacked'))
        }
      }
    })
  }

  const packToggle = (event, id, status, updatedTime) => {
    event.stopPropagation()
    setOrdersToPrint([])
    setShowSpinner(true)

    sr('AppSOKitchenService', 'SetPackedStatus', {
      orderId: id,
      status,
      updatedTime
    }).then((res) => {
      if (res.result) {
        getOrders();
      }
    })
  }

  const changeContract = (value) => {
    setActiveRest(value)
  }

  const receiptPrint = (event, orderIds) => {
    event.stopPropagation()
    let dateString = moment(date).format("YYYY-MM-DD");
    sr("AppSOKitchenService", "CreateRestaurantPdf", {
      date: dateString,
      account: SelectedKitchens.id,
      periodId: activePeriod,
      orderIds: orderIds,
      status: filterStatus.printed,
    }).then((res) => {
      getOrders()
      setOrdersToPrint([])
      window.open(res.data.links.checkList)
    })
  }

  const selectOrderToPrint = (orderId, updatedTime, packedStatus) => {
    if (checkedOrdersToPrint.includes(orderId)) {
      setCheckedOrdersToPrint(prevState => {
        let index = prevState.indexOf(orderId);
        return prevState.filter((_, i) => i !== index)
      })
      setOrdersToPrint(prevState => prevState.filter(el => el.orderId !== orderId))
    } else {
      setCheckedOrdersToPrint(prevState => [...prevState, orderId])
      setOrdersToPrint(prevState => [...prevState, {updatedTime, orderId, packedStatus}])
    }
  }

  const selectAll = () => {
    setOrdersToPrint(filteredOrders.filter(el => {
      if(el.packedStatus === 'new' || el.packedStatus === 'printed') {
        return 1
      } return -1
    }).map(el => ({orderId: el.id, updatedTime: el.updated, packedStatus: el.packedStatus})))
    setCheckedOrdersToPrint(filteredOrders.filter(el => {
      if(el.packedStatus === 'new' || el.packedStatus === 'printed') {
        return 1
      } return -1
    }).map(el => el.id))
  }

  const checkRole = (param) => {
    if (permissions.staff) {
      if (permissions.staff.role === 'admin' && !permissions.permissions.length) {
        return true
      } else {
        return permissions.permissions.includes(param)
      }
    } else {
      return false
    }
  }

  const assemblySelected = (e, orders) => {
    e.preventDefault()
    ordersToPrint.filter(el => el.packedStatus === 'printed').map(el => {
      const {updatedTime, orderId} = el
      sr('AppSOKitchenService', 'SetPackedStatus', {
        orderId,
        status: filterStatus.ready,
        updatedTime
      }).then((res) => {
        if (res.result) {
          getOrders();
        }
      })
    })
  }

  return (
    <AssemblyWrapper>
      {checkRole('screen_assembly') ? (
        <>
          {showSpinner ? (
            <Loader>
              <ClockLoader loading/>
            </Loader>
          ) : null}
          <div style={{width: 1300, margin: '0 auto'}}>
            <Header>
              <DateAndRest className="assembly_date">
                <DatePicker
                  dateFormat="E - d MMMM"
                  selected={date}
                  onChange={date => setDate(date)}
                  className="datepicker"
                  locale={ru}
                />
                {/* {rests.length ? (
                  <Select
                    style={{width: 300, marginLeft: 20}}
                    //defaultValue={_.some(rests, rest => rest.id === '224725') ? '224725' : rests[0].id}
                    value={activeRest}
                    onChange={changeContract}
                  >
                    {rests.map((v) => {
                      return (
                        <Option key={v.id} value={v.id}>
                          {v.name}
                        </Option>
                      )
                    })}
                  </Select>
                ) : null} */}
                <UpdateButton onClick={() => getOrders()}>
                  <p>Обновить</p>
                </UpdateButton>
                <PrintBlock>
                  {checkedOrdersToPrint.length ? (
                    <>
                      <PrintButton onClick={e => receiptPrint(e, ordersToPrint)}>
                        {/*.filter(el => el.packedStatus === 'new')*/}
                        <p>Печать ({ordersToPrint?.length})</p>
                      </PrintButton>
                      {/*<PrintButton onClick={e => assemblySelected(e, ordersToPrint)}>*/}
                      {/*  <p>Собрать ({ordersToPrint?.filter(el => el.packedStatus === 'printed').length})</p>*/}
                      {/*</PrintButton>*/}
                      <ClearButton onClick={() => {
                        setOrdersToPrint([])
                        setCheckedOrdersToPrint([])
                      }}>
                        <p>Очистить выделение</p>
                      </ClearButton>
                    </>
                  ) : null}
                  {orders.length ? (
                    <CheckAllButton onClick={selectAll}>
                      <p>Выбрать все</p>
                    </CheckAllButton>
                  ) : null}
                </PrintBlock>
              </DateAndRest>
              <Periods>
                {Array.isArray(periods) && periods.length !== 0 && periods.map((v, i) => {
                  return (
                    <button key={i} style={{
                      width: 124,
                      height: 27,
                      marginRight: 5,
                      fontWeight: activePeriod === v.id ? 'bold' : 'normal',
                      paddingLeft: 3,
                      paddingRight: 3,
                    }} onClick={() => setActivePeriod(v.id)}>
                      {'с ' + v.timeFrom.slice(0, -3) + ' до ' + v.timeTo.slice(0, -3)}
                    </button>
                  )
                })}
              </Periods>
              <Filters>
                <FilterButton selected={selectedFilter} btnDest={filterStatus.new}
                              onClick={() => setSelectedFilter(filterStatus.new)}>Новые</FilterButton>
                <FilterButton selected={selectedFilter} btnDest={filterStatus.ready}
                              onClick={() => setSelectedFilter(filterStatus.ready)}>Готовые</FilterButton>
                <FilterButton selected={selectedFilter} btnDest={filterStatus.dismissed}
                              onClick={() => setSelectedFilter(filterStatus.dismissed)}>Отмененные</FilterButton>
              </Filters>
            </Header>
          </div>
          <Container>
            {filteredOrders?.length ? (
              <Orders>
                {filteredOrders.map((v, i) => {
                  return (
                    <Order
                      status={v.packedStatus}
                      key={i}
                      style={{
                        borderColor: v.priceResult === '0.00' || v.status === '536870912'
                          ? 'red'
                          : '#ffffff',
                        backgroundColor: checkedOrdersToPrint.includes(v.id) && '#3d425e'
                      }}
                      onClick={() => {
                        selectOrderToPrint(v.id, v.updated, v.packedStatus)
                      }}
                    >
                      <OrderHeader>
                        <OrderNumber selected={checkedOrdersToPrint.includes(v.id)}
                                     style={{fontSize: 24, fontWeight: '700'}}>
                          <TextMarker courierIsSet={v.courierId}>
                            {v?.courierId && couriers.length && couriers.find(el => el.clientId === v.courierId).name.split('_')[0] + "-"}{v.companyId}
                          </TextMarker>
                        </OrderNumber>
                        <OrderStatus color={orderStatus[v.packedStatus].color}>
                          {orderStatus[v.packedStatus].str}
                        </OrderStatus>
                      </OrderHeader>
                      <OrderNumber selected={checkedOrdersToPrint.includes(v.id)}>
                        № <b>{v.id}</b> от {moment(v.created).format('DD.MM.YYYY HH:mm')}
                      </OrderNumber>
                      {v.products ? (
                        <Popover
                          placement="bottomLeft"
                          title="Список товаров"
                          trigger="click"
                          content={
                            <div>
                              {v.products.map((v, i) => {
                                return <p key={i}>{v.name} - {v.amount} шт.</p>
                              })}
                            </div>
                          }
                        >
                          <OrderCounter selected={checkedOrdersToPrint.includes(v.id)}
                                        onClick={e => e.stopPropagation()}>
                            {`${plural(v.products.length, '%d товар', '%d товара', '%d товаров')} на сумму ${+v.priceResult}₽`}
                          </OrderCounter>
                        </Popover>
                      ) : (
                        <OrderCounter selected={checkedOrdersToPrint.includes(v.id)}>
                          0 товаров
                        </OrderCounter>
                      )}
                      {checkRole('method_assembly') && (
                        v.packedStatus === filterStatus.printed && (
                          <OrderButtons>
                            <PackButton
                              style={{backgroundColor: '#ffffff'}}
                              onClick={(e) => packToggle(e, v.id, filterStatus.ready, v.updated)}
                            >
                              <p>Собрать</p>
                            </PackButton>
                          </OrderButtons>
                        )
                      )}
                      {checkRole('method_assembly') && (
                        v.packedStatus === filterStatus.ready && (
                          <OrderButtons>
                            <PackButton
                              style={{backgroundColor: '#ffffff'}}
                              onClick={(e) => packToggle(e, v.id, filterStatus.new, v.updated)}
                            >
                              <p>Разобрать</p>
                            </PackButton>
                          </OrderButtons>
                        )
                      )}
                    </Order>
                  )
                })}
              </Orders>
            ) : (
              <p style={{paddingTop: 143, textAlign: 'center'}}>Нет результатов для выбранного периода</p>
            )}
          </Container>
        </>
      ) : (
        getPermissionsSuccess && (
          <NoPermissions/>
        )
      )}
    </AssemblyWrapper>
  )
}

const AssemblyWrapper = styled.div`
  position: relative;
  top: 60px;
  left: 0;
  width: 100%;
  min-height: calc(100vh - 60px);
  background-color: #F4F5F9;
  font-family: "Roboto", sans-serif;
  text-align: left;
`;

const Loader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.5);
`;

const Container = styled.div`
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 20px 30px;
`;

const Header = styled.div`
  position: fixed;
  width: 1300px;
  top: 64px;
  margin: 0 auto;
  padding: 30px;
  background-color: #F4F5F9;
`;

const DateAndRest = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const UpdateButton = styled.div`
  width: 100px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #3d425e;
  color: #fff;
  margin-left: 20px;
  border-radius: 4px;
  cursor: pointer;
`;

const ClearButton = styled(UpdateButton)`
  width: 160px;
`;

const CheckAllButton = styled(UpdateButton)`
  width: 110px;
`;

const PrintButton = styled(UpdateButton)`

`;

const PrintBlock = styled.div`
  display: flex;
  margin-left: auto;
`;

const Periods = styled.div`
  display: flex;
  height: 27px;
`;

const Filters = styled.div`
  display: flex;
  padding: 10px 0;
  gap: 10px;
`

const FilterButton = styled.button`
  background: ${({selected, btnDest}) => selected === btnDest && '#3d425e'};
  color: ${({selected, btnDest}) => selected === btnDest && '#fff'};
  padding: 3px 5px;
  font-size: 18px;
  border: 1px solid black;
  border-radius: 5px;
  cursor: pointer;

`

const Orders = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-top: 143px;
`;

const Order = styled.div`
  width: calc((100% - 90px) / 4);
  height: 172px;
  margin-right: 30px;
  padding: 10px 15px;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.15);
  border-width: 2px;
  border-style: solid;
  cursor: ${({status}) => status === 'new' && 'pointer'};
  background: ${({status}) => status === 'printed' ? '#bed2ff' : '#fff'};

  @media (min-width: 1280px) {
    &:nth-child(4n) {
      margin-right: 0;
    }

    &:nth-child(n+5) {
      margin-top: 20px;
    }
  }

  @media (min-width: 992px) and (max-width: 1279.99px) {
    width: calc((100% - 60px) / 3);

    &:nth-child(3n) {
      margin-right: 0;
    }

    &:nth-child(n+4) {
      margin-top: 20px;
    }
  }

  @media (max-width: 991.99px) {
    width: calc((100% - 30px) / 2);

    &:nth-child(2n) {
      margin-right: 0;
    }

    &:nth-child(n+3) {
      margin-top: 20px;
    }
  }
`;

const OrderHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const RestInfo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const RestLogo = styled.img`
  width: 25px;
  height: 25px;
  margin-right: 10px;
`;

const RestName = styled.p`
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const PrintMark = styled.img`
  height: 25px;
  margin-left: auto;
`;

const OrderNumber = styled.p`
  margin-bottom: 5px;
  color: ${({selected}) => selected ? '#FFFFFF' : '#333333'};
`;
const TextMarker = styled.span`
  padding: 3px 2px;
  border-radius: 6px;
  background: ${({courierIsSet}) => !courierIsSet && "#ff7676"};
  color: ${({courierIsSet}) => !courierIsSet && "#fff"};
`

const OrderStatus = styled.div`
  padding: 5px;
  border-radius: 8px;
  background: ${({color}) => color};
`

const OrderCounter = styled.p`
  display: inline-block;
  margin-bottom: 15px;
  color: ${({selected}) => selected ? '#FFFFFF' : '#333333'};
  cursor: pointer;
`;

const OrderButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  & p {
    font-size: 24px;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Chrome/Safari/Opera */
    -khtml-user-select: none; /* Konqueror */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                 not supported by any browser */
  }
`;

const ReceiptButton = styled.div`
  width: calc((100% - 15px) * 0.4);
  height: 50px;
  background-color: #D1D3E0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:active {
    opacity: 0.75;
  }
`;

const PackButton = styled.div`
  width: calc((100% - 15px) * 0.6);
  height: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;

  &:active {
    opacity: 0.75;
  }
`;

export default Assembly;

