import React, {useEffect} from "react";
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux"
import CourierOnLineListItem from "../ListItemsComponents/CourierOnLineListItem"
import {gridLayout} from "../CourierSideBar"
import moment from "moment"
import {setTotalData} from "../../../reducers/toolkit/orderMapReducer"
import {CouriersDetailsFlag, Point, Speedometer} from "../../../images/svgs"
import {nanoid} from "nanoid"

const getSecondsFromHours = timeStr => {
  return moment(timeStr).diff('seconds') || null
}
  const CouriersOnLineList = ({showCourierDetails}) => {
  const dispatch = useDispatch()
  const couriers = useSelector(store => store.orderMap.couriers)
  // console.log(couriers,'courierscourierscourierscouriers')
  useEffect(() => {
    //чтобы не бегать десять раз по одному массиву для подсчета статистики, считаем все в одном месте
    if(couriers.length > 0) {
      const totals = couriers.reduce((acc, el) =>{
        // get total orders counters
        if(el.distance !== undefined){
        acc.totalOrders[0] = el.ordersCount + acc.totalOrders[0]
        acc.totalOrders[1] = (el.finishOrdersCount || 0) + acc.totalOrders[1]
        //get total times
        if(acc.time[0] === null || getSecondsFromHours(el.timeToStart) < getSecondsFromHours(acc.time[0])) {
          acc.time[0] = el.timeToStart && el.timeToStart.split(' ')[1]
        }
        if(acc.time[1] === null || getSecondsFromHours(el.timeToFinish) > getSecondsFromHours(acc.time[1])) {
          acc.time[1] = el.timeToStart && el.timeToFinish.split(' ')[1]
        }
        //get total distance
        acc.distance = acc.distance ? acc.distance + +el.distance : +el.distance
        //get total sum
        acc.sum = acc.sum ? acc.sum + +el.sumPrice : +el.sumPrice
        //get total salary
         acc.salary = acc.salary ? acc.salary + +el.salary : +el.salary
        //get total cash
        acc.cash = acc.cash ? acc.cash + +el.sumPriceResultCash : +el.sumPriceResultCash
        return acc
        }else{
          acc.totalOrders[0] = 0
        acc.totalOrders[1] = 0
        //get total times
        if(acc.time[0] === null || getSecondsFromHours(el.timeToStart) < getSecondsFromHours(acc.time[0])) {
          acc.time[0] = 0
        }
        if(acc.time[1] === null || getSecondsFromHours(el.timeToFinish) > getSecondsFromHours(acc.time[1])) {
          acc.time[1] = 0
        }
        //get total distance
        acc.distance = acc.distance ? acc.distance + +el.distance : 0
        //get total sum
        acc.sum = acc.sum ? acc.sum + +el.sumPrice : 0
        //get total salary
         acc.salary = acc.salary ? acc.salary + +el.salary : 0
        //get total cash
        acc.cash = acc.cash ? acc.cash + +el.sumPriceResultCash : 0
        return acc
        }
      }, {
        totalOrders: [0, 0],
        time: [null, null]
      })
      // console.log(totals,'TOTALS')
      dispatch(setTotalData(totals))
    }
  }, [couriers]);
  // console.log(couriers,'showCourierDetails')
  return couriers.length > 0 && (
    <OnLineListWrapper>
      {/*хедер списка с общей статистикой*/}
      <Header showDetails={showCourierDetails}>
        <p>Имя
          <span style={{marginLeft:'7px'}}>Кол-во курьеров: {couriers.length}</span>
        </p> 
        {
          showCourierDetails ? (
            <>
              <p>
                <Speedometer />
                км
              </p>
              <p>Сумма</p>
              <p>Нал.</p>
              <p>Зар.</p>
              
            </>
          ) : (
            
            <>
            
              <p>
                <Point />
                Точки
              </p>
              
              <p>
                <CouriersDetailsFlag />
                Старт-Финиш
              </p>
              
            </>
          )
        }
      </Header>
      {
        couriers.map((courierData, id) => <CourierOnLineListItem key={nanoid()} {...{courierData, id, showCourierDetails}}/>)
      }
    </OnLineListWrapper>
  )
}

export default CouriersOnLineList;

const OnLineListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 10px;
`

const Header = styled(gridLayout())`
  >p{
    font-size: 13px;
    line-height: 15px;
    margin: 0;
    color: #999999;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    
    @media (max-width: 480px) {
      font-size: 8px;
      line-height: 11px;
    }
  }
  p:nth-child(1){
    margin-right: auto;
    text-align: ${({showDetails}) => showDetails ? 'center' : 'left'};
  }
`
