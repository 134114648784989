// import "../../../../wdyr";
import axios from 'axios'
import React, { memo } from 'react'
import { useEffect,useState,useMemo } from 'react'
import styled from 'styled-components'
import Photo from '../../../../images/assort/Ассортимент/Img_load-box_light.svg'
import CloseIco from '../../../../images/close.svg'

 const ImageComponents = (props) => {
   function sendModal(flags,medias){
       props.ActualVersion({flag:flags,medias:medias})
   }
  const memoStyle = useMemo(()=>{
    return props.style
  },[props.style])
   return (
    <>
    {
      props.src  && props.src.src && props.src.src !== '0' && props.src !== '0'
      ?
      <>
    <div style={{position:'relative',display:'flex'}}>
    {props.withBorder || props.newPhoto ? 
    <div style={{border:props.border ? '3px solid #FAF5EA' :'8px solid #E8D49D',borderRadius:'8px'}}>
     <img
     className='JOPA'
     src={props.src.src} 
     style={{...memoStyle,objectFit:'cover'}}
     onClick={props.ActualVersion && props.flag? ()=>sendModal(props.flag.flag,props.id.id): null}
     />
     </div>
    :
      <img
    loading='lazy'
    src={props.src.src} 
    style={{...memoStyle,objectFit:'cover'}}
    onClick={props.ActualVersion && props.flag? ()=>sendModal(props.flag.flag,props.id.id): null}
    />
    }
    {
      props.withBorder 
      ? 
      <NewImg>
        <TextNew>NEW</TextNew>
      </NewImg>
      :
      ''
    }
    {props.open && props.enabled === '0' || props.open && !props.enabled ?
    <Close onClick={props.clear}>
      <img src={CloseIco} style={{height:10,width:10}}/>
    </Close>
    :
    ''
    }
    </div>
     </>
      :
   <NoImage>
     <ImgItem src={Photo}
     />
  </NoImage>

    }
    
    </>
  )
}
export default memo(ImageComponents)


const ImgItem = styled.img`
height: 40px;
width: 40px;
`
const NoImage = styled.div`
display: flex;
align-items: center;
justify-content: center;
height: 90px;
width: 90px;
background: #E6E9F0;
border-radius: 6px;
cursor: pointer;

`
const Close = styled.div`
display: flex;
align-items: center;
justify-content: center;
height: 20px;
width: 20px;
background-color:#F3E9CE;
position: absolute;
top:-5px;
right:-9px;
border-radius: 100%;
cursor:pointer;
  :hover{
    transform: rotate(180deg);
    transition: .4s cubic-bezier(0.645, 0.045, 0.355, 1); 
  }
`
const NewImg = styled.div`
display: flex;
align-items: center;
justify-content: center;
height: 26px;
width: 45px;
border-radius: 6px;
position: absolute;
top:-5px;
right:-20px;
background-color:#F3E9CE;
`
const TextNew = styled.p`
  font-family: 'Roboto';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 16px;

color: #111111;

`