import React,{useState,useEffect} from 'react'
import { useSelector,useDispatch} from 'react-redux';
import styled from "styled-components";
import { Aside } from '../../components/Moderation/Aside';
import { Content } from '../../components/Moderation/Content';
import HeaderMod from '../../components/Moderation/HeaderMod';
import { getPermissionsAction } from '../../reducers/toolkit/mainAppReducer';

function Moderations() {
  const getPermissionsSuccess = useSelector(store => store.appMain.getPermissionsSuccess);
  const permissions = useSelector(store => store.appMain.permissions);
  const dispatch = useDispatch()

//   const checkRole = (param) => {
//     if (permissions.staff) {
//         if (permissions.staff.role === 'admin' && !permissions.permissions.length) {
//             return true
//         } else {
//             return permissions.permissions.includes(param)
//         }
//     } else {
//         return false
//     }
// }
// useEffect(() => {
//   if (!getPermissionsSuccess) {
//       dispatch(getPermissionsAction())
//   }
// }, [])
// useEffect(()=>{
//   console.log(getPermissionsSuccess,'PERM')
// },[permissions])
  return (
    <MainWrapper>
      <Container >
      <HeaderMod {...{}}/>
      <Content {...{}}
      />
      </Container>
      
    </MainWrapper>
  )
}

export default Moderations

const MainWrapper = styled.div`
  position: relative;
  `
const Container = styled.div`
  display: grid;
  justify-content: center;
  grid-template-areas: 
      "header"
      "content";
  grid-template-rows: auto 1fr;
  background: #E5E5E5;
  min-height: 100vh;
`
