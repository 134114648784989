import React,{useEffect,useState} from "react";
import { useSelector,useDispatch} from "react-redux";
import {Select} from "antd";
import styled from "styled-components";
import {getKithensStarted } from "../../actions/AssortmentAction";
import { saveKithensId } from "../../actions/AssortmentAction";


const RestSelector = ({setActiveRest, activeRest}) => {
    const accessibleKitchens = useSelector(store => store.assortment.accessibleKitchens);
    const selectKithens = useSelector(store => store.assortment.selectKithens)
    const SelectedKitchens = useSelector(store => store.appMain.selectedKitchens)

    const dispatch = useDispatch()
    const {Option} = Select;
    // console.log(selectKithens,'SELECT KITCHENS')

    useEffect(() => {
        dispatch(getKithensStarted())
        // console.log(selectKithens,'asdsadasds')
    }, [])

    function active(value){
     setActiveRest(SelectedKitchens.id)
     dispatch(saveKithensId(value))
    }

    return (
        <>
        <SelectorWrapper>
        {/* <TextKithens>Выбор кухни</TextKithens>
            <Select
                style={{width: '100%'}}
                value={activeRest}
                onChange={(value)=>active(value)}
            >
                {accessibleKitchens?.map((v) => {
                    return (
                        <Option key={v.id} value={v.id}>
                            {v?.name}
                        </Option>
                    )
                })}
            </Select> */}
        </SelectorWrapper>    
        </> 
    )
}

export default RestSelector;

const SelectorWrapper = styled.div`
  width: 100%;
  padding: 20px;
`
const TextKithens = styled.p`
display: flex;
align-items: flex-end;
`