import React, {useState,useEffect, memo,useRef} from "react";
import styled from "styled-components";
import {CONTAINER_PADDING, GIRD_CONSISTENCY_SETTINGS} from "./commonStyles";
import {deleteSelectedProducts, modalInfo, toggleToSelectAllProducts} from "../../actions/AssortmentAction";
import {useDispatch, useSelector} from "react-redux";
import CollapsibleBlock from "./common/CollapsibleBlock";
import trashBin from '../../images/Group.svg'

const HeaderMain = ({setAddProductFieldShow, setAddCategoryFieldShow,
                     setSelectAll, productsInSelectedCategory,scrollsRef, setOpenModal,refForAddProd}) => {

    const dispatch = useDispatch()
    const selectAllToggle = useSelector(store => store.assortment.selectAllToggle)
    const selectCat = useSelector(store => store.assortment.selectedCategory)
    const selectedCategory = useSelector(store => store.assortment.selectedCategory)
  const adminMode = useSelector(store=>store.assortment.ModerationMode)
    const cat = useSelector(store => store.assortment.categoryList)
    const prodMod = useSelector(store=>store.assortment.prodMod)
    const productList = useSelector(store=>store.assortment.productList)
    const [productName,setProductName] = useState('')
    const [countMod,setCountMod] = useState([])
    const [countProd,setCountProd] = useState([])
    const statusSaveNew = useSelector((store) => store.assortment.saveProduct);
    const [allProducts, setAllProducts] = useState([]);

    const SelectedKitchens = useSelector(store => store.appMain.selectedKitchens)
    const openModal = useSelector(
      (store) => store.assortment.modalInfo
    );
    const deleteHandle = () => {
        dispatch(deleteSelectedProducts())
    }
    const toggleAllHandle = () => {
        dispatch(toggleToSelectAllProducts(productsInSelectedCategory))
        setSelectAll(prev => !prev)
    }
    // console.log(cat,'setaddcat')
    
    function getName(){
      if(cat.length > 1 && cat && selectCat ){
     const a = cat.find(item=>item.id === selectCat.id)
        setProductName(a?.name?.ru)
      }if(!selectCat){
        setProductName('На проверке')
      }
    }
    useEffect(()=>{
      setCountMod(prodMod)
    },[prodMod])


    const countProdHandler = () =>{
      const count = allProducts?.map(el=>{
        if(+el?.categoryId === +selectedCategory?.id){
            return true
        }
      })
      setCountProd(count)
    }
    function gets() {
      // setProdStatus('pending')
      const plNew = productList
        .map((el) => {
          if (String(el?.categoryId) === selectedCategory?.id) {
            if (el?.historyModeration && el?.historyModeration?.length > 0) {
              if (el?.id === false) {
                // console.log('COUNT'
                return { ...el?.historyModeration.slice(-1)[0], isMOD: true };
              } else {
                if (el?.isEnabled === "1") {
                  return { ...el?.historyModeration.slice(-1)[0], isEnabled: "1" };
                } else {
                  return { ...el?.historyModeration.slice(-1)[0], isEnabled: "0" };
                }
              }
            } else {
              return el;
            }
          }
        })
        .filter((i) => i);
        setAllProducts(plNew);
    }

    useEffect(() => {
      if(!adminMode){
      if (productList.length > 0) {
        gets();
      }
    }
    }, [productList, selectedCategory,statusSaveNew]);
    useEffect(()=>{
      countProdHandler()
    },[productList,allProducts])
    
    useEffect(() => {
      if(cat.length > 1 && cat && selectCat){
      getName()
      }else{
        getName()
      }
      // console.log(setAddProductFieldShow(),'setAddProductFieldShow')
    }, [selectCat])
    const ProductHandle = () =>{
      setAddProductFieldShow(true)
      // setOpenModal(true)
      dispatch(modalInfo(true))
      if(scrollsRef.current.scrollTop > 100){
        scrollsRef.current.scrollTo(0,0)
      }
      // console.log(window.scrl,'SCROLL')
    }     

    return (
        <HeaderWrapper>
            <InnerWrapper>
                <Controls>
                  <ProductName>
                    <p>{adminMode ? productName : countProd?.length > 0 && countProd?.includes(true) ? productName : null }</p>
                  </ProductName>
                  {SelectedKitchens.name !== 'Не выбрано'  && selectedCategory?.id && !adminMode && countProd?.length > 0 && countProd?.includes(true) 
                   &&
                    <button ref={refForAddProd} onClick={ProductHandle}><span>+</span> Добавить товар</button>
                  }
                  </Controls>
                {
                  adminMode ?
                  <Headers>
                  <ColumnsHeaders>
                         <div style={{textAlign:'start',paddingLeft:24}}>
                           Название
                          </div> 
                          <div style={{textAlign:'start'}} >Состав</div>
                          <div style={{textAlign:'start'}}>Цена, ₽</div>
                      <div style={{textAlign:'start'}}>Фото</div>
                      <div style={{textAlign:'center'}}>Вес, г</div>
                     <div style={{textAlign:'start'}}>
                     К / Б / Ж / У
                     </div> 
                  </ColumnsHeaders>
              </Headers> 
              :
                SelectedKitchens.name !== 'Не выбрано' && selectedCategory?.id  &&  countProd?.length > 0 && countProd?.includes(true) &&
                <Headers>
                    <ColumnsHeaders>
                           <div style={{textAlign:'start',paddingLeft:24}}>
                             Название
                            </div> 
                            <div style={{textAlign:'start'}} >Состав</div>
                            <div style={{textAlign:'start'}}>Цена, ₽</div>
                        <div style={{textAlign:'start'}}>Фото</div>
                        <div style={{textAlign:'center'}}>Вес, г</div>
                       <div style={{textAlign:'start'}}>
                       К / Б / Ж / У
                       </div> 
                    </ColumnsHeaders>
                </Headers>           
                }     
            </InnerWrapper>
        </HeaderWrapper>
    )
}

export default memo(HeaderMain);

const HeaderWrapper = styled.header`
  color: #999999;
  grid-area: header;
  display: flex;
  align-items: flex-end;
  margin-bottom: 16px;
  width: calc(100% - 10px);
  /* width: calc(100vw - 376px); */
  /* max-width: 1400px; */
  /* padding: 10px 188px 10px 10px; */
`
const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`
const Controls = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  
  > button{
    cursor: pointer;
    padding: 14px 20px;
    background: #7882A7;
    border-radius: 8px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    border: none;
    color: #FFFFFF;
    
    :hover{
      background: #606690;
    }
    :active{
      background: #858ec8;
    }
  }
`
const Headers = styled.div`
  display: flex;
  align-items: center;
 
`
const ColumnsHeaders = styled.div`
font-family: Roboto;
  font-style: normal;
  line-height: 19px;
  display: grid;
  grid-template-columns:1.2fr 1.2fr .7fr 90px 1fr 1fr .6fr;
  align-items: center;
  width: 100% ;
  height: 59px;
  padding: 8px 12px;
  background: #7882A7;
  box-shadow: 0px 4px 6px rgba(206, 206, 206, 0.25);
  border-radius: 8px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF
`
const DeleteBtn = styled.button`
    margin-left: 20px;
    padding: 4px 18px;
    background: #FFFFFF;
    border: 1px solid #DDDDDD;
    box-sizing: border-box;
    border-radius: 6px;
    
    :hover {
      background: #e5e5e5;
      border: 1px solid #606690;
    }
    :active{
      background: #ffffff;
    }
`
const SelectMarker = styled.div`
  /* margin: 2px auto; */
  width: 18px;
  height: 18px;
  cursor:pointer;
  
  .selectMarker {
    height: 100%;
    border-radius: 6px;
    border: 1px solid #999999;
    transition: all .2s ease;
  }
  .selectMarker[data-isselect="true"] {
    background: #3bdb92;
    border: 1px solid #1cdb46;
    border-radius: 50%;
  }
`
const ProductName = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #161616;
`