import React,{useEffect, useState} from 'react'
import { Link, useLocation } from 'react-router-dom';
import styled from "styled-components";

const LinksUI = ({navs,testPath}) => {
    const [sel,setSel]=useState('')
    const location = useLocation()
    // console.log(navs,'NAVS')
    const forQueryParam = () => location.search  ? sel.to === location.pathname.slice(0,-1) : sel.to === location.pathname
    useEffect(()=>{
        setSel(navs)
    },[])
  return (
  <WrapperLinks key={navs.id} sel = {forQueryParam()} onClick={()=>setSel(navs)}>
        <Link to={navs.to}>{navs.text}</Link>
  </WrapperLinks>
  )
}

export default LinksUI
const WrapperLinks = styled.div`
margin-right: 19px;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  letter-spacing: -0.442px;
  
  padding: 5px 7px;
  background:${({sel})=>sel ?'#7882A7':'none'}  ;
  border-radius: 8px;
    :hover{
      font-weight: 400;
      font-size: 16px;
      background: #7882A7;
      border-radius: 8px;
      a{
        color:#FFFFFF;  
      }
    }
  a{
    color:${({sel})=>sel ?'#FFFFFF':'#2C2E32'};
  }
`
