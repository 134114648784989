import {
    GET_PRODUCT_LIST_BY_RECIPIENT,
    GET_PRODUCT_LIST_BY_RECIPIENT_ERROR,
    GET_PRODUCT_LIST_BY_RECIPIENT_READY
} from "../../actions/WarehouseActions"

const initState = {
    productList: [],
    errorMessage: ''
}

export const _debounceProductListReducer = (state = initState, action) => {
    switch (action.type) {
        case GET_PRODUCT_LIST_BY_RECIPIENT:
            return state
        case GET_PRODUCT_LIST_BY_RECIPIENT_READY:
            return {
                ...state,
                productList: action.payload
            }
        case GET_PRODUCT_LIST_BY_RECIPIENT_ERROR:
            return {
                ...state,
                errorMessage: action.payload
            }
        default:
            return state
    }
}